import { gql } from 'apollo-boost';

export const CMS_CREATE_EVENT = gql`
    mutation createEvent(
        $name: String!
        $logoId: ID!
        $backgroundId: ID!
        $animationId: ID!
        $loop: Int!
        $logoStatus: Boolean!
        $backgroundStatus: Boolean!
        $animationStatus: Boolean!){
        createEvent(data:{
            logoId:$logoId,
            backgroundId:$backgroundId,
            animationId:$animationId,
            name:$name
            animationStatus:$animationStatus,
            backgroundStatus: $backgroundStatus,
            logoStatus: $logoStatus
            loop: $loop
        }){
            _id
            name
            status
            animationId
            logoId
            backgroundId
            logo{
                _id
                uri
            }
            background{
                _id
                uri
            }
            animation{
                _id
                uri
            }
        }
    }
`;

export const CMS_GET_EVENT = gql`
    query getEvent($_id: ID!){
        cmsGetEvent(_id:$_id){
            _id
            name
            status
            loop
            logoStatus
            backgroundStatus
            animationStatus
            animation{
                _id
                uri
            }
            logo{
                _id
                uri
            }
            background{
                _id
                uri
            }
        }
    }
`;

export const CMS_UPDATE_EVENT = gql`
    mutation cmsUpdateEvent($_id: ID!, $data: EventUpdateDataInput){
        updateEvent(_id:$_id, data:$data)
    }
`;

export const CMS_DELETE_EVENT = gql`
    mutation cmsDeleteEvent($_id: ID!){
        cmsDeleteEvent(_id: $_id)
    }
`;

export const CMS_GET_LIST_EVENT = gql`
    query cmsGetListEvent($limit: Int!, $page: Int, $filter: FilterListEvent, $sortBy: EventSortBy){
        cmsListEvent(limit:$limit, page: $page, filter: $filter, sortBy: $sortBy){
            counter
            items{
                _id
                name
                status
                loop
                logoStatus
                backgroundStatus
                animationStatus
                logo{
                    _id
                    uri
                }
            }
        }
    }
`
