import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { Form, Layout, Table, Button, Breadcrumb, Skeleton, Input, Row, Col, Modal, Pagination, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import {
  GET_USERS,
  CMS_GET_USER_POINT_HISTORY_BY_DATE,
  CMS_GET_USER_DETAIL_POINT_HISTORY,
  ASSIGN_BADGE,
  GET_BADGES,
} from '../../graphql/user/users';
import {
  GetUsers,
  GetUsersVariables,
  UserSortByType,
  SortByDirection,
  cmsAssignBadge,
  cmsAssignBadgeVariables,
  cmsUserListBadges,
  cmsUserListBadgesVariables,
  UserGroup,
  TermStatus,
  Gender,
} from '../../graphql/types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import styles from '../../assets/less/app.less';
import { ToastError } from '../../components/Toast';
import { isNullOrUndefined } from 'util';
import { ApolloError } from 'apollo-boost';
import UserDetail from './components/userDetail';

const { dateByFormat } = require('../../helpers/date');
const { dateTypes } = require('../../configs/dateConfig');
const { Search } = Input;
const { Option } = Select;
const { Content } = Layout;

const trustedButton = require('../../assets/images/trustedButton.svg');
const trustedRoundButton = require('../../assets/images/trustedRoundButton.svg');

interface IEditProps extends FormComponentProps {
  match?: any;
  history?: any;
}

const ModalPointSimpleDays = props => {
  const { date, user } = props;
  const [pageParams, setPageParams] = useState({ limit: 10, page: 1 });
  const [counter, setCounter] = useState(0);
  const getUserDetailPointHistory = () => {
    return useQuery(CMS_GET_USER_DETAIL_POINT_HISTORY, {
      fetchPolicy: 'cache-and-network',
      variables: {
        userId: user._id,
        limit: pageParams.limit,
        page: pageParams.page,
        filterDate: date,
      },
    });
  };

  let { data, refetch } = getUserDetailPointHistory();
  useEffect(() => {
    if (data) {
      setCounter(data.getEarnedPointHistories.counter);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      userId: user._id,
      limit: pageParams.limit,
      page: pageParams.page,
      filterDate: date,
    });
  }, [pageParams]);

  const columns = [
    {
      title: 'The reason',
      key: 'reason',
      render: row => {
        return (
          <div>
            {row.trustedPointConfigs[0] && row.trustedPointConfigs[0].description}
            <i>
              {row.additionalData &&
              row.additionalData.walletExchangeHistory &&
              row.additionalData.walletExchangeHistory.hash
                ? ` (${row.additionalData.walletExchangeHistory.hash})`
                : ''}
            </i>
          </div>
        );
      },
    },
    {
      title: `${process.env.REACT_APP_PROJECT_NAME} Point gained`,
      dataIndex: 'earnedPoints',
    },
    {
      title: 'Date',
      key: 'earnedAt',
      render: row => {
        return <>{row.earnedAt && dateByFormat(row.earnedAt)}</>;
      },
    },
  ];

  const onChangePage = page => {
    setPageParams({ ...pageParams, page });
  };
  return (
    <>
      <Table
        rowKey="earnedAt"
        columns={columns}
        dataSource={data ? data.getEarnedPointHistories.items : []}
        onChange={() => {}}
        pagination={false}
      />
      <Pagination
        defaultCurrent={pageParams.page}
        total={counter}
        defaultPageSize={pageParams.limit}
        onChange={onChangePage}
      />
    </>
  );
};

const ModalPointByDays = props => {
  const { user } = props;
  const [showModal, setShowModal] = useState(false);
  const [date, setDate] = useState<any>();
  const [pageParams] = useState({ limit: 10000, page: 1 });
  const getUserPointHistory = () => {
    return useQuery(CMS_GET_USER_POINT_HISTORY_BY_DATE, {
      variables: {
        userId: user._id,
        limit: pageParams.limit,
        page: pageParams.page,
        groupByEarnedAt: true,
      },
    });
  };

  const { data } = getUserPointHistory();
  const columns = [
    {
      title: 'Date',
      key: 'earnedAt',
      render: row => {
        return <div>{row.earnedAt && dateByFormat(row.earnedAt, dateTypes.displayType2)}</div>;
      },
    },
    {
      title: 'Number of changes',
      dataIndex: 'changedCount',
    },
    {
      title: `${process.env.REACT_APP_PROJECT_NAME} Point gained`,
      dataIndex: 'earnedPoints',
    },
    {
      title: 'Detail',
      key: 'detail',
      render: row => {
        return (
          <>
            <img src={trustedButton} alt="" onClick={() => toggleShow(row)} />
          </>
        );
      },
    },
  ];

  const toggleShow = dateDetal => {
    if (!showModal) {
      setDate(dateDetal);
    }
    setShowModal(!showModal);
  };
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <Table
        rowKey="earnedAt"
        columns={columns}
        dataSource={data ? data.getEarnedPointHistories.items : []}
        onChange={() => {}}
        footer={() => (
          <div>
            <div>
              <strong>Total: </strong>
              {user.earnedPoints}
            </div>
            <div>
              <strong>Active point: </strong>
              {user.point}
            </div>
          </div>
        )}
      />
      {showModal && date && (
        <Modal
          width={1024}
          visible={showModal}
          title={
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <span>
                <strong>Date: </strong>
                {date && dateByFormat(date?.earnedAt, dateTypes.displayType2)}
              </span>
              <span>
                <strong>Total point: </strong>
                {date && date?.earnedPoints}
              </span>
            </div>
          }
          onOk={toggleShow}
          onCancel={toggleShow}
          footer=""
        >
          <ModalPointSimpleDays date={date?.earnedAt} user={user} />
        </Modal>
      )}
    </>
  );
};

const UserFormImpl = (props: IEditProps) => {
  // const { getFieldDecorator } = props.form;
  const { history } = props;
  const { params } = props.match;
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [showBadgeModal, setShowBadgeModal] = useState(false);
  const badgeModalRef = useRef(null);
  const [userParams, setUserParams] = useState({
    limit: 10,
    page: 1,
    group: null,
    termStatus: null,
    gender: null,
    badgeId: 'all',
    sortBy: {
      type: UserSortByType.CREATED_AT,
      dir: SortByDirection.DESC,
    },
  });
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [counter, setCounter] = useState(0);
  const [userPopup, setUserPopup] = useState({
    fullName: null,
    phoneNumber: null,
    email: null,
    gender: null,
    group: null,
    termStatus: null,
    badge: null,
    createdAt: null,
    dateOfBirth: null,
    interestingTopicKeys: null,
  });
  const [isShow, setIsShow] = useState(false);

  const [badges, setBadges] = useState([]);

  let { data: badgeData } = useQuery<cmsUserListBadges, cmsUserListBadgesVariables>(GET_BADGES, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 100,
      page: 1,
      q: '',
    },
  });

  useEffect(() => {
    if (badgeData && badgeData.cmsListBadges.items && badgeData.cmsListBadges.counter > 0) {
      setBadges([
        { key: 'ALL', value: 'all' },
        { key: 'None', value: null },
        ...badgeData.cmsListBadges.items.map(item => ({ key: item.title, value: item._id })),
      ]);
    } else {
      setBadges([]);
    }
  }, [badgeData]);

  const groups = ['ALL', UserGroup.ADMIN, UserGroup.MEMBER, UserGroup.PROVIDER, UserGroup.STAFF];
  const statuses = ['ALL', TermStatus.NORMAL, TermStatus.HIDDEN, TermStatus.SUSPENDING, TermStatus.BLOCKED];
  const genders = ['ALL', Gender.MALE, Gender.FEMALE, Gender.OTHER];

  const moveToUseDetail = userID => {
    history.push({
      pathname: '/users/' + userID + '/edit',
      previousPage: userParams.page,
    });
  };

  const columns = [
    {
      title: 'Name',
      key: 'fullName',
      width: '25%',
      sorter: true,
      render: row => {
        return <pre>{row.fullName}</pre>;
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: false,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      sorter: false,
    },
    {
      title: 'Phone number',
      dataIndex: 'phoneNumber',
      sorter: false,
    },
    {
      title: 'Group',
      dataIndex: 'group',
      sorter: false,
    },
    {
      title: 'Status',
      dataIndex: 'termStatus',
      sorter: false,
    },
    {
      title: 'Created at',
      key: 'createdAt',
      sorter: true,
      render: row => {
        return <div className={styles.bgRed}>{dateByFormat(row.createdAt)}</div>;
      },
    },
    {
      title: `History ${process.env.REACT_APP_PROJECT_NAME}  point`,
      key: 'trustedPointHistory',
      render: row => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Button
              className="trusted-point"
              // type="primary"
              onClick={() => toggleModal(row)}
            >
              <div>
                <span>{row.point}</span>
                <img src={trustedRoundButton} alt={`${process.env.REACT_APP_PROJECT_NAME} round icon`} />
              </div>
            </Button>
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: row => {
        return (
          <div>
            <Button type="default" className="" icon="eye" size="small" onClick={e => showUserModal(row)} />

            <Link to={'/users/' + row._id + '/edit'}>
              <Button
                className="btn-rectangle"
                type="primary"
                icon="edit"
                size="small"
                onClick={() => moveToUseDetail(row._id)}
                style={{ marginLeft: 5 }}
              />
            </Link>
          </div>
        );
      },
    },
    {
      title: 'Badge',
      key: '',
      render: row => {
        let badge = 'None';
        if (!isNullOrUndefined(row) && !isNullOrUndefined(row.badge)) {
          badge = row.badge.title;
        }
        return (
          <Button size="small" className="btn-rectangle" type={'default'} onClick={() => toggleBadgeModal(row)}>
            <div>{badge}</div>
          </Button>
        );
      },
      sorter: false,
    },
  ];

  const getUser = () => {
    let variables;
    if (params && params.type) {
      variables = {
        query: query,
        limit: userParams.limit,
        page: userParams.page,
        termStatus: params.type,
        group: userParams.group,
        gender: userParams.gender,
        badgeId: userParams.badgeId,
        sortBy: userParams.sortBy,
      };
    } else {
      variables = {
        query: query,
        limit: userParams.limit,
        page: userParams.page,
        termStatus: userParams.termStatus,
        group: userParams.group,
        gender: userParams.gender,
        badgeId: userParams.badgeId,
        sortBy: userParams.sortBy,
      };
    }
    if (userParams.badgeId === 'all') delete variables.badgeId;

    return useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
      fetchPolicy: 'network-only',
      variables: variables,
    });
  };

  let { data, error, loading, refetch } = getUser();

  const [dynamicColumns, setDynamicColumns] = useState(columns);
  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter && sorter.columnKey) {
      if (sorter.order) {
        let columnKey = sorter.columnKey;
        let order = sorter.order == 'ascend' ? SortByDirection.ASC : SortByDirection.DESC;

        if (sorter.columnKey == 'createdAt' || sorter.columnKey == 'fullName') {
          let c = dynamicColumns.map(item => {
            if (item.key == columnKey) {
              return { ...item, sortOrder: sorter.order };
            }
            return { ...item, sortOrder: false };
          });
          setDynamicColumns(c);
          // update data
          if (columnKey == 'createdAt') {
            setUserParams({ ...userParams, sortBy: { type: UserSortByType.CREATED_AT, dir: order } });
          } else if (columnKey == 'fullName') {
            setUserParams({ ...userParams, sortBy: { type: UserSortByType.FULL_NAME, dir: order } });
          }
        }
      } else {
        if (sorter.columnKey == 'createdAt' || sorter.columnKey == 'fullName') {
          let c = dynamicColumns.map(item => {
            return { ...item, sortOrder: false };
          });
          setDynamicColumns(c);
          setUserParams({
            ...userParams,
            sortBy: {
              type: UserSortByType.CREATED_AT,
              dir: SortByDirection.DESC,
            },
          });
        }
      }
    }
  };

  const showUserModal = row => {
    setIsShow(true);
    setUserPopup(row);
  };

  const close = () => {
    setIsShow(false);
  };

  const search = q => {
    setQuery(q);
    setUserParams({ ...userParams, page: 1 });
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (data && data.cmsGetUsers.items && data.cmsGetUsers.items.length) {
      setUsers(data.cmsGetUsers.items);
      setCounter(data.cmsGetUsers.counter);
    } else {
      setUsers([]);
      setCounter(0);
    }
  }, [data]);

  const toggleModal = (_user: Object) => {
    if (!showModal) {
      setUser(_user);
    }
    setShowModal(!showModal);
  };
  const toggleBadgeModal = (_user: Object) => {
    if (!showBadgeModal) {
      setUser(_user);
    }
    setShowBadgeModal(!showBadgeModal);
  };

  const handleUpdateBadge = () => {
    if (badgeModalRef && badgeModalRef.current) {
      if (badgeModalRef.current.setUserBadge) {
        badgeModalRef.current.setUserBadge();
      }
    }
  };

  const onChangePage = page => {
    setUserParams({ ...userParams, page: page });
  };

  const onShowSizeChange = (current, pageSize) => {
    let cOffset = counter / pageSize;
    let cOffsetUpward = Math.ceil(cOffset);
    let nOffset = userParams.page;
    if (cOffsetUpward < userParams.page) {
      nOffset = cOffsetUpward;
    }

    setUserParams({ ...userParams, limit: pageSize, page: nOffset });
  };

  const changeGroup = group => {
    setUserParams({
      ...userParams,
      group: group !== 'ALL' ? group : undefined,
    });
  };

  const changeStatus = status => {
    setUserParams({
      ...userParams,
      termStatus: status !== 'ALL' ? status : undefined,
    });
  };

  const changeBadge = badge => {
    let badgeFilter;

    if (badge === 'No badge') {
      badgeFilter = null;
    } else if (badge !== 'ALL') {
      badgeFilter = badge;
    }

    setUserParams({
      ...userParams,
      badgeId: badgeFilter,
    });
  };

  const changeGender = gender => {
    setUserParams({
      ...userParams,
      gender: gender !== 'ALL' ? gender : undefined,
    });
  };

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>User</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row gutter={10} className="mt-10 mb-10">
          <Col span={12}>
            <Form.Item label="Search">
              <Search placeholder="Enter name" enterButton="Search" onSearch={value => search(value)} />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Group">
              <Select
                style={{ width: '100%' }}
                defaultValue={groups[0]}
                placeholder="Select group to filter"
                onChange={e => changeGroup(e)}
              >
                {groups.map((r, i) => {
                  return (
                    <Option key={i} value={r}>
                      {r}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Status">
              <Select
                style={{ width: '100%' }}
                defaultValue={statuses[0]}
                placeholder="Select status to filter"
                onChange={e => changeStatus(e)}
              >
                {statuses.map((r, i) => {
                  return (
                    <Option key={i} value={r}>
                      {r}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Badge">
              <Select
                style={{ width: '100%' }}
                defaultValue={(badges[0] && badges[0].value) || ''}
                value={userParams.badgeId}
                placeholder="Select badge to filter"
                onChange={e => changeBadge(e)}
              >
                {badges.map((r, i) => {
                  return (
                    <Option key={i} value={r.value}>
                      {r.key}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Gender">
              <Select
                style={{ width: '100%' }}
                defaultValue={genders[0]}
                placeholder="Select gender to filter"
                onChange={e => changeGender(e)}
              >
                {genders.map((r, i) => {
                  return (
                    <Option key={i} value={r}>
                      {r}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetUsers && data.cmsGetUsers.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}>
            {' '}
          </Skeleton>
        ) : (
          <Row
            style={{
              backgroundColor: 'white',
            }}
          >
            <Table
              rowKey="_id"
              columns={dynamicColumns}
              dataSource={users}
              scroll={{ x: 'calc(700px + 50%)', y: 0 }}
              pagination={false}
              onChange={onTableChange}
            />

            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={userParams.page}
              total={counter}
              defaultPageSize={userParams.limit}
              onChange={onChangePage}
              style={{
                marginTop: 20,
                right: 0,
              }}
            />
          </Row>
        )}
        {showModal && user && (
          <Modal
            width={1024}
            title={
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div>
                  <strong>Name :</strong>
                  {user.fullName}
                </div>
                <div>
                  <strong>Email :</strong>
                  {user.email}
                </div>
                <div>
                  <strong>Phone :</strong>
                  {user.phoneNumber}
                </div>
              </div>
            }
            visible={showModal}
            onOk={toggleModal}
            onCancel={toggleModal}
            footer=""
          >
            <ModalPointByDays user={user} />
          </Modal>
        )}

        {isShow && userPopup && (
          <Modal
            width={512}
            visible={true}
            okText={'Close'}
            onOk={() => close()}
            onCancel={() => close()}
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <UserDetail
              fullName={userPopup.fullName}
              phoneNumber={userPopup.phoneNumber}
              email={userPopup.email}
              gender={userPopup.gender}
              group={userPopup.group}
              termStatus={userPopup.termStatus}
              badge={userPopup.badge}
              createdAt={userPopup.createdAt}
              dateOfBirth={userPopup.dateOfBirth}
              interestingTopicKeys={userPopup.interestingTopicKeys}
            />
          </Modal>
        )}
        {showBadgeModal && (
          <Modal width={1024} visible={showBadgeModal} onOk={handleUpdateBadge} onCancel={toggleBadgeModal}>
            <ModalBadge
              user={user}
              onDone={() => {
                setShowBadgeModal(false);
                refetch({ ...params });
              }}
              ref={badgeModalRef}
            />
          </Modal>
        )}
      </Content>
    </Styled.Container>
  );
};

const UserPage = Form.create()(UserFormImpl);
export default UserPage;

interface IBadgeProps {
  user: any;
  onDone: () => void;
}

const ModalBadge = React.forwardRef((props: IBadgeProps, ref) => {
  const { user } = props;
  const [badges, setBadges] = useState([]);
  let badgeId = user && user.badge ? user.badge._id : '';
  const [value, setValue] = useState(badgeId);

  const [assignBadge] = useMutation<cmsAssignBadge, cmsAssignBadgeVariables>(ASSIGN_BADGE, {
    refetchQueries: ['cmsGetUsers'],
  });

  let { data } = useQuery<cmsUserListBadges, cmsUserListBadgesVariables>(GET_BADGES, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 100,
      page: 1,
      q: '',
    },
  });

  useEffect(() => {
    if (data && data.cmsListBadges.items && data.cmsListBadges.counter > 0) {
      setBadges(data.cmsListBadges.items);
    } else {
      setBadges([]);
    }
  }, [data]);

  useImperativeHandle(ref, () => ({
    setUserBadge() {
      assignBadge({
        variables: {
          userId: user._id,
          badgeId: value,
        },
      })
        .then(res => {
          if (res && res.data && res.data.cmsAssignBadge) {
            props.onDone();
          } else {
            ToastError({
              message: 'Error',
              description: 'Update error!',
            });
          }
        })
        .catch((err: ApolloError) => {
          if (err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].message) {
            return ToastError({
              message: 'Update error!',
              description: err.graphQLErrors[0].message,
            });
          }
          ToastError({
            message: 'Update error!',
            description: 'Update error!',
          });
        });
    },
  }));

  return (
    <>
      <br />
      <div>{'Badge'}</div>
      <br />

      <Select
        style={{ width: '80%' }}
        // defaultValue={value}
        value={value}
        onChange={v => {
          setValue(v);
        }}
      >
        {badges.map((item, index) => {
          return (
            <Option key={item._id} value={item._id}>
              {item.title}
            </Option>
          );
        })}
      </Select>
    </>
  );
});
