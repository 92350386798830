import React, { useEffect, useState } from 'react';
import { Breadcrumb, Form, Layout, Skeleton, Table, DatePicker, Button,Modal } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import {LoadingOutlined} from "@ant-design/icons";
import Moment from 'moment';

import Styled from '../Place/indexStyled';
import { CMS_DELETE_LUCKY_WHEEL, CMS_GET_RUNNING_LUCKY_WHEEL } from '../../graphql/luckyWheel';
import {
  cmsGetRunningLuckyWheel, deleleLuckyWheel, deleleLuckyWheelVariables,
} from '../../graphql/types';
import {dateByFormat} from "../../helpers/date";
import { Link } from 'react-router-dom';
import history from '../../history';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { confirm } = Modal;


interface Props extends FormComponentProps {
  match?: any;
}

const dateFormat = 'DD/MM/YYYY';

const LuckyWheelRunningImpl = (props: Props) => {
  const { params } = props.match;
  const [isLoading, setLoading] = useState(true);
  const [loadingLW,setLoadingLW] = useState(false);
  const [luckyRunning,setLuckyRunning] = useState([]);

  // change API
  const getLuckyWheels = () => {
    return useQuery<cmsGetRunningLuckyWheel>(CMS_GET_RUNNING_LUCKY_WHEEL, {
      fetchPolicy: 'network-only',
    });
  };
  const [deleteLuckyWheelAPI] = useMutation<deleleLuckyWheel,deleleLuckyWheelVariables>(CMS_DELETE_LUCKY_WHEEL,{
    refetchQueries:['cmsGetRunningLuckyWheel']
  });


  const onRemoveLW = ()=>{
    setLoadingLW(true);
    deleteLuckyWheelAPI(({variables:{_id:luckyRunning[0]._id}}))
      .then(()=>{history.push('/lucky-wheels');})
      .catch(()=>setLoadingLW(false))
  };

  let { data, error, loading } = getLuckyWheels();

  const   showDeleteConfirm = () => {
    confirm({
      title: 'Confirm delete running LuckyWheel?',
      content: "Are you sure you want to delete the currently running spin?",
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {onRemoveLW()},
      onCancel() {},
    });
  };


  const columns = [
    {
      title: 'No.',
      key: 'index',
      width: '5%',
      render: (text, record, index) => (index + 1),
    },
    {
      title: 'Title',
      dataIndex:'title'
    },
    {
      title: 'Start date',
      key:'startDate',
      render:(row)=>{
        return  <>{dateByFormat(row.activeDate.startDate)}</>
      }
    },
    {
      title: 'End date',
      key:'endDate',
      render:(row)=>{
        return  <>{dateByFormat(row.activeDate.endDate)}</>
      }
    },
    {
      title: 'Voucher quantity',
      dataIndex:'totalPrize'
    },
    {
      title: 'Voucher redeemed',
      dataIndex:'totalRedeemPrize'
    },
    {
      title: 'Action',
      key: 'operation',
      width: '10%',
      render: row => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}>
            <div>
              <Link to={'/lucky-wheels/edit/' + row._id}>
                <Button type="primary" icon="edit" size="small" onClick={e => {}}/>
              </Link>
            </div>
            <Button type="primary" icon="delete" size="small" onClick={showDeleteConfirm} />
            {loadingLW && <LoadingOutlined />}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  useEffect(()=>{
    if (data && data.cmsGetRunningLuckyWheel) {
      setLuckyRunning([data.cmsGetRunningLuckyWheel]);
    }
  },[data]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Lucky Wheel</Breadcrumb.Item>
          <Breadcrumb.Item>Running</Breadcrumb.Item>
        </Breadcrumb>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div>
            {params.type === 'active' &&
            <RangePicker
              defaultValue={[Moment(Moment(), dateFormat), Moment(Moment(), dateFormat)]}
              format={dateFormat}
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            }

            <Table rowKey="_id"
                   pagination={false}
                   columns={columns}
                   dataSource={luckyRunning}
            />
          </div>
        )}
      </Content>
    </Styled.Container>
  );
};

const LuckyWheelRunning = Form.create()(LuckyWheelRunningImpl);
export default LuckyWheelRunning;
