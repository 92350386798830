import React, { useState } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { cmsSetPagePinnedProduct, cmsSetPagePinnedProductVariables } from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { SET_PINNED_LIMIT } from '../../../graphql/pageVerify/pageVerify';
import { ToastError, ToastSuccess } from '../../../components/Toast';

interface IData {
  _id?: any;
  pinnedLimit?: number;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
  form: any;
}

const regexLimit = /^[-+]?[1-9]\d*$/g;

const EditPinnedProduct = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);

  const [update] = useMutation<cmsSetPagePinnedProduct, cmsSetPagePinnedProductVariables>(SET_PINNED_LIMIT, {
    refetchQueries: ['CmsGetPages'],
  });

  const handleOk = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (props.data && props.data._id) {
          const { data } = props;
          let amount = 0;
          if (values && values.limitValue) {
            amount = parseInt(values.limitValue);
          }
          setLoading(true);
          update({
            variables: {
              data: {
                pageId: data._id,
                amount: amount,
              },
            },
          })
            .catch(e => {
              ToastError({ message: 'Error', description: 'Updated error!' });
              setLoading(false);
            })
            .then((res: any) => {
              ToastSuccess({ message: 'Success', description: 'Updated success!' });
              setShow(false);
              setLoading(false);
              props.closeModal();
            });
        }
      }
    });
  };

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const { visible } = props;
  const [show, setShow] = useState(visible);
  return (
    <div>
      <Modal
        title={'Pinned product'}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Save'}
        confirmLoading={loading}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form>
              <Form.Item label={'Limit'}>
                {getFieldDecorator(`limitValue`, {
                  initialValue: props.data.pinnedLimit,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(regexLimit),
                      message: 'Vui lòng điền số lớn hơn 0!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditPinnedProduct;
