import React from 'react';
import { Form, Layout, Button, Row, Col, Breadcrumb } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import FormDetail from './components/formDetail';
import { useMutation } from 'react-apollo';
import { CountryCode, RegisterForImporter, RegisterForImporterVariables } from 'src/graphql/types';
import { REGISTER_FOR_IMPORTER } from 'src/graphql/user/users';
import { ToastError, ToastSuccess } from 'src/components/Toast';
import { countries, getDialCodeByCountryCode } from 'src/utils/countries';

const { Content } = Layout;

interface IEditProps extends FormComponentProps {
  match?: any;
  history?: any;
}

const UserFormImpl = (props: IEditProps) => {
  const [registerForImporter, { loading }] = useMutation<RegisterForImporter, RegisterForImporterVariables>(
    REGISTER_FOR_IMPORTER,
  );

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };
  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const phoneNumber = `${getDialCodeByCountryCode(values.countryCode)}${values.phoneNumber.replace(/^0/, '')}`;

        registerForImporter({
          variables: {
            data: {
              fullName: values.fullName,
              phoneNumber,
              password: values.password,
              gender: values.gender,
              countryCode: values.countryCode,
            },
          },
        })
          .then(() => {
            ToastSuccess({ message: 'Success', description: 'Create user success' });
            props.history.push('/users');
          })
          .catch(err =>
            ToastError({ message: 'Error', description: err?.graphQLErrors[0]?.message || 'Something went wrong!' }),
          );
      }
    });
  };

  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>User</Breadcrumb.Item>
          <Breadcrumb.Item>Create</Breadcrumb.Item>
        </Breadcrumb>

        <Row type="flex" justify="center">
          <Col span={12}>
            <Form {...formItemLayout} onSubmit={handleSubmit}>
              <FormDetail form={props.form} />

              <Form.Item {...buttonItemLayout}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Styled.Container>
  );
};

const UserPage = Form.create()(UserFormImpl);
export default UserPage;
