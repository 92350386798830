import { gql } from 'apollo-boost';

export const GET_ME = gql`
  query GetMe {
    me {
      _id
      email
      username
      fullName
      photoUrl
      gender
      group
      badgeStatus
      avatar {
        thumbnail
        thumbnail2x
      }
      phoneNumber
    }
  }
`;
