import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Skeleton, Spin, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { ToastError, ToastSuccess } from 'src/components/Toast';
import UploadImage from './components/Uploads';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  cmsUpdateEvent,
  cmsUpdateEventVariables,
  getEvent_cmsGetEvent,
} from 'src/graphql/types';
import { CMS_GET_EVENT, CMS_UPDATE_EVENT } from 'src/graphql/event';
import { getEvent } from 'src/graphql/types';
import { getEventVariables } from 'src/graphql/types';
import history from '../../history';

const { Content } = Layout;

interface IEditProps extends FormComponentProps {
  match?: any;
}

const EventUpdateImpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);
  const _id = props.match.params.id;
  const logoRefs = useRef(null);
  const backgroudRefs = useRef(null);
  const animationRefs = useRef(null);
  const [updateEvent, {data: dataUpload}] = useMutation<cmsUpdateEvent, cmsUpdateEventVariables>(CMS_UPDATE_EVENT);
  const [detailEvent, setDetailEvent] = useState<getEvent_cmsGetEvent>(null);
  const [logoId, setLogoId] = useState(null);
  const [isSubmit, setIsSubmit] =  useState<boolean>(false);
  const [backgroundId, setBackgroundId] = useState(null);
  const [animationId, setAnimationId] = useState(null);
  const {data, loading: fetchLoading} = useQuery<getEvent, getEventVariables>(CMS_GET_EVENT,{
    fetchPolicy: 'network-only',
    variables:{
      _id: _id
    }
  });

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  useEffect(() => {
    if( data && data.cmsGetEvent){
      setDetailEvent(data.cmsGetEvent);
      setLogoId(data.cmsGetEvent.logo?._id);
      setBackgroundId(data.cmsGetEvent.background?._id);
      setAnimationId(data.cmsGetEvent.animation?._id);
    }
  }, [data]);


  const handleSubmit = (e) => {
    e.preventDefault();
    // setLoading(true);
    setIsSubmit(true);

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof logoRefs.current.upload === 'function') {
          logoRefs.current.upload();
        }
        if (typeof backgroudRefs.current.upload === 'function') {
          backgroudRefs.current.upload();
        }
        if (typeof animationRefs.current.upload === 'function') {
          animationRefs.current.upload();
        }
      }else{
        setLoading(false);
      }
    });
  };

  const afterUploadLogo = (mediaIds: []) => {
    console.log('run afterUploadLogo', mediaIds);
    if(!mediaIds.length ){
      setIsSubmit(false);
      setLoading(false);
      ToastError({
        message: "Logo's required",
        description:'Please select logo for this event type'
      });
      return;
    }
    setLogoId(mediaIds)
  };

  const afterUploadBackground = (mediaIds: []) => {
    if(!mediaIds.length ){
      setIsSubmit(false);
      setLoading(false);
      ToastError({
        message: "Background's required",
        description:'Please select background for this event type'
      });
      return;
    }
    setBackgroundId(mediaIds)
  };

  const afterUploadAnimation = (mediaIds: []) => {
    if(!mediaIds.length ){
      setIsSubmit(false);
      setLoading(false);
      ToastError({
        message: "Animation's required",
        description:'Please select animation for this event type'
      });
      return;
    }
    setAnimationId(mediaIds)
  };

  useEffect(() => {
    if(animationId && logoId && backgroundId && isSubmit){
      props.form.validateFieldsAndScroll((err, values) => {
        console.log('values>>>>>>>>>', values);
        if (!err) {
          if (values.name.length > 100) {
            setLoading(false);
            setIsSubmit(false);
            ToastError({
              message: 'Update event error.',
              description: `The maximum length of the title is 100 characters.`,
            });
          } else {
            let data: cmsUpdateEventVariables = {
              _id: _id,
              data:{
                name: values.name,
                loop: Number(values.loop),
                logoStatus: values.logoStatus,
                backgroundStatus: values.backgroundStatus,
                animationStatus: values.animationStatus,
                logoId: logoId[0],
                backgroundId: backgroundId[0],
                animationId: animationId[0]
              }
            };
            console.log('data>>>>>>', data);
            updateEvent({variables: data});
          }
        } else {
          setLoading(false);
        }
      });
    }
  }, [logoId, backgroundId, animationId, isSubmit]);

  useEffect(() => {
    console.log('dataUpload', dataUpload);
    if (dataUpload) {
      history.push('/events');
      ToastSuccess({ message: 'Updated', description: 'Event update success' });
    }
  }, [dataUpload]);

  return <Styled.Container>
    <Content className="content voucher-section">

      <Breadcrumb>
        <Breadcrumb.Item>Event</Breadcrumb.Item>
        <Breadcrumb.Item>Update</Breadcrumb.Item>
      </Breadcrumb>

      {!detailEvent ? (<Skeleton loading={fetchLoading} paragraph={{ rows: 5 }} />):(
        <Row type="flex" justify="center">
          <Col span={ 20 }>
            <Form { ...formItemLayout } onSubmit={ handleSubmit }>
              <Form.Item label="Event name" labelCol={{ span: 4 }} wrapperCol={{ span: 10 }}>
                { getFieldDecorator('name', {
                  initialValue: detailEvent?.name,
                  rules: [
                    {
                      required: true,
                      whitespace:true,
                      message: "Please input event's name!"

                    },
                  ],
                })(<Input autoComplete="off" />) }
              </Form.Item>
              <Row>
                <Col span={8}>
                  <Form.Item label="Logo status"  >
                    { getFieldDecorator('logoStatus',{
                      initialValue: detailEvent?.logoStatus,
                      valuePropName: 'checked'
                    })(<Switch  />)}

                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Background status" >
                    { getFieldDecorator('backgroundStatus',{
                      initialValue: detailEvent?.backgroundStatus,
                      valuePropName: 'checked'
                    })(<Switch  />) }
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Animation status" >
                    { getFieldDecorator('animationStatus',{
                      initialValue: detailEvent?.animationStatus,
                      valuePropName: 'checked'
                    })(<Switch  />) }
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item label="Logo">
                    { getFieldDecorator('logo'
                    )(
                      <UploadImage
                        ref={ logoRefs }
                        images={[detailEvent?.logo]}
                        numberImage={1}
                        onChangeImage={() => setLogoId(null)}
                        onUploaded={ (data: any) => afterUploadLogo(data) } />
                    ) }
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Background">
                    { getFieldDecorator('background'
                    )(
                      <UploadImage
                        ref={ backgroudRefs }
                        images={[detailEvent?.background]}
                        numberImage={1}
                        onChangeImage={() => setBackgroundId(null)}
                        onUploaded={ (data: any) => afterUploadBackground(data) } />
                    ) }
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Animation">
                    { getFieldDecorator('animation'
                    )(
                      <UploadImage
                        ref={ animationRefs }
                        images={[detailEvent?.animation]}
                        numberImage={1}
                        onChangeImage={() => setAnimationId(null)}
                        onUploaded={ (data: any) => afterUploadAnimation(data) } />
                    ) }
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Loop(second)" labelCol={{ span: 4 }} wrapperCol={{ span: 4 }}>
                { getFieldDecorator('loop', {
                  initialValue: detailEvent?.loop.toString(),
                  rules: [
                    {
                      required: true,
                      whitespace:true,
                      message: 'Please input loop number!',

                    },
                  ],
                })(<Input type="number" min={0} />) }
              </Form.Item>


              <Form.Item { ...buttonItemLayout } >
                <Button type="primary" disabled={ loading } htmlType="submit">Update</Button>
                { loading && (<Spin indicator={ <LoadingOutlined style={ { fontSize: 24 } } spin /> } />) }
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}




    </Content>
  </Styled.Container>;
};

const EventUpdate = Form.create()(EventUpdateImpl);
export default EventUpdate;
