import React from 'react';
import { Form, Layout, Button, Row, Col, Breadcrumb } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import FormDetail from './components/formDetail';
import { UPDATE_USER_GROUP } from '../../graphql/user/users';
import { UpdateUserGroup, UpdateUserGroupVariables } from '../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { ToastSuccess } from '../../components/Toast';
import history from '../../history';

const { Content } = Layout;

interface IEditProps extends FormComponentProps {
  match?: any;
}

const PostFormImpl = (props: IEditProps) => {
  const  [updateUserGroup] = useMutation<UpdateUserGroup, UpdateUserGroupVariables>(UPDATE_USER_GROUP, {
    refetchQueries: ['GetUsers']
  });

  const { match } = props;
  const data = {
    fullName: 'fixed data',
    phoneNumber: 'fixed data',
    email: 'fixed data',
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };
  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data =  updateUserGroup({
          variables: {
            userId: match.params.id,
            group: values.group,
          }
        }).catch((e) => {
          console.log("ERROR", e);
        });
        if (data){
          history.push('/users');
          ToastSuccess({message: 'Updated', description: 'User update success'});
        }
      }
    });
  };

  return <Styled.Container>
    <Content className="content">

      <Breadcrumb>
        <Breadcrumb.Item>Post</Breadcrumb.Item>
        <Breadcrumb.Item>Edit</Breadcrumb.Item>
      </Breadcrumb>

      <Row type="flex" justify="center">
        <Col span={12}>
          <Form {...formItemLayout} onSubmit={handleSubmit}>

              <FormDetail form={props.form} defaultData={data}/>

            <Form.Item {...buttonItemLayout} >
              <Button type="primary" htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

    </Content>
  </Styled.Container>;
};

const PostUpdate = Form.create()(PostFormImpl);
export default PostUpdate;
