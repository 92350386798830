import React, { useRef, useState, useEffect } from 'react';
import { Form, Layout, Button, Row, Col, Breadcrumb, Input,
  // Spin,
  Checkbox, Select } from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import { GET_BADGE_REGISTER_FORM, UPDATE_BADGE } from '../../graphql/badge/badge';
import {
  BadgeStatus,
  cmsListBadgeRegisterForms,
  cmsListBadgeRegisterFormsVariables,
  BadgeRegisterFormSortByType,
  SortByDirection,
  cmsUpdateBadge,
  cmsUpdateBadgeVariables
} from '../../graphql/types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  ToastError,
  ToastSuccess
} from '../../components/Toast';
import UploadBadge from './component/Uploads';
import styled from 'styled-components';
import history from '../../history';

const { Content } = Layout;
const { Option } = Select;

const BadgeEditImpl = (props) => {
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);
  const [updateBadge] = useMutation<cmsUpdateBadge, cmsUpdateBadgeVariables>(UPDATE_BADGE, {
    refetchQueries: ['cmsListBadges'],
  });

  let { data } = useQuery<cmsListBadgeRegisterForms, cmsListBadgeRegisterFormsVariables>(GET_BADGE_REGISTER_FORM, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 100,
      sortBy: {
        type: BadgeRegisterFormSortByType.CREATED_AT,
        dir: SortByDirection.DESC
      }
    },
  });

  const [registerForms, serRegisterForms] = useState([]);

  useEffect(() => {
    if (data && data.cmsListBadgeRegisterForms && data.cmsListBadgeRegisterForms.counter > 0) {
      serRegisterForms(data.cmsListBadgeRegisterForms.items);
    }
  }, [data]);

  const refs = useRef(null);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err && values) {
        if (values.title.length > 50) {
          ToastError({
            message: 'Error.',
            description: `Tên huy hiệu không quá 50 ký tự!`,
          });
          setLoading(false);
          return;
        }

        if (values.title.trim() == '') {
          ToastError({
            message: 'Error.',
            description: `Tên huy hiệu không được để trống`,
          });
          setLoading(false);
          return;
        }

        if (values.titleEN.length > 50) {
          ToastError({
            message: 'Error.',
            description: `Tên huy hiệu en không quá 50 ký tự!`,
          });
          setLoading(false);
          return;
        }

        if (values.titleEN.trim() == '') {
          ToastError({
            message: 'Error.',
            description: `Tên huy hiệu en không được để trống`,
          });
          setLoading(false);
          return;
        }

        if (typeof refs.current.upload === 'function') {
          refs.current.upload();
        }
      } else {
        setLoading(false);
      }
    });
  };

  const afterUpload = (mediaIds: [string]) => {
    if (mediaIds.length <= 0) {
      ToastError({ message: "Error", description: "Please choose badge icon!" });
      setLoading(false);
      return;
    }
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err && values) {
        let mediaId = "";
        if (mediaIds.length > 0) {
          mediaId = mediaIds[0];
        }
        let editRow = null;
        if (props.location && props.location.state) {
          editRow = props.location.state;
        }
        updateBadge({
          variables: {
            _id: editRow ? editRow._id : "",
            title: values.title,
            titleEn: values.titleEN,
            status: values.status,
            mediaId: mediaId,
            registerFormIds: values.registerForm
          },
        })
          .then((res) => {
            ToastSuccess({ message: "Success", description: "Badge updated success!" });
            setLoading(false);
            history.push('/badges')
          })
          .catch((e) => {
            setLoading(false);
            ToastSuccess({ message: "Error", description: "Badge updated error!" });
          });
        // }
      } else {
        setLoading(false);
      }
    });
  };

  const onCheckBoxChange = (checkedValues) => {
    console.log('BACHK______onCheckBoxChange: ', checkedValues)
  }

  const _renderContent = () => {
    let forms = [];
    if (registerForms) {
      for (let i = 0; i < registerForms.length; i++) {
        const e = registerForms[i];
        forms.push(e);

      }
    }

    let editRow = null;
    if (props.location && props.location.state) {
      editRow = props.location.state;
    }
    let title = "";
    let titleEN = "";
    let status = BadgeStatus.DISABLED;
    let media = null;
    let registerFormIds = [];
    if (editRow) {
      title = editRow.title;
      titleEN = editRow.titleEn;
      status = editRow.status;
      media = editRow.media;
      if (editRow.registerForms.length > 0) {
        for (let i = 0; i < editRow.registerForms.length; i++) {
          const element = editRow.registerForms[i];
          registerFormIds.push(element._id);
        }
      }
    }
    return (
      <Row type="flex" justify="center">
        <Col span={12}>
          <Form
            {...formItemLayout}
            onSubmit={handleSubmit}>
            <Row type="flex">
              <Col span={18} offset={6}>
                <UploadBadge
                  ref={refs}
                  default={media && {
                    id: media._id,
                    url: media.uri
                  }}
                  onUploaded={(data: any) => afterUpload(data)} />
              </Col>
            </Row>

            <Form.Item label="Title">
              {getFieldDecorator('title', {
                initialValue: title,
                rules: [
                  {
                    required: true,
                    message: 'Please input title!',
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="TitleEN">
              {getFieldDecorator('titleEN', {
                initialValue: titleEN,
                rules: [
                  {
                    required: true,
                    message: 'Please input title en!',
                  },
                ],
              })(<Input />)}
            </Form.Item>

            <Form.Item label="Badge Status">
              {getFieldDecorator('status', {
                initialValue: status,
                rules: [
                  {
                    required: true,
                    message: 'Please select status!',
                  },
                ],
              })(
                <Select
                  style={{ width: '100%' }}
                >
                  <Option key={'enable'} value={BadgeStatus.ENABLED}>
                    {BadgeStatus.ENABLED}
                  </Option>
                  <Option key={'disable'} value={BadgeStatus.DISABLED}>
                    {BadgeStatus.DISABLED}
                  </Option>
                </Select>,
              )}
            </Form.Item>

            <Form.Item label="Register form">
              {getFieldDecorator('registerForm', {
                initialValue: registerFormIds,
                rules: [
                  {
                    required: true,
                    message: 'Please select one!',
                  },
                ],
              })(
                <Checkbox.Group
                  onChange={onCheckBoxChange}
                >
                  {forms.map((item, i) => {
                    return (
                      <div>
                        <Checkbox
                          style={{
                            marginTop: i != 0 ? 15 : 0
                          }}
                          value={item._id}>{item.detail}</Checkbox>
                      </div>
                    )
                  })}
                </Checkbox.Group>
              )}

            </Form.Item>

            <Form.Item {...buttonItemLayout} >
              <Button type="primary" disabled={loading} htmlType="submit">Submit</Button>
              {/*{loading && (<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />)}*/}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    )
  }

  return <Styled.Container>
    <Content className="content">

      <Breadcrumb>
        <Breadcrumb.Item>Badge</Breadcrumb.Item>
        <Breadcrumb.Item>Update</Breadcrumb.Item>
      </Breadcrumb>

      {_renderContent()}

    </Content>
  </Styled.Container >;
};

const BadgeEdit = Form.create()(BadgeEditImpl);
export default BadgeEdit;

const Styled = {
  Container: styled.div`
    .d-flex div{
      padding: 1rem;
      background-color: #33b5e5;
      border: 2px solid #fff;
      color: #fff;
      text-align: center;
    }
    .ant-checkbox-group-item {
      display: block;
      margin-right: 0;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #3E97F1;
      border-color: #3E97F1;
    }
    .ant-checkbox {
      margin-right: 5px;
    }
    .ant-checkbox-wrapper {
      font-size: 14px;
    }
  `,
};
