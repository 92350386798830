import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Alert, Layout, message } from 'antd';
import Helmet from 'react-helmet';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Sidebar from '../../components/Layouts/Sidebar';
import RightContent from '../../components/GlobalHeader/RightContent';
import renderRoutes from '../../router';
import { AppContext } from '../../contexts/AppContext';
import { GET_ME } from '../../graphql/user/me';
import { REFRESH_TOKEN } from '../../graphql/authentication/refreshToken';
import { GetMe, RefreshToken, RefreshTokenVariables, UserBadgeStatus, UserGroup } from '../../graphql/types';
import { AuthContext } from '../../contexts/AuthContext';
import { setToken } from '../../helpers/tokenHelpers';
import { setMe } from '../../helpers/meHelpers';
import { ToastError } from '../../components/Toast';
import VERSION from '../../version';
import { type } from 'os';

const { Content, Header, Footer } = Layout;

interface BaseProps extends RouteComponentProps {}

const StyledContainer = styled.div``;
const StyledHeader = styled(Header)`
  background-color: #fff;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  justify-content: flex-end;
`;

const App = React.memo((props: BaseProps) => {
  const _refreshToken = localStorage.getItem('_refreshToken');
  const [callRefresh, { data: reData }] = useMutation<RefreshToken, RefreshTokenVariables>(REFRESH_TOKEN);
  let isLogin = null;

  const { refetch: getMe } = useQuery<GetMe>(GET_ME, { fetchPolicy: 'network-only' });

  const context = useContext(AuthContext);

  const state = context.state;
  if (state.token) {
    isLogin = true;
    getMe()
      .catch(e => {
        console.log(e);
      })
      .then((res: any) => {
        if (res && res.data) {
          if (
            res.data.me.group === UserGroup.ADMIN ||
            res.data.me.group === UserGroup.STAFF ||
            res.data.me.badgeStatus === UserBadgeStatus.VERIFIED
          ) {
            setMe(res.data.me);
            localStorage.setItem('avatar', res.data.me?.avatar?.thumbnail);
          } else {
            ToastError({ message: 'Error', description: 'Permission Denied!' });
            localStorage.removeItem('_refreshToken');
            window.location.href = '/login';
          }
        }
      });
  } else {
    if (_refreshToken) {
      if (reData) {
        isLogin = true;
        setToken(reData.refreshToken.idToken);
        context.actions.setToken(reData.refreshToken.idToken);
      }
    } else {
      isLogin = false;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!state.token && _refreshToken) {
      callRefresh({
        variables: {
          refreshToken: _refreshToken,
        },
      })
        .catch(error => {
          console.log(error);
          localStorage.removeItem('_refreshToken');
          window.location.href = '/login';
        })
        .then((res: any) => {
          setToken(res.data.refreshToken.idToken);
          context.actions.setToken(res.data.refreshToken.idToken);
        });
    }
  }, []);

  return (
    <StyledContainer>
      <AppContext.Consumer>
        {({ collapsedSidebar }) => {
          if (isLogin === true) {
            return (
              <Layout style={{ minHeight: '100vh' }}>
                <Helmet titleTemplate={'Ecoshare Wallet'} defaultTitle={'Home'} meta={[]} />
                <Sidebar pathname={props.location.pathname} />
                <Layout style={{ paddingLeft: collapsedSidebar ? 80 : 256, position: 'relative' }}>
                  <StyledHeader style={{ paddingRight: 20, backgroundColor: '#fff' }}>
                    <RightContent />
                  </StyledHeader>
                  <Content style={{ margin: '24px', paddingTop: 70 }}>{renderRoutes(isLogin)}</Content>
                </Layout>
              </Layout>
            );
          }
          if (isLogin === false) {
            return <Content style={{ margin: '24px', paddingTop: 70 }}>{renderRoutes(isLogin)}</Content>;
          }
          return <div>Loading....</div>;
        }}
      </AppContext.Consumer>
      <Footer style={{ textAlign: 'center' }}>
        CMS by <strong>Comartek</strong> v{VERSION || '__'}
      </Footer>
    </StyledContainer>
  );
});

export default withRouter(App);
