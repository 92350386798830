import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Form, Input, Layout, Skeleton, Table, Tag, Pagination, Tooltip, Modal } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';

import { CMS_BLOCK_PAGE, CMS_GET_PAGES } from '../../graphql/pageVerify/pageVerify';
import {
  CmsBlockPage,
  CmsBlockPageVariables,
  CmsGetPages,
  CmsGetPagesVariables,
  PageSortByType,
  ReportFor,
  SortByDirection,
  TermStatus,
  VerifyStatus,
} from '../../graphql/types';
import Styled from './indexStyled';
import EditConfig from './components/editConfig';
import EditProviderNews from './components/editProviderNews';
import EditPinnedProduct from './components/editPinnedProduct';
import AdminDetail from './components/AdminDetail';

const { dateByFormat } = require('../../helpers/date');
const imgCheck = require('../../assets/images/icons/page/icon-check.svg');
const imgReject = require('../../assets/images/icons/page/icon-x.svg');
const imgPending = require('../../assets/images/icons/page/pending.svg');

const { Content } = Layout;
const { Search } = Input;

interface Props extends FormComponentProps {
  match?: any;
}

const PageImpl = (props: Props) => {
  const { params } = props.match;
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showHotNewsModal, setShowHotNewsModal] = useState(false);
  const [showPinnedModal, setShowPinnedModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [editHotNewsData, setEditHotNewsData] = useState({});
  const [editPinnedData, setEditPinnedData] = useState({});
  const [pages, setPages] = useState([]);
  const [pageParams, setPageParams] = useState({
    limit: 10,
    offset: 1,
    q: '',
    filter: {},
    sortBy: {
      type: PageSortByType.SET_TERM_STATUS_DATE,
      dir: SortByDirection.DESC,
    },
  });
  const [counter, setCounter] = useState(0);
  const [showModalAdmin, setShowModalAdmin] = useState(false);
  const [admin, setAdmin] = useState({});

  const [block] = useMutation<CmsBlockPage, CmsBlockPageVariables>(CMS_BLOCK_PAGE, { refetchQueries: ['CmsGetPages'] });

  const closeModal = () => {
    setShowModal(false);
  };

  const closeHotNewsModal = () => {
    setShowHotNewsModal(false);
  };

  const closePinnedModel = () => {
    setShowPinnedModal(false);
  };

  const showEdit = (data?, isBlock?, unBlock?) => {
    if (unBlock) {
      block({
        variables: {
          _id: data._id,
          model: ReportFor.PAGE,
          termStatus: TermStatus.NORMAL,
          reason: '',
        },
      })
        .catch(e => {
          console.log(e.toLocaleString());
        })
        .then((res: any) => {
          refetch();
          // console.log('res', res);
        });
    } else {
      setShowModal(true);
      if (data) {
        setEditData({
          _id: data._id,
          isPartner: data.isPartner,
          isBlock,
        });
      }
    }
  };

  const showHotNewsEdit = (data?) => {
    setShowHotNewsModal(true);
    if (data) {
      setEditHotNewsData({
        _id: data._id,
        isHotNews: data.isProvideNews,
      });
    }
  };

  const showPinnedProductEdit = (data?) => {
    setShowPinnedModal(true);
    if (data) {
      setEditPinnedData({
        _id: data._id,
        pinnedLimit: data.pinnedLimit,
      });
    }
  };

  const getPage = () => {
    let variables;
    variables = {
      limit: pageParams.limit,
      offset: pageParams.offset,
      q: pageParams.q,
      filter: pageParams.filter,
      sortBy: pageParams.sortBy,
    };
    return useQuery<CmsGetPages, CmsGetPagesVariables>(CMS_GET_PAGES, {
      fetchPolicy: 'network-only',
      variables: variables,
    });
  };

  const showAdminDetail = row => {
    setAdmin(row.creator);
    setShowModalAdmin(true);
  };

  const { data, error, loading, refetch } = getPage();
  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: 'Info',
      render: row => {
        return (
          <div style={{ maxWidth: '200px' }}>
            <p>
              Phone: <strong>{row.phoneNumber}</strong>
            </p>
            <p>
              Email: <strong>{row.email}</strong>
            </p>
            <p>
              Website: <strong>{row.website}</strong>
            </p>
          </div>
        );
      },
    },
    {
      title: 'Verified',
      key: 'verified',
      render: row => {
        let icon = null;
        if (row.verify) {
          switch (row.verify.status) {
            case VerifyStatus.VERIFIED:
              icon = (
                <Tooltip title="Verified">
                  <img src={imgCheck} alt="" width="30%" />
                </Tooltip>
              );
              break;
            case VerifyStatus.PENDING:
              icon = (
                <Tooltip title="Pending">
                  <img src={imgPending} alt="" width="30%" />
                </Tooltip>
              );
              break;
            case VerifyStatus.REJECTED:
              icon = (
                <Tooltip title="Rejected">
                  <img src={imgReject} alt="" width="30%" />
                </Tooltip>
              );
              break;
          }
        }
        return <div>{icon}</div>;
      },
    },
    {
      title: 'Creator',
      render: row => {
        return (
          <div className="link" onClick={() => showAdminDetail(row)}>
            {row.creator.fullName}
          </div>
        );
      },
    },
    {
      title: 'Is partner',
      dataIndex: 'isPartner',
      sorter: true,
      render: (isPartner, record) => {
        return (
          <p>
            {isPartner ? (
              <Tag onClick={() => showEdit(record)} color="#87d068">
                Yes
              </Tag>
            ) : (
              <Tag onClick={() => showEdit(record)} color="red">
                No
              </Tag>
            )}
          </p>
        );
      },
    },
    {
      title: 'Is Hot News',
      dataIndex: 'isProvideNews',
      sorter: false,
      render: (isHotNews, record) => {
        return (
          <p>
            {isHotNews ? (
              <Tag onClick={() => showHotNewsEdit(record)} color="#87d068">
                Yes
              </Tag>
            ) : (
              <Tag onClick={() => showHotNewsEdit(record)} color="red">
                No
              </Tag>
            )}
          </p>
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      sorter: true,
      render: createdAt => {
        return <>{createdAt && dateByFormat(createdAt)}</>;
      },
    },
    {
      title: 'Pinned product',
      dataIndex: '',
      sorter: false,
      render: record => {
        return (
          <Button className="btn-rectangle" type={'default'} size="small" onClick={e => showPinnedProductEdit(record)}>
            {record.pinnedLimit}
          </Button>
        );
      },
    },
    {
      title: 'Action',
      key: 'operation',
      width: 150,
      render: row => {
        return (
          <Button
            className="btn-rectangle"
            type={params.type === 'BLOCKED' ? 'default' : 'danger'}
            size="small"
            onClick={e => showEdit(row, true, params.type === 'BLOCKED')}
          >
            {params.type === 'BLOCKED' ? 'UNBLOCK' : 'BLOCK'}
          </Button>
        );
      },
    },
  ];
  if (params.type === 'BLOCKED') {
    columns.splice(4, 0, {
      title: 'Reason',
      dataIndex: 'reason.reason',
    });
  }

  const onChange = (pagination, filters, sorter, extra) => {
    let sortBy = pageParams.sortBy;
    switch (sorter.columnKey) {
      case 'name':
        sortBy = {
          type: PageSortByType.NAME,
          dir: sorter.order
            ? sorter.order === 'ascend'
              ? SortByDirection.ASC
              : SortByDirection.DESC
            : pageParams.sortBy.dir === SortByDirection.ASC
            ? SortByDirection.DESC
            : SortByDirection.ASC,
        };
        break;
      case 'type':
        sortBy = {
          type: PageSortByType.TYPE,
          dir: sorter.order
            ? sorter.order === 'ascend'
              ? SortByDirection.ASC
              : SortByDirection.DESC
            : pageParams.sortBy.dir === SortByDirection.ASC
            ? SortByDirection.DESC
            : SortByDirection.ASC,
        };
        break;
      case 'isPartner':
        sortBy = {
          type: PageSortByType.IS_PARTNER,
          dir: sorter.order
            ? sorter.order === 'ascend'
              ? SortByDirection.ASC
              : SortByDirection.DESC
            : pageParams.sortBy.dir === SortByDirection.ASC
            ? SortByDirection.DESC
            : SortByDirection.ASC,
        };
        break;
      case 'createdAt':
        sortBy = {
          type: PageSortByType.CREATED_AT,
          dir: sorter.order
            ? sorter.order === 'ascend'
              ? SortByDirection.ASC
              : SortByDirection.DESC
            : pageParams.sortBy.dir === SortByDirection.ASC
            ? SortByDirection.DESC
            : SortByDirection.ASC,
        };
        break;
      case 'verified':
        sortBy = {
          type: PageSortByType.VERIFIED,
          dir: sorter.order
            ? sorter.order === 'ascend'
              ? SortByDirection.ASC
              : SortByDirection.DESC
            : pageParams.sortBy.dir === SortByDirection.ASC
            ? SortByDirection.DESC
            : SortByDirection.ASC,
        };
        break;
    }

    setPageParams({
      ...pageParams,
      sortBy: sortBy,
    });
  };

  const search = value => {
    setPageParams({ ...pageParams, q: value, offset: 1 });
  };

  useEffect(() => {
    setPageParams({
      ...pageParams,
      filter: {
        termStatus: params && params.type ? params.type : 'NORMAL',
      },
    });

    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading, params]);

  const onChangePage = page => {
    setPageParams({ ...pageParams, offset: page });
  };

  const onShowSizeChange = (current, pageSize) => {
    let cOffset = counter / pageSize;
    let cOffsetUpward = Math.ceil(cOffset);
    let nOffset = pageParams.offset;
    if (cOffsetUpward < pageParams.offset) {
      nOffset = cOffsetUpward;
    }

    setPageParams({ ...pageParams, limit: pageSize, offset: nOffset });
  };

  useEffect(() => {
    if (data && data.cmsGetPages.items) {
      setPages(
        data.cmsGetPages.items.map(item => {
          let { reason } = item;
          if (reason && typeof reason !== 'object') {
            reason = JSON.parse(reason);
            reason = (reason && reason[0]) || '';
            item.reason = reason;
          }
          return item;
        }),
      );
      setCounter(data.cmsGetPages.counter);
    } else {
      setCounter(0);
    }
  }, [data]);

  const closeModalAdmin = () => {
    setShowModalAdmin(false);
  };

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Page</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetPages && data.cmsGetPages.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div>
            <Search
              placeholder="input search text"
              defaultValue={pageParams.q}
              onSearch={value => search(value)}
              style={{ width: 200 }}
            />
            <Table
              rowKey="_id"
              // pagination={{ pageSize: 20 }}
              columns={columns}
              dataSource={pages}
              onChange={onChange}
              scroll={{ x: 'calc(700px + 50%)', y: 0 }}
              pagination={false}
            />

            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={pageParams.offset}
              total={counter}
              defaultPageSize={pageParams.limit}
              onChange={onChangePage}
              style={{
                marginTop: 20,
                right: 0,
              }}
            />
          </div>
        )}

        {showModal ? (
          <EditConfig
            visible={showModal}
            closeModal={() => closeModal()}
            form={props.form}
            data={editData}
            refetchParent={refetch}
          />
        ) : (
          ''
        )}

        {showPinnedModal && (
          <EditPinnedProduct
            visible={showPinnedModal}
            closeModal={() => closePinnedModel()}
            form={props.form}
            data={editPinnedData}
          />
        )}

        {showHotNewsModal ? (
          <EditProviderNews
            visible={showHotNewsModal}
            closeModal={() => closeHotNewsModal()}
            form={props.form}
            data={editHotNewsData}
          />
        ) : (
          ''
        )}

        {showModalAdmin && admin && (
          <Modal
            width={768}
            title="Creator detail"
            visible={showModalAdmin}
            okText="Close"
            onOk={() => closeModalAdmin()}
            onCancel={() => closeModalAdmin()}
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            <AdminDetail admin={admin} />
          </Modal>
        )}
      </Content>
    </Styled.Container>
  );
};

const Page = Form.create()(PageImpl);
export default Page;
