import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Layout, Modal, Pagination, Row, Select, Skeleton } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { FormComponentProps } from 'antd/lib/form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Styled from './indexStyled';
import { CMS_GET_BANNERS, CMS_UPDATE_BANNER, CMS_UPDATE_BANNER_POSITIONS, CMS_DELETE_BANNER } from '../../graphql/banner/banner';
import {
  BannerPositionsInput,
  BannerStatus,
  CmsGetBanners,
  CmsGetBannersVariables,
  CmsUpdateBannerPositions,
  CmsUpdateBannerPositionsVariables,
  updateBanner,
  updateBannerVariables,
  CmsDeleteBanner,
  CmsDeleteBannerVariables,
} from '../../graphql/types';
import arrayMove from 'array-move';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
// import { getMe } from '../../helpers/meHelpers';
const { dateByFormat } = require('../../helpers/date');

const { Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;

const loadingImage = require("../../assets/images/loadingspinner.gif");
const iconEdit = require("../../assets/images/banner-edit.svg");
const iconRemove = require("../../assets/images/ic_baseline-delete-forever.svg");

let timer = null;
let timerStatus = [];
let counter = -1;

interface IEditProps extends FormComponentProps {
  match?: any;
}

const BannerImpl = (props: IEditProps) => {
  // const { params } = props.match;
  const { getFieldDecorator } = props.form;
  const [banners, setBanners] = useState([]);
  const [totalBanner, setTotalBanner] = useState(0);
  const [oPosition, setOPosition] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const bannerStatus = [BannerStatus.ENABLED, BannerStatus.DISABLED];
  const [filterParams, setFilterParams] = useState({
    limit: 10,
    page: 1,
  });

  const [updateBannerPositionsAPI] = useMutation<CmsUpdateBannerPositions, CmsUpdateBannerPositionsVariables>(CMS_UPDATE_BANNER_POSITIONS, {
    refetchQueries: ['cmsBanners'],
  });

  const [updateBannerAPI] = useMutation<updateBanner, updateBannerVariables>(CMS_UPDATE_BANNER, {
    refetchQueries: ['cmsBanners'],
  });

  const [delBannerAPI] = useMutation<CmsDeleteBanner, CmsDeleteBannerVariables>(CMS_DELETE_BANNER, {
    refetchQueries: ['cmsBanners'],
  });


  let { data, loading, error, refetch } = useQuery<CmsGetBanners, CmsGetBannersVariables>(CMS_GET_BANNERS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: filterParams.limit,
      page: filterParams.page,
    },
  });

  const onChangePage = (page) => {
    setFilterParams({ ...filterParams, page });
  };

  useEffect(() => {
    if (data && data.cmsListBanner && data.cmsListBanner.items) {
      setBanners(data.cmsListBanner.items);
      setOPosition(data.cmsListBanner.items.map(i => i.position));
      setTotalBanner(data.cmsListBanner.counter);
    }
  }, [data]);

  // const me = getMe();

  const onChangeStatus = (banner) => {
    setDataEdit(banner);
    setModalStatus(true);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) setBanners(arrayMove(banners, oldIndex, newIndex));
  };

  const actionRemoveBanner = (_id) => {
    confirm({
      title: 'Are you sure delete this banner?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk () {
        onDelBanner(_id)
      },
      onCancel () {
        console.log('Cancel');
      },
    })
  };

  const onDelBanner = (id) => {
    delBannerAPI({
      variables: { id }
    }).then(() => refetch())
  };

  const onoUpdateBannerPositions = (data: BannerPositionsInput[]) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      updateBannerPositionsAPI({
        variables: {
          data: data,
        },
      }).then(() => refetch());
    }, 500);
  };

  useEffect(() => {
    if (loading) setLoading(true);
    if (!loading) setLoading(false);
  }, [loading]);

  useEffect(() => {
    return () => { clearTimeout(timer); }
  }, []);

  useEffect(() => {
    if (counter > 0) {
      const data = banners.map((item, index) => {
        return {
          _id: item._id,
          position: oPosition[index],
        };
      });
      onoUpdateBannerPositions(data);
    } else {
      counter++;
    }
  }, [banners]);

  const handleOk = () => {
    setLoadingStatus(true);
    if (timerStatus[dataEdit._id]) {
      clearTimeout(timerStatus[dataEdit._id]);
    }
    timerStatus[dataEdit._id] = setTimeout(() => {
      updateBannerAPI({
        variables: {
          id: dataEdit._id,
          data: {
            type: dataEdit.type,
            data: {
              link: dataEdit.data.link,
              postId: dataEdit.data.postId,
              pageId: dataEdit.data.pageId,
            },
            mediaId: dataEdit.mediaId,
            startDate: dataEdit.startDate,
            endDate: dataEdit.endDate,
            name: dataEdit.name,
            status: dataEdit.status
          },
        },
      }).then(() => {
        refetch();
        setLoadingStatus(false);
        toggleModalStatus();
      }).catch(() => setLoadingStatus(false));
    }, 1000);

  };
  const toggleModalStatus = () => {
    setModalStatus(!modalStatus);
  };


  if (error) {
    console.log("error", error);
    return <Content className="content">Error</Content>;
  }

  const SortableItem = SortableElement(({ value }) => {
    return (
      <li className="banner-item text-center">
        <Row gutter={ 1 } className="">
          <Col span={ 4 } className="banner-content-item"><p>{ value._id + ' ' + value.name }</p></Col>
          <Col span={ 3 }>
            <img src={ value.media.uri } alt="" width="50%" />
          </Col>
          <Col span={ 2 } className="banner-content-item">{ value.type }</Col>
          <Col span={ 3 } className="banner-content-item">
            { value.data.link || value.data.postId || value.data.pageId || value.data.screen }
          </Col>
          <Col span={ 3 } className="banner-content-item">
            { dateByFormat(value.startDate) }
          </Col>
          <Col span={ 3 } className="banner-content-item">
            { dateByFormat(value.endDate) }
          </Col>
          <Col span={ 2 } className="banner-content-item">
            <Button className="btn-rectangle" type={ value.status === BannerStatus.ENABLED ? 'primary' : 'default' }
              size="small"
              onClick={ () => onChangeStatus(value) }>
              { value.status }
            </Button>
          </Col>
          <Col span={ 3 } className="banner-content-item ">
            <Link to={ '/banners/' + value._id + '/edit' }>
              <Button className="border-none">
                <img src={ iconEdit } alt="" />
              </Button>
            </Link>
            <Button className="border-none" size="small" onClick={ () => actionRemoveBanner(value._id) }>
              <img src={ iconRemove } alt="" />
            </Button>
          </Col>
        </Row>
      </li>
    );
  });


  const SortableList = SortableContainer(({ values }) => {
    return (
      <ul>
        <li className="banner-list-header">
          <Row className="text-center">
            <Col span={ 4 } className="banner-content-item"><p>Name</p></Col>
            <Col span={ 3 }>
              Image
            </Col>
            <Col span={ 2 } className="banner-content-item">Type</Col>
            <Col span={ 3 } className="banner-content-item">Data</Col>
            <Col span={ 3 } className="banner-content-item">
              Start date
            </Col>
            <Col span={ 3 } className="banner-content-item">
              End date
            </Col>
            <Col span={ 2 } className="banner-content-item">
              Status
            </Col>
            <Col span={ 3 } className="banner-content-item">
              Action
            </Col>
          </Row>
        </li>
        {
          values.map((item, index) => {
            return (<SortableItem key={ index } index={ index } value={ item } />);
          })
        }
      </ul>
    );
  });


  return (
    <Styled.Container>
      <Content className="content banner-section">
        <Breadcrumb>
          <Breadcrumb.Item>Banner</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mt-10 mb-10" gutter={ 16 }>
          <Col span={ 12 } offset={ 12 } style={ { textAlign: 'right' } }>
            <Link to={ '/banners/create' }>
              <Button type={ 'primary' }>Add Banner</Button>
            </Link>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsListBanner && data.cmsListBanner.counter) || 0}</strong>
        </p>

        { isLoading ? (
          <Skeleton loading={ isLoading } paragraph={ { rows: 5 } }> </Skeleton>
        ) : (
            <div>
              <SortableList values={ banners } onSortEnd={ onSortEnd } /> <br />
              <Pagination defaultCurrent={ filterParams.page } pageSize={ filterParams.limit } total={ totalBanner }
                onChange={ onChangePage } />
            </div>
          ) }

        {
          modalStatus &&
          (
            <Modal
              title={ <>
                Change banner status
                {
                  loadingStatus && <img src={ loadingImage } className="w20" alt="" />
                }
              </> }
              visible={ modalStatus }
              onOk={ handleOk }
              onCancel={ toggleModalStatus }
            >
              <div>
                <Form>
                  <Form.Item label="">
                    { getFieldDecorator('status', {
                      initialValue: dataEdit.status,
                    })(
                      <Select
                        placeholder="Select a option and change input text above"
                        onChange={ e => {
                          setDataEdit({ ...dataEdit, status: e });
                        } }
                      >
                        { bannerStatus.map((status, i) => {
                          return (
                            <Option disabled={ status === dataEdit.status } key={ i } value={ status }>
                              { status }
                            </Option>
                          );
                        }) }
                      </Select>,
                    ) }
                  </Form.Item>
                </Form>
              </div>

            </Modal>
          )
        }

      </Content>
    </Styled.Container>
  );
};

const BannerList = Form.create()(BannerImpl);
export default BannerList;
