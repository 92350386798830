import { gql } from 'apollo-boost';

export const CMS_GET_GROUPS = gql`
  query CmsSearchGroups($limit: Int!, $q: String) {
    cmsSearchGroups(limit: $limit, q: $q) {
      counter
      items {
        _id
        name
        description
        privacy
        creator {
          _id
          fullName
        }
        createdAt
        verified
        totalPosts
        totalReports
      }
    }
  }
`;

export const GET_ALL_CMS_GROUPS = gql`
  query getAllGroup($limit: Int!, $offset: Int, $filter: FilterGroup) {
    getCmsGroups(limit: $limit, offset: $offset, filter: $filter) {
      counter
      items {
        _id
        name
        creator {
          fullName
        }
        category {
          name {
            VI_VN
          }
        }
        totalMembers
      }
    }
  }
`;

export const REMOVE_CMS_GROUP = gql`
  mutation deleteGroup($id: ID!, $reason: String!) {
    deleteCmsGroup(_id: $id, reason: $reason)
  }
`;
