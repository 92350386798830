import React, {
  // useEffect,
  useState,
} from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  cmsListFailedSpinMessages_cmsListFailedSpinMessages_items,
  cmsUpdateFailedSpinMessage,
  cmsUpdateFailedSpinMessageVariables,
} from '../../../graphql/types';
import { CMS_UPDATE_FAILED_SPIN_MESSAGE } from '../../../graphql/luckyWheel';
import { useMutation } from '@apollo/react-hooks';
import { ToastError, ToastSuccess } from '../../../components/Toast';

const { TextArea } = Input;

const loadingImage = require('../../../assets/images/loadingspinner.gif');

interface PropsMessage extends FormComponentProps {
  defaultData?: any
  messageData:cmsListFailedSpinMessages_cmsListFailedSpinMessages_items
  onClose: any
  reFetchParent: any
}


const EditMessageImpl = (props) => {
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);

  const [updateMessageAPI] = useMutation<cmsUpdateFailedSpinMessage, cmsUpdateFailedSpinMessageVariables>(CMS_UPDATE_FAILED_SPIN_MESSAGE, {
    refetchQueries: ['cmsListFailedSpinMessages'],
  });

  const createVoucherCodeAPI = () => {
    setLoading(true);
    props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        setLoading(false);
      } else {
        updateMessageAPI({
          variables: {
            _id:props.messageData._id,
            data: {
              content: values.content,
              contentEn: values.contentEn,
            },
          },
        }).then(res => {
          ToastSuccess({ message: 'Success', description: 'Edit message success!' });
          props.reFetchParent();
          props.onClose();
        }).catch(()=>{
          setLoading(false);
          ToastError({
            message: 'Error',
            description: "Please try later",
          });
        })
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <Row type="flex" justify="center">
        <Col span={24}>
          <Form {...formItemLayout} onSubmit={onSubmit}>

            <Form.Item label="Message" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} style={{ width: '100%' }}>
              {getFieldDecorator('content', {
                initialValue:props.messageData.content,
                rules: [
                  {
                    required: true,
                    whitespace:true,
                    message: 'Please input title!',
                  },
                ],
              })(<TextArea rows={4}/>)}
            </Form.Item>

            <Form.Item label="Message En" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} style={{ width: '100%' }}>
              {getFieldDecorator('contentEn', {
                initialValue:props.messageData.contentEn,
                rules: [
                  {
                    required: true,
                    whitespace:true,
                    message: 'Please input title!',
                  },
                ],
              })(<TextArea rows={4}/>)}
            </Form.Item>

            <Row>
              <Col offset={20} span={4}>
                <Button type="primary" disabled={loading} htmlType="button" onClick={createVoucherCodeAPI}>Add</Button>
                {
                  loading && <img src={loadingImage} className="w20" alt=""/>
                }
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const EditMessage = Form.create<PropsMessage>()(EditMessageImpl);
export default EditMessage;
