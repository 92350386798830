import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Col, DatePicker, Form, Input, Layout, Row, Select, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { LoadingOutlined } from '@ant-design/icons';
import Styled from './indexStyled';
import moment from 'moment';
import { UPDATE_VOUCHER, VOUCHER } from '../../graphql/voucher/voucher';
import {
  GetVoucher,
  GetVoucherVariables,
  UpdateVoucher,
  UpdateVoucherVariables,
  VoucherType,
} from '../../graphql/types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import history from '../../history';
import { ToastError, ToastSuccess } from '../../components/Toast';
import UploadVoucher from './compoments/Uploads';

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IEditProps extends FormComponentProps {
  match?: any;
}

const VoucherImpl = (props: IEditProps) => {
  const _id = props.match.params.id;
  const { getFieldDecorator } = props.form;
  const [type, setType] = useState(VoucherType.REFERRAL);
  const [loading, setLoading] = useState(false);
  const { data: voucher } = useQuery<GetVoucher, GetVoucherVariables>(VOUCHER, {
    variables: {
      _id: _id,
    },
    fetchPolicy: 'network-only'
  });

  const [updateVoucher] = useMutation<UpdateVoucher, UpdateVoucherVariables>(UPDATE_VOUCHER, {
    refetchQueries: ['cmsVouchers'],
  });

  const refs = useRef(null);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof refs.current.upload === 'function') {
          refs.current.upload();
        }
      }else{
        setLoading(false);
      }
    });
  };

  const rangeConfig = {
    initialValue: voucher && [moment(voucher.voucher.startDate), moment(voucher.voucher.endDate)],
    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
  };

  const onChangeType = (value) => {
    setType(value);
  };

  const afterUpload = (mediaIds: []) => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (values.title.length > 100 || values.titleEn.length > 100) {
          ToastError({
            message: 'Create voucher error.',
            description: `The maximum length of the title is 100 characters.`,
          });
        } else {

          let data:any = {};
          switch (type) {
            case VoucherType.REFERRAL:{
              data = {
                _id: _id,
                title: values.title,
                titleEn: values.titleEn,
                mediaIds: mediaIds.slice(0,1),
                status:voucher.voucher.status,
                type,
                startDate: (new Date(values.dateActive[0])).getTime(),
                endDate: (new Date(values.dateActive[1])).getTime(),
                referralTurns:parseInt(values.referralTurns)
              };
              break;
            }
            default:{
              data = {
                _id: _id,
                title: values.title,
                titleEn: values.titleEn,
                mediaIds: mediaIds,
                highlight: values.highlight,
                highlightEn: values.highlightEn,
                termAndCondition: values.termAndCondition,
                termAndConditionEn: values.termAndConditionEn,
                contact: { phoneNumber: values.phoneNumber, email: values.email, address: values.address },
                description: values.description,
                descriptionEn: values.descriptionEn,
                type,
                pointSpend: type === VoucherType.POINT_EXCHANGE ? parseFloat(values.pointSpend) : 0,
                startDate: (new Date(values.dateActive[0])).getTime(),
                endDate: (new Date(values.dateActive[1])).getTime(),
              }
            }
          }

          updateVoucher({
            variables: data,
          })
          .then(()=>{
            switch (type) {
              case VoucherType.REFERRAL:
                history.push('/vouchers/referrals'); break;
              default:
                history.push('/vouchers');
            }
            ToastSuccess({ message: 'Updated', description: 'Voucher is updated success' });
            })
          .catch(() => {
            ToastError({ message: 'Error!', description: 'Please try again later' });
            setLoading(false)
          });
        }
      }else{
        setLoading(false)
      }
    });
  };

  useEffect(() => {
    if (voucher && voucher.voucher && voucher.voucher.type) {
      setType(voucher.voucher.type);
    }
  }, [voucher]);


  const disableDate = (current) => {
    return current && moment(current).isBefore(moment(),'day');
  };

  const rangeDateType = () => {
    switch (type) {
      case VoucherType.REFERRAL: {
        return <RangePicker disabledDate={ disableDate }
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm" />
      }
      default :return <RangePicker disabledDate={ disableDate } />
    }
  };

  return (
    <Styled.Container>
      <Content className="content voucher-section">
        <Breadcrumb>
          <Breadcrumb.Item>Voucher</Breadcrumb.Item>
          <Breadcrumb.Item>Update</Breadcrumb.Item>
        </Breadcrumb>
        { voucher && (
          <Row type="flex" justify="center">
            <Col span={ 12 }>
              <Form { ...formItemLayout } onSubmit={ handleSubmit }>
                <Row type="flex">
                  <Col span={ 18 } offset={ 6 }>
                    <UploadVoucher images={ voucher.voucher.medias }
                                   numberImage={type === VoucherType.REFERRAL ? 1 : 5}
                                   ref={ refs }
                                   onUploaded={ (data: any) => afterUpload(data) } />
                  </Col>
                </Row>

                <Form.Item label="Title">
                  { getFieldDecorator('title', {
                    initialValue: voucher.voucher.title,
                    rules: [
                      {
                        required: true,
                        message: 'Please input title!',
                        whitespace:true,
                      },
                    ],
                  })(<Input />) }
                </Form.Item>
                <Form.Item label="TitleEn">
                  { getFieldDecorator('titleEn', {
                    initialValue: voucher.voucher.titleEn,
                    rules: [
                      {
                        required: true,
                        message: 'Please input title!',
                        whitespace:true,
                      },
                    ],
                  })(<Input />) }
                </Form.Item>

                <Form.Item label="Type">
                  { getFieldDecorator('type', {
                    initialValue: type,
                    rules: [
                      {
                        required: true,
                        message: 'Please select one!',
                      },
                    ],
                  })(
                    <Select disabled={true} onChange={ onChangeType }  >
                      <Option value={ VoucherType.POINT_EXCHANGE }>Point transfer</Option>
                      <Option value={ VoucherType.GAME }>Game</Option>
                    </Select>
                  ) }
                </Form.Item>

                {
                  type !== VoucherType.REFERRAL &&
                  (
                    <Fragment>

                      <Form.Item label="VoucherID">
                        { getFieldDecorator('voucherId', {
                          initialValue: voucher.voucher.voucherId || '',
                        })(<Input readOnly={ true } />) }
                      </Form.Item>

                      <Form.Item label="Highlight">
                        { getFieldDecorator('highlight', {
                          initialValue: voucher.voucher.highlight || '',
                        })(<Input />) }
                      </Form.Item>
                      <Form.Item label="HighlightEn">
                        { getFieldDecorator('highlightEn', {
                          initialValue: voucher.voucher.highlightEn || '',
                        })(<Input />) }
                      </Form.Item>
                      <Form.Item label="Term and conditions">
                        { getFieldDecorator('termAndCondition', {
                          initialValue: voucher.voucher.termAndCondition || '',
                        })(<Input />) }
                      </Form.Item>
                      <Form.Item label="Term and conditions En">
                        { getFieldDecorator('termAndConditionEn', {
                          initialValue: voucher.voucher.termAndConditionEn || '',
                        })(<Input />) }
                      </Form.Item>
                      <Form.Item label="Contact">
                        <Input.Group size="large">
                          <Row gutter={ 1 }>
                            <Col span={ 6 }>
                              <Form.Item >
                                { getFieldDecorator('phoneNumber', {
                                  initialValue: voucher.voucher.contact && voucher.voucher.contact.phoneNumber || '',
                                })(<Input placeholder="Phone number" />) }
                              </Form.Item>
                            </Col>
                            <Col span={ 8 }>
                              <Form.Item >
                                { getFieldDecorator('email', {
                                  initialValue: voucher.voucher.contact && voucher.voucher.contact.email || '',
                                })(<Input placeholder="Email" />) }
                              </Form.Item>
                            </Col>
                            <Col span={ 10 }>
                              <Form.Item >
                                { getFieldDecorator('address', {
                                  initialValue: voucher.voucher.contact && voucher.voucher.contact.address || '',
                                })(<Input placeholder="Address" />) }
                              </Form.Item>
                            </Col>
                          </Row>
                        </Input.Group>
                      </Form.Item>

                      <Form.Item label="Description">
                        { getFieldDecorator('description', {
                          initialValue: voucher.voucher.description,
                          rules: [
                            {
                              required: true,
                              whitespace:true,
                              message: 'Please input description!',
                            },
                          ],
                        })(<Input />) }
                      </Form.Item>
                      <Form.Item label="DescriptionEn">
                        { getFieldDecorator('descriptionEn', {
                          initialValue: voucher.voucher.descriptionEn,
                          rules: [
                            {
                              required: true,
                              whitespace:true,
                              message: 'Please input description!',
                            },
                          ],
                        })(<Input />) }
                      </Form.Item>

                      {
                        type === VoucherType.POINT_EXCHANGE &&
                        (
                          <Form.Item label="Point Spend">
                            <Input readOnly={ true } type="number" value={ voucher.voucher.pointSpend } />
                          </Form.Item>
                        )
                      }
                    </Fragment>
                  )
                }

                <Form.Item label="Date Active">
                  { getFieldDecorator('dateActive', rangeConfig)(rangeDateType()) }
                </Form.Item>

                {
                  type === VoucherType.REFERRAL &&
                  (
                    <Form.Item label="Rule" className="referral-rule">
                      <Row gutter={ 1 }>
                        <Col span={8}>
                          <input className="referral-input" type="checkbox" id="referral-rule" defaultChecked={true} />
                          <label className="referral-label"  htmlFor="referral-rule">
                            Block x turn
                          </label>
                        </Col>
                        <Col span={16} className="d-flex justify-content-around" >
                          <label htmlFor="referralTurnsIds"> Block</label>

                          { getFieldDecorator('referralTurns', {
                            initialValue:voucher.voucher.referralTurns,
                            rules: [
                              {
                                required: type === VoucherType.REFERRAL,
                                message: 'Please input referralTurns!',
                              },
                            ],
                          })(<Input id="referralTurnsIds" disabled={true} className="referral-turn" type="number" step="1" min={ 0 }  placeholder="block"/>) }
                        </Col>
                      </Row>
                    </Form.Item>
                  )
                }


                <Form.Item { ...buttonItemLayout } >
                  <Button type="primary" disabled={ loading } htmlType="submit">Submit</Button>
                  { loading && (<Spin indicator={ <LoadingOutlined style={ { fontSize: 24 } } spin /> } />) }
                </Form.Item>
              </Form>
            </Col>
          </Row>
        ) }
      </Content>
    </Styled.Container>
  );
};

const VoucherCreate = Form.create()(VoucherImpl);
export default VoucherCreate;
