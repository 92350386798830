import React, { useState } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Col, Form, Input, Modal, Row } from 'antd';
import { useMutation } from '@apollo/react-hooks';

import { ADD_SENSITIVE_WORDS, UPDATE_SENSITIVE_WORD } from '../../../graphql/sensitiveWords/sensitiveWords';
import {
  AddSensitiveWords,
  AddSensitiveWordsVariables,
  UpdateSensitiveWord,
  UpdateSensitiveWordVariables,
} from '../../../graphql/types';

interface IData {
  _id?: any;
  key?: any;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
  form: any;
}

const EditConfig = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;
  const { visible } = props;
  const [show, setShow] = useState(visible);

  const [updateConfig] = useMutation<UpdateSensitiveWord, UpdateSensitiveWordVariables>(UPDATE_SENSITIVE_WORD, {
    refetchQueries: ['GetSensitiveWords'],
  });

  const [createConfig] = useMutation<AddSensitiveWords, AddSensitiveWordsVariables>(ADD_SENSITIVE_WORDS, {
    refetchQueries: ['GetSensitiveWords'],
  });

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (values.keyword && !values.keyword.trim()) {
          props.form.setFields({
            keyword: {
              value: values.keyword,
              errors: [new Error('Please input sensitive word(s)')],
            },
          });
          return;
        }

        let call = null;
        if (props.data && props.data._id) {
          call = updateConfig({
            variables: {
              id: props.data._id,
              keyword: values.keyword,
            },
          });
        } else {
          let arrKeywords = values.keyword.split(',').map(item => item.trim().toLowerCase());
          arrKeywords = Array.from(new Set(arrKeywords));
          arrKeywords = arrKeywords.filter(i => i != '');
          if (!arrKeywords || arrKeywords.length == 0) {
            setShow(false);
            props.closeModal();
            return;
          }
          call = createConfig({
            variables: {
              keywords: arrKeywords,
            },
          });
        }
        call
          .catch(e => {
            console.log(e.toLocaleString());
          })
          .then(() => {
            setShow(false);
            props.closeModal();
          });
      }
    });
  };

  const handleCancel = () => {
    setShow(false);
    props.closeModal();
  };

  return (
    <div>
      <Modal
        title={props.data._id ? 'Edit sensitive word' : 'Add new sensitive words'}
        visible={show}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText={'Save'}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form onSubmit={handleSubmit}>
              <Col span={24}>
                <Form.Item
                  label={props.data._id ? 'Sensitive word' : 'Add 1 or more sensitive words, separated by ","'}
                >
                  {getFieldDecorator(`keyword`, {
                    initialValue: props.data.key,
                    rules: [
                      {
                        required: true,
                        message: 'Please input sensitive word(s)',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditConfig;
