
const moment = require("moment");

export const validateRangeTimes = (campaignRange,rangeBatchs,actionType='create') => {
  let check = true;
  const now = new Date();

  // Check date1  <= date2 : true
  const compareDate  = (date1,date2) => {
    return moment(date1).isBefore(moment(date2),'minute') || moment(date1).isSame(moment(date2),'minute');
  };

  // compare start campaignRange  with now
  if(actionType === 'create' && !compareDate(now,campaignRange[0])){
    console.log("compare with now",);
    return false
  }

  // compare campaignRange with range of first batch;
  if(actionType === 'create' && !compareDate(campaignRange[0],rangeBatchs[0][0])){
    console.log("compare with  start campaignRange",);
    return false
  }

  // compare campaignRange with range of last batch;
  if(!compareDate(rangeBatchs[rangeBatchs.length -1][1],campaignRange[1])){
    console.log("compare with end campaignRange",);
    return false
  }

  // compare inside batch list
  rangeBatchs.map((item,index)=>{
      if(index < rangeBatchs.length && rangeBatchs[index+1] && !compareDate(item[1],rangeBatchs[index+1][0])){
        check = false
      }
  });
  return check;
};
