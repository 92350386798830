import React, { useState } from 'react';
import { Col, Form, Modal, Row, message, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useMutation } from '@apollo/react-hooks';
import {
  TrustedPointConfigType,
  UpdateTrustedPointConfig,
  UpdateTrustedPointConfigVariables,
} from '../../../graphql/types';
import { UPDATE_TRUSTED_POINT_CONFIG } from '../../../graphql/trustedPoint/point';
import { isNull } from 'util';

// function FormError(props) {
//   /* nếu isHidden = true, return null ngay từ đầu */
//   if (props.isHidden) { return null; }

//   return <h5 style={{ color: 'red' }}>{props.errorMessage}</h5>
// }

interface IData {
  _id?: any;
  type?: TrustedPointConfigType;
  points?: number;
  actionType?: string;
  trustedPointsFrom?: any;
  trustedPointsTo?: any;
  rankFrom?: any;
  rankTo?: any;
  engagementAdjustPercent?: any;
  postContentLength?: any;
  totalPlaceReviewsFrom?: any;
  totalPlaceReviewsTo?: any;
  newPlaceCoefficient?: any;
  unlimitedPostsPerDayWhenTrustedPointGreaterThan?: any;
  maxTenPostsPerDayWhenTrustedPointLessThan?: any;
  maxFivePostsPerDayWhenTrustedPointLessThan?: any;
  description?: string;
  adjustPointPercent?: any;
  invitedFrom?: number;
  invitedTo?: number;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  datas?: Array<IData>;
}

const regxPoint = /^(\d*\.)?\d+$/g;
const regxRange = /^[1-9][0-9]*$/g;

const AddOrEditConfigImpl = (props: IDefaultProps) => {
  const datas = [...props.datas];
  const [sliders, setSliders] = useState(datas);
  const [updateConfig] = useMutation<UpdateTrustedPointConfig, UpdateTrustedPointConfigVariables>(
    UPDATE_TRUSTED_POINT_CONFIG,
    {
      refetchQueries: ['GetTrustedPointConfigs'],
    },
  );

  const handleOk = e => {
    handleSubmit(e);
  };

  const validateData = (): boolean => {
    for (let i = 0; i < sliders.length; i++) {
      if (sliders[i].invitedTo) {
        if (sliders[i].invitedTo <= sliders[i].invitedFrom) {
          message.error("Range end phải lớn hơn range start!");
          return false;
        }
      }

      if (i > 0 && sliders[i - 1].invitedTo && sliders[i].invitedFrom <= sliders[i - 1].invitedTo) {
        message.error("Range start level sau phải lớn hơn range end level trước đó!");
        return false;
      }
    }
    return true;
  }

  const handleSubmit = e => {
    console.log('BACHK______handleSubmit:', sliders)
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (!validateData()) {
          return;
        }
        const { datas } = props;
        let configs = [];
        for (let i = 0; i < datas.length; i++) {
          let data = datas[i];
          let slider = sliders.find(i => i._id == data._id);
          let config = updateConfig({
            variables: {
              _id: data._id,
              type: data.type,
              points: slider.points,
              description: data.description,
              trustedPointsFrom: data.trustedPointsFrom,
              trustedPointsTo: data.trustedPointsTo,
              rankFrom: data.rankFrom,
              rankTo: data.rankTo,
              invitedFrom: slider.invitedFrom,
              invitedTo: slider.invitedTo,
              adjustPointPercent: data.adjustPointPercent,
              engagementAdjustPercent: data.engagementAdjustPercent,
              postContentLength: data.postContentLength,
              totalPlaceReviewsFrom: data.totalPlaceReviewsFrom,
              totalPlaceReviewsTo: data.totalPlaceReviewsTo,
              newPlaceCoefficient: data.newPlaceCoefficient,
              unlimitedPostsPerDayWhenTrustedPointGreaterThan: data.unlimitedPostsPerDayWhenTrustedPointGreaterThan,
              maxTenPostsPerDayWhenTrustedPointLessThan: data.maxTenPostsPerDayWhenTrustedPointLessThan,
              maxFivePostsPerDayWhenTrustedPointLessThan: data.maxFivePostsPerDayWhenTrustedPointLessThan,
            },
          });

          configs.push(config);
        }

        Promise.all(configs).then(r => {
          setShow(false);
          props.closeModal();
        }).catch(e => {
          message.error("Có lỗi xảy ra!");
        })
      }
    });
  }

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const rangeStartChange = (e, id) => {
    if (new RegExp(regxRange).test(e.target.value)) {
      let _slider;
      let itemIndex = sliders.findIndex(j => j._id == id);
      let indexLastEnd = itemIndex - 1;
      let value = parseInt(e.target.value);
      _slider = sliders.map((item, i) => {
        if (item._id == id) {
          return { ...item, invitedFrom: value || 1 }
        }
        if (indexLastEnd > 0 && i == indexLastEnd) {
          let nValue = value - 1;
          if (isNaN(nValue) || isNull(nValue) || nValue <= 0) {
            return { ...item };
          }
          props.form.setFieldsValue({
            [`invitedTo_${item._id}`]: nValue
          });
          return { ...item, invitedTo: nValue }
        }
        return item;
      });
      setSliders(_slider);
    }
  };

  const rangeEndChange = (e, id) => {
    if (new RegExp(regxRange).test(e.target.value)) {
      let _slider;
      let itemIndex = sliders.findIndex(j => j._id == id);
      let indexNext = itemIndex + 1;
      let value = parseInt(e.target.value);
      _slider = sliders.map((item, i) => {
        if (item._id == id) {
          return { ...item, invitedTo: value || 1 }
        }
        if (indexNext < sliders.length && i == indexNext) {
          let nValue = value + 1;
          if (isNaN(nValue) || isNull(nValue)) {
            return { ...item };
          }
          props.form.setFieldsValue({
            [`invitedFrom_${item._id}`]: nValue
          });
          return { ...item, invitedFrom: nValue }
        }
        return item;
      });
      setSliders(_slider);
    }

  };

  const rangeValueChange = (e, id) => {
    if (new RegExp(regxPoint).test(e.target.value)) {
      let _slider = sliders.map(i => {
        if (i._id == id) {
          return { ...i, points: Number(e.target.value) }
        }
        return i;
      });
      setSliders(_slider);
    }
  };

  const { visible } = props;
  const [show, setShow] = useState(visible);
  const { getFieldDecorator } = props.form;
  return (
    <div>
      <Modal
        title={'Referral Config'}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Save'}
        width={768}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form onSubmit={handleSubmit}>
              <Row type="flex" justify="center" gutter={8}>
                <Col span={24}>
                  {sliders[0] && (
                    <Row gutter={10} key={0}>
                      <Col span={24}>
                        <Form.Item
                          label={'Điểm cộng cho người được giới thiệu'}>
                          {getFieldDecorator(`point_${sliders[0]._id}`, {
                            initialValue: sliders[0].points ? sliders[0].points + "" : "0",
                            rules: [
                              {
                                required: true,
                                pattern: new RegExp(regxPoint),
                                message: "Điểm phải là số lớn hơn hoặc bằng 0"
                              }
                            ]
                          })(
                            <Input
                              style={{ width: '100%' }}
                              onChange={e => rangeValueChange(e, sliders[0]._id)}
                              min={0}
                            />
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Form.Item label={'Điểm cộng khi giới thiệu thành công'}>
                    {sliders.map(function (r, i) {
                      if (i == 0) {
                        return null;
                      }

                      if (i == sliders.length - 1) {
                        return (
                          <Row gutter={10} key={i}>
                            <Col span={18}>
                              <Form.Item
                                label={'Range start ' + (i) + ' (>=)'}>
                                {getFieldDecorator(`invitedFrom_${r._id}`, {
                                  initialValue: r.invitedFrom ? r.invitedFrom + "" : "1",
                                  rules: [
                                    {
                                      required: true,
                                      pattern: new RegExp(regxRange),
                                      message: "Range start phải là số nguyên lớn hơn 0"
                                    }
                                  ]
                                })(
                                  <Input
                                    style={{ width: '100%' }}
                                    onChange={e => rangeStartChange(e, r._id)}
                                    required
                                    min={1}
                                  />
                                )}

                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label={'Value ' + (i) + ''}>
                                {getFieldDecorator(`point_${r._id}`, {
                                  initialValue: r.points ? r.points + "" : "0",
                                  rules: [
                                    {
                                      required: true,
                                      pattern: new RegExp(regxPoint),
                                      message: "Điểm phải là số lớn hơn hoặc bằng 0"
                                    }
                                  ]
                                })(
                                  <Input
                                    style={{ width: '100%' }}
                                    required
                                    onChange={e => rangeValueChange(e, r._id)}
                                    min={0}
                                  />
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      }
                      return (
                        <Row gutter={10} key={i}>
                          <Col span={9}>
                            <Form.Item label={'Range start ' + (i) + ' (>=)'}>
                              {getFieldDecorator(`invitedFrom_${r._id}`, {
                                initialValue: r.invitedFrom ? r.invitedFrom + "" : "1",
                                rules: [
                                  {
                                    required: true,
                                    pattern: new RegExp(regxRange),
                                    message: "Range start phải là số nguyên lớn hơn 0"
                                  }
                                ]
                              })(
                                <Input
                                  style={{ width: '100%' }}
                                  required
                                  onChange={e => rangeStartChange(e, r._id)}
                                  min={1}
                                />
                              )}
                            </Form.Item>
                          </Col>
                          <Col span={9}>
                            <Form.Item label={'Range end ' + (i) + ' (<=)'}>
                              {getFieldDecorator(`invitedTo_${r._id}`, {
                                initialValue: r.invitedTo ? r.invitedTo + "" : "0",
                                rules: [
                                  {
                                    required: true,
                                    pattern: new RegExp(regxRange),
                                    message: "Range end phải là số nguyên lớn hơn 0"
                                  }
                                ]
                              })(
                                <Input
                                  style={{ width: '100%' }}
                                  required
                                  onChange={e => rangeEndChange(e, r._id)}
                                  min={1}
                                />
                              )}
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              label={'Value ' + (i) + ''}>
                              {getFieldDecorator(`point_${r._id}`, {
                                initialValue: r.points ? r.points + "" : "0",
                                rules: [
                                  {
                                    required: true,
                                    pattern: new RegExp(regxPoint),
                                    message: "Điểm phải là số lớn hơn hoặc bằng 0"
                                  }
                                ]
                              })(
                                <Input
                                  style={{ width: '100%' }}
                                  required
                                  onChange={e => rangeValueChange(e, r._id)}
                                  min={0}
                                />
                              )}
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    })}
                  </Form.Item>

                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AddOrEditConfigImpl;
