import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Form, Input, Layout, Modal, Select, Skeleton, Table } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { CMS_GET_GROUP_REPORT, CMS_REPORT_DETAIL } from '../../graphql/report/report';
import {
  CmsGetGroupReports,
  CmsGetGroupReportsVariables, CmsListReportByTargetId, CmsListReportByTargetIdVariables,
  CmsSetTermStatus,
  CmsSetTermStatusVariables,
  ReportFor,
  TermStatus,
} from '../../graphql/types';
import Styled from './indexStyled';
import { CMS_SET_TERM_STATUS } from '../../graphql/report/termStatus';
import { ToastError, ToastSuccess } from '../../components/Toast';
import { reportTypes } from './messages';
const {dateByFormat} = require("../../helpers/date");

const { Content } = Layout;
const { Option } = Select;

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

const GroupReportImpl = (props: FormComponentProps) => {
  const { getFieldDecorator } = props.form;
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState<CmsSetTermStatusVariables>({
    _id: null,
    model: ReportFor.GROUP,
    termStatus: null,
    reason: '',
  });
  const [setTermStatus, {}] = useMutation<CmsSetTermStatus, CmsSetTermStatusVariables>(CMS_SET_TERM_STATUS);
  const { data, error, loading, refetch } = useQuery<CmsGetGroupReports, CmsGetGroupReportsVariables>(CMS_GET_GROUP_REPORT, {
    fetchPolicy: 'network-only',
    variables: {
      limit: 100,
    },
  });
  const [filterMessagesParams, setFilterMessagesParams] = useState({
    limit: 500,
    targetId: null,
    type: ReportFor.GROUP,
  });

  const { data: getMessages } = useQuery<CmsListReportByTargetId, CmsListReportByTargetIdVariables>(CMS_REPORT_DETAIL, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables: filterMessagesParams,
  });


  const columnMes = [
    {
      title: 'Message',
      dataIndex: '',
      render: row => {
        return (
          <p>
            {row.message} {row.postReportType && (<strong>{`(${reportTypes[row.postReportType]})`}</strong>)}
          </p>
        );
      },
    },
    {
      title: 'Creator',
      dataIndex: 'creator.fullName',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render:row => {
        return <>{dateByFormat(row.createdAt)}</>
      }
    },
  ];
  const termStatusEdit = [TermStatus.NORMAL, TermStatus.BLOCKED, TermStatus.SUSPENDING];

  const handleOk = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        setShowModal(false);
        setTermStatus({
          variables: {
            ...editData,
            _id: editData._id,
            reason: editData.reason,
            termStatus: editData.termStatus,
          },
        })
          .catch(e => {
            ToastError({ message: 'Error', description: e.toString() });
          })
          .then(res => {
            refetch();
            ToastSuccess({ message: 'Updated', description: 'Status admin update success' });
          });
      }
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const setEditStatus = data => {
    setFilterMessagesParams({
      ...filterMessagesParams,
      targetId: data._id,
    });
    setEditData({
      ...editData,
      _id: data._id,
      termStatus: data.termStatus || TermStatus.NORMAL,
      reason: data.reason,
    });
    setShowModal(true);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'TotalReports',
      dataIndex: 'totalReports',
    },
    {
      title: 'TotalMembers',
      dataIndex: 'totalMembers',
    },
    {
      title: 'TotalPosts',
      dataIndex: 'totalPosts',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      render:reason => {
        if(reason){
          let reasonParse =  JSON.parse(reason);
          return ( reasonParse && reasonParse[0] && reasonParse[0].reason ) || ''
        }
        return ''
      }
    },
    {
      title: 'termStatus',
      render: row => {
        let type: any = 'default';
        if (row.termStatus == TermStatus.SUSPENDING) type = 'primary';
        if (row.termStatus == TermStatus.BLOCKED) type = 'danger';
        return (
          <Button className="btn-rectangle" type={type} size="small" onClick={e => setEditStatus(row)}>
            {row.termStatus || TermStatus.NORMAL}
          </Button>
        );
      },
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
    },
    {
      title: 'Creator',
      dataIndex: 'creator.fullName',
    },
  ];

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Group reports</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetGroupReports && data.cmsGetGroupReports.total) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <Table rowKey="_id" columns={columns} dataSource={data.cmsGetGroupReports.items} onChange={onChange} scroll={{ x: 'calc(700px + 50%)', y: 0 }}  />
        )}
      </Content>
      <Modal
        width={768}
        title="Change group report status"
        visible={showModal}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        {showModal && (
          <div>
            <Form>
              {getMessages && (
                <Table rowKey="_id" columns={columnMes} dataSource={getMessages.cmsListReportByTargetId} />
              )}
              <Form.Item label="">
                {getFieldDecorator('statusUpdate', {
                  initialValue: editData.termStatus,
                })(
                  <Select
                    placeholder="Select a option and change input text above"
                    onChange={(e: any) => setEditData({ ...editData, termStatus: e })}
                  >
                    {termStatusEdit.map((r, i) => {
                      return (
                        <Option disabled={r == editData.termStatus} key={i} value={r}>
                          {r}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
              {(
                <Form.Item label="">
                  {getFieldDecorator('reasonUpdate', {
                    initialValue: editData.reason,
                    rules: [{ required: true, message: 'Please input reason!' }],
                  })(
                    <Input
                      placeholder="Reason"
                      onChange={(e: any) => {
                        setEditData({ ...editData, reason: e.target.value });
                      }}
                    />,
                  )}
                </Form.Item>
              )}
            </Form>
          </div>
        )}
      </Modal>
    </Styled.Container>
  );
};

const GroupReport = Form.create()(GroupReportImpl);
export default GroupReport;
