import React, { useState } from 'react';
import { Layout, Row, Col, Form, Select, message, Modal } from 'antd';
import Styled from './../indexStyled';
import { PostStatus, setStatusPost, setStatusPostVariables } from 'src/graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { SET_STATUS } from 'src/graphql/feed/feed';
import './customSelect.css';
import VideoPlayer from 'src/components/VideoPlayer';

const { dateByFormat } = require('../../../helpers/date');
const { Content } = Layout;
const { Option } = Select;
const imgPlay = require('../../../assets/images/icons/page/play_btn.svg');

interface IEditProps {
  match?: any;
  title?: string;
  content?: string;
  medias?: any;
  createdAt?: string;
  creator?: any;
  sharedPost?: any;
  post: any;
  setStatusPost: (e: any) => void;
}

const PostDetail = (props: IEditProps) => {
  const [isShow, setIsShow] = useState(false);
  const statuses = [PostStatus.PENDING, PostStatus.VERIFIED, PostStatus.NOT_VERIFIED, PostStatus.INCORRECT];
  const styleSelect = { width: '100%' };

  const close = () => {
    setIsShow(false);
  };

  const showModal = () => {
    setIsShow(!isShow);
  };

  const handleChangeStatus = () => {
    close();
  };

  return (
    <Styled.Container>
      <Content>
        <p>
          <strong>{props.title}</strong>
        </p>
        <p>{props.content}</p>
        <p>
          Creator:
          {props.creator ? <strong> {props.creator.fullName}</strong> : null}
          <span className="ml-15">at {dateByFormat(props.createdAt)}</span>
        </p>
        {props.post.group && (
          <p>
            Group:
            {<strong> {props.post.group.name}</strong>}
          </p>
        )}

        <Row gutter={8}>
          {props.medias.map(function(row, i) {
            return (
              <Col span={4}>
                <div className="thumb">
                  <img className="image" src={`${row.type === 'PHOTO' ? row.uri : row.thumbnail}`} alt="" />
                  {row.type === 'VIDEO' && (
                    <img src={imgPlay} width={40} height={40} className="play_btn" onClick={showModal} alt="play_btn" />
                  )}
                </div>
                {isShow && (
                  <Modal
                    width={1024}
                    title="Watch Video"
                    visible={true}
                    okText="Ok"
                    onCancel={() => close()}
                    footer={null}
                    onOk={() => handleChangeStatus()}
                  >
                    {' '}
                    <VideoPlayer
                      offset={0}
                      width={300}
                      height={300}
                      options={{
                        autoplay: true,
                        poster: '',
                        sources: row.uri,
                      }}
                    />
                  </Modal>
                )}
              </Col>
            );
          })}
        </Row>
        {props.sharedPost && (
          <>
            <hr></hr>
            <p>
              <strong>Post shared:</strong>
            </p>
            <p>Title: {props.sharedPost.title}</p>
            <p>Content: {props.sharedPost.content}</p>
            <p>Creator: {props.sharedPost.creator.fullName}</p>
            <p>Created at: {dateByFormat(props.sharedPost.createdAt)}</p>
            <Row gutter={8}>
              {props.sharedPost.medias.map(function(row, i) {
                return (
                  <Col span={4}>
                    <div className="thumb">
                      <img className="image" src={row.uri} alt="" />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </>
        )}
        {/* <div className="w-full">
          <Select
            placeholder="Select a option and change input text above"
            onChange={e => props.setStatusPost(e)}
            defaultValue={props.post.status}
            className="mt-3"
            style={styleSelect}
          >
            {statuses.map((r, i) => {
              return (
                <Option key={i} value={r}>
                  {r.replace('_', ' ')}
                </Option>
              );
            })}
          </Select>
        </div> */}
      </Content>
    </Styled.Container>
  );
};
export default PostDetail;
