import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button, Col,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Row,
  Select,
  Skeleton,
  Table,
  Tooltip,
} from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailReports from './components/DetailReports';
import { CMS_GET_PAGE_REPORT } from '../../graphql/report/report';
import {
  CmsGetPageReports,
  CmsGetPageReportsVariables,
  CmsSetTermStatus,
  CmsSetTermStatusVariables, PageType,
  ReportFor,
  TermStatus,
} from '../../graphql/types';
import Styled from './indexStyled';
import { CMS_SET_TERM_STATUS } from '../../graphql/report/termStatus';
import { ToastError, ToastSuccess } from '../../components/Toast';
import { dateByFormat } from "../../helpers/date";

const { Content } = Layout;
const { Option } = Select;


function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

const businessTypeFilter = ["ALL", PageType.BUSINESS_OR_BRAND, PageType.COMMUNITY_OR_PUBLIC_FIGURE];
const termStatusFilter = ["ALL", TermStatus.NORMAL, TermStatus.HIDDEN, TermStatus.SUSPENDING, TermStatus.BLOCKED];

const PageReportImpl = (props: FormComponentProps) => {
  const { getFieldDecorator } = props.form;
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [pageEdit, setPageEdit] = useState(null);
  const [pageReports,setPageReports] = useState([]);
  const [total, setTotal] = useState(0)
  const [editData, setEditData] = useState({
    _id: null,
    model: ReportFor.PAGE,
    termStatus: null,
    reason: {},
  });
  const [pageParams, setPageParams] = useState<CmsGetPageReportsVariables>({
    limit: 1,
    offset: 1,
    filter:{},
  });

  const [setTermStatus, {}] = useMutation<CmsSetTermStatus, CmsSetTermStatusVariables>(CMS_SET_TERM_STATUS);
  const { data, error, loading, refetch } = useQuery<CmsGetPageReports, CmsGetPageReportsVariables>(CMS_GET_PAGE_REPORT, {
    fetchPolicy: 'network-only',
    variables: pageParams
  });


  useEffect(()=>{
    if(data && data.cmsGetPageReports && data.cmsGetPageReports.items){
      setTotal(data.cmsGetPageReports.total);
      setPageReports(data.cmsGetPageReports.items);
    }
  },[data]);


  const [filterMessagesParams, setFilterMessagesParams] = useState({
    limit: 500,
    targetId: null,
    type: ReportFor.PAGE,
  });


  const termStatusEdit = [TermStatus.NORMAL, TermStatus.BLOCKED, TermStatus.SUSPENDING];

  const handleOk = () => {
    props.form.validateFields((err, values) => {
      if (!err && values.statusUpdate !== editData.termStatus ) {
        setShowModal(false);
        setTermStatus({
          variables: {
            ...editData,
            _id: editData._id,
            reason: values.reason,
            termStatus: values.statusUpdate,
          },
        })
          .catch(e => {
            console.log("e",e);
            ToastError({ message: 'Error', description: e.toString() });
          })
          .then(res => {
            refetch();
            ToastSuccess({ message: 'Updated', description: 'Status admin update success' });
          });
      }else{
        setShowModal(false);
      }
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleChangePage = (page) => {
    setPageParams({
      ...pageParams,
      offset:page
    })
  };

  const handleShowReport = (pageId) => {
    setPageEdit(pageId);
    setShowReports(true);
  };
  const closeReport = () =>{
    setPageEdit(null);
    setShowReports(false);
  };

  const setEditStatus = data => {
    let {reason} =  data;
    reason =  reason ? JSON.parse(reason) : [];
    setFilterMessagesParams({
      ...filterMessagesParams,
      targetId: data._id,
    });

    setEditData({
      ...editData,
      _id: data._id,
      termStatus: data.termStatus || TermStatus.NORMAL,
      reason: (reason && reason[0] && reason[0].reason) || '',
    });
    setShowModal(true);
  };


  const handleChangeFilterStatus = (value) => {
    if(value !== termStatusFilter[0]){
      setPageParams({
        ...pageParams,
        offset:1,
        filter:{
          ...pageParams.filter,
          termStatus:value
        }
      })
    }else{
      const newFilter = pageParams.filter;
      delete newFilter.termStatus;
      setPageParams({
        ...pageParams,
        offset:1,
        filter:newFilter
      });
      refetch()
    }
  };

  const handleChangeFilterType = (value) => {
    if(value !== termStatusFilter[0]){
      setPageParams({
        ...pageParams,
        offset:1,
        filter:{
          ...pageParams.filter,
          type:value
        }
      })
    }else{
      const newFilter = pageParams.filter;
      delete newFilter.type;
      setPageParams({
        ...pageParams,
        offset:1,
        filter:newFilter
      });
      refetch()
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'businessType',
      dataIndex: 'businessType',
    },
    {
      title: 'termStatus',
      render: row => {
        let type: any = 'default';
        if (row.termStatus == TermStatus.SUSPENDING) type = 'primary';
        if (row.termStatus == TermStatus.BLOCKED) type = 'danger';
        return (
          <Button className="btn-rectangle" type={type} size="small" onClick={e => setEditStatus(row)}>
            {row.termStatus || TermStatus.NORMAL}
          </Button>
        );
      },
    },
    {
      title: 'Creator',
      dataIndex: 'creator.fullName',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render:createdAt => {
        return <>{dateByFormat(createdAt)}</>
      }
    },
    {
      title:"Action",
      key:'action',
      render:(row)=>{
        return <>
          <Tooltip title="History reports">
            <FontAwesomeIcon
              onClick={()=>handleShowReport(row._id)}
              icon={['fas', 'layer-group']}
              size="2x"
              className="action-icon history-report"/>
          </Tooltip>
        </>;
      }
    }
  ];

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Page reports</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetPageReports && data.cmsGetPageReports.total) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div>
            <Row gutter={10} >
              <Col span={4}>
                <Form.Item label="Status">
                  <Select
                    className="w100"
                    showSearch
                    value={pageParams.filter.termStatus || termStatusFilter[0]}
                    defaultValue={termStatusFilter[0]}
                    onChange={handleChangeFilterStatus}
                  >
                    {termStatusFilter.map((termStatus, index) => {
                      return (<Option key={index} value={termStatus}> {termStatus} </Option>);
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Type">
                  <Select
                    className="w100"
                    showSearch
                    value={pageParams.filter.type || businessTypeFilter[0]}
                    defaultValue={businessTypeFilter[0]}
                    onChange={handleChangeFilterType}
                  >
                    {businessTypeFilter.map((type, index) => {
                      return (<Option key={index} value={type}> {type} </Option>);
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Table rowKey="_id"
                   columns={columns}
                   dataSource={pageReports}
                   onChange={onChange}
                   pagination={false}
            />
            <br/>
            <Pagination
              defaultCurrent={pageParams.offset}
              total={total}
              current={pageParams.offset}
              defaultPageSize={pageParams.limit}
              onChange={handleChangePage}
            />
          </div>

        )}
      </Content>
      <Modal
        width={768}
        title="Change page report status"
        visible={showModal}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        {showModal && (
          <div>
            <Form>
              <Form.Item label="">
                {getFieldDecorator('statusUpdate', {
                  initialValue: editData.termStatus,
                })(
                  <Select
                    placeholder="Select a option and change input text above"
                  >
                    {termStatusEdit.map((r, i) => {
                      return (
                        <Option disabled={r == editData.termStatus} key={i} value={r}>
                          {r}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
              {(
                <Form.Item label="">
                  {getFieldDecorator('reason', {
                    initialValue: editData.reason,
                    rules: [{ required: true, message: 'Please input reason!' }],
                  })(
                    <Input
                      placeholder="Reason"
                    />,
                  )}
                </Form.Item>
              )}
            </Form>
          </div>
        )}
      </Modal>
      <DetailReports refId={pageEdit} for={ReportFor.PAGE} visible={showReports} close={closeReport}  />
    </Styled.Container>
  );
};

const PageReport = Form.create()(PageReportImpl);
export default PageReport;
