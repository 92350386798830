/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_login_idTokenPayload_user {
  __typename: "User";
  badgeStatus: UserBadgeStatus | null;
  group: UserGroup;
}

export interface Login_login_idTokenPayload {
  __typename: "JWTIDTokenPayload";
  user: Login_login_idTokenPayload_user;
}

export interface Login_login {
  __typename: "JWT";
  idToken: string;
  refreshToken: string;
  expiresAt: any;
  idTokenPayload: Login_login_idTokenPayload;
}

export interface Login {
  login: Login_login;
}

export interface LoginVariables {
  data: LoginInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RefreshToken
// ====================================================

export interface RefreshToken_refreshToken {
  __typename: "JWT";
  idToken: string;
  refreshToken: string;
  expiresAt: any;
}

export interface RefreshToken {
  refreshToken: RefreshToken_refreshToken;
}

export interface RefreshTokenVariables {
  refreshToken: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListBadgeRegisterForms
// ====================================================

export interface cmsListBadgeRegisterForms_cmsListBadgeRegisterForms_items_figure {
  __typename: "Media";
  _id: string;
  thumbnail: string | null;
  uri: string;
}

export interface cmsListBadgeRegisterForms_cmsListBadgeRegisterForms_items {
  __typename: "BadgeRegisterForm";
  _id: string;
  detail: string;
  detailEn: string | null;
  inputType: BadgeInputType;
  required: boolean;
  figure: cmsListBadgeRegisterForms_cmsListBadgeRegisterForms_items_figure | null;
}

export interface cmsListBadgeRegisterForms_cmsListBadgeRegisterForms {
  __typename: "BadgeRegisterFormRes";
  counter: number | null;
  items: cmsListBadgeRegisterForms_cmsListBadgeRegisterForms_items[] | null;
}

export interface cmsListBadgeRegisterForms {
  cmsListBadgeRegisterForms: cmsListBadgeRegisterForms_cmsListBadgeRegisterForms | null;
}

export interface cmsListBadgeRegisterFormsVariables {
  limit?: number | null;
  page?: number | null;
  sortBy?: BadgeRegisterFormSortBy | null;
  filter?: BadgeFilterType | null;
  q?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsCreateBadgeRegisterForm
// ====================================================

export interface cmsCreateBadgeRegisterForm_cmsCreateBadgeRegisterForm {
  __typename: "BadgeRegisterForm";
  detail: string;
  inputType: BadgeInputType;
  required: boolean;
}

export interface cmsCreateBadgeRegisterForm {
  cmsCreateBadgeRegisterForm: cmsCreateBadgeRegisterForm_cmsCreateBadgeRegisterForm | null;
}

export interface cmsCreateBadgeRegisterFormVariables {
  detail: string;
  detailEn?: string | null;
  inputType: BadgeInputType;
  required: boolean;
  figureId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsUpdateBadgeRegisterForm
// ====================================================

export interface cmsUpdateBadgeRegisterForm_cmsUpdateBadgeRegisterForm {
  __typename: "BadgeRegisterForm";
  _id: string;
}

export interface cmsUpdateBadgeRegisterForm {
  cmsUpdateBadgeRegisterForm: cmsUpdateBadgeRegisterForm_cmsUpdateBadgeRegisterForm | null;
}

export interface cmsUpdateBadgeRegisterFormVariables {
  _id: string;
  detail: string;
  detailEn?: string | null;
  inputType: BadgeInputType;
  required: boolean;
  figureId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsDeleteBadgeRegisterForm
// ====================================================

export interface cmsDeleteBadgeRegisterForm {
  cmsDeleteBadgeRegisterForm: boolean | null;
}

export interface cmsDeleteBadgeRegisterFormVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListBadges
// ====================================================

export interface cmsListBadges_cmsListBadges_items_media {
  __typename: "Media";
  _id: string;
  thumbnail: string | null;
  uri: string;
}

export interface cmsListBadges_cmsListBadges_items_registerForms {
  __typename: "BadgeRegisterForm";
  _id: string;
  detail: string;
  inputType: BadgeInputType;
  required: boolean;
}

export interface cmsListBadges_cmsListBadges_items {
  __typename: "Badge";
  _id: string;
  title: string;
  titleEn: string | null;
  status: BadgeStatus;
  media: cmsListBadges_cmsListBadges_items_media | null;
  registerForms: cmsListBadges_cmsListBadges_items_registerForms[] | null;
}

export interface cmsListBadges_cmsListBadges {
  __typename: "BadgeRes";
  counter: number | null;
  items: cmsListBadges_cmsListBadges_items[] | null;
}

export interface cmsListBadges {
  cmsListBadges: cmsListBadges_cmsListBadges | null;
}

export interface cmsListBadgesVariables {
  limit?: number | null;
  page?: number | null;
  filter?: BadgeFilter | null;
  sortBy?: BadgeSortBy | null;
  q?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBadge
// ====================================================

export interface getBadge_getBadge_media {
  __typename: "Media";
  _id: string;
  thumbnail: string | null;
  uri: string;
}

export interface getBadge_getBadge_registerForms_figure {
  __typename: "Media";
  _id: string;
  thumbnail: string | null;
  uri: string;
}

export interface getBadge_getBadge_registerForms {
  __typename: "BadgeRegisterForm";
  _id: string;
  detail: string;
  inputType: BadgeInputType;
  required: boolean;
  figureId: string | null;
  figure: getBadge_getBadge_registerForms_figure | null;
}

export interface getBadge_getBadge {
  __typename: "Badge";
  title: string;
  titleEn: string | null;
  status: BadgeStatus;
  media: getBadge_getBadge_media | null;
  registerForms: getBadge_getBadge_registerForms[] | null;
}

export interface getBadge {
  getBadge: getBadge_getBadge | null;
}

export interface getBadgeVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsCreateBadge
// ====================================================

export interface cmsCreateBadge_cmsCreateBadge {
  __typename: "Badge";
  _id: string;
}

export interface cmsCreateBadge {
  cmsCreateBadge: cmsCreateBadge_cmsCreateBadge | null;
}

export interface cmsCreateBadgeVariables {
  title: string;
  titleEn?: string | null;
  registerFormIds?: string[] | null;
  status: BadgeStatus;
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsDeleteBadge
// ====================================================

export interface cmsDeleteBadge {
  cmsDeleteBadge: boolean | null;
}

export interface cmsDeleteBadgeVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsUpdateBadge
// ====================================================

export interface cmsUpdateBadge_cmsUpdateBadge {
  __typename: "Badge";
  _id: string;
}

export interface cmsUpdateBadge {
  cmsUpdateBadge: cmsUpdateBadge_cmsUpdateBadge | null;
}

export interface cmsUpdateBadgeVariables {
  _id: string;
  title: string;
  titleEn?: string | null;
  status: BadgeStatus;
  mediaId: string;
  registerFormIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListRequestBadges
// ====================================================

export interface cmsListRequestBadges_cmsListRequestBadges_items_creator {
  __typename: "User";
  fullName: string;
}

export interface cmsListRequestBadges_cmsListRequestBadges_items {
  __typename: "RequestBadge";
  _id: string;
  title: string;
  status: RequestBadgeStatus;
  creator: cmsListRequestBadges_cmsListRequestBadges_items_creator;
  phoneNumber: string;
  createdAt: any;
}

export interface cmsListRequestBadges_cmsListRequestBadges {
  __typename: "RequestBadgeRes";
  counter: number | null;
  items: cmsListRequestBadges_cmsListRequestBadges_items[] | null;
}

export interface cmsListRequestBadges {
  cmsListRequestBadges: cmsListRequestBadges_cmsListRequestBadges | null;
}

export interface cmsListRequestBadgesVariables {
  limit: number;
  page?: number | null;
  filter?: RequestBadgeFilter | null;
  sortBy?: BadgeRequestFormSortBy | null;
  q?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getRequestBadge
// ====================================================

export interface getRequestBadge_getRequestBadge_creator {
  __typename: "User";
  fullName: string;
}

export interface getRequestBadge_getRequestBadge_requestForms_media {
  __typename: "Media";
  uri: string;
  thumbnail: string | null;
  _id: string;
}

export interface getRequestBadge_getRequestBadge_requestForms {
  __typename: "BadgeRequestForm";
  detail: string;
  inputType: BadgeInputType;
  required: boolean | null;
  mediaId: string | null;
  media: getRequestBadge_getRequestBadge_requestForms_media | null;
  text: string | null;
}

export interface getRequestBadge_getRequestBadge {
  __typename: "RequestBadge";
  title: string;
  status: RequestBadgeStatus;
  creator: getRequestBadge_getRequestBadge_creator;
  _id: string;
  phoneNumber: string;
  createdAt: any;
  requestForms: getRequestBadge_getRequestBadge_requestForms[] | null;
}

export interface getRequestBadge {
  getRequestBadge: getRequestBadge_getRequestBadge | null;
}

export interface getRequestBadgeVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsBadgeSetStatus
// ====================================================

export interface cmsBadgeSetStatus {
  cmsBadgeSetStatus: boolean | null;
}

export interface cmsBadgeSetStatusVariables {
  _id: string;
  status: RequestBadgeStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsCreateBanner
// ====================================================

export interface CmsCreateBanner_createBanner {
  __typename: "Banner";
  _id: string;
  name: string;
  status: BannerStatus;
  type: BannerType;
  position: number;
  mediaId: string;
  startDate: any;
  endDate: any;
  createdBy: string;
  createdAt: any;
}

export interface CmsCreateBanner {
  createBanner: CmsCreateBanner_createBanner | null;
}

export interface CmsCreateBannerVariables {
  type: BannerType;
  data: BannerDataInput;
  mediaId: string;
  startDate: any;
  endDate: any;
  name: string;
  status?: BannerStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetBanners
// ====================================================

export interface CmsGetBanners_cmsListBanner_items_data {
  __typename: "BannerData";
  postId: string | null;
  link: string | null;
  pageId: string | null;
  screen: MobileScreenType | null;
}

export interface CmsGetBanners_cmsListBanner_items_media {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface CmsGetBanners_cmsListBanner_items {
  __typename: "Banner";
  _id: string;
  name: string;
  mediaId: string;
  position: number;
  startDate: any;
  endDate: any;
  status: BannerStatus;
  type: BannerType;
  data: CmsGetBanners_cmsListBanner_items_data;
  media: CmsGetBanners_cmsListBanner_items_media | null;
}

export interface CmsGetBanners_cmsListBanner {
  __typename: "ListBannerResponse";
  counter: number | null;
  items: CmsGetBanners_cmsListBanner_items[] | null;
}

export interface CmsGetBanners {
  cmsListBanner: CmsGetBanners_cmsListBanner | null;
}

export interface CmsGetBannersVariables {
  limit: number;
  page?: number | null;
  filter?: FilterListBanner | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsUpdateBannerPositions
// ====================================================

export interface CmsUpdateBannerPositions {
  updateBannerPositions: boolean | null;
}

export interface CmsUpdateBannerPositionsVariables {
  data: BannerPositionsInput[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateBanner
// ====================================================

export interface updateBanner {
  updateBanner: boolean | null;
}

export interface updateBannerVariables {
  id: string;
  data?: BannerInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsDeleteBanner
// ====================================================

export interface CmsDeleteBanner {
  cmsDeleteBanner: boolean | null;
}

export interface CmsDeleteBannerVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListBannerScreen
// ====================================================

export interface cmsListBannerScreen_cmsListBannerScreen {
  __typename: "ListBannerScreen";
  title: string;
  screen: MobileScreenType | null;
}

export interface cmsListBannerScreen {
  cmsListBannerScreen: (cmsListBannerScreen_cmsListBannerScreen | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getBanner
// ====================================================

export interface getBanner_banner_data {
  __typename: "BannerData";
  link: string | null;
  postId: string | null;
  pageId: string | null;
  screen: MobileScreenType | null;
}

export interface getBanner_banner_media {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface getBanner_banner {
  __typename: "Banner";
  _id: string;
  name: string;
  status: BannerStatus;
  type: BannerType;
  data: getBanner_banner_data;
  startDate: any;
  endDate: any;
  media: getBanner_banner_media | null;
  createdBy: string;
  createdAt: any;
}

export interface getBanner {
  banner: getBanner_banner | null;
}

export interface getBannerVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getMenuBanner
// ====================================================

export interface getMenuBanner_getMenuBanner_display {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface getMenuBanner_getMenuBanner_download {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface getMenuBanner_getMenuBanner {
  __typename: "MenuBannerType";
  display: getMenuBanner_getMenuBanner_display | null;
  download: getMenuBanner_getMenuBanner_download | null;
}

export interface getMenuBanner {
  getMenuBanner: getMenuBanner_getMenuBanner | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsDeleteBannerMenu
// ====================================================

export interface cmsDeleteBannerMenu {
  cmsDeleteBannerMenu: boolean;
}

export interface cmsDeleteBannerMenuVariables {
  bannerType: MediaType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetConfigs
// ====================================================

export interface GetConfigs_getConfigs {
  __typename: "Config";
  _id: string;
  key: string;
  value: string;
  type: ConfigType;
}

export interface GetConfigs {
  /**
   * Get all config
   */
  getConfigs: GetConfigs_getConfigs[] | null;
}

export interface GetConfigsVariables {
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateConfig
// ====================================================

export interface CreateConfig_createConfig {
  __typename: "Config";
  key: string;
  value: string;
}

export interface CreateConfig {
  /**
   * Create new config
   */
  createConfig: CreateConfig_createConfig;
}

export interface CreateConfigVariables {
  key: string;
  value: string;
  type?: ConfigType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateConfig
// ====================================================

export interface UpdateConfig_updateConfig {
  __typename: "Config";
  key: string;
  value: string;
}

export interface UpdateConfig {
  /**
   * Edit config
   */
  updateConfig: UpdateConfig_updateConfig;
}

export interface UpdateConfigVariables {
  _id: string;
  key: string;
  value: string;
  type?: ConfigType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteConfig
// ====================================================

export interface DeleteConfig {
  /**
   * Delete config
   */
  deleteConfig: boolean;
}

export interface DeleteConfigVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsNumberOfUsers
// ====================================================

export interface cmsNumberOfUsers {
  cmsNumberOfUsers: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsNumberOfPartnerPages
// ====================================================

export interface cmsNumberOfPartnerPages {
  cmsNumberOfPartnerPages: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsNumberOfUsersByCountry
// ====================================================

export interface cmsNumberOfUsersByCountry_cmsNumberOfUsersByCountry {
  __typename: "NumberOfUsersByCountry";
  country: string;
  users: number;
  percentage: number;
}

export interface cmsNumberOfUsersByCountry {
  cmsNumberOfUsersByCountry: cmsNumberOfUsersByCountry_cmsNumberOfUsersByCountry[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsNewUsersPerDay
// ====================================================

export interface cmsNewUsersPerDay_cmsNewUsersPerDay {
  __typename: "NewUsersPerDay";
  newUsers: number;
  date: string;
}

export interface cmsNewUsersPerDay {
  cmsNewUsersPerDay: cmsNewUsersPerDay_cmsNewUsersPerDay[] | null;
}

export interface cmsNewUsersPerDayVariables {
  startDate: any;
  endDate: any;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsTopUsersByPointsPerDay
// ====================================================

export interface cmsTopUsersByPointsPerDay_cmsTopUsersByPointsPerDay_user_avatar {
  __typename: "Media";
  uri: string;
}

export interface cmsTopUsersByPointsPerDay_cmsTopUsersByPointsPerDay_user {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  phoneNumber: string | null;
  avatar: cmsTopUsersByPointsPerDay_cmsTopUsersByPointsPerDay_user_avatar | null;
}

export interface cmsTopUsersByPointsPerDay_cmsTopUsersByPointsPerDay {
  __typename: "TopUsersByPointsPerDay";
  userId: string;
  user: cmsTopUsersByPointsPerDay_cmsTopUsersByPointsPerDay_user | null;
  changedCount: number;
  earnedPoints: number;
}

export interface cmsTopUsersByPointsPerDay {
  cmsTopUsersByPointsPerDay: cmsTopUsersByPointsPerDay_cmsTopUsersByPointsPerDay[] | null;
}

export interface cmsTopUsersByPointsPerDayVariables {
  limit: number;
  page?: number | null;
  filterDate?: any | null;
  filterRange?: any[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createEvent
// ====================================================

export interface createEvent_createEvent_logo {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface createEvent_createEvent_background {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface createEvent_createEvent_animation {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface createEvent_createEvent {
  __typename: "Event";
  _id: string;
  name: string;
  status: boolean | null;
  animationId: string;
  logoId: string;
  backgroundId: string;
  logo: createEvent_createEvent_logo | null;
  background: createEvent_createEvent_background | null;
  animation: createEvent_createEvent_animation | null;
}

export interface createEvent {
  createEvent: createEvent_createEvent | null;
}

export interface createEventVariables {
  name: string;
  logoId: string;
  backgroundId: string;
  animationId: string;
  loop: number;
  logoStatus: boolean;
  backgroundStatus: boolean;
  animationStatus: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEvent
// ====================================================

export interface getEvent_cmsGetEvent_animation {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface getEvent_cmsGetEvent_logo {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface getEvent_cmsGetEvent_background {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface getEvent_cmsGetEvent {
  __typename: "Event";
  _id: string;
  name: string;
  status: boolean | null;
  loop: number;
  logoStatus: boolean | null;
  backgroundStatus: boolean | null;
  animationStatus: boolean | null;
  animation: getEvent_cmsGetEvent_animation | null;
  logo: getEvent_cmsGetEvent_logo | null;
  background: getEvent_cmsGetEvent_background | null;
}

export interface getEvent {
  cmsGetEvent: getEvent_cmsGetEvent | null;
}

export interface getEventVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsUpdateEvent
// ====================================================

export interface cmsUpdateEvent {
  updateEvent: boolean | null;
}

export interface cmsUpdateEventVariables {
  _id: string;
  data?: EventUpdateDataInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsDeleteEvent
// ====================================================

export interface cmsDeleteEvent {
  cmsDeleteEvent: boolean | null;
}

export interface cmsDeleteEventVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsGetListEvent
// ====================================================

export interface cmsGetListEvent_cmsListEvent_items_logo {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface cmsGetListEvent_cmsListEvent_items {
  __typename: "Event";
  _id: string;
  name: string;
  status: boolean | null;
  loop: number;
  logoStatus: boolean | null;
  backgroundStatus: boolean | null;
  animationStatus: boolean | null;
  logo: cmsGetListEvent_cmsListEvent_items_logo | null;
}

export interface cmsGetListEvent_cmsListEvent {
  __typename: "ListEventResponse";
  counter: number | null;
  items: cmsGetListEvent_cmsListEvent_items[] | null;
}

export interface cmsGetListEvent {
  cmsListEvent: cmsGetListEvent_cmsListEvent | null;
}

export interface cmsGetListEventVariables {
  limit: number;
  page?: number | null;
  filter?: FilterListEvent | null;
  sortBy?: EventSortBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPosts
// ====================================================

export interface GetPosts_cmsGetPosts_items_sharedPost_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
}

export interface GetPosts_cmsGetPosts_items_sharedPost_medias {
  __typename: "Media";
  uri: string;
  type: MediaType;
  thumbnail: string | null;
}

export interface GetPosts_cmsGetPosts_items_sharedPost {
  __typename: "Post";
  _id: string;
  title: string | null;
  content: string | null;
  creator: GetPosts_cmsGetPosts_items_sharedPost_creator | null;
  medias: GetPosts_cmsGetPosts_items_sharedPost_medias[] | null;
}

export interface GetPosts_cmsGetPosts_items_place {
  __typename: "Place";
  _id: string;
  name: string;
}

export interface GetPosts_cmsGetPosts_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  photoUrl: string | null;
}

export interface GetPosts_cmsGetPosts_items_medias {
  __typename: "Media";
  type: MediaType;
  thumbnail: string | null;
  uri: string;
}

export interface GetPosts_cmsGetPosts_items_group {
  __typename: "Group";
  name: string;
}

export interface GetPosts_cmsGetPosts_items {
  __typename: "Post";
  _id: string;
  type: PostType;
  title: string | null;
  content: string | null;
  createdAt: any;
  totalLikes: number;
  status: PostStatus | null;
  totalComments: number;
  totalReports: number | null;
  sharedPost: GetPosts_cmsGetPosts_items_sharedPost | null;
  place: GetPosts_cmsGetPosts_items_place | null;
  creator: GetPosts_cmsGetPosts_items_creator | null;
  medias: GetPosts_cmsGetPosts_items_medias[] | null;
  group: GetPosts_cmsGetPosts_items_group | null;
}

export interface GetPosts_cmsGetPosts {
  __typename: "cmsGetPostsRes";
  counter: number | null;
  items: (GetPosts_cmsGetPosts_items | null)[] | null;
}

export interface GetPosts {
  cmsGetPosts: GetPosts_cmsGetPosts | null;
}

export interface GetPostsVariables {
  limit: number;
  offset?: number | null;
  after?: string | null;
  filter: CmsGetPostFiltersInput;
  sortBy?: PostSortBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBlockedFeed
// ====================================================

export interface GetBlockedFeed_cmsGetBlockedPosts_item_place {
  __typename: "Place";
  _id: string;
  name: string;
}

export interface GetBlockedFeed_cmsGetBlockedPosts_item_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  photoUrl: string | null;
}

export interface GetBlockedFeed_cmsGetBlockedPosts_item_medias {
  __typename: "Media";
  type: MediaType;
  thumbnail: string | null;
  uri: string;
}

export interface GetBlockedFeed_cmsGetBlockedPosts_item {
  __typename: "Post";
  _id: string;
  type: PostType;
  title: string | null;
  content: string | null;
  createdAt: any;
  totalLikes: number;
  totalComments: number;
  status: PostStatus | null;
  totalReports: number | null;
  place: GetBlockedFeed_cmsGetBlockedPosts_item_place | null;
  creator: GetBlockedFeed_cmsGetBlockedPosts_item_creator | null;
  medias: GetBlockedFeed_cmsGetBlockedPosts_item_medias[] | null;
  reason: string | null;
}

export interface GetBlockedFeed_cmsGetBlockedPosts {
  __typename: "BlockedPostOutput";
  counter: number | null;
  item: GetBlockedFeed_cmsGetBlockedPosts_item[] | null;
}

export interface GetBlockedFeed {
  cmsGetBlockedPosts: GetBlockedFeed_cmsGetBlockedPosts | null;
}

export interface GetBlockedFeedVariables {
  limit: number;
  after?: string | null;
  filter: CmsGetPostFiltersInput;
  page?: number | null;
  sortBy?: PostSortBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlockPost
// ====================================================

export interface BlockPost {
  /**
   * Thay đổi term status cho item, hiện tại dùng trong trường hợp block do bị report là chính
   */
  cmsSetTermStatus: boolean;
}

export interface BlockPostVariables {
  _id: string;
  model: ReportFor;
  termStatus: TermStatus;
  reason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsSetPostTermStatus
// ====================================================

export interface cmsSetPostTermStatus {
  /**
   * Thay đổi term status cho item, hiện tại dùng trong trường hợp block do bị report là chính
   */
  cmsSetTermStatus: boolean;
}

export interface cmsSetPostTermStatusVariables {
  _id: string;
  model: ReportFor;
  termStatus: TermStatus;
  reason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: setStatusPost
// ====================================================

export interface setStatusPost {
  cmsSetPostStatus: boolean | null;
}

export interface setStatusPostVariables {
  id: string;
  status: PostStatus;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsSearchGroups
// ====================================================

export interface CmsSearchGroups_cmsSearchGroups_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
}

export interface CmsSearchGroups_cmsSearchGroups_items {
  __typename: "Group";
  _id: string;
  name: string;
  description: string | null;
  privacy: GroupPrivacy;
  creator: CmsSearchGroups_cmsSearchGroups_items_creator | null;
  createdAt: any;
  verified: boolean | null;
  totalPosts: number;
  totalReports: number | null;
}

export interface CmsSearchGroups_cmsSearchGroups {
  __typename: "GroupOutput";
  counter: number | null;
  items: CmsSearchGroups_cmsSearchGroups_items[] | null;
}

export interface CmsSearchGroups {
  cmsSearchGroups: CmsSearchGroups_cmsSearchGroups | null;
}

export interface CmsSearchGroupsVariables {
  limit: number;
  q?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getAllGroup
// ====================================================

export interface getAllGroup_getCmsGroups_items_creator {
  __typename: "User";
  fullName: string;
}

export interface getAllGroup_getCmsGroups_items_category_name {
  __typename: "PageCategoryName";
  VI_VN: string;
}

export interface getAllGroup_getCmsGroups_items_category {
  __typename: "PageCategory";
  name: getAllGroup_getCmsGroups_items_category_name;
}

export interface getAllGroup_getCmsGroups_items {
  __typename: "Group";
  _id: string;
  name: string;
  creator: getAllGroup_getCmsGroups_items_creator | null;
  category: getAllGroup_getCmsGroups_items_category | null;
  totalMembers: number;
}

export interface getAllGroup_getCmsGroups {
  __typename: "PaginatedGroup";
  counter: number | null;
  items: getAllGroup_getCmsGroups_items[] | null;
}

export interface getAllGroup {
  getCmsGroups: getAllGroup_getCmsGroups | null;
}

export interface getAllGroupVariables {
  limit: number;
  offset?: number | null;
  filter?: FilterGroup | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteGroup
// ====================================================

export interface deleteGroup {
  deleteCmsGroup: boolean | null;
}

export interface deleteGroupVariables {
  id: string;
  reason: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createLuckyWheel
// ====================================================

export interface createLuckyWheel_createLuckyWheel_activeDate {
  __typename: "ActiveDate";
  startDate: any;
  endDate: any;
}

export interface createLuckyWheel_createLuckyWheel {
  __typename: "LuckyWheel";
  _id: string;
  title: string;
  activeDate: createLuckyWheel_createLuckyWheel_activeDate;
}

export interface createLuckyWheel {
  createLuckyWheel: createLuckyWheel_createLuckyWheel;
}

export interface createLuckyWheelVariables {
  title: string;
  titleEn: string;
  batchs: BatchInput[];
  activeDate: ActiveDateInput;
  numberOfBatch: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListLuckyWheel
// ====================================================

export interface cmsListLuckyWheel_cmsListLuckyWheel_items_activeDate {
  __typename: "ActiveDate";
  startDate: any;
  endDate: any;
}

export interface cmsListLuckyWheel_cmsListLuckyWheel_items_batchs_activeDate {
  __typename: "ActiveDate";
  startDate: any;
  endDate: any;
}

export interface cmsListLuckyWheel_cmsListLuckyWheel_items_batchs {
  __typename: "Batch";
  _id: string;
  activeDate: cmsListLuckyWheel_cmsListLuckyWheel_items_batchs_activeDate;
  createdAt: any;
}

export interface cmsListLuckyWheel_cmsListLuckyWheel_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
}

export interface cmsListLuckyWheel_cmsListLuckyWheel_items {
  __typename: "LuckyWheel";
  _id: string;
  title: string;
  totalPrize: number | null;
  totalRedeemPrize: number | null;
  totalRemainingPrize: number | null;
  activeDate: cmsListLuckyWheel_cmsListLuckyWheel_items_activeDate;
  numberOfBatch: number;
  batchs: (cmsListLuckyWheel_cmsListLuckyWheel_items_batchs | null)[] | null;
  createdAt: any;
  creator: cmsListLuckyWheel_cmsListLuckyWheel_items_creator | null;
  deletedAt: any | null;
}

export interface cmsListLuckyWheel_cmsListLuckyWheel {
  __typename: "ListLuckyWheelResponse";
  counter: number | null;
  items: cmsListLuckyWheel_cmsListLuckyWheel_items[] | null;
}

export interface cmsListLuckyWheel {
  cmsListLuckyWheel: cmsListLuckyWheel_cmsListLuckyWheel | null;
}

export interface cmsListLuckyWheelVariables {
  limit: number;
  page?: number | null;
  filter?: LuckyWheelFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsGetRunningLuckyWheel
// ====================================================

export interface cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_activeDate {
  __typename: "ActiveDate";
  startDate: any;
  endDate: any;
}

export interface cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_batchs_activeDate {
  __typename: "ActiveDate";
  startDate: any;
  endDate: any;
}

export interface cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_batchs_prizes {
  __typename: "Prize";
  _id: string;
  name: string;
  point: number | null;
  prize_index: number | null;
}

export interface cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_batchs {
  __typename: "Batch";
  _id: string;
  activeDate: cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_batchs_activeDate;
  prizes: cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_batchs_prizes[] | null;
  createdAt: any;
}

export interface cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
}

export interface cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel {
  __typename: "LuckyWheel";
  _id: string;
  title: string;
  totalPrize: number | null;
  totalRedeemPrize: number | null;
  totalRemainingPrize: number | null;
  activeDate: cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_activeDate;
  numberOfBatch: number;
  batchs: (cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_batchs | null)[] | null;
  createdAt: any;
  creator: cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel_creator | null;
}

export interface cmsGetRunningLuckyWheel {
  cmsGetRunningLuckyWheel: cmsGetRunningLuckyWheel_cmsGetRunningLuckyWheel | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsLuckyWheel
// ====================================================

export interface cmsLuckyWheel_cmsLuckyWheel_activeDate {
  __typename: "ActiveDate";
  startDate: any;
  endDate: any;
}

export interface cmsLuckyWheel_cmsLuckyWheel_batchs_activeDate {
  __typename: "ActiveDate";
  startDate: any;
  endDate: any;
}

export interface cmsLuckyWheel_cmsLuckyWheel_batchs_prizes_media {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface cmsLuckyWheel_cmsLuckyWheel_batchs_prizes {
  __typename: "Prize";
  _id: string;
  name: string;
  nameEn: string | null;
  qty: number;
  prize_index: number | null;
  point: number | null;
  remaining: number | null;
  voucherId: string | null;
  prizeTier: PrizeTier | null;
  media: cmsLuckyWheel_cmsLuckyWheel_batchs_prizes_media | null;
}

export interface cmsLuckyWheel_cmsLuckyWheel_batchs {
  __typename: "Batch";
  _id: string;
  activeDate: cmsLuckyWheel_cmsLuckyWheel_batchs_activeDate;
  totalPrize: number | null;
  totalRedeemPrize: number | null;
  prizes: cmsLuckyWheel_cmsLuckyWheel_batchs_prizes[] | null;
}

export interface cmsLuckyWheel_cmsLuckyWheel {
  __typename: "LuckyWheel";
  _id: string;
  title: string;
  titleEn: string | null;
  activeDate: cmsLuckyWheel_cmsLuckyWheel_activeDate;
  numberOfBatch: number;
  batchs: (cmsLuckyWheel_cmsLuckyWheel_batchs | null)[] | null;
}

export interface cmsLuckyWheel {
  cmsLuckyWheel: cmsLuckyWheel_cmsLuckyWheel | null;
}

export interface cmsLuckyWheelVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListLuckyWheelPrizeWinners
// ====================================================

export interface cmsListLuckyWheelPrizeWinners_cmsListLuckyWheelPrizeWinners_wonUsers {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  phoneNumber: string | null;
}

export interface cmsListLuckyWheelPrizeWinners_cmsListLuckyWheelPrizeWinners_designatedUsers {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  phoneNumber: string | null;
}

export interface cmsListLuckyWheelPrizeWinners_cmsListLuckyWheelPrizeWinners {
  __typename: "ListLuckyWheelPrizeWinners";
  wonUsers: cmsListLuckyWheelPrizeWinners_cmsListLuckyWheelPrizeWinners_wonUsers[] | null;
  designatedUsers: cmsListLuckyWheelPrizeWinners_cmsListLuckyWheelPrizeWinners_designatedUsers[] | null;
}

export interface cmsListLuckyWheelPrizeWinners {
  cmsListLuckyWheelPrizeWinners: cmsListLuckyWheelPrizeWinners_cmsListLuckyWheelPrizeWinners | null;
}

export interface cmsListLuckyWheelPrizeWinnersVariables {
  prizeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateLuckyWheelPrizeWinner
// ====================================================

export interface updateLuckyWheelPrizeWinner_updateLuckyWheelPrizeWinners {
  __typename: "ListLuckyWheelPrizeWinners";
  wonUserIds: (string | null)[] | null;
  designatedUserIds: (string | null)[] | null;
}

export interface updateLuckyWheelPrizeWinner {
  updateLuckyWheelPrizeWinners: updateLuckyWheelPrizeWinner_updateLuckyWheelPrizeWinners | null;
}

export interface updateLuckyWheelPrizeWinnerVariables {
  prizeId: string;
  designatedUserPhoneNumbers?: (string | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteLuckyWheelPrize
// ====================================================

export interface deleteLuckyWheelPrize {
  deleteLuckyWheelPrize: boolean;
}

export interface deleteLuckyWheelPrizeVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createLuckyWheelPrize
// ====================================================

export interface createLuckyWheelPrize_createLuckyWheelPrize {
  __typename: "Prize";
  _id: string;
  name: string;
  qty: number;
  prizeTier: PrizeTier | null;
  prize_index: number | null;
  mediaId: string | null;
  voucherId: string | null;
}

export interface createLuckyWheelPrize {
  createLuckyWheelPrize: createLuckyWheelPrize_createLuckyWheelPrize;
}

export interface createLuckyWheelPrizeVariables {
  batchId: string;
  data: PrizeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateLuckyWheelPrize
// ====================================================

export interface updateLuckyWheelPrize_updateLuckyWheelPrize {
  __typename: "Prize";
  _id: string;
  name: string;
  qty: number;
  prizeTier: PrizeTier | null;
  prize_index: number | null;
  mediaId: string | null;
  voucherId: string | null;
}

export interface updateLuckyWheelPrize {
  updateLuckyWheelPrize: updateLuckyWheelPrize_updateLuckyWheelPrize;
}

export interface updateLuckyWheelPrizeVariables {
  _id: string;
  data: PrizeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateLuckyWheel
// ====================================================

export interface updateLuckyWheel_updateLuckyWheel_activeDate {
  __typename: "ActiveDate";
  startDate: any;
  endDate: any;
}

export interface updateLuckyWheel_updateLuckyWheel {
  __typename: "LuckyWheel";
  _id: string;
  title: string;
  activeDate: updateLuckyWheel_updateLuckyWheel_activeDate;
}

export interface updateLuckyWheel {
  updateLuckyWheel: updateLuckyWheel_updateLuckyWheel;
}

export interface updateLuckyWheelVariables {
  _id: string;
  data: LuckyWheelInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsUpdateLuckyPrizesName
// ====================================================

export interface cmsUpdateLuckyPrizesName {
  cmsUpdateLuckyPrizesName: boolean;
}

export interface cmsUpdateLuckyPrizesNameVariables {
  luckyId: string;
  prizeTier: PrizeTier;
  name: string;
  nameEn: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsLuckyWheelInstructionTypes
// ====================================================

export interface cmsLuckyWheelInstructionTypes_cmsLuckyWheelInstructionTypes {
  __typename: "InstructionTypesResponse";
  type: string;
  title: string | null;
}

export interface cmsLuckyWheelInstructionTypes {
  cmsLuckyWheelInstructionTypes: (cmsLuckyWheelInstructionTypes_cmsLuckyWheelInstructionTypes | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListLuckyWheelInstruction
// ====================================================

export interface cmsListLuckyWheelInstruction_cmsListLuckyWheelInstruction_items {
  __typename: "LuckyWheelInstruction";
  _id: string;
  title: string;
  titleEn: string | null;
  descriptionEn: string | null;
  description: string | null;
  turns: number;
  type: LuckyWheelInstructionType;
  createdAt: any;
  deletedAt: any | null;
  updatedAt: any | null;
}

export interface cmsListLuckyWheelInstruction_cmsListLuckyWheelInstruction {
  __typename: "ListLuckyWheelInstructionResponse";
  counter: number | null;
  items: cmsListLuckyWheelInstruction_cmsListLuckyWheelInstruction_items[] | null;
}

export interface cmsListLuckyWheelInstruction {
  cmsListLuckyWheelInstruction: cmsListLuckyWheelInstruction_cmsListLuckyWheelInstruction | null;
}

export interface cmsListLuckyWheelInstructionVariables {
  limit: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createLuckyWheelInstruction
// ====================================================

export interface createLuckyWheelInstruction_createLuckyWheelInstruction {
  __typename: "LuckyWheelInstruction";
  _id: string;
  type: LuckyWheelInstructionType;
  title: string;
  titleEn: string | null;
  turns: number;
  description: string | null;
  descriptionEn: string | null;
  createdAt: any;
}

export interface createLuckyWheelInstruction {
  createLuckyWheelInstruction: createLuckyWheelInstruction_createLuckyWheelInstruction | null;
}

export interface createLuckyWheelInstructionVariables {
  data?: LuckyWheelInstructionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateLuckyWheelInstruction
// ====================================================

export interface updateLuckyWheelInstruction_updateLuckyWheelInstruction {
  __typename: "LuckyWheelInstruction";
  _id: string;
  type: LuckyWheelInstructionType;
  title: string;
  titleEn: string | null;
  turns: number;
  descriptionEn: string | null;
  description: string | null;
  createdAt: any;
}

export interface updateLuckyWheelInstruction {
  updateLuckyWheelInstruction: updateLuckyWheelInstruction_updateLuckyWheelInstruction | null;
}

export interface updateLuckyWheelInstructionVariables {
  _id: string;
  data?: LuckyWheelInstructionInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteLuckyWheelInstruction
// ====================================================

export interface deleteLuckyWheelInstruction {
  deleteLuckyWheelInstruction: boolean | null;
}

export interface deleteLuckyWheelInstructionVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListLuckyWheelConfigs
// ====================================================

export interface cmsListLuckyWheelConfigs_cmsListLuckyWheelConfigs {
  __typename: "LuckyWheelConfig";
  _id: string;
  prizeTier: PrizeTier;
  description: string | null;
  conditionType: LuckyWheelConfigType;
  missionType: LuckyWheelInstructionType | null;
  minimum: number;
}

export interface cmsListLuckyWheelConfigs {
  cmsListLuckyWheelConfigs: (cmsListLuckyWheelConfigs_cmsListLuckyWheelConfigs | null)[] | null;
}

export interface cmsListLuckyWheelConfigsVariables {
  limit: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsUpdateLuckyWheelConfig
// ====================================================

export interface cmsUpdateLuckyWheelConfig_cmsUpdateLuckyWheelConfig {
  __typename: "LuckyWheelConfig";
  _id: string;
  prizeTier: PrizeTier;
  conditionType: LuckyWheelConfigType;
  description: string | null;
  missionType: LuckyWheelInstructionType | null;
  minimum: number;
}

export interface cmsUpdateLuckyWheelConfig {
  cmsUpdateLuckyWheelConfig: cmsUpdateLuckyWheelConfig_cmsUpdateLuckyWheelConfig | null;
}

export interface cmsUpdateLuckyWheelConfigVariables {
  _id: string;
  prizeTier: PrizeTier;
  conditionType: LuckyWheelConfigType;
  missionType?: LuckyWheelInstructionType | null;
  minimum: number;
  description?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleleLuckyWheel
// ====================================================

export interface deleleLuckyWheel {
  deleteLuckyWheel: boolean;
}

export interface deleleLuckyWheelVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createLuckyWheelBatch
// ====================================================

export interface createLuckyWheelBatch_createLuckyWheelBatch_activeDate {
  __typename: "ActiveDate";
  startDate: any;
  endDate: any;
}

export interface createLuckyWheelBatch_createLuckyWheelBatch {
  __typename: "Batch";
  _id: string;
  activeDate: createLuckyWheelBatch_createLuckyWheelBatch_activeDate;
}

export interface createLuckyWheelBatch {
  createLuckyWheelBatch: createLuckyWheelBatch_createLuckyWheelBatch;
}

export interface createLuckyWheelBatchVariables {
  luckyWheelId: string;
  data?: BatchInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteLuckyWheelBatch
// ====================================================

export interface deleteLuckyWheelBatch {
  deleteLuckyWheelBatch: boolean;
}

export interface deleteLuckyWheelBatchVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListFailedSpinMessages
// ====================================================

export interface cmsListFailedSpinMessages_cmsListFailedSpinMessages_items {
  __typename: "FailedSpinMessage";
  _id: string;
  content: string | null;
  contentEn: string | null;
}

export interface cmsListFailedSpinMessages_cmsListFailedSpinMessages {
  __typename: "FailedSpinMessageResponse";
  counter: number | null;
  items: cmsListFailedSpinMessages_cmsListFailedSpinMessages_items[] | null;
}

export interface cmsListFailedSpinMessages {
  cmsListFailedSpinMessages: cmsListFailedSpinMessages_cmsListFailedSpinMessages | null;
}

export interface cmsListFailedSpinMessagesVariables {
  q?: string | null;
  limit: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsCreateFailedSpinMessages
// ====================================================

export interface cmsCreateFailedSpinMessages_cmsCreateFailedSpinMessages {
  __typename: "FailedSpinMessage";
  _id: string;
  content: string | null;
  contentEn: string | null;
  createdAt: any | null;
}

export interface cmsCreateFailedSpinMessages {
  cmsCreateFailedSpinMessages: cmsCreateFailedSpinMessages_cmsCreateFailedSpinMessages;
}

export interface cmsCreateFailedSpinMessagesVariables {
  data: FailedSpinMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsDeleteFailedSpinMessages
// ====================================================

export interface cmsDeleteFailedSpinMessages {
  cmsDeleteFailedSpinMessages: boolean;
}

export interface cmsDeleteFailedSpinMessagesVariables {
  _ids?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsImportFailedSpinMessages
// ====================================================

export interface cmsImportFailedSpinMessages {
  cmsImportFailedSpinMessages: boolean;
}

export interface cmsImportFailedSpinMessagesVariables {
  data: ImportFailedSpinMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsUpdateFailedSpinMessage
// ====================================================

export interface cmsUpdateFailedSpinMessage_cmsUpdateFailedSpinMessage {
  __typename: "FailedSpinMessage";
  _id: string;
  content: string | null;
  contentEn: string | null;
}

export interface cmsUpdateFailedSpinMessage {
  cmsUpdateFailedSpinMessage: cmsUpdateFailedSpinMessage_cmsUpdateFailedSpinMessage;
}

export interface cmsUpdateFailedSpinMessageVariables {
  _id: string;
  data?: FailedSpinMessageInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsDetailLuckyWheelTerm
// ====================================================

export interface cmsDetailLuckyWheelTerm_cmsDetailLuckyWheelTerm {
  __typename: "LuckyWheelTerm";
  _id: string;
  content: string;
  contentEn: string;
  createdAt: any;
  updatedAt: any | null;
}

export interface cmsDetailLuckyWheelTerm {
  cmsDetailLuckyWheelTerm: cmsDetailLuckyWheelTerm_cmsDetailLuckyWheelTerm | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createLuckyWheelTerm
// ====================================================

export interface createLuckyWheelTerm_cmsCreateLuckyWheelTerm {
  __typename: "LuckyWheelTerm";
  _id: string;
  content: string;
  contentEn: string;
  createdAt: any;
  updatedAt: any | null;
}

export interface createLuckyWheelTerm {
  cmsCreateLuckyWheelTerm: createLuckyWheelTerm_cmsCreateLuckyWheelTerm;
}

export interface createLuckyWheelTermVariables {
  data: luckyWheelTermInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateLuckyWheelTerm
// ====================================================

export interface updateLuckyWheelTerm {
  cmsUpdateLuckyWheelTerm: boolean;
}

export interface updateLuckyWheelTermVariables {
  _id: string;
  data: luckyWheelTermInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPageCategories
// ====================================================

export interface GetPageCategories_cmsGetPageCategories_items_name {
  __typename: "PageCategoryName";
  VI_VN: string;
  EN_US: string;
}

export interface GetPageCategories_cmsGetPageCategories_items {
  __typename: "PageCategory";
  _id: string;
  name: GetPageCategories_cmsGetPageCategories_items_name;
  enum: string;
  deletedAt: any | null;
}

export interface GetPageCategories_cmsGetPageCategories {
  __typename: "cmsGetPageCategoriesRes";
  counter: number | null;
  items: GetPageCategories_cmsGetPageCategories_items[] | null;
}

export interface GetPageCategories {
  cmsGetPageCategories: GetPageCategories_cmsGetPageCategories | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsUpdatePageCategory
// ====================================================

export interface CmsUpdatePageCategory {
  cmsUpdatePageCategory: boolean;
}

export interface CmsUpdatePageCategoryVariables {
  _id: string;
  name_vi: string;
  enum: string;
  name_en: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsCreatePageCategory
// ====================================================

export interface CmsCreatePageCategory {
  cmsCreatePageCategory: boolean;
}

export interface CmsCreatePageCategoryVariables {
  name_vi: string;
  enum: string;
  name_en: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPageVerify
// ====================================================

export interface GetPageVerify_cmsGetVerifies_items_owner {
  __typename: "User";
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
}

export interface GetPageVerify_cmsGetVerifies_items_photoIdentityMedias {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface GetPageVerify_cmsGetVerifies_items_businessLicenseMedias {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface GetPageVerify_cmsGetVerifies_items_photosTaken {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface GetPageVerify_cmsGetVerifies_items {
  __typename: "Verify";
  _id: string;
  targetName: string;
  /**
   * Dùng để tìm kiếm theo tên target, targetKeyName = targetName-_id
   */
  targetKeyName: string;
  /**
   * Loại xác thực: page, user
   */
  type: VerifyFor;
  targetType: PageType | null;
  status: VerifyStatus;
  /**
   * Admin thực hiện xác thực hoặc reject
   */
  description: string | null;
  createdAt: any | null;
  rejectReason: string | null;
  owner: GetPageVerify_cmsGetVerifies_items_owner | null;
  photoIdentityMedias: (GetPageVerify_cmsGetVerifies_items_photoIdentityMedias | null)[] | null;
  businessLicenseMedias: (GetPageVerify_cmsGetVerifies_items_businessLicenseMedias | null)[] | null;
  photosTaken: (GetPageVerify_cmsGetVerifies_items_photosTaken | null)[] | null;
}

export interface GetPageVerify_cmsGetVerifies {
  __typename: "cmsGetVerifiesRes";
  counter: number | null;
  items: GetPageVerify_cmsGetVerifies_items[] | null;
}

export interface GetPageVerify {
  cmsGetVerifies: GetPageVerify_cmsGetVerifies | null;
}

export interface GetPageVerifyVariables {
  limit: number;
  offset?: number | null;
  after?: string | null;
  q?: string | null;
  status?: VerifyStatus | null;
  type?: VerifyFor | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetPages
// ====================================================

export interface CmsGetPages_cmsGetPages_items_verify {
  __typename: "Verify";
  status: VerifyStatus;
}

export interface CmsGetPages_cmsGetPages_items_creator_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetPages_cmsGetPages_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
  point: number | null;
  createdAt: any;
  avatar: CmsGetPages_cmsGetPages_items_creator_avatar | null;
}

export interface CmsGetPages_cmsGetPages_items {
  __typename: "Page";
  _id: string;
  name: string;
  description: string | null;
  phoneNumber: string | null;
  website: string | null;
  verify: CmsGetPages_cmsGetPages_items_verify | null;
  email: string | null;
  /**
   * Page đối tác
   */
  isPartner: boolean | null;
  type: PageType;
  creator: CmsGetPages_cmsGetPages_items_creator | null;
  createdAt: any;
  /**
   * Page chính chủ
   */
  verified: boolean | null;
  totalLikes: number;
  totalPosts: number;
  totalFollows: number;
  totalReviews: number | null;
  totalReports: number | null;
  reason: string | null;
  /**
   * Page cung cấp tin tức hot
   */
  isProvideNews: boolean | null;
  pinnedLimit: number | null;
}

export interface CmsGetPages_cmsGetPages {
  __typename: "cmsGetPagesRes";
  counter: number | null;
  items: (CmsGetPages_cmsGetPages_items | null)[] | null;
}

export interface CmsGetPages {
  /**
   * Danh sách page cho cms admin
   */
  cmsGetPages: CmsGetPages_cmsGetPages | null;
}

export interface CmsGetPagesVariables {
  limit: number;
  offset?: number | null;
  after?: EsAfter | null;
  q?: string | null;
  filter?: PageFilter | null;
  sortBy?: PageSortBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsSetVerifyStatus
// ====================================================

export interface CmsSetVerifyStatus {
  cmsSetVerifyStatus: boolean;
}

export interface CmsSetVerifyStatusVariables {
  _id: string;
  status: VerifyStatus;
  reason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsSetPagePartner
// ====================================================

export interface CmsSetPagePartner {
  /**
   * Admin Set page is partner
   */
  cmsSetPagePartner: boolean;
}

export interface CmsSetPagePartnerVariables {
  _id: string;
  status: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsSetPageNews
// ====================================================

export interface cmsSetPageNews {
  /**
   * Admin Set page is provide news
   */
  cmsSetPageNews: boolean;
}

export interface cmsSetPageNewsVariables {
  _id: string;
  active: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsBlockPage
// ====================================================

export interface CmsBlockPage {
  /**
   * Thay đổi term status cho item, hiện tại dùng trong trường hợp block do bị report là chính
   */
  cmsSetTermStatus: boolean;
}

export interface CmsBlockPageVariables {
  _id: string;
  model: ReportFor;
  termStatus: TermStatus;
  reason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsSetPagePinnedProduct
// ====================================================

export interface cmsSetPagePinnedProduct {
  cmsSetPagePinnedProduct: boolean | null;
}

export interface cmsSetPagePinnedProductVariables {
  data?: PagePinnedProductSettingInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UploadPhoto
// ====================================================

export interface UploadPhoto_uploadPhoto {
  __typename: "Media";
  _id: string;
}

export interface UploadPhoto {
  uploadPhoto: UploadPhoto_uploadPhoto;
}

export interface UploadPhotoVariables {
  file: any;
  dimensions: PhotoDimensionsInput;
  bannerType?: MediaType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopPlaces
// ====================================================

export interface TopPlaces_topPlaces_primaryImage {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface TopPlaces_topPlaces_medias {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface TopPlaces_topPlaces_location_place {
  __typename: "Place";
  _id: string;
  name: string;
}

export interface TopPlaces_topPlaces_location {
  __typename: "PlaceLocation";
  _id: string;
  place: TopPlaces_topPlaces_location_place | null;
  country: string | null;
}

export interface TopPlaces_topPlaces {
  __typename: "Place";
  _id: string;
  name: string;
  description: string | null;
  /**
   * Tổng số lần checkin
   */
  checkins: number | null;
  /**
   * Tổng số lần review
   */
  reviews: number | null;
  primaryImage: TopPlaces_topPlaces_primaryImage | null;
  medias: (TopPlaces_topPlaces_medias | null)[] | null;
  location: TopPlaces_topPlaces_location | null;
  phone: string | null;
  isVerified: boolean | null;
  createdAt: any;
}

export interface TopPlaces {
  topPlaces: TopPlaces_topPlaces[] | null;
}

export interface TopPlacesVariables {
  limit: number;
  q?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsSetPlacePrimaryImage
// ====================================================

export interface CmsSetPlacePrimaryImage {
  cmsSetPlacePrimaryImage: boolean;
}

export interface CmsSetPlacePrimaryImageVariables {
  _id: string;
  imageId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PartnerProducts
// ====================================================

export interface PartnerProducts_cmsPartnerProducts_items_medias {
  __typename: "Media";
  uri: string;
  type: MediaType;
  thumbnail: string | null;
}

export interface PartnerProducts_cmsPartnerProducts_items_creator_avatar {
  __typename: "Media";
  uri: string;
}

export interface PartnerProducts_cmsPartnerProducts_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
  avatar: PartnerProducts_cmsPartnerProducts_items_creator_avatar | null;
  point: number | null;
  termStatus: TermStatus | null;
}

export interface PartnerProducts_cmsPartnerProducts_items_page_logo {
  __typename: "Media";
  uri: string;
}

export interface PartnerProducts_cmsPartnerProducts_items_page_owner_avatar {
  __typename: "Media";
  uri: string;
}

export interface PartnerProducts_cmsPartnerProducts_items_page_owner {
  __typename: "User";
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
  avatar: PartnerProducts_cmsPartnerProducts_items_page_owner_avatar | null;
}

export interface PartnerProducts_cmsPartnerProducts_items_page_admins_avatar {
  __typename: "Media";
  uri: string;
}

export interface PartnerProducts_cmsPartnerProducts_items_page_admins {
  __typename: "User";
  fullName: string;
  phoneNumber: string | null;
  avatar: PartnerProducts_cmsPartnerProducts_items_page_admins_avatar | null;
}

export interface PartnerProducts_cmsPartnerProducts_items_page {
  __typename: "Page";
  _id: string;
  name: string;
  email: string | null;
  phoneNumber: string | null;
  logo: PartnerProducts_cmsPartnerProducts_items_page_logo | null;
  owner: PartnerProducts_cmsPartnerProducts_items_page_owner | null;
  admins: PartnerProducts_cmsPartnerProducts_items_page_admins[] | null;
  termStatus: TermStatus | null;
}

export interface PartnerProducts_cmsPartnerProducts_items {
  __typename: "Post";
  _id: string;
  type: PostType;
  title: string | null;
  content: string | null;
  termStatus: TermStatus | null;
  totalReports: number | null;
  /**
   * Giá sản phẩm
   */
  price: number | null;
  medias: PartnerProducts_cmsPartnerProducts_items_medias[] | null;
  availableOnTravelStore: boolean | null;
  creator: PartnerProducts_cmsPartnerProducts_items_creator | null;
  page: PartnerProducts_cmsPartnerProducts_items_page | null;
  createdAt: any;
  totalLikes: number;
  totalShares: number;
}

export interface PartnerProducts_cmsPartnerProducts {
  __typename: "cmsPartnerProductsRes";
  counter: number | null;
  items: (PartnerProducts_cmsPartnerProducts_items | null)[] | null;
}

export interface PartnerProducts {
  cmsPartnerProducts: PartnerProducts_cmsPartnerProducts | null;
}

export interface PartnerProductsVariables {
  limit: number;
  offset?: number | null;
  q?: string | null;
  type?: TermStatus | null;
  filter?: ProductFilter | null;
  sortBy?: ProductSortBy | null;
  onTravelStore?: OnTravelStore | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteProduct
// ====================================================

export interface DeleteProduct {
  cmsDeleteProduct: boolean | null;
}

export interface DeleteProductVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetPageReports
// ====================================================

export interface CmsGetPageReports_cmsGetPageReports_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  email: string | null;
}

export interface CmsGetPageReports_cmsGetPageReports_items {
  __typename: "Page";
  _id: string;
  name: string;
  /**
   * Tìm kiếm user theo tên và sort, truyền lên bằng "after"
   */
  keyName: string | null;
  type: PageType;
  businessType: PageBusinessType | null;
  description: string | null;
  phoneNumber: string | null;
  reason: string | null;
  createdAt: any;
  /**
   * Status owner có thể tự chuyển được
   */
  status: PageStatus | null;
  termStatus: TermStatus | null;
  creator: CmsGetPageReports_cmsGetPageReports_items_creator | null;
}

export interface CmsGetPageReports_cmsGetPageReports {
  __typename: "CmsPageData";
  total: number;
  offset: number | null;
  limit: number | null;
  items: CmsGetPageReports_cmsGetPageReports_items[] | null;
}

export interface CmsGetPageReports {
  /**
   * Danh sách item bị report, gồm có Post, Page, User, Comment, Media...
   */
  cmsGetPageReports: CmsGetPageReports_cmsGetPageReports;
}

export interface CmsGetPageReportsVariables {
  limit: number;
  offset?: number | null;
  filter?: CmsGetPageReportFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsListReportByTargetId
// ====================================================

export interface CmsListReportByTargetId_cmsListReportByTargetId_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
}

export interface CmsListReportByTargetId_cmsListReportByTargetId {
  __typename: "Report";
  _id: string;
  message: string | null;
  postReportType: PostReportType;
  creator: CmsListReportByTargetId_cmsListReportByTargetId_creator | null;
  createdAt: any | null;
}

export interface CmsListReportByTargetId {
  /**
   * Lấy danh sách report liên quan đến 1 đối tượng, sắp xếp từ mới đến cũ
   */
  cmsListReportByTargetId: CmsListReportByTargetId_cmsListReportByTargetId[] | null;
}

export interface CmsListReportByTargetIdVariables {
  limit: number;
  type: ReportFor;
  targetId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetPostReports
// ====================================================

export interface CmsGetPostReports_cmsGetPostReports_items_medias {
  __typename: "Media";
  uri: string;
}

export interface CmsGetPostReports_cmsGetPostReports_items_creator_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetPostReports_cmsGetPostReports_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
  avatar: CmsGetPostReports_cmsGetPostReports_items_creator_avatar | null;
  point: number | null;
  termStatus: TermStatus | null;
}

export interface CmsGetPostReports_cmsGetPostReports_items_page_logo {
  __typename: "Media";
  uri: string;
}

export interface CmsGetPostReports_cmsGetPostReports_items_page_owner_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetPostReports_cmsGetPostReports_items_page_owner {
  __typename: "User";
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
  avatar: CmsGetPostReports_cmsGetPostReports_items_page_owner_avatar | null;
}

export interface CmsGetPostReports_cmsGetPostReports_items_page_admins_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetPostReports_cmsGetPostReports_items_page_admins {
  __typename: "User";
  fullName: string;
  phoneNumber: string | null;
  avatar: CmsGetPostReports_cmsGetPostReports_items_page_admins_avatar | null;
}

export interface CmsGetPostReports_cmsGetPostReports_items_page {
  __typename: "Page";
  _id: string;
  name: string;
  email: string | null;
  phoneNumber: string | null;
  logo: CmsGetPostReports_cmsGetPostReports_items_page_logo | null;
  owner: CmsGetPostReports_cmsGetPostReports_items_page_owner | null;
  admins: CmsGetPostReports_cmsGetPostReports_items_page_admins[] | null;
  termStatus: TermStatus | null;
}

export interface CmsGetPostReports_cmsGetPostReports_items {
  __typename: "Post";
  _id: string;
  note: string | null;
  medias: CmsGetPostReports_cmsGetPostReports_items_medias[] | null;
  title: string | null;
  type: PostType;
  reason: string | null;
  createdAt: any;
  termStatus: TermStatus | null;
  content: string | null;
  totalReports: number | null;
  lastReportAt: any | null;
  isAdminReport: boolean | null;
  creator: CmsGetPostReports_cmsGetPostReports_items_creator | null;
  page: CmsGetPostReports_cmsGetPostReports_items_page | null;
}

export interface CmsGetPostReports_cmsGetPostReports {
  __typename: "CmsPostData";
  total: number;
  offset: number | null;
  limit: number | null;
  items: CmsGetPostReports_cmsGetPostReports_items[] | null;
}

export interface CmsGetPostReports {
  cmsGetPostReports: CmsGetPostReports_cmsGetPostReports;
}

export interface CmsGetPostReportsVariables {
  limit: number;
  offset?: number | null;
  filter?: CmsGetPostReportFilter | null;
  sortType?: SortReportType | null;
  sortDir?: SortByDirection | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetUserReports
// ====================================================

export interface CmsGetUserReports_cmsGetUserReports_items_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetUserReports_cmsGetUserReports_items {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  email: string | null;
  avatar: CmsGetUserReports_cmsGetUserReports_items_avatar | null;
  reason: string | null;
  createdAt: any;
  termStatus: TermStatus | null;
}

export interface CmsGetUserReports_cmsGetUserReports {
  __typename: "CmsUserData";
  total: number;
  offset: number | null;
  limit: number | null;
  items: CmsGetUserReports_cmsGetUserReports_items[] | null;
}

export interface CmsGetUserReports {
  cmsGetUserReports: CmsGetUserReports_cmsGetUserReports;
}

export interface CmsGetUserReportsVariables {
  limit: number;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetCommentReports
// ====================================================

export interface CmsGetCommentReports_cmsGetCommentReports_items_medias {
  __typename: "Media";
  uri: string;
}

export interface CmsGetCommentReports_cmsGetCommentReports_items_creator_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetCommentReports_cmsGetCommentReports_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
  avatar: CmsGetCommentReports_cmsGetCommentReports_items_creator_avatar | null;
  point: number | null;
  termStatus: TermStatus | null;
}

export interface CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_logo {
  __typename: "Media";
  uri: string;
}

export interface CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_owner_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_owner {
  __typename: "User";
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
  avatar: CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_owner_avatar | null;
}

export interface CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_admins_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_admins {
  __typename: "User";
  fullName: string;
  phoneNumber: string | null;
  avatar: CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_admins_avatar | null;
}

export interface CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage {
  __typename: "Page";
  _id: string;
  name: string;
  email: string | null;
  phoneNumber: string | null;
  logo: CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_logo | null;
  owner: CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_owner | null;
  admins: CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage_admins[] | null;
  termStatus: TermStatus | null;
}

export interface CmsGetCommentReports_cmsGetCommentReports_items {
  __typename: "Comment";
  _id: string;
  content: string | null;
  isAdminReport: boolean | null;
  medias: CmsGetCommentReports_cmsGetCommentReports_items_medias[] | null;
  creator: CmsGetCommentReports_cmsGetCommentReports_items_creator | null;
  commentedAsPage: CmsGetCommentReports_cmsGetCommentReports_items_commentedAsPage | null;
  reason: string | null;
  createdAt: any;
  termStatus: TermStatus | null;
  lastReportAt: any | null;
  totalReports: number | null;
}

export interface CmsGetCommentReports_cmsGetCommentReports {
  __typename: "CmsCommentData";
  total: number;
  offset: number | null;
  limit: number | null;
  items: CmsGetCommentReports_cmsGetCommentReports_items[] | null;
}

export interface CmsGetCommentReports {
  cmsGetCommentReports: CmsGetCommentReports_cmsGetCommentReports;
}

export interface CmsGetCommentReportsVariables {
  limit: number;
  offset?: number | null;
  sortDir?: SortByDirection | null;
  filter?: CmsGetCommentFilterInput | null;
  sortType?: SortReportType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetMediaReports
// ====================================================

export interface CmsGetMediaReports_cmsGetMediaReports_items_uploader {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  email: string | null;
}

export interface CmsGetMediaReports_cmsGetMediaReports_items {
  __typename: "Media";
  _id: string;
  uri: string;
  uploader: CmsGetMediaReports_cmsGetMediaReports_items_uploader | null;
  reason: string | null;
  totalLikes: number | null;
  termStatus: TermStatus | null;
  uploadedAt: any;
  totalReports: number | null;
}

export interface CmsGetMediaReports_cmsGetMediaReports {
  __typename: "CmsMediaData";
  total: number;
  offset: number | null;
  limit: number | null;
  items: CmsGetMediaReports_cmsGetMediaReports_items[] | null;
}

export interface CmsGetMediaReports {
  cmsGetMediaReports: CmsGetMediaReports_cmsGetMediaReports;
}

export interface CmsGetMediaReportsVariables {
  limit: number;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetGroupReports
// ====================================================

export interface CmsGetGroupReports_cmsGetGroupReports_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  email: string | null;
}

export interface CmsGetGroupReports_cmsGetGroupReports_items {
  __typename: "Group";
  _id: string;
  name: string;
  creator: CmsGetGroupReports_cmsGetGroupReports_items_creator | null;
  reason: string | null;
  termStatus: TermStatus | null;
  totalReports: number | null;
  createdAt: any;
  totalMembers: number;
  totalPosts: number;
}

export interface CmsGetGroupReports_cmsGetGroupReports {
  __typename: "CmsGroupData";
  total: number;
  offset: number | null;
  limit: number | null;
  items: CmsGetGroupReports_cmsGetGroupReports_items[] | null;
}

export interface CmsGetGroupReports {
  cmsGetGroupReports: CmsGetGroupReports_cmsGetGroupReports;
}

export interface CmsGetGroupReportsVariables {
  limit: number;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetProductReports
// ====================================================

export interface CmsGetProductReports_cmsGetProductReports_items_creator_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetProductReports_cmsGetProductReports_items_creator {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
  avatar: CmsGetProductReports_cmsGetProductReports_items_creator_avatar | null;
  point: number | null;
  termStatus: TermStatus | null;
}

export interface CmsGetProductReports_cmsGetProductReports_items_medias {
  __typename: "Media";
  uri: string;
}

export interface CmsGetProductReports_cmsGetProductReports_items_page_logo {
  __typename: "Media";
  uri: string;
}

export interface CmsGetProductReports_cmsGetProductReports_items_page_owner_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetProductReports_cmsGetProductReports_items_page_owner {
  __typename: "User";
  fullName: string;
  email: string | null;
  phoneNumber: string | null;
  avatar: CmsGetProductReports_cmsGetProductReports_items_page_owner_avatar | null;
}

export interface CmsGetProductReports_cmsGetProductReports_items_page_admins_avatar {
  __typename: "Media";
  uri: string;
}

export interface CmsGetProductReports_cmsGetProductReports_items_page_admins {
  __typename: "User";
  fullName: string;
  phoneNumber: string | null;
  avatar: CmsGetProductReports_cmsGetProductReports_items_page_admins_avatar | null;
}

export interface CmsGetProductReports_cmsGetProductReports_items_page {
  __typename: "Page";
  _id: string;
  name: string;
  email: string | null;
  phoneNumber: string | null;
  logo: CmsGetProductReports_cmsGetProductReports_items_page_logo | null;
  owner: CmsGetProductReports_cmsGetProductReports_items_page_owner | null;
  admins: CmsGetProductReports_cmsGetProductReports_items_page_admins[] | null;
  termStatus: TermStatus | null;
}

export interface CmsGetProductReports_cmsGetProductReports_items {
  __typename: "Post";
  _id: string;
  title: string | null;
  content: string | null;
  creator: CmsGetProductReports_cmsGetProductReports_items_creator | null;
  medias: CmsGetProductReports_cmsGetProductReports_items_medias[] | null;
  reason: string | null;
  type: PostType;
  termStatus: TermStatus | null;
  totalReports: number | null;
  lastReportAt: any | null;
  createdAt: any;
  isAdminReport: boolean | null;
  /**
   * Giá sản phẩm
   */
  price: number | null;
  page: CmsGetProductReports_cmsGetProductReports_items_page | null;
}

export interface CmsGetProductReports_cmsGetProductReports {
  __typename: "CmsPostData";
  total: number;
  offset: number | null;
  limit: number | null;
  items: CmsGetProductReports_cmsGetProductReports_items[] | null;
}

export interface CmsGetProductReports {
  cmsGetProductReports: CmsGetProductReports_cmsGetProductReports;
}

export interface CmsGetProductReportsVariables {
  limit: number;
  offset?: number | null;
  filter?: CmsGetPostFiltersInput | null;
  sortBy?: PostSortBy | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CmsGetRecentProductReports
// ====================================================

export interface CmsGetRecentProductReports_cmsListReportByTargetId_creator {
  __typename: "User";
  fullName: string;
}

export interface CmsGetRecentProductReports_cmsListReportByTargetId {
  __typename: "Report";
  _id: string;
  message: string | null;
  postReportType: PostReportType;
  creator: CmsGetRecentProductReports_cmsListReportByTargetId_creator | null;
  createdAt: any | null;
}

export interface CmsGetRecentProductReports {
  /**
   * Lấy danh sách report liên quan đến 1 đối tượng, sắp xếp từ mới đến cũ
   */
  cmsListReportByTargetId: CmsGetRecentProductReports_cmsListReportByTargetId[] | null;
}

export interface CmsGetRecentProductReportsVariables {
  targetId: string;
  type: ReportFor;
  limit: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsReportPost
// ====================================================

export interface cmsReportPost {
  cmsReportPost: boolean | null;
}

export interface cmsReportPostVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsCancelReportPost
// ====================================================

export interface cmsCancelReportPost {
  cmsCancelReportPost: boolean | null;
}

export interface cmsCancelReportPostVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsReportComment
// ====================================================

export interface cmsReportComment {
  cmsReportComment: boolean | null;
}

export interface cmsReportCommentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsCancelReportComment
// ====================================================

export interface cmsCancelReportComment {
  cmsCancelReportComment: boolean | null;
}

export interface cmsCancelReportCommentVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsGetPostsByCreator
// ====================================================

export interface cmsGetPostsByCreator_cmsGetPostsByCreator_medias {
  __typename: "Media";
  type: MediaType;
  thumbnail: string | null;
  uri: string;
}

export interface cmsGetPostsByCreator_cmsGetPostsByCreator {
  __typename: "Post";
  _id: string;
  type: PostType;
  title: string | null;
  content: string | null;
  createdAt: any;
  totalLikes: number;
  totalComments: number;
  medias: cmsGetPostsByCreator_cmsGetPostsByCreator_medias[] | null;
}

export interface cmsGetPostsByCreator {
  cmsGetPostsByCreator: cmsGetPostsByCreator_cmsGetPostsByCreator[] | null;
}

export interface cmsGetPostsByCreatorVariables {
  targetId: string;
  limit: number;
  isPage?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsGetDetailReports
// ====================================================

export interface cmsGetDetailReports_cmsGetDetailReports_items_creator {
  __typename: "User";
  fullName: string;
}

export interface cmsGetDetailReports_cmsGetDetailReports_items {
  __typename: "Report";
  _id: string;
  message: string | null;
  creator: cmsGetDetailReports_cmsGetDetailReports_items_creator | null;
  for: ReportFor;
  postReportType: PostReportType;
  createdAt: any | null;
}

export interface cmsGetDetailReports_cmsGetDetailReports {
  __typename: "getReportsOutput";
  counter: number | null;
  items: cmsGetDetailReports_cmsGetDetailReports_items[] | null;
}

export interface cmsGetDetailReports {
  cmsGetDetailReports: cmsGetDetailReports_cmsGetDetailReports;
}

export interface cmsGetDetailReportsVariables {
  id: string;
  forModel: ReportFor;
  limit: number;
  page: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsGetBlockedDetailList
// ====================================================

export interface cmsGetBlockedDetailList_cmsGetBlockedDetailList_items {
  __typename: "cmsBlockType";
  reason: string | null;
  status: TermStatus | null;
  createdAt: any | null;
}

export interface cmsGetBlockedDetailList_cmsGetBlockedDetailList {
  __typename: "getBlockedListOutput";
  counter: number | null;
  items: cmsGetBlockedDetailList_cmsGetBlockedDetailList_items[] | null;
}

export interface cmsGetBlockedDetailList {
  cmsGetBlockedDetailList: cmsGetBlockedDetailList_cmsGetBlockedDetailList;
}

export interface cmsGetBlockedDetailListVariables {
  id: string;
  forModel: ReportFor;
  limit: number;
  page: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsSetTermStatus
// ====================================================

export interface CmsSetTermStatus {
  /**
   * Thay đổi term status cho item, hiện tại dùng trong trường hợp block do bị report là chính
   */
  cmsSetTermStatus: boolean;
}

export interface CmsSetTermStatusVariables {
  _id: string;
  model: ReportFor;
  termStatus: TermStatus;
  reason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSensitiveWords
// ====================================================

export interface GetSensitiveWords_cmsBlackListWords_items {
  __typename: "BlackListWord";
  _id: string;
  key: string;
}

export interface GetSensitiveWords_cmsBlackListWords {
  __typename: "cmsBlackListWordsRes";
  counter: number | null;
  items: GetSensitiveWords_cmsBlackListWords_items[] | null;
}

export interface GetSensitiveWords {
  cmsBlackListWords: GetSensitiveWords_cmsBlackListWords | null;
}

export interface GetSensitiveWordsVariables {
  q?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AddSensitiveWords
// ====================================================

export interface AddSensitiveWords {
  addWordsToBlackList: boolean | null;
}

export interface AddSensitiveWordsVariables {
  keywords?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSensitiveWord
// ====================================================

export interface UpdateSensitiveWord_updateBlackWord {
  __typename: "BlackListWord";
  _id: string;
  key: string;
}

export interface UpdateSensitiveWord {
  updateBlackWord: UpdateSensitiveWord_updateBlackWord;
}

export interface UpdateSensitiveWordVariables {
  id: string;
  keyword?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveSensitiveWordByIds
// ====================================================

export interface RemoveSensitiveWordByIds {
  removeBlackListWordByIds: boolean | null;
}

export interface RemoveSensitiveWordByIdsVariables {
  ids?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveSensitiveWordByKeys
// ====================================================

export interface RemoveSensitiveWordByKeys {
  removeBlackListWordByKeys: boolean | null;
}

export interface RemoveSensitiveWordByKeysVariables {
  keywords?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSettings
// ====================================================

export interface GetSettings_cmsGetSettings {
  __typename: "Setting";
  _id: string;
  key: SettingKey;
  value: string;
  description: string;
  type: SettingType;
}

export interface GetSettings {
  cmsGetSettings: GetSettings_cmsGetSettings[] | null;
}

export interface GetSettingsVariables {
  limit?: number | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSetting
// ====================================================

export interface UpdateSetting_cmsUpdateSetting {
  __typename: "Setting";
  _id: string;
  key: SettingKey;
  value: string;
  description: string;
  type: SettingType;
}

export interface UpdateSetting {
  cmsUpdateSetting: UpdateSetting_cmsUpdateSetting;
}

export interface UpdateSettingVariables {
  data: SettingInput;
  key: SettingKey;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMyNotifications
// ====================================================

export interface GetMyNotifications_getSystemNotifications_items_fromUser {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
}

export interface GetMyNotifications_getSystemNotifications_items {
  __typename: "Notification";
  _id: string;
  sendToAllUsers: boolean | null;
  customMessage: string | null;
  systemNotificationTo: SystemNotificationTo | null;
  sent: boolean | null;
  type: NotificationType;
  createdAt: any;
  fromUser: GetMyNotifications_getSystemNotifications_items_fromUser | null;
}

export interface GetMyNotifications_getSystemNotifications {
  __typename: "systemNotificationRes";
  counter: number | null;
  items: GetMyNotifications_getSystemNotifications_items[] | null;
}

export interface GetMyNotifications {
  getSystemNotifications: GetMyNotifications_getSystemNotifications | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateSystemNotification
// ====================================================

export interface CreateSystemNotification_createSystemNotification {
  __typename: "Notification";
  _id: string;
  sendToAllUsers: boolean | null;
  systemNotificationTo: SystemNotificationTo | null;
  customMessage: string | null;
}

export interface CreateSystemNotification {
  createSystemNotification: CreateSystemNotification_createSystemNotification | null;
}

export interface CreateSystemNotificationVariables {
  customMessage: string;
  systemNotificationTo: SystemNotificationTo;
  excludedUserIds?: string[] | null;
  excludedPageIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSystemNotification
// ====================================================

export interface UpdateSystemNotification_updateSystemNotification {
  __typename: "Notification";
  _id: string;
  sendToAllUsers: boolean | null;
  systemNotificationTo: SystemNotificationTo | null;
  customMessage: string | null;
}

export interface UpdateSystemNotification {
  updateSystemNotification: UpdateSystemNotification_updateSystemNotification | null;
}

export interface UpdateSystemNotificationVariables {
  _id: string;
  customMessage: string;
  systemNotificationTo: SystemNotificationTo;
  excludedUserIds?: string[] | null;
  excludedPageIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteSystemNotification
// ====================================================

export interface DeleteSystemNotification {
  deleteSystemNotification: boolean;
}

export interface DeleteSystemNotificationVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendSystemNotification
// ====================================================

export interface SendSystemNotification {
  sendSystemNotification: boolean;
}

export interface SendSystemNotificationVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTrustedPointConfigs
// ====================================================

export interface GetTrustedPointConfigs_getTrustedPointConfigs {
  __typename: "TrustedPointConfig";
  _id: string;
  type: TrustedPointConfigType;
  actionType: TrustedPointAction | null;
  points: number;
  description: string | null;
  trustedPointsFrom: number | null;
  trustedPointsTo: any | null;
  rankFrom: number | null;
  rankTo: number | null;
  engagementAdjustPercent: number | null;
  postContentLength: number | null;
  totalPlaceReviewsFrom: number | null;
  totalPlaceReviewsTo: any | null;
  newPlaceCoefficient: number | null;
  adjustPointPercent: number | null;
  unlimitedPostsPerDayWhenTrustedPointGreaterThan: number | null;
  maxTenPostsPerDayWhenTrustedPointLessThan: number | null;
  maxFivePostsPerDayWhenTrustedPointLessThan: number | null;
  createdAt: any | null;
  invitedFrom: number | null;
  invitedTo: number | null;
}

export interface GetTrustedPointConfigs {
  getTrustedPointConfigs: GetTrustedPointConfigs_getTrustedPointConfigs[] | null;
}

export interface GetTrustedPointConfigsVariables {
  q?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateTrustedPointConfig
// ====================================================

export interface UpdateTrustedPointConfig_updateTrustedPointConfig {
  __typename: "TrustedPointConfig";
  _id: string;
  type: TrustedPointConfigType;
  actionType: TrustedPointAction | null;
  points: number;
  invitedFrom: number | null;
  invitedTo: number | null;
}

export interface UpdateTrustedPointConfig {
  updateTrustedPointConfig: UpdateTrustedPointConfig_updateTrustedPointConfig | null;
}

export interface UpdateTrustedPointConfigVariables {
  _id: string;
  type: TrustedPointConfigType;
  description?: string | null;
  trustedPointsFrom?: number | null;
  trustedPointsTo?: any | null;
  rankFrom?: number | null;
  rankTo?: number | null;
  engagementAdjustPercent?: number | null;
  postContentLength?: number | null;
  totalPlaceReviewsFrom?: number | null;
  totalPlaceReviewsTo?: any | null;
  newPlaceCoefficient?: number | null;
  adjustPointPercent?: number | null;
  unlimitedPostsPerDayWhenTrustedPointGreaterThan?: number | null;
  maxTenPostsPerDayWhenTrustedPointLessThan?: number | null;
  maxFivePostsPerDayWhenTrustedPointLessThan?: number | null;
  points: number;
  invitedFrom?: number | null;
  invitedTo?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMe
// ====================================================

export interface GetMe_me_avatar {
  __typename: "Media";
  thumbnail: string | null;
  thumbnail2x: string | null;
}

export interface GetMe_me {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  email: string | null;
  username: string;
  fullName: string;
  photoUrl: string | null;
  gender: Gender | null;
  group: UserGroup;
  badgeStatus: UserBadgeStatus | null;
  avatar: GetMe_me_avatar | null;
  phoneNumber: string | null;
}

export interface GetMe {
  me: GetMe_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsers
// ====================================================

export interface GetUsers_cmsGetUsers_items_badge {
  __typename: "Badge";
  _id: string;
  title: string;
}

export interface GetUsers_cmsGetUsers_items {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  username: string;
  createdAt: any;
  photoUrl: string | null;
  email: string | null;
  gender: Gender | null;
  phoneNumber: string | null;
  fullName: string;
  deleted: boolean | null;
  totalFriends: number | null;
  /**
   * Post
   */
  totalPosts: number | null;
  point: number | null;
  earnedPoints: number | null;
  group: UserGroup;
  termStatus: TermStatus | null;
  badge: GetUsers_cmsGetUsers_items_badge | null;
  dateOfBirth: any | null;
  interestingTopicKeys: string[] | null;
}

export interface GetUsers_cmsGetUsers {
  __typename: "cmsUsersRes";
  counter: number | null;
  items: (GetUsers_cmsGetUsers_items | null)[] | null;
}

export interface GetUsers {
  cmsGetUsers: GetUsers_cmsGetUsers | null;
}

export interface GetUsersVariables {
  query: string;
  limit: number;
  page?: number | null;
  termStatus?: TermStatus | null;
  group?: UserGroup | null;
  sortBy?: UserSortBy | null;
  gender?: Gender | null;
  badgeId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUser
// ====================================================

export interface GetUser_cmsGetUser {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  username: string;
  photoUrl: string | null;
  email: string | null;
  gender: Gender | null;
  phoneNumber: string | null;
  fullName: string;
  deleted: boolean | null;
  createdAt: any;
  totalFriends: number | null;
  /**
   * Post
   */
  totalPosts: number | null;
  group: UserGroup;
  termStatus: TermStatus | null;
  countryCode: CountryCode | null;
}

export interface GetUser {
  cmsGetUser: GetUser_cmsGetUser | null;
}

export interface GetUserVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUserGroup
// ====================================================

export interface UpdateUserGroup {
  updateUserGroup: boolean;
}

export interface UpdateUserGroupVariables {
  userId: string;
  group: UserGroup;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BlockUser
// ====================================================

export interface BlockUser {
  /**
   * Block a user
   */
  blockUser: boolean;
}

export interface BlockUserVariables {
  targetId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CmsSetUserStatus
// ====================================================

export interface CmsSetUserStatus {
  /**
   * Thay đổi term status cho item, hiện tại dùng trong trường hợp block do bị report là chính
   */
  cmsSetTermStatus: boolean;
}

export interface CmsSetUserStatusVariables {
  _id: string;
  termStatus: TermStatus;
  model: ReportFor;
  reason?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnblockUser
// ====================================================

export interface UnblockUser {
  /**
   * UnBlock a user
   */
  unBlockUser: boolean;
}

export interface UnblockUserVariables {
  targetId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RegisterForImporter
// ====================================================

export interface RegisterForImporter_registerForImporter {
  __typename: "JWT";
  idToken: string;
}

export interface RegisterForImporter {
  registerForImporter: RegisterForImporter_registerForImporter;
}

export interface RegisterForImporterVariables {
  data: RegisterInputForImporter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEarnedPointHistoriesByDate
// ====================================================

export interface getEarnedPointHistoriesByDate_getEarnedPointHistories_items {
  __typename: "EarnedPointHistory";
  earnedPoints: number;
  earnedAt: any;
  changedCount: number | null;
}

export interface getEarnedPointHistoriesByDate_getEarnedPointHistories {
  __typename: "getEarnedPointHistories";
  items: getEarnedPointHistoriesByDate_getEarnedPointHistories_items[] | null;
}

export interface getEarnedPointHistoriesByDate {
  getEarnedPointHistories: getEarnedPointHistoriesByDate_getEarnedPointHistories | null;
}

export interface getEarnedPointHistoriesByDateVariables {
  userId: string;
  limit: number;
  page?: number | null;
  after?: string | null;
  groupByEarnedAt?: boolean | null;
  filterDate?: string | null;
  filterRange?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getEarnedPointHistories
// ====================================================

export interface getEarnedPointHistories_getEarnedPointHistories_items_trustedPointConfigs {
  __typename: "TrustedPointConfig";
  description: string | null;
  points: number;
  trustedPointsFrom: number | null;
}

export interface getEarnedPointHistories_getEarnedPointHistories_items_additionalData_walletExchangeHistory {
  __typename: "WalletExchangeHistory";
  hash: string;
}

export interface getEarnedPointHistories_getEarnedPointHistories_items_additionalData {
  __typename: "EarnedPointHistoryAdditionalData";
  walletExchangeHistory: getEarnedPointHistories_getEarnedPointHistories_items_additionalData_walletExchangeHistory | null;
}

export interface getEarnedPointHistories_getEarnedPointHistories_items_user {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  point: number | null;
  earnedPoints: number | null;
}

export interface getEarnedPointHistories_getEarnedPointHistories_items {
  __typename: "EarnedPointHistory";
  trustedPointConfigs: getEarnedPointHistories_getEarnedPointHistories_items_trustedPointConfigs[] | null;
  additionalData: getEarnedPointHistories_getEarnedPointHistories_items_additionalData | null;
  earnedPoints: number;
  earnedAt: any;
  postId: string | null;
  user: getEarnedPointHistories_getEarnedPointHistories_items_user | null;
}

export interface getEarnedPointHistories_getEarnedPointHistories {
  __typename: "getEarnedPointHistories";
  counter: number | null;
  items: getEarnedPointHistories_getEarnedPointHistories_items[] | null;
}

export interface getEarnedPointHistories {
  getEarnedPointHistories: getEarnedPointHistories_getEarnedPointHistories | null;
}

export interface getEarnedPointHistoriesVariables {
  userId: string;
  limit: number;
  page?: number | null;
  after?: string | null;
  groupByEarnedAt?: boolean | null;
  filterDate?: string | null;
  filterRange?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsAssignBadge
// ====================================================

export interface cmsAssignBadge {
  cmsAssignBadge: boolean | null;
}

export interface cmsAssignBadgeVariables {
  userId: string;
  badgeId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsUserListBadges
// ====================================================

export interface cmsUserListBadges_cmsListBadges_items {
  __typename: "Badge";
  _id: string;
  title: string;
}

export interface cmsUserListBadges_cmsListBadges {
  __typename: "BadgeRes";
  counter: number | null;
  items: cmsUserListBadges_cmsListBadges_items[] | null;
}

export interface cmsUserListBadges {
  cmsListBadges: cmsUserListBadges_cmsListBadges | null;
}

export interface cmsUserListBadgesVariables {
  limit: number;
  page?: number | null;
  filter?: BadgeFilter | null;
  sortBy?: BadgeSortBy | null;
  q?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsTopReferralUsers
// ====================================================

export interface cmsTopReferralUsers_cmsTopReferralUsers_items_avatar {
  __typename: "Media";
  uri: string;
}

export interface cmsTopReferralUsers_cmsTopReferralUsers_items {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  phoneNumber: string | null;
  referredUserNumber: number | null;
  avatar: cmsTopReferralUsers_cmsTopReferralUsers_items_avatar | null;
}

export interface cmsTopReferralUsers_cmsTopReferralUsers {
  __typename: "cmsUsersRes";
  counter: number | null;
  items: (cmsTopReferralUsers_cmsTopReferralUsers_items | null)[] | null;
}

export interface cmsTopReferralUsers {
  cmsTopReferralUsers: cmsTopReferralUsers_cmsTopReferralUsers;
}

export interface cmsTopReferralUsersVariables {
  limit: number;
  filter?: FilterTopReferral | null;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVouchers
// ====================================================

export interface GetVouchers_cmsVouchers_items_medias {
  __typename: "Media";
  _id: string;
  thumbnail: string | null;
  uri: string;
}

export interface GetVouchers_cmsVouchers_items_creator {
  __typename: "User";
  fullName: string;
}

export interface GetVouchers_cmsVouchers_items {
  __typename: "Voucher";
  _id: string;
  medias: GetVouchers_cmsVouchers_items_medias[] | null;
  title: string;
  createdBy: string;
  type: VoucherType | null;
  creator: GetVouchers_cmsVouchers_items_creator | null;
  voucherId: string;
  startDate: any;
  enable: boolean | null;
  available: number | null;
  pointSpend: number | null;
  qty: number | null;
  endDate: any;
  createdAt: any;
  status: VoucherStatus;
  adminStatus: VoucherAdminStatus;
}

export interface GetVouchers_cmsVouchers {
  __typename: "cmsVouchersRes";
  counter: number | null;
  items: GetVouchers_cmsVouchers_items[] | null;
}

export interface GetVouchers {
  /**
   * ADMIN
   * Danh sách vouchers
   */
  cmsVouchers: GetVouchers_cmsVouchers | null;
}

export interface GetVouchersVariables {
  limit: number;
  uid?: string | null;
  page?: number | null;
  status?: VoucherStatus | null;
  withTrash?: boolean | null;
  adminStatus?: VoucherAdminStatus | null;
  q?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetVoucherAdminStatus
// ====================================================

export interface SetVoucherAdminStatus {
  cmsSetVoucherAdminStatus: boolean;
}

export interface SetVoucherAdminStatusVariables {
  _ids: string[];
  adminStatus?: VoucherAdminStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateVoucher
// ====================================================

export interface CreateVoucher_createVoucher {
  __typename: "Voucher";
  _id: string;
  title: string;
}

export interface CreateVoucher {
  createVoucher: CreateVoucher_createVoucher;
}

export interface CreateVoucherVariables {
  title: string;
  titleEn: string;
  pointSpend?: number | null;
  termAndCondition?: string | null;
  termAndConditionEn?: string | null;
  highlight?: string | null;
  highlightEn?: string | null;
  type: VoucherType;
  mediaIds?: string[] | null;
  description?: string | null;
  descriptionEn?: string | null;
  contact?: VoucherContactInput | null;
  status?: VoucherStatus | null;
  startDate: any;
  endDate: any;
  referralTurns?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetVoucher
// ====================================================

export interface GetVoucher_voucher_medias {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface GetVoucher_voucher_contact {
  __typename: "VoucherContact";
  phoneNumber: string | null;
  email: string | null;
  address: string | null;
}

export interface GetVoucher_voucher {
  __typename: "Voucher";
  _id: string;
  title: string;
  titleEn: string | null;
  mediaIds: string[] | null;
  enable: boolean | null;
  type: VoucherType | null;
  medias: GetVoucher_voucher_medias[] | null;
  referralTurns: number | null;
  description: string | null;
  pointSpend: number | null;
  qty: number | null;
  available: number | null;
  highlight: string | null;
  highlightEn: string | null;
  voucherId: string;
  createdBy: string;
  descriptionEn: string | null;
  termAndCondition: string | null;
  termAndConditionEn: string | null;
  prizeTitle: string | null;
  prizeTitleEn: string | null;
  contact: GetVoucher_voucher_contact | null;
  status: VoucherStatus;
  adminStatus: VoucherAdminStatus;
  startDate: any;
  endDate: any;
}

export interface GetVoucher {
  /**
   * Chi tiết 1 voucher
   */
  voucher: GetVoucher_voucher | null;
}

export interface GetVoucherVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateVoucher
// ====================================================

export interface UpdateVoucher_updateVoucher {
  __typename: "Voucher";
  _id: string;
  title: string;
}

export interface UpdateVoucher {
  updateVoucher: UpdateVoucher_updateVoucher;
}

export interface UpdateVoucherVariables {
  _id: string;
  title: string;
  titleEn: string;
  pointSpend?: number | null;
  termAndCondition?: string | null;
  termAndConditionEn?: string | null;
  description?: string | null;
  descriptionEn?: string | null;
  type: VoucherType;
  highlight?: string | null;
  highlightEn?: string | null;
  contact?: VoucherContactInput | null;
  mediaIds?: string[] | null;
  status?: VoucherStatus | null;
  startDate: any;
  endDate: any;
  referralTurns?: number | null;
  prizeTitle?: string | null;
  prizeTitleEn?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: createVoucherCode
// ====================================================

export interface createVoucherCode_createVoucherCode_items_voucher_voucherCodes {
  __typename: "VoucherCode";
  code: string | null;
}

export interface createVoucherCode_createVoucherCode_items_voucher_contact {
  __typename: "VoucherContact";
  phoneNumber: string | null;
  email: string | null;
}

export interface createVoucherCode_createVoucherCode_items_voucher {
  __typename: "Voucher";
  _id: string;
  voucherCodes: (createVoucherCode_createVoucherCode_items_voucher_voucherCodes | null)[] | null;
  highlight: string | null;
  contact: createVoucherCode_createVoucherCode_items_voucher_contact | null;
}

export interface createVoucherCode_createVoucherCode_items {
  __typename: "VoucherCode";
  _id: string;
  voucherId: string;
  code: string | null;
  qty: number | null;
  sold_qty: number | null;
  voucher: createVoucherCode_createVoucherCode_items_voucher | null;
}

export interface createVoucherCode_createVoucherCode {
  __typename: "createVoucherRes";
  exists: (string | null)[] | null;
  items: createVoucherCode_createVoucherCode_items[] | null;
}

export interface createVoucherCode {
  createVoucherCode: createVoucherCode_createVoucherCode | null;
}

export interface createVoucherCodeVariables {
  voucherId: string;
  voucherCodes?: (string | null)[] | null;
  file?: any | null;
  qty?: number | null;
  vendorCode?: string | null;
  remarks?: string | null;
  type?: TypeVoucherCode | null;
  startDate: any;
  endDate: any;
  reCreate?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsListVoucherCode
// ====================================================

export interface cmsListVoucherCode_cmsListVoucherCode_items_redeemedUser {
  __typename: "User";
  /**
   * Basic information
   */
  _id: string;
  fullName: string;
  phoneNumber: string | null;
}

export interface cmsListVoucherCode_cmsListVoucherCode_items {
  __typename: "VoucherCode";
  _id: string;
  voucherId: string;
  code: string | null;
  qty: number | null;
  remarks: string | null;
  status: VoucherCodeStatus | null;
  redeemedAt: any | null;
  redeemedUserId: string | null;
  redeemedUser: cmsListVoucherCode_cmsListVoucherCode_items_redeemedUser | null;
  vendorCode: string | null;
  sold_qty: number | null;
  startDate: any;
  endDate: any;
  deletedAt: any | null;
}

export interface cmsListVoucherCode_cmsListVoucherCode {
  __typename: "cmsVoucherCodesRes";
  counter: number | null;
  items: (cmsListVoucherCode_cmsListVoucherCode_items | null)[] | null;
}

export interface cmsListVoucherCode {
  cmsListVoucherCode: cmsListVoucherCode_cmsListVoucherCode | null;
}

export interface cmsListVoucherCodeVariables {
  voucherId: string;
  limit: number;
  page?: number | null;
  filter?: listVoucherCodeFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: exportVoucherCode
// ====================================================

export interface exportVoucherCode {
  exportVoucherCode: string | null;
}

export interface exportVoucherCodeVariables {
  voucherId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: vouchersLuckyWheel
// ====================================================

export interface vouchersLuckyWheel_vouchersGame_medias {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface vouchersLuckyWheel_vouchersGame {
  __typename: "Voucher";
  _id: string;
  code: string | null;
  voucherId: string;
  highlight: string | null;
  title: string;
  pointSpend: number | null;
  type: VoucherType | null;
  startDate: any;
  endDate: any;
  enable: boolean | null;
  qty: number | null;
  available: number | null;
  medias: vouchersLuckyWheel_vouchersGame_medias[] | null;
}

export interface vouchersLuckyWheel {
  /**
   * Danh sách voucher for games
   */
  vouchersGame: vouchersLuckyWheel_vouchersGame[] | null;
}

export interface vouchersLuckyWheelVariables {
  limit: number;
  page?: number | null;
  filter?: VoucherFilter | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: cmsUpdateLuckyWheelPrize
// ====================================================

export interface cmsUpdateLuckyWheelPrize_updateLuckyWheelPrize {
  __typename: "Prize";
  _id: string;
  name: string;
  prizeTier: PrizeTier | null;
}

export interface cmsUpdateLuckyWheelPrize {
  updateLuckyWheelPrize: cmsUpdateLuckyWheelPrize_updateLuckyWheelPrize;
}

export interface cmsUpdateLuckyWheelPrizeVariables {
  _id: string;
  data?: PrizeInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: cmsReferralVouchers
// ====================================================

export interface cmsReferralVouchers_cmsReferralVouchers_items_medias {
  __typename: "Media";
  _id: string;
  uri: string;
}

export interface cmsReferralVouchers_cmsReferralVouchers_items {
  __typename: "Voucher";
  _id: string;
  title: string;
  titleEn: string | null;
  type: VoucherType | null;
  code: string | null;
  referralTurns: number | null;
  status: VoucherStatus;
  startDate: any;
  endDate: any;
  mediaIds: string[] | null;
  medias: cmsReferralVouchers_cmsReferralVouchers_items_medias[] | null;
}

export interface cmsReferralVouchers_cmsReferralVouchers {
  __typename: "cmsVouchersRes";
  counter: number | null;
  items: cmsReferralVouchers_cmsReferralVouchers_items[] | null;
}

export interface cmsReferralVouchers {
  cmsReferralVouchers: cmsReferralVouchers_cmsReferralVouchers | null;
}

export interface cmsReferralVouchersVariables {
  limit: number;
  page?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: importReferralCodes
// ====================================================

export interface importReferralCodes_importReferralCodes_items {
  __typename: "VoucherCode";
  _id: string;
  voucherId: string;
  code: string | null;
}

export interface importReferralCodes_importReferralCodes {
  __typename: "createVoucherRes";
  exists: (string | null)[] | null;
  items: importReferralCodes_importReferralCodes_items[] | null;
}

export interface importReferralCodes {
  importReferralCodes: importReferralCodes_importReferralCodes | null;
}

export interface importReferralCodesVariables {
  data: ImportReferralCodesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteVoucherCode
// ====================================================

export interface deleteVoucherCode {
  deleteVoucherCode: boolean | null;
}

export interface deleteVoucherCodeVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: myPrizeReferralVoucherCodes
// ====================================================

export interface myPrizeReferralVoucherCodes_myPrizeReferralVoucherCodes_voucher {
  __typename: "Voucher";
  title: string;
  titleEn: string | null;
}

export interface myPrizeReferralVoucherCodes_myPrizeReferralVoucherCodes {
  __typename: "VoucherCode";
  _id: string;
  voucherId: string;
  code: string | null;
  qty: number | null;
  sold_qty: number | null;
  voucher: myPrizeReferralVoucherCodes_myPrizeReferralVoucherCodes_voucher | null;
}

export interface myPrizeReferralVoucherCodes {
  /**
   * Danh sách  các  Referral voucher code đã được nhận
   */
  myPrizeReferralVoucherCodes: myPrizeReferralVoucherCodes_myPrizeReferralVoucherCodes[] | null;
}

export interface myPrizeReferralVoucherCodesVariables {
  limit: number;
  after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteVoucher
// ====================================================

export interface deleteVoucher {
  deleteVoucher: boolean;
}

export interface deleteVoucherVariables {
  _id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: currentReferralCampaign
// ====================================================

export interface currentReferralCampaign_currentReferralCampaign_currentCampaign {
  __typename: "Voucher";
  _id: string;
  title: string;
  titleEn: string | null;
  prizeTitle: string | null;
  prizeTitleEn: string | null;
}

export interface currentReferralCampaign_currentReferralCampaign {
  __typename: "currentReferralCampaignDetails";
  invitedNumbers: number | null;
  currentCampaign: currentReferralCampaign_currentReferralCampaign_currentCampaign | null;
}

export interface currentReferralCampaign {
  currentReferralCampaign: currentReferralCampaign_currentReferralCampaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BadgeFilterIputType {
  ALL = "ALL",
  IMAGE = "IMAGE",
  TEXT = "TEXT",
}

export enum BadgeFilterRequire {
  ALL = "ALL",
  NO = "NO",
  YES = "YES",
}

export enum BadgeInputType {
  IMAGE = "IMAGE",
  TEXT = "TEXT",
}

export enum BadgeRegisterFormSortByType {
  CREATED_AT = "CREATED_AT",
  DETAIL = "DETAIL",
  INPUT_TYPE = "INPUT_TYPE",
  REQUIRED = "REQUIRED",
}

export enum BadgeRequestFormSortByType {
  CREATED_AT = "CREATED_AT",
  NAME = "NAME",
  STATUS = "STATUS",
  TITLE = "TITLE",
}

export enum BadgeSortByType {
  CREATED_AT = "CREATED_AT",
  STATUS = "STATUS",
  TITLE = "TITLE",
}

export enum BadgeStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum BannerStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum BannerType {
  LINK = "LINK",
  PAGE = "PAGE",
  POST = "POST",
  SCREEN = "SCREEN",
}

export enum ConfigType {
  NORMAL = "NORMAL",
  VALUE_RANGE = "VALUE_RANGE",
}

export enum CountryCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AN = "AN",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  ST = "ST",
  SV = "SV",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZM = "ZM",
  ZW = "ZW",
}

export enum EventSortByType {
  CREATEDAT = "CREATEDAT",
  STATUS = "STATUS",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum GroupPrivacy {
  CLOSE = "CLOSE",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum LuckyWheelConfigType {
  MINIMUM_MISSION_COMPLETED = "MINIMUM_MISSION_COMPLETED",
  MINIMUM_USED_TURNS = "MINIMUM_USED_TURNS",
}

export enum LuckyWheelInstructionType {
  ENTERED_REFERRAL_FRIENDS = "ENTERED_REFERRAL_FRIENDS",
  LAUNCH_APP = "LAUNCH_APP",
  SHARE_LINK_INSTALL_APP_VIA_FACEBOOK = "SHARE_LINK_INSTALL_APP_VIA_FACEBOOK",
  VERIFY_ACCOUNT = "VERIFY_ACCOUNT",
  WRITE_POST_REVIEW = "WRITE_POST_REVIEW",
}

export enum LuckyWheelMissionType {
  DAILY = "DAILY",
  NORMAL = "NORMAL",
}

export enum MediaType {
  FILE = "FILE",
  MENU_BANNER_DISPLAY = "MENU_BANNER_DISPLAY",
  MENU_BANNER_DOWNLOAD = "MENU_BANNER_DOWNLOAD",
  PHOTO = "PHOTO",
  VIDEO = "VIDEO",
}

export enum MobileScreenType {
  LUCKY_WHEEL = "LUCKY_WHEEL",
}

export enum NotificationType {
  ACCEPTED_FRIEND_REQUEST = "ACCEPTED_FRIEND_REQUEST",
  ADDED_COMMENT_TO_MEDIA = "ADDED_COMMENT_TO_MEDIA",
  ADDED_COMMENT_TO_POST = "ADDED_COMMENT_TO_POST",
  ADDED_COMMENT_TO_POST_ON_PAGE = "ADDED_COMMENT_TO_POST_ON_PAGE",
  ADDED_COMMENT_TO_POST_THAT_TAGGED_YOU = "ADDED_COMMENT_TO_POST_THAT_TAGGED_YOU",
  ADDED_POST = "ADDED_POST",
  ADDED_POST_FROM_PAGE_YOU_FOLLOWED = "ADDED_POST_FROM_PAGE_YOU_FOLLOWED",
  ALSO_REPLIED_COMMENT_BY_FRIEND = "ALSO_REPLIED_COMMENT_BY_FRIEND",
  APPROVED_REQUEST_BADGE = "APPROVED_REQUEST_BADGE",
  CHANGE_POST_STATUS = "CHANGE_POST_STATUS",
  COMMENT_PRODUCT_ON_PAGE = "COMMENT_PRODUCT_ON_PAGE",
  DELETE_PRODUCT = "DELETE_PRODUCT",
  DISLIKED_COMMENT = "DISLIKED_COMMENT",
  DISLIKED_MEDIA = "DISLIKED_MEDIA",
  DISLIKED_PAGE = "DISLIKED_PAGE",
  DISLIKED_PAGE_PRODUCT = "DISLIKED_PAGE_PRODUCT",
  DISLIKED_POST = "DISLIKED_POST",
  DISLIKED_POST_THAT_TAGGED_YOU = "DISLIKED_POST_THAT_TAGGED_YOU",
  ENTERED_REFERRAL_CODE = "ENTERED_REFERRAL_CODE",
  FOLLOWED_PAGE = "FOLLOWED_PAGE",
  FOLLOWED_USER = "FOLLOWED_USER",
  GOT_PRIZE_REFERRAL_CAMPAIGN = "GOT_PRIZE_REFERRAL_CAMPAIGN",
  GROUP_DELETED = "GROUP_DELETED",
  GROUP_INVITATION_CREATED = "GROUP_INVITATION_CREATED",
  INVITED_TO_JOIN_A_CONVERSATION = "INVITED_TO_JOIN_A_CONVERSATION",
  INVITED_TO_LIKE_A_PAGE = "INVITED_TO_LIKE_A_PAGE",
  JOIN_GROUP_REQUEST_ACCEPTED = "JOIN_GROUP_REQUEST_ACCEPTED",
  JOIN_GROUP_REQUEST_CREATED = "JOIN_GROUP_REQUEST_CREATED",
  LIKED_COMMENT = "LIKED_COMMENT",
  LIKED_MEDIA = "LIKED_MEDIA",
  LIKED_PAGE = "LIKED_PAGE",
  LIKED_PAGE_PRODUCT = "LIKED_PAGE_PRODUCT",
  LIKED_POST = "LIKED_POST",
  LIKED_POST_THAT_TAGGED_YOU = "LIKED_POST_THAT_TAGGED_YOU",
  LUCKY_WHEEL = "LUCKY_WHEEL",
  MENTION_IN_A_COMMENT = "MENTION_IN_A_COMMENT",
  MENTION_IN_A_POST = "MENTION_IN_A_POST",
  NEW_DIRECT_MESSAGE = "NEW_DIRECT_MESSAGE",
  NEW_FRIEND_REQUESTED = "NEW_FRIEND_REQUESTED",
  NEW_GROUP_CONVERSATION = "NEW_GROUP_CONVERSATION",
  NEW_GROUP_MESSAGE = "NEW_GROUP_MESSAGE",
  NEW_PAGE_MESSAGE = "NEW_PAGE_MESSAGE",
  NEW_SUPPORT_MESSAGE = "NEW_SUPPORT_MESSAGE",
  REJECTED_REQUEST_BADGE = "REJECTED_REQUEST_BADGE",
  REPLIED_COMMENT = "REPLIED_COMMENT",
  REPLIED_COMMENT_ON_YOUR_POST = "REPLIED_COMMENT_ON_YOUR_POST",
  REPLIED_MENTION_COMMENT = "REPLIED_MENTION_COMMENT",
  SETTING_FRIEND = "SETTING_FRIEND",
  SETTING_FRIEND_ADDED_POST = "SETTING_FRIEND_ADDED_POST",
  SETTING_OTHER = "SETTING_OTHER",
  SETTING_PAGE = "SETTING_PAGE",
  SETTING_POST_AND_COMMENT = "SETTING_POST_AND_COMMENT",
  SETTING_STOP_ALL = "SETTING_STOP_ALL",
  SET_NOT_VERIFIED_POST = "SET_NOT_VERIFIED_POST",
  SET_PENDING_POST = "SET_PENDING_POST",
  SET_TERM_STATUS = "SET_TERM_STATUS",
  SET_VERIFIED_POST = "SET_VERIFIED_POST",
  SHARED_POST = "SHARED_POST",
  SHARED_PRODUCT_ON_PAGE = "SHARED_PRODUCT_ON_PAGE",
  SHARED_TAGGED_POST = "SHARED_TAGGED_POST",
  SYSTEM = "SYSTEM",
  TAGGED_IN_A_COMMENT = "TAGGED_IN_A_COMMENT",
  TAGGED_IN_A_POST = "TAGGED_IN_A_POST",
  USER_REACT_ON_MESSAGE = "USER_REACT_ON_MESSAGE",
  VERIFY_PAGE_ACCEPTED = "VERIFY_PAGE_ACCEPTED",
  VERIFY_PAGE_REJECTED = "VERIFY_PAGE_REJECTED",
  VERIFY_USER_ACCEPTED = "VERIFY_USER_ACCEPTED",
  VERIFY_USER_REJECTED = "VERIFY_USER_REJECTED",
  WINNING_A_LUCKY_WHEEL_PRIZE = "WINNING_A_LUCKY_WHEEL_PRIZE",
}

export enum OnTravelStore {
  NO = "NO",
  YES = "YES",
}

export enum PageBusinessType {
  EVENT = "EVENT",
  HOTEL = "HOTEL",
  RESTAURANT = "RESTAURANT",
  TOUR = "TOUR",
}

export enum PageSortByType {
  CREATED_AT = "CREATED_AT",
  IS_PARTNER = "IS_PARTNER",
  NAME = "NAME",
  SET_TERM_STATUS_DATE = "SET_TERM_STATUS_DATE",
  TOTAL_COMMENTS = "TOTAL_COMMENTS",
  TOTAL_LIKES = "TOTAL_LIKES",
  TYPE = "TYPE",
  VERIFIED = "VERIFIED",
}

export enum PageStatus {
  NORMAL = "NORMAL",
  SUSPENDING = "SUSPENDING",
}

export enum PageType {
  BUSINESS_OR_BRAND = "BUSINESS_OR_BRAND",
  COMMUNITY_OR_PUBLIC_FIGURE = "COMMUNITY_OR_PUBLIC_FIGURE",
}

export enum PostReportType {
  FAKE_NEWS = "FAKE_NEWS",
  HARASSMENT = "HARASSMENT",
  HATE_SPEECH = "HATE_SPEECH",
  NUDITY = "NUDITY",
  SOMETHING_ELSE = "SOMETHING_ELSE",
  SPAM = "SPAM",
  VIOLENCE = "VIOLENCE",
}

export enum PostSortByType {
  CREATED_AT = "CREATED_AT",
  LAST_REPORT_AT = "LAST_REPORT_AT",
  TOTAL_REPORTS = "TOTAL_REPORTS",
}

export enum PostStatus {
  INCORRECT = "INCORRECT",
  NOT_VERIFIED = "NOT_VERIFIED",
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
}

export enum PostType {
  CHECK_IN = "CHECK_IN",
  DISCOVERY = "DISCOVERY",
  HOTEL = "HOTEL",
  REVIEW = "REVIEW",
  SHARE = "SHARE",
  STATUS = "STATUS",
  TOUR = "TOUR",
}

export enum PrizeTier {
  BRONZE = "BRONZE",
  DIAMOND = "DIAMOND",
  GOLD = "GOLD",
  SILVER = "SILVER",
}

export enum ProductSortByType {
  CREATED_AT = "CREATED_AT",
  PRICE = "PRICE",
  UPDATED_AT = "UPDATED_AT",
}

export enum ProductType {
  HOTEL = "HOTEL",
  TOUR = "TOUR",
}

export enum ReportFor {
  COMMENT = "COMMENT",
  GROUP = "GROUP",
  MEDIA = "MEDIA",
  PAGE = "PAGE",
  POST = "POST",
  USER = "USER",
}

export enum RequestBadgeStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum SettingKey {
  MENTION_COMMENT_LIMIT = "MENTION_COMMENT_LIMIT",
  MENTION_LIMIT = "MENTION_LIMIT",
}

export enum SettingType {
  NORMAL = "NORMAL",
  VALUE_RANGE = "VALUE_RANGE",
}

export enum SortByDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum SortReportType {
  BOTH = "BOTH",
  REPORT_DATE = "REPORT_DATE",
  REPORT_TOTAL = "REPORT_TOTAL",
}

export enum SystemNotificationTo {
  PAGE = "PAGE",
  USER = "USER",
}

export enum TermStatus {
  BLOCKED = "BLOCKED",
  HIDDEN = "HIDDEN",
  NORMAL = "NORMAL",
  SUSPENDING = "SUSPENDING",
}

export enum TrustedPointAction {
  ADDED_NON_STANDARD_REVIEW_POST = "ADDED_NON_STANDARD_REVIEW_POST",
  ADDED_REVIEW_POST_WITH_PHOTO = "ADDED_REVIEW_POST_WITH_PHOTO",
  ADDED_REVIEW_POST_WITH_VIDEO = "ADDED_REVIEW_POST_WITH_VIDEO",
  ADDED_STATUS_POST_WITH_LONG_CONTENT = "ADDED_STATUS_POST_WITH_LONG_CONTENT",
  ADDED_STATUS_POST_WITH_PHOTO = "ADDED_STATUS_POST_WITH_PHOTO",
  ADDED_STATUS_POST_WITH_SHORT_CONTENT = "ADDED_STATUS_POST_WITH_SHORT_CONTENT",
  ADDED_STATUS_POST_WITH_VIDEO = "ADDED_STATUS_POST_WITH_VIDEO",
  ADD_TO_INVITED_USER = "ADD_TO_INVITED_USER",
  ADD_TO_REFERRAL_USER_LEVEL_1 = "ADD_TO_REFERRAL_USER_LEVEL_1",
  ADD_TO_REFERRAL_USER_LEVEL_2 = "ADD_TO_REFERRAL_USER_LEVEL_2",
  ADD_TO_REFERRAL_USER_LEVEL_3 = "ADD_TO_REFERRAL_USER_LEVEL_3",
  ADD_TO_REFERRAL_USER_LEVEL_4 = "ADD_TO_REFERRAL_USER_LEVEL_4",
  ADJUST_ANX = "ADJUST_ANX",
  COMMENTED_POST = "COMMENTED_POST",
  CONTENT_LENGTH = "CONTENT_LENGTH",
  FOLLOWED = "FOLLOWED",
  FRIEND = "FRIEND",
  LIKED_POST = "LIKED_POST",
  LUCKY_WHEEL_PRIZE = "LUCKY_WHEEL_PRIZE",
  RESTRICTED_POST_CONTENT = "RESTRICTED_POST_CONTENT",
  RESTRICTED_POST_PHOTO = "RESTRICTED_POST_PHOTO",
  RESTRICTED_POST_VIDEO = "RESTRICTED_POST_VIDEO",
  SHARED_POST = "SHARED_POST",
  UNFOLLOWED = "UNFOLLOWED",
  UNFRIEND = "UNFRIEND",
  UNFRIENDED = "UNFRIENDED",
  YOU_COMMENTED_THE_POST = "YOU_COMMENTED_THE_POST",
  YOU_LIKED_THE_POST = "YOU_LIKED_THE_POST",
  YOU_SHARED_THE_POST = "YOU_SHARED_THE_POST",
}

export enum TrustedPointConfigType {
  ACTION = "ACTION",
  ENGAGEMENT_MULTIPLICATION = "ENGAGEMENT_MULTIPLICATION",
  LIMITATION = "LIMITATION",
  OTHERS = "OTHERS",
  PROOF_OF_RATING = "PROOF_OF_RATING",
  RANKING = "RANKING",
  REFERRAL = "REFERRAL",
}

export enum TypeListBanner {
  ALL = "ALL",
  DISPLAYED = "DISPLAYED",
}

export enum TypeVoucherCode {
  GENERATE = "GENERATE",
  IMPORT = "IMPORT",
  MANUAL = "MANUAL",
}

export enum UserBadgeStatus {
  FAIL = "FAIL",
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
}

export enum UserGroup {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  PROVIDER = "PROVIDER",
  STAFF = "STAFF",
}

export enum UserSortByType {
  CREATED_AT = "CREATED_AT",
  FULL_NAME = "FULL_NAME",
  POINT = "POINT",
}

export enum VerifyFor {
  PAGE = "PAGE",
  USER = "USER",
}

export enum VerifyStatus {
  IGNORED = "IGNORED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  VERIFIED = "VERIFIED",
}

export enum VoucherAdminStatus {
  APPROVED = "APPROVED",
  PENDING = "PENDING",
  REJECTED = "REJECTED",
}

export enum VoucherCodeStatus {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
  REDEEMED = "REDEEMED",
}

export enum VoucherStatus {
  DISABLED = "DISABLED",
  ENABLED = "ENABLED",
}

export enum VoucherType {
  GAME = "GAME",
  POINT_EXCHANGE = "POINT_EXCHANGE",
  REFERRAL = "REFERRAL",
}

export enum handlePostFilter {
  NO = "NO",
  YES = "YES",
}

export interface ActiveDateForEachBatchInput {
  batchId?: string | null;
  activeDate?: ActiveDateInput | null;
}

export interface ActiveDateInput {
  startDate: any;
  endDate: any;
}

export interface BadgeFilter {
  createdDate?: DateRangeInput | null;
  status?: BadgeStatus | null;
}

export interface BadgeFilterType {
  require?: BadgeFilterRequire | null;
  inputType?: BadgeFilterIputType | null;
}

export interface BadgeRegisterFormSortBy {
  type: BadgeRegisterFormSortByType;
  dir: SortByDirection;
}

export interface BadgeRequestFormSortBy {
  type: BadgeRequestFormSortByType;
  dir: SortByDirection;
}

export interface BadgeSortBy {
  type: BadgeSortByType;
  dir: SortByDirection;
}

export interface BannerDataInput {
  link?: string | null;
  postId?: string | null;
  pageId?: string | null;
  screen?: MobileScreenType | null;
}

export interface BannerInput {
  type: BannerType;
  data: BannerDataInput;
  mediaId: string;
  startDate: any;
  endDate: any;
  name: string;
  status?: BannerStatus | null;
}

export interface BannerPositionsInput {
  _id: string;
  position: number;
}

export interface BatchInput {
  activeDate: ActiveDateInput;
  prizes: PrizeInput[];
}

export interface CmsGetCommentFilterInput {
  termStatus?: TermStatus | null;
}

export interface CmsGetPageReportFilter {
  termStatus?: TermStatus | null;
  type?: PageType | null;
}

export interface CmsGetPostFiltersInput {
  q?: string | null;
  pageId?: string | null;
  groupId?: string | null;
  createdBy?: string | null;
  createdAt?: DateRangeInput | null;
  termStatus?: TermStatus | null;
  type?: PostType | null;
  status?: PostStatus | null;
}

export interface CmsGetPostReportFilter {
  termStatus?: TermStatus | null;
  postType?: PostType | null;
  handle?: handlePostFilter | null;
}

export interface DateRangeInput {
  from?: any | null;
  to?: any | null;
}

export interface EsAfter {
  q: string;
  uid: string;
}

export interface EventSortBy {
  type?: EventSortByType | null;
  dir?: SortByDirection | null;
}

export interface EventUpdateDataInput {
  name?: string | null;
  logoId?: string | null;
  backgroundId?: string | null;
  animationId?: string | null;
  loop?: number | null;
  status?: boolean | null;
  logoStatus?: boolean | null;
  backgroundStatus?: boolean | null;
  animationStatus?: boolean | null;
}

export interface FailedSpinMessageInput {
  content: string;
  contentEn: string;
}

export interface FilterGroup {
  _id?: string | null;
  name_contain?: string | null;
  ownerId?: string | null;
  categoryId?: string | null;
}

export interface FilterListBanner {
  type?: TypeListBanner | null;
}

export interface FilterListEvent {
  status?: boolean | null;
}

export interface FilterTopReferral {
  rangeDate?: DateRangeInput | null;
  q?: string | null;
}

export interface ImportFailedSpinMessageInput {
  file?: any | null;
}

export interface ImportReferralCodesInput {
  voucherId: string;
  file: any;
}

export interface LoginInput {
  phoneNumber: string;
  password: string;
}

export interface LuckyWheelFilter {
  deletedAt?: boolean | null;
}

export interface LuckyWheelInput {
  title: string;
  titleEn?: string | null;
  batchs?: BatchInput[] | null;
  activeDate: ActiveDateInput;
  numberOfBatch: number;
  activeDateForEachBatch?: (ActiveDateForEachBatchInput | null)[] | null;
}

export interface LuckyWheelInstructionInput {
  title: string;
  titleEn?: string | null;
  description?: string | null;
  descriptionEn?: string | null;
  turns: number;
  type: LuckyWheelInstructionType;
  missionType: LuckyWheelMissionType;
}

export interface PageFilter {
  createdDate?: DateRangeInput | null;
  termStatus?: TermStatus | null;
  status?: PageStatus | null;
  verified?: boolean | null;
  isPartner?: boolean | null;
  withTrash?: boolean | null;
}

export interface PagePinnedProductSettingInput {
  pageId: string;
  amount: number;
}

export interface PageSortBy {
  type: PageSortByType;
  dir: SortByDirection;
}

export interface PhotoDimensionsInput {
  width: number;
  height: number;
}

export interface PostSortBy {
  type?: PostSortByType | null;
  dir?: SortByDirection | null;
}

export interface PrizeInput {
  name: string;
  nameEn?: string | null;
  prize_index?: number | null;
  voucherId?: string | null;
  point?: number | null;
  qty: number;
  mediaId: string;
  prizeTier: PrizeTier;
}

export interface ProductFilter {
  type?: (ProductType | null)[] | null;
  termStatus?: TermStatus | null;
}

export interface ProductSortBy {
  type: ProductSortByType;
  dir: SortByDirection;
}

export interface RegisterInputForImporter {
  fullName: string;
  gender?: Gender | null;
  dateOfBirth?: any | null;
  countryCode?: CountryCode | null;
  avatarMediaId?: string | null;
  coverMediaId?: string | null;
  password: string;
  phoneNumber: string;
  createdAt?: any | null;
  updatedAt?: any | null;
}

export interface RequestBadgeFilter {
  status?: RequestBadgeStatus | null;
  badge?: string | null;
}

export interface SettingInput {
  value?: string | null;
  description?: string | null;
  type?: SettingType | null;
}

export interface UserSortBy {
  type: UserSortByType;
  dir: SortByDirection;
}

export interface VoucherContactInput {
  email?: string | null;
  phoneNumber?: string | null;
  address?: string | null;
}

export interface VoucherFilter {
  uid?: string | null;
  status?: VoucherStatus | null;
  adminStatus?: VoucherAdminStatus | null;
  withTrash?: boolean | null;
  activeDate?: ActiveDateInput | null;
}

export interface listVoucherCodeFilter {
  status?: VoucherCodeStatus | null;
}

export interface luckyWheelTermInput {
  content: string;
  contentEn: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
