import React, { useEffect, useState } from 'react';
import { Form, Layout, Table, Button, Breadcrumb, Popconfirm, notification, Skeleton, Col, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import Styled from './indexStyled';
import { GetConfigs, GetConfigsVariables, DeleteConfig, DeleteConfigVariables } from '../../graphql/types';
import { GET_CONFIGS, DELETE_CONFIG } from '../../graphql/config/config';
import AddConfig from './components/addConfig';

const SettingImpl = (props: FormComponentProps) => {
  const [isLoading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState({});

  const { data, error, loading, refetch } = useQuery<GetConfigs, GetConfigsVariables>(GET_CONFIGS, {
    variables: {
      limit: 100,
    },
  });
  const [deleteConfig] = useMutation<DeleteConfig, DeleteConfigVariables>(DELETE_CONFIG);

  const showEdit = (data) => {
    setVisible(true);
    setEditData({
      _id: data._id,
      key: data.key,
      value: data.value,
      type: data.type,
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      sorter: true,
    },
    {
      title: 'Key',
      dataIndex: 'key',
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      sorter: true,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 150,
      fixed: 'right' as 'right',
      render: (row) => {
        return (
          <div>
            <Button type="primary" icon="edit" size="small" onClick={(e) => showEdit(row)}/>
            <Popconfirm placement="left" title={text} onConfirm={() => confirm(row._id)} okText="Yes" cancelText="No">
              <Button className="ml-15" type="danger" icon="delete" size="small" onClick={(e) => console.log(row._id)}/>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const { Content } = Layout;

  const text = 'Are you sure to delete this task?';

  function onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  function confirm(id) {
    deleteConfig({
      variables: {
        _id: id
      }
    }).catch(() => {

    }).then(() => {
      notification.open({
        placement: 'bottomRight',
        type: 'success',
        message: 'Deleted',
        description:
          'Record has been deleted.',
      });
      reloadData();
    })
  }

  useEffect(() => {
    if (loading) setLoading(true);
    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  const closeModal = () => {
    setVisible(false);
    reloadData();
    setEditData({
    });
  };

  const reloadData = () => {
    refetch().catch(() => {

    });
  };

  if (error) return <Content className="content">Error</Content>;
  return <Styled.Container>
    <Content className="content">
      <Row type="flex" justify="start" className="pb-20">
        <Col span={12}>
          <Breadcrumb>
            <Breadcrumb.Item>Setting</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button type="primary" onClick={() => setVisible(true)}>Add Config</Button>
        </Col>
      </Row>
      {isLoading ? <Skeleton loading={isLoading} paragraph={{ rows: 5 }}>
      </Skeleton> : <Table rowKey="_id" columns={columns} dataSource={data.getConfigs} onChange={onChange} scroll={{ x: 'calc(700px + 50%)', y: 0 }} />}
      {visible ? <AddConfig form={props.form} visible={visible} data={editData} closeModal={(e) => closeModal()}/> : ''}

    </Content>
  </Styled.Container>;
};

const UserPage = Form.create()(SettingImpl);
export default UserPage;
