import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Layout,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Table,
} from 'antd';
import { ToastError, ToastSuccess } from '../../components/Toast';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { CMS_DELETE_EVENT, CMS_GET_LIST_EVENT, CMS_UPDATE_EVENT } from '../../graphql/event';
import {
  cmsDeleteEvent,
  cmsDeleteEventVariables,
  cmsGetListEvent,
  cmsGetListEventVariables,
  cmsUpdateEvent,
  cmsUpdateEventVariables,
  EventSortByType,
  getEvent_cmsGetEvent,
  SortByDirection,
} from '../../graphql/types';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;

interface IEditProps extends FormComponentProps {
  match?: any;
}

const EventsImpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const [isLoading, setLoading] = useState(true);
  const [eventParam, setEventParam] = useState<cmsGetListEventVariables>({
    sortBy: {
      type: EventSortByType.CREATEDAT,
      dir: SortByDirection.DESC
    },
    limit: 5,
    page: 1,
  });
  const [events, setEvents] = useState([]);
  const [dataEdit, setDataEdit] = useState<getEvent_cmsGetEvent>(null);
  const [counter, setCounter] = useState(0);
  const [modalStatus, setModalStatus] = useState(false);
  const [isChangeStatus, setChange] = useState(false);
  let { data, error, loading } = useQuery<cmsGetListEvent, cmsGetListEventVariables>(CMS_GET_LIST_EVENT, {
    fetchPolicy: 'network-only',
    variables: eventParam,
  });

  const toggleModalStatus = () => {
    if(modalStatus){
      setChange(false);
    }
    setModalStatus(!modalStatus);
  };

  const [deleteEvent] = useMutation<
    cmsDeleteEvent,
    cmsDeleteEventVariables
    >(CMS_DELETE_EVENT, { refetchQueries: ['cmsGetListEvent'] });

  const [updateBadge] = useMutation<
    cmsUpdateEvent,
    cmsUpdateEventVariables
    >(CMS_UPDATE_EVENT, { refetchQueries: ['cmsGetListEvent'] });

  const columns = [
    {
      title: 'Logo',
      key: 'logo',
      dataIndex: 'logo',
      sorter: false,
      render: (logo) => {
        if (logo && logo.uri) {
          return (
            <div className="thumb">
              <img src={logo.uri} alt="" />
            </div>
          );
        }
        return <>{"-"}</>
      },
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Status',
      key: 'status',
      sorter: true,
      render: row => {
        let type: any = (row.status) ? 'primary' : 'danger';
        return (
          <Button
            className="btn-rectangle"
            type={type} size="small"
            onClick={ () => onChangeStatus(row) }
          >
            {row.status ? 'Enable': 'Disable'}
          </Button>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      render: row => {
        return (
          <div style={{
            display: 'flex',
          }}>
            <Link to={{
              pathname: `/event/edit/${row._id}`
            }}>
              <Button
                type="primary"
                icon="edit"
                size="small"
                onClick={() => { }}
                style={{ marginLeft: 20 }} />
            </Link>

            <Popconfirm
              placement="left"
              title={"Are you sure to delete this event?"}
              onConfirm={e => confirmDeleteEvent(row._id)} okText="Yes" cancelText="No">
              <Button
                type="danger"
                icon="delete"
                size="small"
                onClick={e => {

                }}
                style={{ marginLeft: 20 }} />
            </Popconfirm>

          </div>
        );
      },
    },
  ];

  const confirmDeleteEvent = id => {
    deleteEvent({
      variables: {
        _id: id,
      },
    }).then(res => {
      if (res && res.data && res.data.cmsDeleteEvent) {
        ToastSuccess({ message: 'Deleted', description: 'Event has been deleted!' });
      } else {
        ToastError({ message: 'Deleted', description: 'Event cannot delete!' });
      }
    }).catch((err) => {
      ToastError({ message: 'Deleted', description: 'Event cannot delete!' });
    });

  };

  // const [dynamicColumns, setDynamicColumns] = useState(columns);


  const onChangeStatus = (event) => {
    setDataEdit(event);
    setModalStatus(true);
  };

  const onTableChange = (pagination, filters, sorter) => {
    if(sorter?.column){
      setEventParam({...eventParam,
      sortBy:{
        type: sorter.columnKey === "status" && EventSortByType.STATUS,
        dir: sorter.order === "ascend" ? SortByDirection.ASC : SortByDirection.DESC
      }})
    }else{
      setEventParam({...eventParam,
      sortBy:{
        type: EventSortByType.CREATEDAT,
        dir: SortByDirection.DESC
      }});
    }
  };

  useEffect(() => {
    if (loading) setLoading(true);
    if (!loading) setLoading(false);
  }, [loading]);

  useEffect(() => {
    if (data && data.cmsListEvent.items && data.cmsListEvent.counter > 0) {
      setEvents(data.cmsListEvent.items);
      setCounter(data.cmsListEvent.counter)
    } else {
      setEvents([]);
      setCounter(0);
    }
  }, [data]);

  const onChangePage = (page) => {
    setEventParam({ ...eventParam, page: page })
  };

  const onShowSizeChange = (current, pageSize) => {
    let cOffset = counter / pageSize;
    let cOffsetUpward = Math.ceil(cOffset);
    let nOffset = eventParam.page;
    if (cOffsetUpward < eventParam.page) {
      nOffset = cOffsetUpward;
    }
    setEventParam({ ...eventParam, limit: pageSize, page: nOffset });
  };

  const showConfirm = () => {
    if(isChangeStatus){
      confirm({
        title: 'Do you sure change this status?',
        onOk() {
          handleOk()
        },
        onCancel() {

          toggleModalStatus()
        },
      });
    }else{
      toggleModalStatus();
    }
  };


  const handleOk = () => {
    updateBadge({
      variables:{
        _id: dataEdit._id,
        data:{
          status: dataEdit.status
        }
      }
    }).then(res => {
      toggleModalStatus();
      setDataEdit(null);
    })
  };


  if (error) return <Content className="content">Error</Content>;

  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Event</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row
          className="mt-10 mb-10"
          gutter={16}
          type="flex"
          style={{ alignItems: 'flex-end' }}>
          <Col offset={12} span={12} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Link to={'/event/create'}>
                <Button type={'primary'}>Add Event</Button>
              </Link>
            </Form.Item>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsListEvent && data.cmsListEvent.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}> </Skeleton>
        ) : (
          <Row style={{
            backgroundColor: 'white'
          }}>
            <Table rowKey="_id"
                   columns={columns}
                   dataSource={events}
                   scroll={{ x: 'calc(700px + 50%)', y: 0 }}
                   pagination={false}
                   onChange={onTableChange}
            />

            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={eventParam.page}
              total={counter}
              defaultPageSize={eventParam.limit}
              onChange={onChangePage}
              style={{
                marginTop: 20,
                right: 0,
              }}
            />
          </Row>
        )}

        {
          modalStatus &&
          (
            <Modal
              title={ <>
                Change event status
              </> }
              visible={ modalStatus }
              onOk={ showConfirm }
              onCancel={ toggleModalStatus }
            >
              <div>
                <Form>
                  <Form.Item label="">
                    { getFieldDecorator('status', {
                      initialValue: dataEdit.status ? "enable" : "disable",
                    })(
                      <Select
                        placeholder="Select a option and change input text above"
                        onChange={ e => {
                          console.log('<><><><',dataEdit.status,(e === "enable"), (e === "enable") === dataEdit.status );
                          if((e === "enable") !== dataEdit.status ){
                            setChange(true);
                          }
                          setDataEdit({ ...dataEdit, status: e === "enable" });
                        } }
                      >
                        <Option disabled={ dataEdit.status === true } value="enable" >
                          Enable
                        </Option>
                        <Option disabled={ dataEdit.status === false } value="disable" >
                          Disable
                        </Option>
                      </Select>,
                    ) }
                  </Form.Item>
                </Form>
              </div>

            </Modal>
          )
        }
      </Content>

    </Styled.Container>
  );
};

const Events = Form.create()(EventsImpl);
export default Events;
