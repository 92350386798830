import styled from 'styled-components';

const Styled = {
  Container: styled.div`
    .content{
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
      min-height: 80vh;
    }
    .ant-btn-sm{
      padding: 0;
      height: auto;
      &.btn-rectangle{
        padding: 2px 5px;
      }
    }
    .thumb{
      width: 80px;
      max-height: 80px;
      img{
        width: 1000%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }
    
    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
    .thumb{
      width: 70px;
      max-height: 150px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    
    .thumb-modal {
        width: 100%;
        position: relative;
        padding-top: 100%;
        overflow: hidden;
        background-color: #cccccc;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center;
        }
      }
  `,
};
export default Styled;
