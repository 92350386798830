import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Form, Input, Layout, Skeleton, Table } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { GET_PLACES } from '../../graphql/place/place';
import { TopPlaces, TopPlacesVariables } from '../../graphql/types';
import Styled from './indexStyled';
import EditConfig from './components/editConfig';
const { Content } = Layout;
const { Search } = Input;
const {dateByFormat} = require("../../helpers/date");

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

const ProductImpl = (props: FormComponentProps) => {
  const [isLoading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [editData, setEditData] = useState({});
  const [filterData, setFilter] = useState({
    limit: 100,
    q: ''
  });
  const { data, error, loading } = useQuery<TopPlaces, TopPlacesVariables>(GET_PLACES, {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    variables: filterData,
  });


  const showEdit = (data) => {
    setVisible(true);
    setEditData({
      _id: data._id,
      type: data.type,
      actionType: data.actionType,
      points: data.points,
    });
  };

  const closeModal = () => {
    setVisible(false);
  };

  const columns = [
    {
      title: 'Media',
      render: row => {
        return <img width={100} src={row.primaryImage && row.primaryImage.uri} alt="" />;
      },
    },
    {
      title: 'Name',
      render: row => {
        return (
          <div style={{ maxWidth: '200px' }}>
            <strong>{row.name}</strong>
            <p>
              {row.description && row.description.substr(0, 150)}
              {row.description && row.description.length > 150 && '...'}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Checkins',
      dataIndex: 'checkins',
      sorter: false,
    },
    {
      title: 'Reviews',
      dataIndex: 'reviews',
      sorter: false,
    },
    {
      title: 'Location',

      render: row => {
        return (
          <div style={{ maxWidth: '150px' }}>
            {row.location.place.name}
          </div>
        );
      },
      sorter: false,
    },
    {
      title: 'creator',
      dataIndex: 'creator.fullName',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render:createdAt =>{
        return (<>{createdAt && dateByFormat(createdAt)}</>)
      }
    },
    {
      title: 'Action',
      key: 'operation',
      render: row => {
        return (
          <div>
            <Button type="primary" icon="edit" size="small" onClick={(e) => showEdit(row)}/>
          </div>
        );
      },
    },
  ];


  const search = value => {
    setFilter({ ...filterData, q: value });
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Place</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>
        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div>
            <Search
              placeholder="input search text"
              defaultValue={filterData.q}
              onSearch={value => search(value)}
              style={{ width: 200 }}
            />
            <Table rowKey="_id" columns={columns} dataSource={data.topPlaces} onChange={onChange} scroll={{ x: 'calc(700px + 50%)', y: 0 }}  />
          </div>
        )}
        {visible ? <EditConfig form={props.form} visible={visible} data={editData} closeModal={(e) => closeModal()}/> : ''}
      </Content>
    </Styled.Container>
  );
};

const Product = Form.create()(ProductImpl);
export default Product;
