import { gql } from 'apollo-boost';

export const CMS_CREATE_BANNER = gql`
    mutation CmsCreateBanner(
        $type:BannerType!,
        $data:BannerDataInput!,
        $mediaId:ID!,
        $startDate: DateTime!,
        $endDate: DateTime!,
        $name: String!,
        $status: BannerStatus
    ){
        createBanner(data:{
            type:$type,
            data:$data,
            name:$name,
            mediaId:$mediaId,
            startDate:$startDate,
            endDate:$endDate,
            status:$status
        }){
            _id
            name
            status
            type
            position
            mediaId
            startDate
            endDate
            createdBy
            createdAt
        }
    }
`;

export const CMS_GET_BANNERS = gql`
    query CmsGetBanners($limit:Int!,$page:Int,$filter:FilterListBanner){
        cmsListBanner(limit:$limit,page:$page,filter:$filter){
            counter
            items{
                _id
                name
                mediaId
                position
                startDate
                endDate
                status
                type
                data{
                    postId
                    link
                    pageId
                    screen
                }
#                dataScreen{
#                    title
#                    screen
#                }
                media{
                    _id
                    uri
                }
            }
        }
    }
`;

export const CMS_UPDATE_BANNER_POSITIONS = gql`
    mutation CmsUpdateBannerPositions($data:[BannerPositionsInput!]!){
        updateBannerPositions(data:$data)
    }
`;
export const CMS_UPDATE_BANNER = gql`
    mutation updateBanner($id:ID!,$data:BannerInput){
        updateBanner(_id:$id,data:$data)
    }
`;

export const CMS_DELETE_BANNER = gql`
    mutation CmsDeleteBanner($id:ID!){
        cmsDeleteBanner(_id:$id)
    }
`;

export const CMS_GET_SCREENS_BANNER = gql`
    query cmsListBannerScreen{
        cmsListBannerScreen{
            title
            screen
        }
    }
`;

export const CMS_GET_BANNER = gql`
    query getBanner($_id:ID!){
        banner(_id:$_id){
            _id
            name
            status
            type
            data{
                link
                postId
                pageId
                screen
            }
            startDate
            endDate
            media{
                _id
                uri
            }
            createdBy
            createdAt
        }
    }
`;

export const CMS_GET_MENU_BANNER = gql`
    query getMenuBanner{
        getMenuBanner{
            display{
                _id
                uri
            }
            download{
                _id
                uri
            }
        }
    }
`;

export const CMS_DELETE_MENU_BANNER = gql`
    mutation cmsDeleteBannerMenu( $bannerType: MediaType! ){
        cmsDeleteBannerMenu(bannerType: $bannerType)
    }
`;
