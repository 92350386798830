import { PrizeInput } from '../../../graphql/types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Input, Popconfirm, Row } from 'antd';
import UploadVoucher from '../compoments/Uploads';
import { CREATE_MEDIA_LUCKY_WHEEL } from '../../../contexts/type';
import { AppContext } from '../../../contexts/AppContext';

const removeIcon = require('../../../assets/images/icons/luckyWheel/remove.png');
const checkBox = require('../../../assets/images/icons/luckyWheel/checkbox.svg');

interface InputData extends PrizeInput {
  voucherMedia?: {_id:String,uri:String}
}

interface VoucherProps  {
  position:number
  voucherName: string,
  data:InputData,
  qtyForEachBatch:number[]
  onRemove:any
}

const VoucherPrizeTmpl = (props:VoucherProps) => {
  const context = useContext(AppContext);
  const [media,setMedia] = useState([]);
  const onChangeImage = (media: File) => {
    if(media[0] && media[0].originFileObj){
      context.onUpdateLuckyWheelData({position:props.position,media:media[0].originFileObj},CREATE_MEDIA_LUCKY_WHEEL);
    }else{
      context.onUpdateLuckyWheelData({position:props.position,media:null},CREATE_MEDIA_LUCKY_WHEEL);
    }
  };

  useEffect(()=>{
    const {voucherMedia} = props.data;
    if(voucherMedia && voucherMedia._id){
      if(voucherMedia._id){
        context.onUpdateLuckyWheelData({position:props.position,mediaSavedId:voucherMedia._id},CREATE_MEDIA_LUCKY_WHEEL);
      }
      setMedia([voucherMedia]);
    }
  },[]);

  return (
    <Row gutter={10} className="voucher-item prize-item-section mb-3" justify="space-around" align="middle">
      <Col span={3}>
        <div className="d-flex justify-content-between">
          <img src={checkBox} alt=""/>
          <div className="m-auto"> Voucher</div>
        </div>
      </Col>
      <Col span={5}><Input readOnly={true} value={props.voucherName}/></Col>
      <Col span={2}>
        <UploadVoucher onChangeImage={onChangeImage}
                       numberImagesAllowed={1}
                       imageAccept=""
                       images={media}
        />
      </Col>
      <Col span={4}>
        <Row>
          <Col offset={1} span={16}>Total quantity</Col>
          <Col span={7}><input className="form-control" type="text" disabled={true} value={props.data.qty}/></Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row gutter={3}>
          <Col offset={1} span={8}>Quantity for each batch</Col>
          <Col span={14}>
            <div className="d-flex flex-wrap">
              {
                props.qtyForEachBatch.map((item,index) => {
                  return (
                    <input key={index} className="form-control input-size mg2 max-width-18" type="text" disabled={true} value={item}/>
                  );
                })
              }
            </div>
          </Col>

        </Row>
      </Col>
      <Col span={1}>
        <Popconfirm  title="Are you sure？" onConfirm={()=>props.onRemove(props.position)}
        >
          <div>
            <Button type="default" className="remove-icon p015px" style={{width:'3em',height:'3em'}} >
              <img src={removeIcon} alt="" className="w100"/>
            </Button>
          </div>
        </Popconfirm>
      </Col>
    </Row>
  );
};

export default VoucherPrizeTmpl;
