import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Layout, Modal, Row, Select, Skeleton, Table, Input, Pagination } from 'antd';

import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { GET_VOUCHERS, SET_VOUCHER_ADMIN_STATUS, CMS_EXPORT_VOUCHER_CODE } from '../../graphql/voucher/voucher';
import {
  GetVouchers,
  GetVouchersVariables,
  SetVoucherAdminStatus,
  SetVoucherAdminStatusVariables,
  VoucherAdminStatus,
  VoucherStatus,
  VoucherType,
} from '../../graphql/types';

import VoucherCodeList from './compoments/VoucherCodeList';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { ToastError, ToastSuccess } from '../../components/Toast';
// import { getMe } from '../../helpers/meHelpers';

const { dateByFormat } = require('../../helpers/date');

const imgDefault = require('../../assets/images/default_voucher_image.jpg');

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;

interface IEditProps extends FormComponentProps {
  match?: any;
}

const VoucherImpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const { params } = props.match;
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalAddCodes, setModalAddCodes] = useState(false);
  const [voucher, setVoucher] = useState(null);
  const [vouchers, setVouchers] = useState([]);
  const [counter, setCounter] = useState<number>(0);
  const adminStatuses = ['ALL', VoucherAdminStatus.PENDING, VoucherAdminStatus.APPROVED, VoucherAdminStatus.REJECTED];
  const statuses = ['ALL', VoucherStatus.ENABLED, VoucherStatus.DISABLED];
  const [filterParams, setFilterParams] = useState<GetVouchersVariables>({
    limit: 10,
    page: 1,
    adminStatus: (params.type && params.type.toUpperCase()) || null,
    status: null,
    q: null,
  });
  const pageType = params.type;

  const [editData, setEditData] = useState<SetVoucherAdminStatusVariables>({
    _ids: [],
    adminStatus: VoucherAdminStatus.APPROVED,
  });

  const [srtVoucherAdminStatus, { data: setVoucherStatusResult }] = useMutation<
    SetVoucherAdminStatus,
    SetVoucherAdminStatusVariables
  >(SET_VOUCHER_ADMIN_STATUS, {
    refetchQueries: ['cmsVouchers'],
  });

  const [cmsExportVoucherCodes] = useMutation(CMS_EXPORT_VOUCHER_CODE);

  let { data, error, refetch, loading } = useQuery<GetVouchers, GetVouchersVariables>(GET_VOUCHERS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: filterParams.limit,
      page: filterParams.page,
      adminStatus: filterParams.adminStatus,
      status: filterParams.status,
      q: filterParams.q,
    },
  });

  useEffect(
    function() {
      setFilterParams({
        ...filterParams,
        adminStatus: (params.type && params.type.toUpperCase()) || null,
      });
    },
    [params],
  );

  console.log('data', data);

  useEffect(() => {
    if (data && data.cmsVouchers) {
      setVouchers(data.cmsVouchers.items);
      setCounter(data.cmsVouchers.counter);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [filterParams]);

  const toggleModalAddCodes = _voucher => {
    if (!modalAddCodes) {
      setVoucher(_voucher);
    }
    setModalAddCodes(!modalAddCodes);
  };

  const exportVoucherCode = voucherId => {
    cmsExportVoucherCodes({
      variables: {
        voucherId,
      },
    }).then(res => {
      if (res) {
        window.open(process.env.REACT_APP_SERVER_URI + res.data.exportVoucherCode);
      }
    });
  };

  // const me = getMe();

  const columns = [
    {
      title: 'No.',
      key: 'index',
      width: '5%',
      render: (text, record, index) => (filterParams.page - 1) * filterParams.limit + index + 1,
    },
    {
      title: 'Image',
      key: 'image',
      render: row => {
        return (
          <div className="thumb">
            <img src={row.medias[0] ? row.medias[0].uri : imgDefault} alt="" />
          </div>
        );
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Type',
      key: 'type',
      width: '10%',
      render: row => {
        return row.type === VoucherType.GAME ? 'GAME' : 'Point exchange';
      },
    },
    {
      title: 'Code',
      dataIndex: 'voucherId',
      width: '5%',
      sorter: false,
    },
    {
      title: 'Creator',
      dataIndex: 'creator.fullName',
      width: '10%',
      sorter: false,
    },
    {
      title: 'Created At',
      key: 'createdAt',
      sorter: false,
      width: '12%',
      render: row => {
        return <>{dateByFormat(row.createdAt)}</>;
      },
    },
    {
      title: 'Point spend',
      dataIndex: 'pointSpend',
      sorter: false,
    },
    {
      title: 'Quantity',
      dataIndex: 'qty',
      sorter: false,
    },
    {
      title: 'Voucher redeemed',
      key: 'used',
      render: row => {
        return <>{row.qty - row.available}</>;
      },
    },
    {
      title: 'Status',
      key: 'status',
      sorter: false,
      render: row => {
        return <div>{row.enable ? VoucherStatus.ENABLED : VoucherStatus.DISABLED}</div>;
      },
    },
    {
      title: 'Admin Status',
      render: row => {
        let type: any = 'default';
        if (row.adminStatus == VoucherAdminStatus.APPROVED) type = 'primary';
        if (row.adminStatus == VoucherAdminStatus.REJECTED) type = 'danger';
        return (
          <Button className="btn-rectangle" type={type} size="small" onClick={e => setEditVoucher(row)}>
            {row.adminStatus}
          </Button>
        );
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: row => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {/*{(me && me._id == row.createdBy) && (*/}
            <div>
              <Link to={'/vouchers/' + row._id + '/edit'}>
                <Button type="primary" icon="edit" size="small" onClick={() => {}} />
              </Link>
            </div>
            {/*)}*/}
            <Button type="primary" icon="plus" size="small" onClick={() => toggleModalAddCodes(row)} />
            <Button type="primary" icon="export" size="small" onClick={() => exportVoucherCode(row._id)} />
          </div>
        );
      },
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    // console.log('params', pagination, filters, sorter, extra);
  }

  const onSearch = val => {
    setFilterParams({
      ...filterParams,
      q: val,
    });
  };

  const changeVoucherAdminStatus = e => {
    setEditData({
      ...editData,
      adminStatus: e,
    });
  };

  const changeVoucherStatus = e => {
    setFilterParams({
      ...filterParams,
      status: e != 'ALL' ? e : null,
    });
  };

  const changeAdminStatus = e => {
    setFilterParams({
      ...filterParams,
      adminStatus: e != 'ALL' ? e : null,
    });
  };

  const handleOk = () => {
    setShowModal(false);
    srtVoucherAdminStatus({
      variables: {
        _ids: editData._ids,
        adminStatus: editData.adminStatus,
      },
    })
      .catch(e => {
        ToastError({ message: 'Error', description: e.toString() });
      })
      .then(res => {
        refetch();
        ToastSuccess({ message: 'Updated', description: 'Status admin update success' });
      });
    if (setVoucherStatusResult) {
      // console.log('ok');
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const setEditVoucher = data => {
    setEditData({
      _ids: [data._id],
      adminStatus: data.adminStatus,
    });
    setShowModal(true);
  };

  useEffect(() => {
    if (loading) setLoading(true);
    if (!loading) setLoading(false);
  }, [loading]);

  const onChangePage = page => {
    setFilterParams({ ...filterParams, page });
  };

  // if (error) return <Content className="content">Error</Content>;

  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Voucher</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mt-10 mb-10" gutter={16}>
          <Col span={6}>
            <Form.Item label="Voucher Status">
              {getFieldDecorator('status', {
                initialValue: filterParams.status,
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select status to filter"
                  onChange={e => changeVoucherStatus(e)}
                >
                  {statuses.map((r, i) => {
                    return (
                      <Option key={i} value={r}>
                        {r}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Admin Status">
              {getFieldDecorator('adminStatus', {
                initialValue: filterParams.adminStatus,
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select admin status to filter"
                  onChange={e => changeAdminStatus(e)}
                >
                  {adminStatuses.map((r, i) => {
                    return pageType ? (
                      pageType === r && (
                        <Option key={i} value={r}>
                          {r}
                        </Option>
                      )
                    ) : (
                      <Option key={i} value={r}>
                        {r}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Search">
              <Search
                placeholder={filterParams.q ? filterParams.q : 'Enter Title'}
                enterButton="Search"
                size="large"
                onSearch={value => onSearch(value)}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Link to={'/vouchers/create'}>
              <Button type={'primary'}>Add Voucher</Button>
            </Link>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {counter}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}>
            {' '}
          </Skeleton>
        ) : (
          <Fragment>
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={vouchers}
              onChange={onChange}
              scroll={{ x: 'calc(700px + 50%)', y: 0 }}
              pagination={false}
            />
            <Pagination
              defaultCurrent={filterParams.page || 1}
              total={counter}
              defaultPageSize={filterParams.limit}
              onChange={onChangePage}
              style={{
                marginTop: 20,
                right: 0,
              }}
            />
          </Fragment>
        )}
      </Content>

      <Modal
        title="Change voucher admin status"
        visible={showModal}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        {showModal && (
          <div>
            <Form>
              <Form.Item label="">
                {getFieldDecorator('statusUpdate', {
                  initialValue: editData.adminStatus,
                })(
                  <Select
                    placeholder="Select a option and change input text above"
                    onChange={e => changeVoucherAdminStatus(e)}
                  >
                    {adminStatuses.map((r, i) => {
                      return (
                        <Option disabled={r == editData.adminStatus} key={i} value={r}>
                          {r}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
      {modalAddCodes && (
        <Modal
          visible={modalAddCodes}
          width={1250}
          title={'Voucher code list : ' + voucher.title}
          onOk={toggleModalAddCodes}
          onCancel={toggleModalAddCodes}
          footer=""
        >
          <VoucherCodeList voucher={voucher} form={props.form} />
        </Modal>
      )}
    </Styled.Container>
  );
};

const Voucher = Form.create()(VoucherImpl);
export default Voucher;
