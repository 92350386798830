import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Table,
  Modal,
} from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { DELETE_PRODUCT, PARTNER_PRODUCTIONS } from '../../graphql/product/product';
import { CMS_SET_TERM_STATUS } from '../../graphql/report/termStatus';
import {
  BlockPost,
  BlockPostVariables,
  DeleteProduct,
  DeleteProductVariables,
  PartnerProducts,
  PartnerProductsVariables,
  ProductType,
  ReportFor,
  TermStatus,
  ProductSortByType,
  SortByDirection,
  MediaType,
  OnTravelStore,
} from '../../graphql/types';
import Styled from './indexStyled';
import { ToastError, ToastSuccess } from '../../components/Toast';
import CreatorDetail from '../../components/CreatorDetail';
import imagePage from '../../assets/images/page.png';
import ReasonConfig from '../Post/components/ReasonConfig';
import ProductDetail from './components/detail';

const { Option } = Select;
const { Search } = Input;
const { dateByFormat } = require('../../helpers/date');

const { Content } = Layout;

interface ProductInterface extends FormComponentProps {
  match: any;
}

const ProductImpl = (props: ProductInterface) => {
  const { type } = props.match.params;
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [filterData, setFilter] = useState({
    q: '',
    offset: 1,
    filter: {
      type: [ProductType.HOTEL, ProductType.TOUR],
      termStatus: null,
    },
    limit: 10,
    type: null,
    onTravelStore: null
  });

  const [counter, setCounter] = useState(0);
  const { data, error, loading, refetch } = useQuery<PartnerProducts, PartnerProductsVariables>(PARTNER_PRODUCTIONS, {
    fetchPolicy: 'network-only',
    variables: filterData,
  });
  const [showCreatorDetail, setShowCreatorDetail] = useState(false);
  const [creator, setCreator] = useState({
    name: null,
    fullName: null,
    email: null,
    phoneNumber: null,
    avatar: null,
    point: null,
    termStatus: null,
    logo: null,
    owner: null,
    admins: null,
    isPage: false,
  });
  const [showProductDetail, setShowProductDetail] = useState(false);
  const [product, setProduct] = useState(null);

  const [setTermStatusAPI] = useMutation<BlockPost, BlockPostVariables>(CMS_SET_TERM_STATUS);
  const [blockData, setBlockData] = useState({});

  const showModalReason = data => {
    setShowModal(true);
    setBlockData({
      _id: data._id,
    });
  };

  useEffect(() => {
    if ((type && type === TermStatus.BLOCKED) || !type) {
      refetch();
      setFilter({
        ...filterData,
        type: type,
      });
    }
  }, [type]);

  const [deleteProduct] = useMutation<DeleteProduct, DeleteProductVariables>(DELETE_PRODUCT, {
    refetchQueries: ['PartnerProducts'],
  });

  const closeModalReason = () => {
    setShowModal(false);
  };

  const handlePostTermStatus = postId => {
    setTermStatusAPI({
      variables: {
        _id: postId,
        model: ReportFor.POST,
        termStatus: TermStatus.NORMAL,
      },
    }).then(() => refetch());
  };

  const showDetailCreator = row => {
    setCreator(
      row.page
        ? {
            ...row.page,
            isPage: true,
          }
        : {
            ...row.creator,
            isPage: false,
          },
    );
    setShowCreatorDetail(true);
  };
  const closeCreatorDetail = () => {
    setShowCreatorDetail(false);
  };

  const showDetailProduct = row => {
    setProduct(row);
    setShowProductDetail(true);
  };

  const columns = [
    {
      title: 'Media',
      render: row => {
        return (
          <img
            className="thumb"
            src={
              row.medias && row.medias[0]
                ? row.medias[0].type === MediaType.PHOTO
                  ? row.medias[0].uri
                  : row.medias[0].type === MediaType.VIDEO
                  ? row.medias[0].thumbnail
                  : ''
                : ''
            }
            alt=""
          />
        );
      },
    },
    {
      title: 'Title',
      render: row => {
        return (
          <div style={{ maxWidth: '200px' }}>
            <strong>{row.title}</strong>
            <p>
              {row.content && row.content.substr(0, 150)}
              {row.content && row.content.length > 150 && '...'}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: false,
    },
    {
      title: 'Creator',
      render: row => {
        return (
          <div className="link" onClick={() => showDetailCreator(row)}>
            {row.page ? row.page.name : row.creator.fullName}
            {row.page && <img className="imagePage" src={imagePage} alt="" />}
          </div>
        );
      },
    },
    {
      title: 'Total report',
      dataIndex: 'totalReports',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: createdAt => {
        return <>{createdAt && dateByFormat(createdAt)}</>;
      },
    },
    {
      title: 'On travel store',
      dataIndex: 'availableOnTravelStore',
      render: availableOnTravelStore => {
        return (
          <span
            style={{
              fontWeight: 600,
              color: 'green',
            }}
          >
            {availableOnTravelStore ? '✓' : ''}
          </span>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      render: row => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button type="default" icon="eye" size="small" onClick={e => showDetailProduct(row)} />

            <Popconfirm
              placement="left"
              title={'Are you sure to delete this product?'}
              onConfirm={e => confirm(row._id)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" icon="delete" size="small" style={{ marginLeft: 5 }} />
            </Popconfirm>

            {row.termStatus === TermStatus.BLOCKED ? (
              <Popconfirm title="Are you sure unblock this product？" onConfirm={() => handlePostTermStatus(row._id)}>
                <Button style={{ marginLeft: 5 }} className="btn-rectangle" type="default" size="small">
                  UNBLOCK
                </Button>
              </Popconfirm>
            ) : (
              <Button
                style={{ marginLeft: 5 }}
                icon="stop"
                type="danger"
                size="small"
                onClick={e => showModalReason(row)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const [dynamicColumns, setDynamicColumns] = useState(columns);
  const onChange = (pagination, filters, sorter, extra) => {
    if (sorter && sorter.columnKey) {
      if (sorter.order) {
        let columnKey = sorter.columnKey;
        let order = sorter.order === 'ascend' ? SortByDirection.ASC : SortByDirection.DESC;
        if (sorter.columnKey === 'createdAt') {
          let c = dynamicColumns.map(item => {
            if (item.key === columnKey) {
              return { ...item, sortOrder: sorter.order };
            }
            return { ...item, sortOrder: false };
          });
          setDynamicColumns(c);

          if (columnKey === 'createdAt') {
            // @ts-ignore
            setFilter({ ...filterData, sortBy: { type: ProductSortByType.CREATED_AT, dir: order } });
          }
        }
      } else {
        if (sorter.columnKey === 'createdAt') {
          let c = dynamicColumns.map(item => {
            return { ...item, sortOrder: false };
          });
          setDynamicColumns(c);
          setFilter({
            q: filterData.q,
            offset: filterData.offset,
            filter: { 
              ...filterData.filter
            },  
            limit: filterData.limit,
            type: filterData.type,
            onTravelStore: filterData.onTravelStore,
          });
        }
      }
    }
  };

  const confirm = id => {
    deleteProduct({
      variables: {
        _id: id,
      },
    })
      .then(res => {
        if (res && res.data && res.data.cmsDeleteProduct) {
          ToastSuccess({ message: 'Deleted', description: 'Product has been deleted!' });
        } else {
          ToastError({ message: 'Deleted', description: 'Product cannot delete!' });
        }
      })
      .catch(err => {
        ToastError({ message: 'Deleted', description: 'Product cannot delete!' });
      });
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (data && data.cmsPartnerProducts && data.cmsPartnerProducts.counter) {
      setCounter(data.cmsPartnerProducts.counter);
    } else {
      setCounter(0);
    }
  }, [data]);

  const search = value => {
    setFilter({ ...filterData, q: value, offset: 1 });
  };

  const onChangePage = page => {
    setFilter({ ...filterData, offset: page });
  };

  const onShowSizeChange = (current, pageSize) => {
    setFilter({ ...filterData, limit: pageSize, offset: 1 });
  };

  if (error) return <Content className="content">Error</Content>;
  const postTypes = ['ALL', ProductType.HOTEL, ProductType.TOUR];
  const changeFilter = e => {
    const filter = e === 'ALL' ? [ProductType.HOTEL, ProductType.TOUR] : [e];
    setFilter({
      ...filterData,
      filter: { 
        type: filter,
        termStatus: filterData.filter.termStatus,
      },
      onTravelStore: filterData.onTravelStore,
      offset: 1,
    });
  };

  const statusTypes = ['ALL', TermStatus.NORMAL, TermStatus.SUSPENDING, TermStatus.HIDDEN];
  const changeStatusFilter = e => {
    const filter = e === 'ALL' ? null : e;
    setFilter({
      ...filterData, 
      filter: {
        type: filterData.filter.type,
        termStatus: filter,
      },
      onTravelStore: filterData.onTravelStore,
    });
  };

  const onTravelStoreTypes = ['ALL', OnTravelStore.YES, OnTravelStore.NO];
  const changeOnTravelStore = e => {
    const filter = e === 'ALL' ? null : e;
    setFilter({
      ...filterData,
      onTravelStore: filter,
    });
  };

  const closeProductDetail = () => {
    setShowProductDetail(false);
  };
  
  return (
    <Styled.Container>
      <Content className="content product-page-section">
        <Breadcrumb>
          <Breadcrumb.Item>Post</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsPartnerProducts && data.cmsPartnerProducts.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
            <Row
              className="mt-10 mb-10" 
              gutter={16}
              type="flex"
              style={{ alignItems: 'flex-end' }}>
              <Col span={4}>
                <Form.Item label="Type">
                  <Select
                    style={{ width: '100%' }}
                    onChange={e => changeFilter(e)}
                    defaultValue={filterData.filter.type.length > 1 ? ['ALL'] : filterData.filter.type}
                  >
                    {postTypes.map(function(item, i) {  
                      return (
                        <Option key={i} value={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                  </Form.Item>
              </Col>
              {!type && (
              <>  
              <Col span={4}>  
                <Form.Item label="Status">
                  <Select
                    style={{ width: '100%' }}
                    onChange={e => changeStatusFilter(e)}
                    defaultValue={!filterData.filter.termStatus ? 'ALL' : filterData.filter.termStatus}
                  >
                    {statusTypes.map(function(item, i) {  
                      return (
                        <Option key={i} value={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>  
              </Col>
              <Col span={4}>  
                <Form.Item label="On travel store">
                  <Select
                    style={{ width: '100%' }}
                    onChange={e => changeOnTravelStore(e)}
                    defaultValue={!filterData.onTravelStore ? 'ALL' : filterData.onTravelStore}
                  >
                    {onTravelStoreTypes.map(function(item, i) {  
                      return (
                        <Option key={i} value={item}>
                          {item}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>  
              </Col>
              </>
              )
              }
              <Col span={3}>
                <Form.Item label="Search">
                  <Search
                    placeholder="input search text"
                    defaultValue={filterData.q}
                    onSearch={value => search(value)}
                    style={{ width: 200 }}
                  />
                </Form.Item>
              </Col>
           
              <Col span={24}>
                <Table
                  rowKey="_id"
                  columns={dynamicColumns}
                  dataSource={data.cmsPartnerProducts.items}
                  onChange={onChange}
                  scroll={{ x: 'calc(700px + 50%)', y: 0 }}
                  pagination={false}
                />

                <Pagination
                  showSizeChanger
                  onShowSizeChange={onShowSizeChange}
                  defaultCurrent={filterData.offset}
                  total={counter}
                  defaultPageSize={filterData.limit}
                  onChange={onChangePage}
                  style={{
                    marginTop: 20,
                    right: 0,
                  }}
                />
              </Col>
          </Row>
        )}
      </Content>

      {showCreatorDetail && creator && (
        <Modal
          width={768}
          title="Creator detail"
          visible={showCreatorDetail}
          okText="Close"
          onOk={() => closeCreatorDetail()}
          onCancel={() => closeCreatorDetail()}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <CreatorDetail creator={creator} />
        </Modal>
      )}

      {showModal && (
        <ReasonConfig
          visible={showModal}
          refetchParent={refetch}
          closeModal={() => closeModalReason()}
          form={props.form}
          data={blockData}
          product={true}
        />
      )}

      {showProductDetail && product && (
        <Modal
          width={768}
          title="Product detail"
          visible={showProductDetail}
          okText="Close"
          onOk={() => closeProductDetail()}
          onCancel={() => closeProductDetail()}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <ProductDetail
            id={product._id}
            title={product.title}
            content={product.content}
            medias={product.medias}
            creator={product.creator}
            createdAt={product.createdAt}
            type={product.type}
            price={product.price}
          />
        </Modal>
      )}
    </Styled.Container>
  );
};

const Product = Form.create()(ProductImpl);
export default Product;