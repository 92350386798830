import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Layout, Row, Skeleton, Table } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { GET_PAGE_CATEGORIES } from '../../graphql/pageCategory/pageCategory';
import { GetPageCategories } from '../../graphql/types';
import Styled from './indexStyled';
import EditConfig from './components/editConfig';
const { Content } = Layout;

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

const PageImpl = (props: FormComponentProps) => {
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [filterData] = useState({
    limit: 100,
    q: '',
  });

  const showEdit = data => {
    setShowModal(true);
    if (data) {
      setEditData({
        _id: data._id,
        name: data.name,
        enum: data.enum,
        locale: data.locale,
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setEditData({});
  };

  const { data, error, loading } = useQuery<GetPageCategories>(GET_PAGE_CATEGORIES, {
    fetchPolicy: 'network-only',
    variables: filterData,
  });

  const columns = [
    {
      title: 'Name',
      render: row => {
        return (
          <div>
            <p>{row.name.VI_VN}</p>
            <p>{row.name.EN_US}</p>
          </div>
        );
      },
    },
    {
      title: 'Enum',
      dataIndex: 'enum',
      sorter: false,
    },
    {
      title: 'Action',
      key: 'operation',
      render: row => {
        return (
          <div>
            <Button type="primary" icon="edit" size="small" onClick={() => showEdit(row)} />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Place</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col span={12}>
            <strong>Total: {(data && data.cmsGetPageCategories && data.cmsGetPageCategories.counter) || 0}</strong>
          </Col>
          <Col span={12} style={{ textAlign: 'right', marginBottom: '15px' }}>
            <Button type={'primary'} onClick={() => showEdit(null)}>
              Add new
            </Button>
          </Col>
        </Row>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <Table
            rowKey="_id"
            pagination={{ pageSize: 50 }}
            columns={columns}
            dataSource={data.cmsGetPageCategories.items}
            onChange={onChange}
            scroll={{ x: 'calc(700px + 50%)', y: 0 }}
          />
        )}

        {showModal ? (
          <EditConfig data={editData} visible={showModal} closeModal={() => closeModal()} form={props.form} />
        ) : (
          ''
        )}
      </Content>
    </Styled.Container>
  );
};

const Page = Form.create()(PageImpl);
export default Page;
