import { gql } from 'apollo-boost';

export const GET_SENSITIVE_WORDS = gql`
  query GetSensitiveWords($q: String) {
    cmsBlackListWords(q: $q) {
      counter
      items {
        _id
        key
      }
    }
  }
`;

export const ADD_SENSITIVE_WORDS = gql`
  mutation AddSensitiveWords($keywords: [String!]) {
    addWordsToBlackList(keywords: $keywords)
  }
`;

export const UPDATE_SENSITIVE_WORD = gql`
  mutation UpdateSensitiveWord($id: ID!, $keyword: String) {
    updateBlackWord(_id: $id, keyword: $keyword) {
      _id
      key
    }
  }
`;

export const REMOVE_SENSITIVE_WORD_BY_IDS = gql`
  mutation RemoveSensitiveWordByIds($ids: [ID!]) {
    removeBlackListWordByIds(ids: $ids)
  }
`;

export const REMOVE_SENSITIVE_WORD_BY_KEYS = gql`
  mutation RemoveSensitiveWordByKeys($keywords: [String!]) {
    removeBlackListWordByKeys(keywords: $keywords)
  }
`;
