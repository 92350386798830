import React, { useState } from 'react';
import { Button, Col, Form, Input, Layout, Row, Select, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { LoadingOutlined } from '@ant-design/icons';
import Styled from '../../UserPage/indexStyled';
import { useMutation } from '@apollo/react-hooks';
import {
  createLuckyWheelInstruction,
  createLuckyWheelInstructionVariables,
  LuckyWheelMissionType
} from '../../../graphql/types';
import {
  CMS_CREATE_LUCK_WHEEL_INSTRUCTION
} from '../../../graphql/luckyWheel';
import { ToastError, ToastSuccess } from '../../../components/Toast';

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;


interface IEditProps extends FormComponentProps {
  instructionTypes: any,
  onClose: any
}


const AddInstructionImpl = (props) => {

  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);

  const [createInstruction] = useMutation<createLuckyWheelInstruction, createLuckyWheelInstructionVariables>(CMS_CREATE_LUCK_WHEEL_INSTRUCTION, {
    refetchQueries: ['cmsListLuckyWheelInstruction']
  });

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        createInstruction({
          variables: {
            data: {
              title: values.title,
              titleEn: values.titleEn,
              description: values.description,
              descriptionEn: values.descriptionEn,
              turns: parseInt(values.turns),
              type: values.type,
              missionType: LuckyWheelMissionType.NORMAL
            }
          }
        }).then((res) => {
          setLoading(false);
          ToastSuccess({ message: 'Created', description: 'Instruction create success' });
          props.onClose();
        }).catch(err => {
          console.log("err", err);
          setLoading(false);
          ToastError({
            message: 'Create Error',
            description: "Please try later",
          });
        });
      } else {
        setLoading(false);
      }
    });
  };



  return (
    <Styled.Container>
      <Content className="content">
        <Row type="flex" justify="center">
          <Col span={ 18 }>
            <Form { ...formItemLayout } onSubmit={ handleSubmit }>
              <Form.Item label="Title">
                { getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      whitespace:true,
                      message: 'Please input title!',
                    },
                  ],
                })(<Input />) }
              </Form.Item>
              <Form.Item label="TitleEn">
                { getFieldDecorator('titleEn', {
                  rules: [
                    {
                      required: true,
                      whitespace:true,
                      message: 'Please input title en!',
                    },
                  ],
                })(<Input />) }
              </Form.Item>

              <Form.Item label="Type">
                { getFieldDecorator('type', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      whitespace:true,
                      message: 'Please select one!',
                    },
                  ],
                })(
                  <Select>
                    {
                      props.instructionTypes && props.instructionTypes.map((item, index) => {
                        return <Option key={ index } value={ item.type }>{ item.type }</Option>
                      })

                    }
                  </Select>
                ) }
              </Form.Item>

              <Form.Item label="Turns">
                { getFieldDecorator('turns', {
                  rules: [
                    {
                      required: true,
                      whitespace:true,
                      message: 'Please input turns!',
                    },
                  ],
                })(<Input type="number" min={ 0 } />) }
              </Form.Item>


              <Form.Item label="Description">
                { getFieldDecorator('description', {
                  rules: [
                    {
                      required: true,
                      whitespace:true,
                      message: 'Please input description!',
                    },
                  ],
                })(<TextArea
                  placeholder=""
                  autoSize={ { minRows: 2, maxRows: 6 } }
                />) }
              </Form.Item>
              <Form.Item label="DescriptionEn">
                { getFieldDecorator('descriptionEn', {
                  rules: [
                    {
                      required: true,
                      whitespace:true,
                      message: 'Please input description en!',
                    },
                  ],
                })(<TextArea
                  placeholder=""
                  autoSize={ { minRows: 2, maxRows: 6 } }
                />) }
              </Form.Item>

              <Form.Item { ...buttonItemLayout } >
                <Button type="primary" disabled={ loading } htmlType="submit">Submit</Button>
                { loading && (<Spin indicator={ <LoadingOutlined style={ { fontSize: 24 } } spin /> } />) }
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Styled.Container>
  );
};

const AddInstruction = Form.create<IEditProps>()(AddInstructionImpl);
export default AddInstruction;
