import React, { useState, useEffect } from 'react';
import { Upload, Modal, Icon } from 'antd';
import { ToastError } from '../../../components/Toast';

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

interface IEditProps {
  onChangeImage?:any;
  numberImagesAllowed?:Number;
  imageAccept?:string;
  maxSize?:string;
  images?: any;
}

const UploadImpl =(props: IEditProps) => {
  const [imageList, setimageList] = useState<Array<any>>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const {numberImagesAllowed,imageAccept} = props;

  const updateImage = () => {
    let imgs = [];
    if (props.images) {
      props.images.map(function(r, i) {
        imgs.push({
          uid: r._id,
          thumbUrl: r.uri,
          url: r.uri,
          uploaded: true,
        });
      });
      setimageList(imgs);
    }
  }

  useEffect(function() {
    updateImage()
  }, []);

  // useEffect(()=>{
  //   console.log("props.images",props.images);
  //   if(props.images){
  //     updateImage();
  //   }
  // },[props.images]);


  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
  };

  const handleChange = ({ fileList }) => {
    if(imageList.length < numberImagesAllowed || fileList.length < numberImagesAllowed){
      const max = 10 * 1024 * 1024;
      const error = fileList.filter(row => row.size > max);
      if (error.length > 0) {
        ToastError({
          message: 'Upload image error',
          description: `Please select image has size less than ${max / (1024 * 1024)} MB`,
        });
      }
      let data = fileList.filter(row => row.size?row.size <= max:true);
      data = data.slice(0,numberImagesAllowed);
      setimageList(data);
      setPreviewVisible(false);
      props.onChangeImage(data)
    }else{
      ToastError({
        message: 'Upload image error',
        description: `You can only upload up to 5 photos`,
      });
    }
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      <Upload
        listType="picture-card"
        fileList={imageList}
        multiple={true}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={file => {
          return false;
        }}
        accept={imageAccept || "image/*"}
      >
        {imageList.length >= numberImagesAllowed ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default UploadImpl;
