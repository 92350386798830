import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export class ExportFile {

    static fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    // static fileExtension = '.xlsx';

    static exportToExcel = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: ExportFile.fileType });
        FileSaver.saveAs(data, fileName + '.xlsx');
    }
}