import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Form, Layout, Skeleton, Table, Tag, Icon, Row, Col } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { GET_SYSTEM_NOTIFICATIONS } from '../../graphql/systemNotification/systemNotification';
import { GetMyNotifications } from '../../graphql/types';
import Styled from './indexStyled';
import EditConfig from './components/editConfig';
const { Content } = Layout;
const { dateByFormat } = require('../../helpers/date');

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

const SystemNotificationImpl = (props: FormComponentProps) => {
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [filterData] = useState({
    q: '',
    filter: [],
    limit: 100,
  });
  const { data, error, loading } = useQuery<GetMyNotifications>(GET_SYSTEM_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    variables: filterData,
  });

  const closeModal = () => {
    setShowModal(false);
    setEditData({});
  };

  const showEdit = (data?) => {
    setShowModal(true);
    if (data) {
      setEditData({
        _id: data._id,
        customMessage: data.customMessage,
      });
    }
  };

  const columns = [
    {
      title: 'Message',
      dataIndex: 'customMessage',
    },
    {
      title: 'Send To All Users',
      render: row => {
        return (
          <div>
            {row.sendToAllUsers && (
              <Tag color="#87d068">
                <Icon type="check" />
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Sent',
      render: row => {
        return (
          <div>
            {row.sent && (
              <Tag color="#87d068">
                <Icon type="check" />
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: 'Creator',
      dataIndex: 'fromUser.fullName',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render: createdAt => {
        return <>{createdAt && dateByFormat(createdAt)}</>;
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: row => {
        return (
          <div>{!row.sent && <Button type="primary" icon="edit" size="small" onClick={e => showEdit(row)} />}</div>
        );
      },
    },
  ];

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Tour</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col span={12}>
            <strong>Total: {(data && data.getSystemNotifications && data.getSystemNotifications.counter) || 0}</strong>
          </Col>
          <Col span={12} style={{ textAlign: 'right', marginBottom: '15px' }}>
            <Button type="primary" size="default" onClick={() => showEdit()}>
              Add new Notification
            </Button>
          </Col>
        </Row>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={data.getSystemNotifications.items}
            onChange={onChange}
            scroll={{ x: 'calc(700px + 50%)', y: 0 }}
          />
        )}
        {showModal ? (
          <EditConfig visible={showModal} closeModal={() => closeModal()} form={props.form} data={editData} />
        ) : (
          ''
        )}
      </Content>
    </Styled.Container>
  );
};

const SystemNotification = Form.create()(SystemNotificationImpl);
export default SystemNotification;
