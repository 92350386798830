import React, { useState, useEffect } from 'react';
import { Layout, Menu, Icon } from 'antd';
import styled from 'styled-components';
import history from '../../history';
import { AppContext } from '../../contexts/AppContext';
import { getMe } from 'src/helpers/meHelpers';
import { GetMe, UserGroup } from 'src/graphql/types';
import { useQuery } from '@apollo/react-hooks';
import { GET_ME } from 'src/graphql/user/me';

const { Sider } = Layout;

const { SubMenu } = Menu;

const StyledLogo = styled.div`
  position: relative;
  height: 64px;
  padding-left: 24px;
  overflow: hidden;
  line-height: 64px;
  background: #001529;
  transition: all 0.3s;
  h1 {
    display: inline-block;
    margin: 0 0 0 12px;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
    vertical-align: middle;
  }
  img {
    display: inline-block;
    height: 40px;
    vertical-align: middle;
  }
`;

const StyledContainer = styled.div``;

interface BaseProps {
  pathname: string;
}

const Sidebar = React.memo((props: BaseProps) => {
  const [activePath, setActivePath] = useState([]);
  const [activeSidebar, setActiveSidebar] = useState();
  const { refetch: getMe } = useQuery<GetMe>(GET_ME, { fetchPolicy: 'network-only' });

  getMe()
    .catch(e => {
      console.log(e);
    })
    .then((res: any) => {
      if (res && res.data) {
        setActiveSidebar(res.data.me.group);
      }
    });

  console.log(activeSidebar);

  useEffect(() => {
    if (window.location.pathname) {
      setActivePath(window.location.pathname.split('/'));
    }
  }, []);

  const changePath = (item: any) => {
    if (item.key) {
      history.push(item.key);
      setActivePath(item.key.split('/'));
    } else {
      setActivePath(item);
    }
  };

  return (
    <StyledContainer>
      <AppContext.Consumer>
        {({ collapsedSidebar, onUpdateContext }) => (
          <Sider
            width={256}
            style={{
              overflow: 'auto',
              height: '100vh',
              zIndex: 100,
              position: 'fixed',
              left: 0,
            }}
          >
            <StyledLogo>
              <a href="/dashboard">
                <img src="../../logo.png" alt="" />
              </a>
            </StyledLogo>
            <Menu
              style={{ marginTop: -5 }}
              selectedKeys={[props.pathname]}
              mode="inline"
              theme="dark"
              onClick={item => changePath(item)}
              onOpenChange={item => changePath(item)}
              openKeys={activePath}
            >
              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key={'dashboard' || ''}
                  title={
                    <span>
                      <Icon type="home" />
                      <span>Dashboard</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['dashboard'])}
                >
                  <Menu.Item key="/dashboard">Statistic</Menu.Item>
                  {/* <Menu.Item key="/dashboard/contents">Contents</Menu.Item> */}
                  <Menu.Item key="/dashboard/top-users">Top users</Menu.Item>
                  {/* <Menu.Item key="/dashboard/top-referrals">Top users referrals</Menu.Item> */}
                  {/* <Menu.Item key="/menu_banner">Menu banner</Menu.Item> */}
                  {/* <Menu.Item key="/event/noel">Noel</Menu.Item> */}
                </SubMenu>
              )}

              <SubMenu
                key="posts"
                title={
                  <span>
                    <Icon type="database" />
                    <span>Posts management</span>
                  </span>
                }
                onTitleClick={item => setActivePath(['posts'])}
              >
                <Menu.Item key="/posts">Posts</Menu.Item>
                <Menu.Item key="/posts/blocked">Blocked posts</Menu.Item>
                {/* <Menu.Item key="/posts/create">Create post</Menu.Item> */}
              </SubMenu>

              {/* <SubMenu
                key="events"
                title={
                  <span>
                    <Icon type="database" />
                    <span>Events management</span>
                  </span>
                }
                onTitleClick={item => setActivePath(['events'])}
              >
                <Menu.Item key="/events">Events</Menu.Item>
                <Menu.Item key="/event/create">Create event</Menu.Item>
              </SubMenu> */}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="users"
                  title={
                    <span>
                      <Icon type="user" />
                      <span>Users management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['users'])}
                >
                  <Menu.Item key="/users">Users list</Menu.Item>
                  <Menu.Item key="/users/BLOCKED">Users blocked list</Menu.Item>
                  {/* <Menu.Item key="/users/providers">Providers</Menu.Item> */}
                  <Menu.Item key="/users/create">Create user</Menu.Item>
                </SubMenu>
              )}

              {/* {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="vouchers"
                  title={
                    <span>
                      <Icon type="idcard" />
                      <span>Voucher management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['vouchers'])}
                >
                  <Menu.Item key="/vouchers">Voucher list</Menu.Item>
                  <Menu.Item key="/vouchers/referrals">Referral vouchers</Menu.Item>
                  <Menu.Item key="/vouchers/type/APPROVED">Approve voucher list</Menu.Item>
                  <Menu.Item key="/vouchers/type/REJECTED">Reject voucher list</Menu.Item>
                  <Menu.Item key="/vouchers/create">Create voucher</Menu.Item>
                </SubMenu>
              )} */}

              {/* <SubMenu
                key="lucky-wheels"
                title={
                  <span>
                    <Icon type="gift" />
                    <span>LuckyWheel management</span>
                  </span>
                }
                onTitleClick={item => setActivePath(['lucky-wheels'])}
              >
                <Menu.Item key="/instruction/lucky-wheels">Instruction</Menu.Item>
                <Menu.Item key="/lucky-wheels/create">Create </Menu.Item>
                <Menu.Item key="/lucky-wheels">Campaign list</Menu.Item>
                <Menu.Item key="/running/lucky-wheels">Running Lucky Wheel</Menu.Item>
                <Menu.Item key="/lucky-wheels/deleted">Deleted list</Menu.Item>
                <Menu.Item key="/winning-condition/lucky-wheels">Winning condition</Menu.Item>
                <Menu.Item key="/message/lucky-wheels">Message result</Menu.Item>

                <Menu.Item key="/terms/lucky-wheels">Terms</Menu.Item>
              </SubMenu> */}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="badges"
                  title={
                    <span>
                      <Icon type="star" />
                      <span>Badge management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['badges'])}
                >
                  <Menu.Item key="/badges">Badge list</Menu.Item>
                  <Menu.Item key="/badges/verifies">Badge verify list</Menu.Item>
                  <Menu.Item key="/badges/register_form">Register form</Menu.Item>
                  <Menu.Item key="/badges/create">Create Badge</Menu.Item>
                </SubMenu>
              )}

              {/* <SubMenu
                key="banners"
                title={
                  <span>
                    <Icon type="shop" />
                    <span>Banner management</span>
                  </span>
                }
                onTitleClick={item => setActivePath(['banners'])}
              >
                <Menu.Item key="/banners/create">Create banner</Menu.Item>
                <Menu.Item key="/banners">Banner list</Menu.Item>
                <Menu.Item key="/banners/app">Banner displayed</Menu.Item>
              </SubMenu> */}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="verifies"
                  title={
                    <span>
                      <Icon type="bell" />
                      <span>Verify management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['verifies'])}
                >
                  <Menu.Item key="/verifies/page">Page verify list</Menu.Item>
                  <Menu.Item key="/verifies/page/VERIFIED">Approve page verify list</Menu.Item>
                  <Menu.Item key="/verifies/page/REJECTED">Reject page verify list</Menu.Item>
                  <Menu.Item key="/verifies/page/IGNORED">Ignore page verify list</Menu.Item>
                  <Menu.Item key="/verifies/user">User verify list</Menu.Item>
                  <Menu.Item key="/verifies/user/VERIFIED">Approve user verify list</Menu.Item>
                  <Menu.Item key="/verifies/user/REJECTED">Reject user verify list</Menu.Item>
                  <Menu.Item key="/verifies/user/IGNORED">Ignore user verify list</Menu.Item>
                </SubMenu>
              )}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="reports"
                  title={
                    <span>
                      <Icon type="alert" />
                      <span>Reports management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['page-verifies'])}
                >
                  <Menu.Item key="/reports/page">Page reports</Menu.Item>
                  <Menu.Item key="/reports/post">Post reports</Menu.Item>
                  <Menu.Item key="/reports/user">User reports</Menu.Item>
                  <Menu.Item key="/reports/comment">Comment reports</Menu.Item>
                  <Menu.Item key="/reports/group">Group reports</Menu.Item>
                  <Menu.Item key="/reports/media">Media reports</Menu.Item>
                  <Menu.Item key="/reports/product">Product reports</Menu.Item>
                </SubMenu>
              )}

              {/* <SubMenu
                key="products"
                title={
                  <span>
                    <Icon type="appstore" />
                    <span>Product management</span>
                  </span>
                }
                onTitleClick={item => setActivePath(['product'])}
              >
                <Menu.Item key="/products">Products</Menu.Item>
                <Menu.Item key="/products/BLOCKED">Blocked Products</Menu.Item>
              </SubMenu> */}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="places"
                  title={
                    <span>
                      <Icon type="database" />
                      <span>Place management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['places'])}
                >
                  <Menu.Item key="/places">Places</Menu.Item>
                </SubMenu>
              )}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="pages"
                  title={
                    <span>
                      <Icon type="book" />
                      <span>Pages management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['pages'])}
                >
                  <Menu.Item key="/pages">Pages</Menu.Item>
                  <Menu.Item key="/pages/BLOCKED">Blocked pages</Menu.Item>
                </SubMenu>
              )}
              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="groups"
                  title={
                    <span>
                      <Icon type="book" />
                      <span>Groups management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['groups'])}
                >
                  <Menu.Item key="/groups">Groups</Menu.Item>
                </SubMenu>
              )}

              {/* <SubMenu
                key="groups"
                title={
                  <span>
                    <Icon type="ordered-list" />
                    <span>Groups management</span>
                  </span>
                }
                onTitleClick={item => setActivePath(['groups'])}
              >
                <Menu.Item key="/groups">Groups</Menu.Item>
              </SubMenu> */}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="page-categories"
                  title={
                    <span>
                      <Icon type="database" />
                      <span>Page categories management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['page-categories'])}
                >
                  <Menu.Item key="/page-categories">Page categories</Menu.Item>
                </SubMenu>
              )}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="trusted-points"
                  title={
                    <span>
                      <Icon type="star" />
                      <span>Trusted point management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['trusted-points'])}
                >
                  <Menu.Item key="/trusted-points">Trusted point</Menu.Item>
                </SubMenu>
              )}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="sensitive-words"
                  title={
                    <span>
                      <Icon type="close-circle" />
                      <span>Sensitive words management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['sensitive-words'])}
                >
                  <Menu.Item key="/sensitive-words">Sensitive words</Menu.Item>
                </SubMenu>
              )}

              {activeSidebar === UserGroup.ADMIN && (
                <SubMenu
                  key="system-notifications"
                  title={
                    <span>
                      <Icon type="alert" />
                      <span>System notifications management</span>
                    </span>
                  }
                  onTitleClick={item => setActivePath(['system-notifications'])}
                >
                  <Menu.Item key="/system-notifications">System notifications</Menu.Item>
                </SubMenu>
              )}

              {activeSidebar === UserGroup.ADMIN && (
                <Menu.Item key="/settings">
                  <Icon type="tool" />
                  <span>Config APP</span>
                </Menu.Item>
              )}

              {activeSidebar === UserGroup.ADMIN && (
                <Menu.Item key="/system-settings">
                  <Icon type="tool" />
                  <span>System Settings</span>
                </Menu.Item>
              )}
            </Menu>
          </Sider>
        )}
      </AppContext.Consumer>
    </StyledContainer>
  );
});

export default Sidebar;
