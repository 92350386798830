import "moment-timezone";
const moment = require('moment');
const {timezones,dateTypes} = require("../configs/dateConfig");



export const dateWithTimeZone = (date="now",timezone=timezones.VI.tz) => {
  try{
    return date === "now"?moment().tz(timezone):moment(date).tz(timezone);
  }catch (e) {
    return  date;
  }
};

export const dateByFormat = (date,format =  dateTypes.displayType) => {
  let dateTZ = dateWithTimeZone(date);
  return moment(dateTZ).format(format);
};
