import { gql } from 'apollo-boost';

export const GET_PAGE_CATEGORIES = gql`
  query GetPageCategories {
    cmsGetPageCategories {
      counter
      items {
        _id
        name {
          VI_VN
          EN_US
        }
        enum
        deletedAt
      }
    }
  }
`;

export const CMS_UPDATE_PAGE_CATEGORIES = gql`
  mutation CmsUpdatePageCategory($_id: ID!, $name_vi: String!, $enum: String!, $name_en: String!) {
    cmsUpdatePageCategory(_id: $_id, data: { name: { VI_VN: $name_vi, EN_US: $name_en }, enum: $enum })
  }
`;

export const CMS_CREATE_PAGE_CATEGORIES = gql`
  mutation CmsCreatePageCategory($name_vi: String!, $enum: String!, $name_en: String!) {
    cmsCreatePageCategory(data: { name: { VI_VN: $name_vi, EN_US: $name_en }, enum: $enum })
  }
`;
