import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Skeleton, Table } from 'antd';

import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { GET_USERS } from '../../graphql/user/users';
import { GetUsers, GetUsersVariables, UserGroup } from '../../graphql/types';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';

const { Search } = Input;

const columns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    sorter: false,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    sorter: false,
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    sorter: false,
  },
  {
    title: 'Phone number',
    dataIndex: 'phoneNumber',
    sorter: false,
  },
  {
    title: 'Group',
    dataIndex: 'group',
    sorter: false,
  },
  {
    title: 'Status',
    dataIndex: 'termStatus',
    sorter: false,
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    sorter: false,
  },
  {
    title: 'Action',
    key: 'operation',
    render: row => {
      return (
        <div>
          <Link to={'/users/' + row._id + '/edit'}>
            <Button
              className="btn-rectangle"
              type="primary"
              icon="edit"
              size="small"
              onClick={e => console.log(row._id)}
            />
          </Link>
        </div>
      );
    },
  },
];

const { Content } = Layout;

// const text = 'Are you sure to delete this task?';
//
// function confirm() {
//   notification.open({
//     placement: 'bottomRight',
//     type: 'success',
//     message: 'Deleted',
//     description:
//       'Record has been deleted.',
//   });
// }

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

interface IEditProps extends FormComponentProps {
  match?: any;
}

const ProviderImpl = (props: IEditProps) => {
  const [isLoading, setLoading] = useState(true);
  const [query, setQuery] = useState('');
  const getUser = () => {
    return useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
      fetchPolicy: 'network-only',
      variables: {
        query: query,
        group: UserGroup.PROVIDER,
        limit: 100,
      },
    });
  };

  const search = q => {
    setQuery(q);
  };

  const { data, error, loading } = getUser();

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Providers</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mt-10 mb-10">
          <Col span={6}>
            <Search placeholder="Enter name" enterButton="Search" size="large" onSearch={value => search(value)} />
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetUsers && data.cmsGetUsers.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}></Skeleton>
        ) : (
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={data.cmsGetUsers.items}
            onChange={onChange}
            scroll={{ x: 'calc(700px + 50%)', y: 0 }}
          />
        )}
      </Content>
    </Styled.Container>
  );
};

const ProviderPage = Form.create()(ProviderImpl);
export default ProviderPage;
