import React, { useEffect, useMemo, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Form,
  Layout,
  Modal,
  Skeleton,
  Table,
  Row,
  Pagination,
  Col,
  DatePicker,
  Select,
} from 'antd';

import { FormComponentProps } from 'antd/lib/form';
import { GET_POSTS, SET_STATUS } from '../../graphql/feed/feed';
import {
  GetPosts,
  GetPostsVariables,
  SortByDirection,
  PostSortByType,
  PostStatus,
  setStatusPost,
  setStatusPostVariables,
  GetUsers,
  GetUsersVariables,
} from '../../graphql/types';
import Styled from './indexStyled';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DetailPost from './components/detail';
import ReasonConfig from './components/ReasonConfig';
import moment from 'moment';
import './index.css';
import Search from 'antd/lib/input/Search';
import { GET_USERS } from 'src/graphql/user/users';
import { CloseCircleOutlined } from '@ant-design/icons';

const { dateByFormat } = require('../../helpers/date');

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props extends FormComponentProps {
  match?: any;
}

const PostFormImpl = (props: Props) => {
  const [isLoading, setLoading] = useState(true);
  const [counter, setCounter] = useState(0);
  const [posts, setPosts] = useState([]);
  const [users, setUser] = useState([]);
  const [query, setQuery] = useState('');
  const [dataLoaded, setDataLoaded] = useState([]);
  const [blockData, setBlockData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [post, setPost] = useState({
    title: null,
    medias: null,
    content: null,
    createdAt: null,
    creator: null,
    sharedPost: null,
    _id: null,
  });
  const [postParams, setPostParams] = useState<any>({
    limit: 10,
    offset: 1,
    filter: {},
    sortBy: {
      type: PostSortByType.CREATED_AT,
      dir: SortByDirection.DESC,
    },
  });
  const statuses = ['ALL', PostStatus.INCORRECT, PostStatus.NOT_VERIFIED, PostStatus.PENDING, PostStatus.VERIFIED];
  const creator = useMemo(() => users.map(item => ({ title: item.fullName, value: item._id })), [users]);

  const getPosts = () => {
    let variables;
    variables = {
      limit: postParams.limit,
      offset: postParams.offset,
      filter: postParams.filter,
      sortBy: postParams.sortBy,
    };
    console.log(variables);
    return useQuery<GetPosts, GetPostsVariables>(GET_POSTS, {
      variables: variables,
    });
  };
  const getUser = () => {
    let variables;
    variables = {
      query: query,
      limit: 10,
    };

    return useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
      fetchPolicy: 'network-only',
      variables: variables,
    });
  };

  const { data, error, refetch, loading } = getPosts();
  const { data: usersData } = getUser();

  const onChangePost = page => {
    setPostParams({ ...postParams, offset: page });
  };

  const onShowSizeChange = (current, postSize) => {
    let cOffset = counter / postSize;
    let cOffsetUpward = Math.ceil(cOffset);
    let nOffset = postParams.offset;
    if (cOffsetUpward < postParams.offset) {
      nOffset = cOffsetUpward;
    }

    setPostParams({ ...postParams, limit: postSize, offset: nOffset });
  };

  const show = row => {
    setPost(row);
    setIsShow(true);
  };

  const close = () => {
    setIsShow(false);
  };

  const showReason = (data?) => {
    setShowModal(true);
    setBlockData({
      _id: data._id,
    });
  };

  const closeModalReason = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Title',
      render: row => {
        return (
          <div style={{ maxWidth: '200px' }}>
            <strong>{row.title}</strong>
            <p>
              {row.content && row.content.substr(0, 150)}
              {row.content && row.content.length > 150 && '...'}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Like',
      dataIndex: 'totalLikes',
    },
    {
      title: 'Comment',
      dataIndex: 'totalComments',
    },
    {
      title: 'Report',
      dataIndex: 'totalReports',
      render: row => <div>{row || 0}</div>,
    },
    {
      title: 'Creator',
      dataIndex: 'creator.fullName',
    },
    {
      title: 'Created at',
      key: 'createdAt',
      sorter: true,
      width: 135,
      render: row => {
        return <div>{row && dateByFormat(row.createdAt)}</div>;
      },
    },
    // {
    //   title: 'Status',
    //   fixed: 'right' as 'right',
    //   align: 'center' as 'center',
    //   render: row => {
    //     let type: any = 'default';
    //     if (row.status === PostStatus.VERIFIED) type = 'primary';
    //     if (row.status === PostStatus.NOT_VERIFIED) type = 'danger';
    //     return (
    //       <Button className="w-status" type={type} size="small" onClick={e => show(row)}>
    //         {row.status === PostStatus.NOT_VERIFIED ? 'NOT VERIFIED' : row.status}
    //       </Button>
    //     );
    //   },
    // },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right' as 'right',
      width: 90,
      render: row => {
        return (
          <div>
            <Button type="default" icon="edit" size="small" onClick={e => show(row)} />

            <Button style={{ marginLeft: 5 }} icon="stop" type="danger" size="small" onClick={e => showReason(row)} />
          </div>
        );
      },
    },
  ];

  const [dynamicColumns, setDynamicColumns] = useState(columns);
  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter && sorter.columnKey) {
      if (sorter.order) {
        let columnKey = sorter.columnKey;
        let order = sorter.order == 'ascend' ? SortByDirection.ASC : SortByDirection.DESC;

        if (sorter.columnKey == 'createdAt') {
          let c = dynamicColumns.map(item => {
            if (item.key == columnKey) {
              return { ...item, sortOrder: sorter.order };
            }
            return { ...item, sortOrder: false };
          });
          setDynamicColumns(c);
          // update data
          setPostParams({ ...postParams, sortBy: { type: PostSortByType.CREATED_AT, dir: order } });
        }
      } else {
        if (sorter.columnKey == 'createdAt') {
          let c = dynamicColumns.map(item => {
            return { ...item, sortOrder: false };
          });
          setDynamicColumns(c);
          setPostParams({
            ...postParams,
            filter: {},
            sortBy: {
              type: PostSortByType.CREATED_AT,
              dir: SortByDirection.DESC,
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    refetch({
      limit: postParams.limit,
      filter: postParams.filter,
      offset: postParams.offset,
      sortBy: postParams.sortBy,
    });
  }, [postParams]);

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
      if (data && data.cmsGetPosts && data.cmsGetPosts.items) {
        dataLoaded.push(data.cmsGetPosts.items);
        setDataLoaded(dataLoaded);
      }
    }
  }, [loading]);

  useEffect(() => {
    if (data && data.cmsGetPosts.items && data.cmsGetPosts.items.length) {
      setCounter(data.cmsGetPosts.counter);
      setPosts(data.cmsGetPosts.items);
    } else {
      setCounter(0);
      setPosts([]);
    }
  }, [data]);

  useEffect(() => {
    if (usersData && usersData.cmsGetUsers.items && usersData.cmsGetUsers.items.length) {
      setUser(usersData.cmsGetUsers.items);
    } else {
      setUser([]);
    }
  }, [usersData]);

  const onChangeDateRange = dates => {
    if (!!dates.length) {
      setPostParams({
        ...postParams,
        filter: {
          ...postParams.filter,
          createdAt: {
            from: dates[0]
              .set({ hour: 12, minute: 0 })
              .utc()
              .toDate(),
            to: dates[1]
              .set({ hour: 12, minute: 0 })
              .utc()
              .toDate(),
          },
        },
      });
    } else {
      setPostParams({
        ...postParams,
        filter: {
          ...postParams.filter,
          createdAt: undefined,
        },
      });
    }
  };
  const [setStatus] = useMutation<setStatusPost, setStatusPostVariables>(SET_STATUS, {
    refetchQueries: ['GetPosts'],
  });
  const [statusPost, setStatusPost] = useState();
  const changeEditStatus = () => {
    setStatus({ variables: { id: post._id, status: statusPost } });
  };
  const disableDate = current => {
    return current && current > moment().endOf('day');
  };
  const handleChangeStatus = () => {
    close();
    statusPost && changeEditStatus();
  };

  const handleSearch = value => {
    setPostParams({
      ...postParams,
      filter: {
        ...postParams.filter,
        q: value,
      },
    });
  };
  const changeStatus = status => {
    if (status !== 'ALL') {
      setPostParams({
        ...postParams,
        filter: {
          ...postParams.filter,
          status: status,
        },
      });
    } else {
      setPostParams({
        ...postParams,
        filter: {
          ...postParams.filter,
          status: undefined,
        },
      });
    }
  };
  const changeUser = createdBy => {
    setPostParams({
      ...postParams,
      filter: {
        ...postParams.filter,
        createdBy: createdBy,
      },
    });
  };
  const onSearch = val => {
    if (!!val) {
      setQuery(val);
    } else {
      setQuery('');
    }
  };
  useEffect(() => {
    if (!postParams.filter.createdBy) {
      setQuery('');
    }
  }, [postParams]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Post</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mt-10 mb-10" gutter={16}>
          <Col span={11}>
            <Form.Item label="Search">
              <Search placeholder="Enter title" enterButton="Search" onSearch={value => handleSearch(value)} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Created At">
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                onChange={onChangeDateRange}
                disabledDate={disableDate}
                className="w100"
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Status">
              <Select
                style={{ width: '100%' }}
                defaultValue={statuses[0]}
                placeholder="Select group to filter"
                onChange={e => changeStatus(e)}
              >
                {statuses.map((r, i) => {
                  return (
                    <Option key={i} value={r}>
                      {r}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="User">
              <Select
                style={{ width: '100%' }}
                placeholder="Select creator"
                onChange={e => changeUser(e)}
                onSearch={onSearch}
                showSearch={true}
                allowClear
                filterOption={false}
              >
                {creator.map((r, i) => {
                  return (
                    <Option key={i} value={r.value}>
                      {r.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetPosts && data.cmsGetPosts.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}></Skeleton>
        ) : (
          <Row
            style={{
              backgroundColor: 'white',
            }}
          >
            <Table
              rowKey="_id"
              columns={dynamicColumns}
              dataSource={posts}
              scroll={{ x: 'calc(700px + 50%)', y: 0 }}
              pagination={false}
              onChange={onTableChange}
            />
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={postParams.offset}
              total={counter}
              defaultPageSize={postParams.limit}
              onChange={onChangePost}
              style={{
                marginTop: 20,
                right: 0,
              }}
            />
          </Row>
        )}

        {isShow && post && (
          <Modal
            width={1024}
            title="Post Detail"
            visible={true}
            okText="Ok"
            onOk={() => handleChangeStatus()}
            onCancel={() => close()}
          >
            <DetailPost
              title={post.title}
              content={post.content}
              medias={post.medias}
              creator={post.creator}
              createdAt={post.createdAt}
              sharedPost={post.sharedPost}
              post={post}
              setStatusPost={setStatusPost}
            />
          </Modal>
        )}

        {showModal ? (
          <ReasonConfig visible={showModal} closeModal={() => closeModalReason()} form={props.form} data={blockData} />
        ) : (
          ''
        )}
      </Content>
    </Styled.Container>
  );
};

const Post = Form.create()(PostFormImpl);
export default Post;
