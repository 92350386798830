import { gql } from 'apollo-boost';

export const GET_USERS = gql`
    query GetUsers($query: String!, $limit: Int!,$page: Int, $termStatus: TermStatus, $group: UserGroup,$sortBy:UserSortBy, $gender: Gender, $badgeId: ID) {
        cmsGetUsers(query: $query, limit: $limit, page: $page, filter: { termStatus: $termStatus, group: $group, gender: $gender, badgeId: $badgeId },sortBy:$sortBy) {
            counter
            items{
                _id
                username
                createdAt
                photoUrl
                email
                gender
                phoneNumber
                fullName
                deleted
                totalFriends
                totalPosts
                point
                earnedPoints
                group
                termStatus
                badge {
                    _id
                    title
                }
                dateOfBirth
                interestingTopicKeys
                # countryCode
            }
        }
    }
`;

export const GET_USER = gql`
    query GetUser($userId: ID!) {
        cmsGetUser(userId: $userId) {
            _id
            username
            photoUrl
            email
            gender
            phoneNumber
            fullName
            deleted
            createdAt
            totalFriends
            totalPosts
            group
            termStatus
            countryCode
        }
    }
`;

export const UPDATE_USER_GROUP = gql`
    mutation UpdateUserGroup($userId: ID!, $group: UserGroup!) {
        updateUserGroup(userId: $userId, group: $group)
    }
`;

export const BLOCK_USER = gql`
    mutation BlockUser($targetId: ID!) {
        blockUser(targetId: $targetId)
    }
`;

export const CMS_SET_USER_STATUS = gql`
    mutation CmsSetUserStatus($_id: ID!, $termStatus: TermStatus!, $model: ReportFor!, $reason: String) {
        cmsSetTermStatus(_id: $_id, termStatus: $termStatus, model: $model, reason: $reason)
    }
`;

export const UNBLOCK_USER = gql`
    mutation UnblockUser($targetId: ID!) {
        unBlockUser(targetId: $targetId)
    }
`;

export const REGISTER_FOR_IMPORTER = gql`
    mutation RegisterForImporter($data: RegisterInputForImporter!) {
        registerForImporter(data: $data) {
            idToken
        }
    }
`;

export const CMS_GET_USER_POINT_HISTORY_BY_DATE = gql`
    query getEarnedPointHistoriesByDate($userId: ID!,$limit:Int!,$page:Int,$after:ID,$groupByEarnedAt: Boolean,$filterDate: String,$filterRange: [String!]){
        getEarnedPointHistories (
            userId:$userId,
            limit: $limit,
            page: $page,
            groupByEarnedAt:$groupByEarnedAt,
            after: $after,
            filterDate: $filterDate,
            filterRange: $filterRange,
        ){
            items{
                earnedPoints
                earnedAt
                changedCount
            }
        }
    }
`;


export const CMS_GET_USER_DETAIL_POINT_HISTORY = gql`
    query getEarnedPointHistories($userId: ID!,$limit:Int!,$page:Int,$after:ID,$groupByEarnedAt: Boolean,$filterDate: String,$filterRange: [String!]){
        getEarnedPointHistories (
            userId:$userId,
            limit: $limit,
            page: $page,
            groupByEarnedAt:$groupByEarnedAt,
            after: $after,
            filterDate: $filterDate,
            filterRange: $filterRange,
        ){
            counter
            items{
                trustedPointConfigs{
                    description
                    points
                    trustedPointsFrom
                }
                additionalData{
                    walletExchangeHistory{
                        hash
                    }
                }
                earnedPoints
                earnedAt
                postId
                user{
                    _id
                    point
                    earnedPoints
                }
            }
        }
    }
`;

export const ASSIGN_BADGE = gql`
    mutation cmsAssignBadge($userId: ID!, $badgeId: ID!) {
        cmsAssignBadge(userId: $userId,badgeId: $badgeId)
    }
`;

export const GET_BADGES = gql`
    query cmsUserListBadges($limit: Int!, $page: Int, $filter: BadgeFilter, $sortBy: BadgeSortBy, $q: String) {
        cmsListBadges(limit: $limit, page: $page, filter: $filter, sortBy: $sortBy, q: $q) {
            counter
            items {
              _id
              title
            }
        }
    }
`;

export const CMS_TOP_USERS_REFERRAL = gql`
    query cmsTopReferralUsers($limit:Int!, $filter:FilterTopReferral, $page:Int){
        cmsTopReferralUsers(limit:$limit, filter:$filter, page:$page){
            counter
            items{
                _id
                fullName
                phoneNumber
                referredUserNumber
                avatar{
                    uri
                }
            }
        }
    }
`;
