import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Layout, Pagination, Skeleton, Table, Row, Modal } from 'antd';
import Styled from '../../Place/indexStyled';
import { FormComponentProps } from 'antd/lib/form';
import CreateMessage from './addMessage';
import EditMessage from './editMessage';

import {
  CMS_LIST_FAILED_SPIN_MESSAGE,
  CMS_DELETE_FAILED_SPIN_MESSAGE,
} from '../../../graphql/luckyWheel';
import {
  cmsListFailedSpinMessages,
  cmsListFailedSpinMessagesVariables,
  cmsDeleteFailedSpinMessages,
  cmsDeleteFailedSpinMessagesVariables,

} from '../../../graphql/types';
import { useMutation, useQuery } from '@apollo/react-hooks';
const { confirm } = Modal;
const { Content } = Layout;

interface Props extends FormComponentProps {
  match?: any;
}

const MessageResultImpl = (props: Props) => {
  // const {params} = props.match;

  const [isLoading] = useState(false);
  const [loadingDelete] = useState(false);
  const [modalAddMessage, setModalAddMessage] = useState(false);
  const [modalEditMessage, setModalEditMessage] = useState(false);
  const [messageEdit, setMessageEdit] = useState(null);
  const [selectedRowKeys, setSelectRowKeys] = useState([]);
  const [failedMessages, setFailedMessages] = useState([]);
  const [totalItem, setTotalItem] = useState(0);
  const [filterParams,setFilterParams] = useState({
    page: 1,
    limit: 10,
  });

  const { data: dataFailedMessageAPI,refetch } = useQuery<cmsListFailedSpinMessages, cmsListFailedSpinMessagesVariables>(CMS_LIST_FAILED_SPIN_MESSAGE,{
    variables:{
      limit:filterParams.limit,
      page:filterParams.page
    },
    fetchPolicy: 'network-only',
  });
  const [deleteFailedMessagesAPI]  = useMutation<cmsDeleteFailedSpinMessages, cmsDeleteFailedSpinMessagesVariables>(CMS_DELETE_FAILED_SPIN_MESSAGE,{
    refetchQueries:['cmsListFailedSpinMessages']
  });


  const columns = [
    {
      title: 'No.',
      key: 'index',
      width: '10%',
      render: (text, record, index) => (index + 1) + (filterParams.page - 1) * filterParams.limit,
    },
    {
      title: 'Message',
      dataIndex: 'content',
    },
    {
      title: 'Message english',
      dataIndex: 'contentEn',
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: row => {
        return (
          <div >
            <Button type="primary" icon="edit" size="small" onClick={e => onEditMessage(row)}/>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page) => {
    setFilterParams({ ...filterParams, page });
  };

  const onEditMessage = (messageData) => {
    setMessageEdit(messageData);
    toggleModalEditMessage();
  };

  const toggleModalAddMessage = () => {
    setModalAddMessage(!modalAddMessage);
  };

  const toggleModalEditMessage = () => {
    setModalEditMessage(!modalEditMessage);
  };

  const onSelectChange = (selected) => {
    setSelectRowKeys([...selected]);
  };

  const onRemoveMessageResult = () => {
    deleteFailedMessagesAPI({
      variables:{
        _ids:selectedRowKeys
      }
    }).then(()=>{
      setSelectRowKeys([]);
      setFilterParams({...filterParams,page: 1});
    }
      )
  };

  const onRemoveMessage = () => {
    confirm({
      title: 'Confirm delete Message',
      content: "Are you sure you want delete these message?",
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {onRemoveMessageResult()},
      onCancel() {},
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    if (dataFailedMessageAPI && dataFailedMessageAPI.cmsListFailedSpinMessages && dataFailedMessageAPI.cmsListFailedSpinMessages.items) {
      setFailedMessages(dataFailedMessageAPI.cmsListFailedSpinMessages.items);
      setTotalItem(dataFailedMessageAPI.cmsListFailedSpinMessages.counter);
    }
  }, [dataFailedMessageAPI]);

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Lucky Wheel</Breadcrumb.Item>
          <Breadcrumb.Item>Message miss prize</Breadcrumb.Item>
        </Breadcrumb>
        <br/>
        <Row>
          <Col span={12} offset={12} style={{ textAlign: 'right' }}>
            <Button type={'primary'} onClick={toggleModalAddMessage}>Add Message</Button>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>
            Total:{' '}
            {(dataFailedMessageAPI &&
              dataFailedMessageAPI.cmsListFailedSpinMessages &&
              dataFailedMessageAPI.cmsListFailedSpinMessages.counter) ||
              0}
          </strong>
        </p>

        <br />
        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}/>
        ) : (
          <div className='message-result'>
            <Button className="mb-3" type="primary"
                    onClick={onRemoveMessage}
                    disabled={!hasSelected}
                    loading={loadingDelete}>
              Remove
            </Button>
            <Table rowKey="_id"
                   rowSelection={rowSelection}
                   pagination={false}
                   columns={columns}
                   dataSource={failedMessages}
            />
            <br/>
            <Pagination defaultCurrent={filterParams.page}
                        current={filterParams.page}
                        pageSize={filterParams.limit}
                        total={totalItem}
                        onChange={onChangePage}/>
          </div>
        )}
        {
          modalAddMessage && (
            <Modal
              visible={modalAddMessage}
              width={1000}
              title={'Add message'}
              onOk={toggleModalAddMessage}
              onCancel={toggleModalAddMessage}
              footer=""
            >
              <CreateMessage onClose={toggleModalAddMessage}
                             reFetchParent={() => {
                             }}

              />
            </Modal>
          )
        }
        {
          modalEditMessage &&
          (
            <Modal
              className="lucky-instruction-modal"
              visible={modalEditMessage}
              width={900}
              title={'Edit instruction'}
              onOk={toggleModalEditMessage}
              onCancel={toggleModalEditMessage}
              footer=""
            >
              <EditMessage
                messageData={messageEdit}
                onClose={toggleModalEditMessage}
                reFetchParent={refetch}
              />
            </Modal>
          )
        }
      </Content>
    </Styled.Container>
  );
};

const MessageResult = Form.create()(MessageResultImpl);
export default MessageResult;
