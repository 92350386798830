import { gql } from 'apollo-boost';

export const GET_CONFIGS = gql`
 query GetConfigs($limit: Int!){
  getConfigs (limit: $limit){
    _id
    key
    value
    type
  }
} 
`;

export const CREATE_CONFIG = gql`
 mutation CreateConfig($key: String!, $value: String!, $type: ConfigType){
  createConfig (key: $key, value: $value, type: $type){
    key
    value
  }
}
`;

export const UPDATE_CONFIG = gql`
 mutation UpdateConfig($_id: ID!, $key: String!, $value: String!, $type: ConfigType){
  updateConfig (_id: $_id, key: $key, value: $value, type: $type){
    key
    value
  }
} 
`;

export const DELETE_CONFIG = gql`
 mutation DeleteConfig($_id: ID!){
  deleteConfig (_id: $_id)
} 
`;


