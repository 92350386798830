import React, { useState } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { CMS_UPDATE_PAGE_CATEGORIES, CMS_CREATE_PAGE_CATEGORIES } from '../../../graphql/pageCategory/pageCategory';
import {
  CmsUpdatePageCategory,
  CmsUpdatePageCategoryVariables,
  CmsCreatePageCategory,
  CmsCreatePageCategoryVariables,
} from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';

interface IData {
  _id?: any;
  name?: any;
  enum?: string;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
  form: any;
}

const EditConfig = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;

  const [updateConfig] = useMutation<CmsUpdatePageCategory, CmsUpdatePageCategoryVariables>(
    CMS_UPDATE_PAGE_CATEGORIES,
    {
      refetchQueries: ['GetPageCategories'],
    },
  );

  const [createConfig] = useMutation<CmsCreatePageCategory, CmsCreatePageCategoryVariables>(
    CMS_CREATE_PAGE_CATEGORIES,
    {
      refetchQueries: ['GetPageCategories'],
    },
  );

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        let call = null;
        if (props.data && props.data._id) {
          call = updateConfig({
            variables: {
              name_vi: values.name_vi,
              name_en: values.name_en,
              _id: props.data._id,
              enum: values.enum,
            },
          });
        } else {
          call = createConfig({
            variables: {
              name_vi: values.name_vi,
              name_en: values.name_en,
              enum: values.enum,
            },
          });
        }
        call
          .catch(e => {
            console.log(e.toLocaleString());
          })
          .then(() => {
            setShow(false);
            props.closeModal();
          });
      }
    });
  };

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const { visible } = props;
  const [show, setShow] = useState(visible);
  return (
    <div>
      <Modal
        title={props.data._id ? 'Edit page Category' : 'Add new page category'}
        visible={show}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText={'Save'}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form onSubmit={handleSubmit}>
              <Col span={24}>
                <Form.Item label="Name VI">
                  {getFieldDecorator(`name_vi`, {
                    initialValue: props.data.name && props.data.name.VI_VN,
                    rules: [
                      {
                        required: true,
                        message: 'Please input name!',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="Name EN">
                  {getFieldDecorator(`name_en`, {
                    initialValue: props.data.name && props.data.name.EN_US,
                    rules: [
                      {
                        required: true,
                        message: 'Please input name!',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
                <Form.Item label="Enum">
                  {getFieldDecorator(`enum`, {
                    initialValue: props.data.enum,
                    rules: [
                      {
                        required: true,
                        message: 'Please input enum!',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditConfig;
