import styled from 'styled-components';

const Styled = {
  Container: styled.div`
    .content{
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
      min-height: 80vh;
    }
    ul{
      margin: 0;
      padding: 0;
    }
    .ant-btn-circle.ant-btn-icon-only{
      padding-left: 0;
      padding-right: 0;
      height: 30px;
      width: 30px;
      min-width: 30px;
      line-height: 30px;
    }
    .ant-input{
      height: 40px;
      padding: 5px 10px;
    }
  `,
};
export default Styled;