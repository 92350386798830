import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { UPDATE_SETTING } from '../../../graphql/settings/settings';
import { UpdateSetting, UpdateSettingVariables, SettingKey }  from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';

interface IData {
  key?: SettingKey;
  value?: string;
  description?: string;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
}

const EditSettingImpl = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;
  const { visible } = props;
  const [ show, setShow ] = useState(visible);
  const [ value ] = useState(props.data && props.data.value ? props.data.value : '');
  const [ key ] = useState(props.data && props.data.key ? props.data.key : '');
  const [ description ] = useState(props.data && props.data.description ? props.data.description : '');

  const [updateSetting] = useMutation<UpdateSetting, UpdateSettingVariables>(UPDATE_SETTING);

  const handleOk = e => {
    handleSubmit(e);
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (props.data && props.data.key) {
          const { data } = props;
          updateSetting({
            variables: {
              data: {
                value: values.value,
                description: values.description
              },
              key: data.key
            },
          })
          .catch(e => {
            console.log(e.toLocaleString());
          })
          .then(() => {
            setShow(false);
            props.closeModal();
          });
        }
      }
    });
  }

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  useEffect(function() {

  }, [props.data]);

  return (
    <div>
      <Modal
        title={'Edit Setting'}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Save'}
        width={768}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form onSubmit={handleSubmit}>
              <Row type="flex" justify="center" gutter={8}>
                <Col span={24}>
                  <Form.Item label="Key"> 
                    {getFieldDecorator(`key`, {
                      initialValue: key,
                    })(<Input readOnly={true} />)}
                  </Form.Item>
                </Col>  
                <Col span={24}>
                  <Form.Item label="Value"> 
                    {getFieldDecorator(`value`, {
                      initialValue: value,
                        rules: [
                        {
                          required: true,
                          message: 'Please input your value!',
                        },
                        ],
                    })(<Input readOnly={false} />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Description"> 
                    {getFieldDecorator(`description`, {
                      initialValue: description,
                        rules: [
                        {
                          required: false,
                          message: 'Please input your description!',
                        },
                        ],
                    })(<Input readOnly={false} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default EditSettingImpl;