import { gql } from 'apollo-boost';

export const GET_SETTINGS = gql`
 query GetSettings($limit: Int, $offset: Int){
  cmsGetSettings(limit: $limit, offset: $offset){
    _id
    key
    value
    description
    type   
  }
}
`;

export const UPDATE_SETTING = gql`
 mutation UpdateSetting($data: SettingInput!, $key: SettingKey!){
  cmsUpdateSetting(data: $data, key: $key){
    _id
    key
    value
    description
    type
  }
}
`;