import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Col, DatePicker, Form, Input, Modal, Popconfirm, Row, Table } from 'antd';
import UploadPrizeMedia from '../compoments/Uploads';
import AssignWinner from './AssignWinner';
import {
  GetVoucher_voucher,
  PrizeInput,
  updateLuckyWheelPrize,
  updateLuckyWheelPrizeVariables,
} from '../../../graphql/types';
import { CMS_DELETE_LUCKY_PRIZE, CMS_UPDATE_LUCKY_WHEEL_PRIZE } from '../../../graphql/luckyWheel';
import { VOUCHER } from '../../../graphql/voucher/voucher';
import {
  GetVoucher,
  GetVoucherVariables,
  deleteLuckyWheelPrize,
  deleteLuckyWheelPrizeVariables,
  PrizeTier,
} from '../../../graphql/types';
import { FormComponentProps } from 'antd/lib/form';
import { EDIT_MEDIA_LUCKY_WHEEL } from '../../../contexts/type';
import { AppContext } from '../../../contexts/AppContext';
import { ToastError, ToastSuccess } from '../../../components/Toast';

const moment = require('moment');

const { RangePicker } = DatePicker;

const removeIcon = require('../../../assets/images/icons/luckyWheel/remove.png');
const checkBox = require('../../../assets/images/icons/luckyWheel/checkbox.svg');
const actionBatch = require('../../../assets/images/icons/luckyWheel/actionBatch.svg');
const loadingImage = require('../../../assets/images/loadingspinner.gif');

interface dataInput extends PrizeInput {
  media: object,
  prizeId: string
}

let timerUpdatePrize = {};

interface VoucherProps extends FormComponentProps {
  position: number,
  type: PrizeTier
  voucherName: string,
  data: dataInput,
  rangeBatch: [],
  batchs: [],
  onRemove: any,
}

const VoucherPrizeDetail = (props) => {
  const now = new Date();
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);
  const [batchRange, setBatchRange] = useState({ startDate: now, endDate: now });
  const [batchEdit, setBatchEdit] = useState(null);
  const [modalAssign, setModalAssigne] = useState(false);
  const [canRemove,setCanRemove] = useState(true);
  const [voucher, setVoucher] = useState<GetVoucher_voucher>();
  const context = useContext(AppContext);
  const [updatePrize] = useMutation<updateLuckyWheelPrize, updateLuckyWheelPrizeVariables>(CMS_UPDATE_LUCKY_WHEEL_PRIZE, {
    refetchQueries: ['cmsLuckyWheel'],
  });

  const onChangeImage = (media: File) => {
    const { batchs } = props;
    if (media[0] && media[0].originFileObj) {
      context.onUpdateLuckyWheelData({
        position: props.position,
        media: media[0].originFileObj,
        batchs: batchs,
      }, EDIT_MEDIA_LUCKY_WHEEL);
    } else {
      context.onUpdateLuckyWheelData({ position: props.position, media: null, batchs: batchs }, EDIT_MEDIA_LUCKY_WHEEL);
    }
  };
  useEffect(()=>{
    let check = true;
    props.batchs && props.batchs.length && props.batchs.map(item=>{
      if(item.qty > item.remaining){
        check = false;
      }
    });
    setCanRemove(check);
  },[]);


  const { data: VoucherDetail } = useQuery<GetVoucher, GetVoucherVariables>(VOUCHER, {
    variables: {
      _id: props.data.voucherId,
    },
  });
  const [delPrize] = useMutation<deleteLuckyWheelPrize, deleteLuckyWheelPrizeVariables>(CMS_DELETE_LUCKY_PRIZE);

  const checkTimeDisable = (rangeDate) => {
    if (rangeDate && rangeDate.startDate) {
      return moment(rangeDate.startDate).isBefore(moment(new Date()));
    }
    return false;
  };

  const onChangeBatchPoint = (event, prize) => {
    const { value } = event.target;
    if (value) {
      let total = parseInt(value) || 0;
      props.batchs.map(item => {
        total += item._id !== prize._id ? parseInt(item.qty) : 0;
      });
      if (voucher && (total > voucher.available)) {
        ToastError({
          message: 'Error',
          description: 'The total prize in batches cannot be greater than the total number!',
        });
      } else {
        if (timerUpdatePrize[prize._id]) {
          clearTimeout(timerUpdatePrize[prize._id]);
        }
        timerUpdatePrize[prize._id] = setTimeout(() => {
          updatePrize({
            variables: {
              _id: prize._id,
              data: {
                mediaId: prize.media._id,
                qty: parseInt(value),
                prizeTier: prize.prizeTier,
                name: prize.name,
              },
            },
          }).then(res => {
            ToastSuccess({ message: 'Updated', description: 'Lucky prize updated success!' });
          }).catch(err => {
            ToastError({ message: 'Error', description: 'Please try again later!' });
          });
        }, 1000);
      }
    }
  };


  const columns = [
    {
      title: 'Batch',
      key: 'date',
      width: '40%',
      render: (row, record, index) => {
        const rangeConfigBatch = (position) => {
          const { rangeBatch } = props;
          return {
            initialValue: rangeBatch && rangeBatch[position] && [moment(rangeBatch[position].startDate), moment(rangeBatch[position].endDate)],
            rules: [{ type: 'array', required: true, message: 'Please select time!' }],
          };
        };
        return (
          <Form.Item key={index}>
            {getFieldDecorator('batch' + index, rangeConfigBatch(index))(
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                disabled={true}
                onChange={() => {
                }}
                disabledDate={() => true} className="w100"/>)}
          </Form.Item>
        );
      },
    },
    {
      title: 'Quantity',
      key: 'qty',
      render: (row, r, index) => {
        return (
          <Form.Item label="">
            {
              getFieldDecorator('qty' + index, {
                initialValue: row.qty, rules: [
                  {
                    required: true,
                    message: 'Please input this field!',
                  },
                ],
              })(
                <Input disabled={checkTimeDisable(props.rangeBatch[index])}
                       onChange={(event) => {
                         onChangeBatchPoint(event, row);
                       }}
                       className="w50 text-center"/>)
            }
          </Form.Item>
        );
      },
    },
    {
      title: 'Redeemed Quantity',
      key: 'redeem',
      render: (row) => {
        return (
          <Form.Item label="">
            {
              getFieldDecorator('redeem', {
                initialValue: (row.qty - row.remaining) || 0,
              })(
                <Input disabled={true} className="w50 text-center"/>,
              )
            }
          </Form.Item>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (row, record, index) => {
        return (
          <img src={actionBatch} alt="" className="w40" onClick={() => editBatchConfig(row, index)}/>);
      },
    },
  ];

  const editBatchConfig = (prize, position) => {
    setBatchEdit(prize);
    setBatchRange(props.rangeBatch[position]);
    toggleModalAssignWinner();
  };


  const toggleModalAssignWinner = () => {
    setModalAssigne(!modalAssign);
  };

  const onRemovePrize = () => {
    const PrizeIds = props.batchs.map(item => item._id);
    setLoading(true);
    Promise.all(PrizeIds.map(item => {
      return delPrize({ variables: { _id: item } });
    })).then(res => {
      props.onRemove(props.position, props.type);
    })
      .catch(err => {
        console.log('err===>', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (VoucherDetail && VoucherDetail.voucher) {
      setVoucher(VoucherDetail.voucher);
    }
  }, [VoucherDetail]);

  return (
    <div className="mb-5 prize-item-section">
      {
        voucher &&
        (
          <>
            <Row gutter={10} className="voucher-item" justify="space-around" align="middle">
              <Col span={3}>
                <div className="d-flex justify-content-between">
                  <img src={checkBox} alt=""/>
                  <div className="m-auto"> Voucher</div>
                </div>
              </Col>
              <Col span={6}><Input readOnly={true} value={voucher.title}/></Col>
              <Col span={2}>
                <UploadPrizeMedia onChangeImage={onChangeImage}
                                  numberImagesAllowed={1}
                                  imageAccept=""
                                  images={(props.data.media && props.data.media.uri) ? [props.data.media] : []}
                />
              </Col>
              <Col offset={3} span={8}>
                <Row>
                  <Col offset={1} span={12}>Total quantity</Col>
                  <Col span={8}><input className="form-control text-center" type="text" disabled={true}
                                       value={voucher.qty}/></Col>
                </Row>
              </Col>
              <Col span={2}>
                {
                  canRemove &&
                  <Popconfirm title="Are you sure？" onConfirm={onRemovePrize}
                  >
                    <div>
                      <Button type="default" className="remove-icon p015px" style={{ width: '3em', height: '3em' }}>
                        <img src={removeIcon} alt="" className="w100"/>
                      </Button>
                    </div>
                    {
                      loading && <img src={loadingImage} className="w100" alt=""/>
                    }
                  </Popconfirm>
                }
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  rowKey="_id"
                  columns={columns}
                  dataSource={props.batchs}
                  onChange={() => {
                  }}
                  pagination={false}
                />
              </Col>
            </Row>
          </>
        )
      }

      {
        modalAssign &&
        (
          <Modal
            visible={modalAssign}
            width={1000}
            title={
              (
                <div className="w40 d-flex justify-content-between">
                  <label className="m-auto ">
                    <div className="mr-3">Batch</div>
                  </label>
                  <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    disabled={true}
                    value={[moment(batchRange.startDate), moment(batchRange.endDate)]}
                    onChange={() => {
                    }}
                    disabledDate={() => true} className="w100"/>
                </div>
              )
            }
            onOk={toggleModalAssignWinner}
            onCancel={toggleModalAssignWinner}
            footer=""
          >
            <AssignWinner batch={batchEdit}
                          onClose={toggleModalAssignWinner}
                          prizeInfor={{
                            ...props.data.batchs,
                            prizeType: 'voucher',
                            prize: voucher.title,
                          }}
            />
          </Modal>
        )
      }

    </div>
  );
};

const VoucherPrize = Form.create<VoucherProps>()(VoucherPrizeDetail);
export default VoucherPrize;
