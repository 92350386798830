import React, {
  useContext, useEffect,
  useState,
} from 'react';
import {
  Breadcrumb, Button, Col,
  DatePicker,
  Form, Input,
  Layout, Modal,
  Row,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormComponentProps } from 'antd/lib/form';
import Styled from '../indexStyled';
import { AppContext } from '../../../contexts/AppContext';

import AddPrize from './modalPrize';
import VoucherPrizeTmpl from './VoucherPrizeTmpl';
import PointTmpl from './PointTmpl';
import { validateRangeTimes } from '../../../utils/luck-wheel';
import {
  PrizeTier,
  UploadPhoto, UploadPhotoVariables,
  BatchInput,
  createLuckyWheel,
  createLuckyWheelVariables,
} from '../../../graphql/types';
import { CREATE_LUCKY_WHEEL } from '../../../graphql/luckyWheel';
import {
  useMutation,
} from '@apollo/react-hooks';
import { UPLOAD_PHOTO } from '../../../graphql/photo/uploadPhoto';
import { ToastError, ToastSuccess } from '../../../components/Toast';
import history from '../../../history';
import { Link } from 'react-router-dom';
import { CLEAR_MEDIA_LUCKY_WHEEL } from '../../../contexts/type';

const moment = require('moment');


const { Content } = Layout;
const { RangePicker } = DatePicker;
const loadingImage = require('../../../assets/images/loadingspinner.gif');

interface IEditProps extends FormComponentProps {
}

const LuckyWheel = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const now = new Date();
  const [numberBatch, setNumberBatch] = useState(1);
  const [loading, setLoading] = useState(false);
  const [addPrize, setAddPrize] = useState(false);
  const [typeAdd, setTypeAdd] = useState(null);
  const [campaignRange, setCampaignRange] = useState([now, now]);
  const [availableRangeBatch, setAvailableRangeBatch] = useState([[now, now]]);
  const [prizes, setPrizes] = useState([]);
  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };
  const [uploadPhoto] = useMutation<UploadPhoto, UploadPhotoVariables>(UPLOAD_PHOTO, {
    refetchQueries: ['uploadPhoto'],
  });

  const [createLuckyWheel, { error: createLuckError }] = useMutation<createLuckyWheel, createLuckyWheelVariables>(CREATE_LUCKY_WHEEL, {
    refetchQueries: ['cmsListLuckyWheel'],

  });

  const context = useContext(AppContext);
  const { medias: mediasContext } = context.luckyWheel;


  //  Handle store medias .
  const handleUploadMedia = async (medias) => {
    return await Promise.all<{ position: number, mediaId: string }>(medias.map(async item => {
      if (item.media) {
        const mediaId = await uploadPhoto({
          variables: {
            file: item.media,
            dimensions: {
              height: 100,
              width: 100,
            },
          },
        });
        return {
          position: item.position,
          mediaId: mediaId.data.uploadPhoto._id,
        };
      } else if (item.mediaSavedId) {
        return {
          position: item.position,
          mediaId: item.mediaSavedId,
        };
      }
      return true;
    }));
  };

  const mergeLuckyData = async (values, prizes, mediasContext) => {
    const listMedias = await handleUploadMedia(mediasContext);

    // merge media with prize
    prizes = prizes.map((item, index) => {
      let media = listMedias.find(media => media.position === index);
      return {
        ...item,
        mediaId: (media && media.mediaId) || '',
      };
    });

    // Split prize by batch;
    let batchs = new Array(numberBatch).fill(null);
    batchs = batchs.map<BatchInput>((item, index) => {
      let batchPrize = prizes.map((prize, prize_index) => {
        return {
          name: values[prize.prizeTier + '_vi_title'],
          nameEn: values[prize.prizeTier + '_en_title'],
          qty: prize.qtyForEachBatch[index],
          prize_index: prize_index,
          prizeTier: prize.prizeTier,
          mediaId: prize.mediaId,
          point: prize.point,
          voucherId: prize.voucherId,
        };
      });
      return {
        activeDate: { startDate: values['batch' + index][0], endDate: values['batch' + index][1] },
        prizes: batchPrize,
      };
    });

    return {
      title: values.title,
      titleEn: values.titleEn,
      activeDate: { startDate: values.dateActive[0], endDate: values.dateActive[1] },
      numberOfBatch: parseInt(values.numberBatch),
      batchs,
    };
  };

  const getBatchsFromValues = (valuesForm) => {
    let rangeBatchs = [];
    Object.keys(valuesForm).map(item => {
      if (item.indexOf('batch') > -1) {
        rangeBatchs = [...rangeBatchs, valuesForm[item]];
      }
    });
    return rangeBatchs;
  };

  const validateByHand = (values, prizes, mediasContext) => {

    const rangeBatchs = getBatchsFromValues(values);
    let { title } = values;
    if (title) title = title.trim();
    if (!prizes || !prizes.length) {
      setLoading(false);
      ToastError({ message: 'Error prize', description: 'You have not created any prizes!' });
      return false;
    }
    if (prizes.length > mediasContext.length) {
      setLoading(false);
      ToastError({ message: 'Error media', description: 'Please select enough photos for the prize!' });
      return false;
    }

    if (!validateRangeTimes(campaignRange, rangeBatchs)) {
      setLoading(false);
      ToastError({ message: 'Error duration', description: 'The duration(s) you have chosen is not valid!' });
      return false;
    }
    if (!title || title.length > 100) {
      setLoading(false);
      ToastError({ message: 'Error', description: 'The maximum title length is 100 characters!' });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err && validateByHand(values, prizes, mediasContext)) {
        const finalData = await mergeLuckyData(values, prizes, mediasContext);
        createLuckyWheel({
          variables: finalData,
        }).then(res => {
          setLoading(false);
          history.push('/lucky-wheels');
          ToastSuccess({ message: 'Created', description: 'Lucky wheel create success' });
        }).catch(err => {
          console.log('err', err);
        });
      } else {
        setLoading(false);
      }
    });
  };

  const onHandleAddPrize = (values) => {
    setPrizes([...prizes, values]);
    toggleModalAddPrize();
  };

  const openModalAdd = (type) => {
    setTypeAdd(type);
    setAddPrize(true);
  };

  const toggleModalAddPrize = () => {
    setAddPrize(!addPrize);
  };

  const onChangeCampaignRange = (dates) => {
    setCampaignRange(dates);
    availableRangeBatch[0] = dates;
    setAvailableRangeBatch(availableRangeBatch.map(item => {
      item[1] = dates[1];
      return [...item];
    }));
  };

  const onChangeBatchRange = (dates, position) => {
    if (position < numberBatch - 1) {
      let newNextAvaiRange = availableRangeBatch;
      newNextAvaiRange[position + 1] = [moment(dates[1]).add(10, 'minutes'), campaignRange[1]];
      setAvailableRangeBatch([].concat(newNextAvaiRange));
    }
  };

  const onChangeNumberBatch = (event) => {
    const value = parseInt(event.target.value);
    setNumberBatch(value > 1 ? value : 1);
    if (value > availableRangeBatch.length) {
      const newOne = new Array(value - availableRangeBatch.length).fill([now, now]);
      setAvailableRangeBatch(availableRangeBatch.concat(newOne));
    } else if (value < availableRangeBatch.length) {
      availableRangeBatch.splice(value + 1, availableRangeBatch.length);
      setAvailableRangeBatch(availableRangeBatch);
    }
  };

  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
  };
  const disableDate = (current) => {
    return current && current < moment().add(-1, 'days').endOf('day');
  };
  const disableBatchDate = (current, position) => {
    return current &&
      current < moment(availableRangeBatch[position][0]).add(-1, 'days').endOf('day') ||
      current > moment(availableRangeBatch[position][1]).endOf('day');
  };

  const onRemovePrize = (position) => {
    prizes.splice(position, 1);
    setPrizes([...prizes]);
  };

  const renderNumberBatch = () => {
    return (new Array(numberBatch).fill(null)).map((item, index) => {
      return (
        <Form.Item key={index}>
          {getFieldDecorator('batch' + index, rangeConfig)(
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              disabled={index !== 0 && moment(availableRangeBatch[index] ? availableRangeBatch[index][0] : new Date()).isSame(now, 'minute')}
              onChange={(dates) => onChangeBatchRange(dates, index)}
              disabledDate={(current => disableBatchDate(current, index))} className="w100"/>,
          )}
        </Form.Item>
      );
    });
  };

  const renderPrizeType = (type) => {
    return prizes.map((item, index) => {
      if (item.prizeTier === type) {
        if (item.point) {
          return <PointTmpl key={index} position={index} data={item} qtyForEachBatch={item.qtyForEachBatch}
                            onRemove={onRemovePrize}/>;
        } else {
          return <VoucherPrizeTmpl key={index} position={index} data={item} qtyForEachBatch={item.qtyForEachBatch}
                                   voucherName={item.voucherName} onRemove={onRemovePrize}/>;
        }
      }
      return null;
    });
  };

  useEffect(() => {
    if (createLuckError && createLuckError.message) {
      setLoading(false);
      ToastError({ message: 'Error', description: createLuckError.message });
    }
  }, [createLuckError]);

  useEffect(() => {
    return () => {
      context.onUpdateLuckyWheelData([], CLEAR_MEDIA_LUCKY_WHEEL);
    };
  }, []);

  return <Styled.Container>
    <Content className="content lucky-wheel fwBold">
      <Breadcrumb>
        <Breadcrumb.Item>LuckyWheel</Breadcrumb.Item>
        <Breadcrumb.Item><strong>Create Lucky Wheel Campaign</strong> </Breadcrumb.Item>
      </Breadcrumb>

      <br/>
      <Row>
        <Col span={24}>
          <Form onSubmit={handleSubmit}>
            <Form.Item label="Title" labelCol={{ span: 7 }} wrapperCol={{ span: 10 }}>
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please input title!',
                  },
                ],
              })(<Input placeholder="Title" min={5}/>)}
            </Form.Item>
            <Form.Item label="Title En" labelCol={{ span: 7 }} wrapperCol={{ span: 10 }}>
              {getFieldDecorator('titleEn', {
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please input title!',
                  },
                ],
              })(<Input placeholder="Title english" min={5}/>)}
            </Form.Item>
            <Form.Item label="Date Active" labelCol={{ span: 7 }} wrapperCol={{ span: 10 }}>
              {getFieldDecorator('dateActive', rangeConfig)(
                <RangePicker
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY-MM-DD HH:mm"
                  onChange={onChangeCampaignRange} disabledDate={disableDate} className="w100"/>,
              )}
            </Form.Item>
            <Form.Item label="Number of batch" labelCol={{ span: 7 }} wrapperCol={{ span: 10 }}>
              {getFieldDecorator('numberBatch', {
                initialValue: numberBatch.toString(),
                rules: [
                  {
                    min: 1,
                    required: true,
                    whitespace: true,
                    message: 'Please input number of batch!',
                  },
                ],
              })(<Input type="number"
                        min={1}
                        onChange={onChangeNumberBatch}
              />)}
            </Form.Item>
            <Form.Item label="Active date for each batch" labelCol={{ span: 7 }} wrapperCol={{ span: 10 }}>
              {renderNumberBatch()}
            </Form.Item>
            <strong><h3>Prize</h3></strong>

            {/*1*/}

            <div className="prize-section">
              <Row gutter={5}>
                <Col span={10} className="d-flex justify-content-between">
                  <Form.Item className="w40">
                    {getFieldDecorator(PrizeTier.DIAMOND + '_vi_title', {
                      initialValue: 'Giải nhất',
                      rules: [
                        {
                          max: 50,
                          message: 'Prize title must not exceed 50 characters',
                        },
                        {
                          required: true,
                          whitespace: true,
                          message: 'This field is required!',
                        },
                      ],
                    })(<Input/>)}
                  </Form.Item>
                  <Form.Item className="w40">
                    {getFieldDecorator(PrizeTier.DIAMOND + '_en_title', {
                      initialValue: 'First prize',
                      rules: [
                        {
                          max: 50,
                          message: 'Prize title must not exceed 50 characters',
                        },
                        {
                          required: true,
                          whitespace: true,
                          message: 'This field is required!',
                        },
                      ],
                    })(<Input placeholder="Prize english name "/>)}
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button type="default" className="pd0-10" onClick={() => openModalAdd(PrizeTier.DIAMOND)}>
                    <PlusOutlined className="iconPlus"/>
                  </Button>
                </Col>
              </Row>
              {
                renderPrizeType(PrizeTier.DIAMOND)
              }
            </div>

            {/*2*/}
            <div className="prize-section">
              <Row gutter={5}>
                <Col span={10} className="d-flex justify-content-between">
                  <Form.Item className="w40">
                    {getFieldDecorator(PrizeTier.GOLD + '_vi_title', {
                      initialValue: 'Giải nhì',
                      rules: [
                        {
                          max: 50,
                          message: 'Prize title must not exceed 50 characters',
                        },
                        {
                          required: true,
                          whitespace: true,
                          message: 'This field is required!',
                        },
                      ],
                    })(<Input/>)}
                  </Form.Item>
                  <Form.Item className="w40">
                    {getFieldDecorator(PrizeTier.GOLD + '_en_title', {
                      initialValue: 'Second',
                      rules: [
                        {
                          max: 50,
                          message: 'Prize title must not exceed 50 characters',
                        },
                        {
                          required: true,
                          whitespace: true,
                          message: 'This field is required!',
                        },
                      ],
                    })(<Input placeholder="Prize english name "/>)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button type="default" className="pd0-10" onClick={() => openModalAdd(PrizeTier.GOLD)}>
                    <PlusOutlined className="iconPlus"/>
                  </Button>
                </Col>
              </Row>
              {
                renderPrizeType(PrizeTier.GOLD)
              }
            </div>

            {/*3*/}
            <div className="prize-section">
              <Row gutter={5}>
                <Col span={10} className="d-flex justify-content-between">
                  <Form.Item className="w40">
                    {getFieldDecorator(PrizeTier.SILVER + '_vi_title', {
                      initialValue: 'Giải ba',
                      rules: [
                        {
                          max: 50,
                          message: 'Prize title must not exceed 50 characters',
                        },
                        {
                          required: true,
                          whitespace: true,
                          message: 'This field is required!',
                        },
                      ],
                    })(<Input/>)}
                  </Form.Item>
                  <Form.Item className="w40">
                    {getFieldDecorator(PrizeTier.SILVER + '_en_title', {
                      initialValue: 'Third prize',
                      rules: [
                        {
                          max: 50,
                          message: 'Prize title must not exceed 50 characters',
                        },
                        {
                          required: true,
                          whitespace: true,
                          message: 'This field is required!',
                        },
                      ],
                    })(<Input placeholder="Prize english name "/>)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button type="default" className="pd0-10" onClick={() => openModalAdd(PrizeTier.SILVER)}>
                    <PlusOutlined className="iconPlus"/>
                  </Button>
                </Col>
              </Row>
              {
                renderPrizeType(PrizeTier.SILVER)
              }
            </div>

            {/*4*/}
            <div className="prize-section">
              <Row gutter={5}>
                <Col span={10} className="d-flex justify-content-between">
                  <Form.Item className="w40">
                    {getFieldDecorator(PrizeTier.BRONZE + '_vi_title', {
                      initialValue: 'Giải khuyến khích',
                      rules: [
                        {
                          max: 50,
                          message: 'Prize title must not exceed 50 characters',
                        },
                        {
                          required: true,
                          whitespace: true,
                          message: 'This field is required!',
                        },
                      ],
                    })(<Input/>)}
                  </Form.Item>
                  <Form.Item className="w40">
                    {getFieldDecorator(PrizeTier.BRONZE + '_en_title', {
                      initialValue: 'Fourth prize',
                      rules: [
                        {
                          max: 50,
                          message: 'Prize title must not exceed 50 characters',
                        },
                        {
                          required: true,
                          whitespace: true,
                          message: 'This field is required!',
                        },
                      ],
                    })(<Input placeholder="Prize english name "/>)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Button type="default" className="pd0-10" onClick={() => openModalAdd(PrizeTier.BRONZE)}>
                    <PlusOutlined className="iconPlus"/>
                  </Button>
                </Col>
              </Row>
              {
                renderPrizeType(PrizeTier.BRONZE)
              }
            </div>
            <Row>
              <Col offset={16} span={2}>
                <Link to={'/lucky-wheels/'}>
                  <Form.Item {...buttonItemLayout} >
                    <Button type="primary" className="as-danger-btn p015px" htmlType="submit">Cancel</Button>
                  </Form.Item>
                </Link>
              </Col>
              <Col span={4}>
                <Form.Item {...buttonItemLayout} >
                  <Button type="primary" disabled={loading} className="as-primary-btn p015px"
                          htmlType="submit">Create</Button>
                  {
                    loading && <img src={loadingImage} className="w20" alt=""/>
                  }
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      {
        addPrize &&
        (
          <Modal
            visible={addPrize}
            width={800}
            title="Add prize"
            onOk={toggleModalAddPrize}
            onCancel={toggleModalAddPrize}
            footer=""
          >
            <AddPrize type={typeAdd}
                      campaignRange={campaignRange}
                      numberBatch={numberBatch}
                      onClose={toggleModalAddPrize}
                      onHandleAddPrize={onHandleAddPrize}
            />
          </Modal>
        )
      }


    </Content>
  </Styled.Container>;
};

const LuckyWheelCreate = Form.create()(LuckyWheel);
export default LuckyWheelCreate;
