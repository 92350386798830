import React, { useEffect, useRef, useState } from 'react';
import { Form, Layout, Button, Row, Col, Breadcrumb, Input, DatePicker, Select, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import moment from 'moment';
import { CMS_UPDATE_BANNER, CMS_GET_BANNER, CMS_GET_SCREENS_BANNER } from '../../graphql/banner/banner';
import {
  BannerType,
  updateBanner,
  updateBannerVariables,
  getBanner,
  getBannerVariables, cmsListBannerScreen,
} from '../../graphql/types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import history from '../../history';
import { ToastSuccess } from '../../components/Toast';
import UploadVoucher from './compoments/Uploads';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;
const loadingImage = require("../../assets/images/loadingspinner.gif");

interface IEditProps extends FormComponentProps {
  match?: any;
}

const BannerUpdateImpl = (props: IEditProps) => {
  const _id = props.match.params.id;
  const { getFieldDecorator} = props.form;
  const [screens,setScreens] = useState([]);
  const [type,setType] = useState(BannerType.LINK);
  const [loading,setLoading] =  useState(false);


  const { data: banner } = useQuery<getBanner, getBannerVariables>(CMS_GET_BANNER, {
    variables: {
      _id: _id,
    },
  });

  const {data:screenData } = useQuery<cmsListBannerScreen>(CMS_GET_SCREENS_BANNER);

  const [updateBannerAPI, { data }] = useMutation<updateBanner, updateBannerVariables>(CMS_UPDATE_BANNER, {
    refetchQueries: ['cmsVouchers'],
  });

  const refs = useRef(null);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const onChangeScreen = (value) => {
    console.log(`selected ${value}`);
  };

  const  onBlurScreen = () => {
    console.log('blur');
  };

  const onFocusScreen = () => {
    console.log('focus');
  };

  const onSearchScreen = (val) => {
    console.log('search:', val);
  };

  useEffect(()=>{
    if(screenData && screenData.cmsListBannerScreen && screenData.cmsListBannerScreen.length){
      setScreens(screenData.cmsListBannerScreen);
    }
  },[screenData]);

  useEffect(()=>{
    if(banner && banner.banner){
      setType(BannerType[banner.banner.type])
    }
  },[banner]);

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof refs.current.upload === 'function') {
          refs.current.upload();
        }
      }else{
        setLoading(true);
      }
    });
  };

  const onChangeType = (event) => {
    setType(event.target.value);
  };

  const rangeConfig = {
    initialValue: banner && [moment(banner.banner.startDate), moment(banner.banner.endDate)],
    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
  };

  const afterUpload = (mediaId: [string]) => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        updateBannerAPI({
          variables: {
            id: _id,
            data:{
              name: values.name,
              data:{
                link: type === BannerType.LINK ? values.link : null,
                postId: type === BannerType.POST ? values.postId : null,
                pageId: type === BannerType.PAGE ? values.pageId : null,
                screen: type === BannerType.SCREEN ? values.screen : null,
              },
              type: BannerType[type],
              mediaId: mediaId[0],
              status: values.status,
              startDate: (new Date(values.dateActive[0])).getTime(),
              endDate: (new Date(values.dateActive[1])).getTime(),
            }
          },
        }).then(()=>setLoading(false)).catch(() => setLoading(false));
      }else{setLoading(true);}
    });
  };

  const disableDate = (current) => {
    return current && current < moment().add(-1, 'days').endOf('day');
  };

  if (data) {
    history.push('/banners');
    ToastSuccess({ message: 'Updated', description: 'Banner update success' });
  }

  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Banner</Breadcrumb.Item>
          <Breadcrumb.Item>Create</Breadcrumb.Item>
        </Breadcrumb>
        {
          banner &&
          (
            <Row type="flex" justify="center">
              <Col span={12}>
                <Form {...formItemLayout} onSubmit={handleSubmit}>
                  <Row type="flex">
                    <Col span={18} offset={6}>
                      <UploadVoucher ref={refs}
                                     images={(banner && banner.banner.media && [banner.banner.media]) ||[]}
                                     onUploaded={(data: any) => afterUpload(data)}
                                     numberImagesAllowed={1}
                                     imageAccept=""
                      />
                    </Col>
                  </Row>

                  <Form.Item label="Name">
                    {getFieldDecorator('name', {
                      initialValue: banner && banner.banner && banner.banner.name,
                      rules: [
                        {
                          required: true,
                          message: 'Please input name!',
                        },
                      ],
                    })(<Input/>)}
                  </Form.Item>

                  <Form.Item label="Target">
                    <Radio.Group  size="large" value={type} onChange={onChangeType}  className="width-100" >
                      <Row type="flex">
                        <Col span={6}>
                          <Radio name="type" value={BannerType.LINK}>Link</Radio>
                        </Col>
                        <Col span={18}>
                          <Form.Item>
                            {getFieldDecorator('link', {
                              initialValue: banner && banner.banner && banner.banner.data.link,
                              rules: [
                                {
                                  required: type === BannerType.LINK,
                                  message: 'Please input link!',
                                },
                              ],
                            })(<Input  disabled={type !== BannerType.LINK}/>)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type="flex">
                        <Col span={6}>
                          <Radio name="type"  value={BannerType.PAGE}>PageID</Radio>
                        </Col>
                        <Col span={18}>
                          <Form.Item>
                            {getFieldDecorator('pageId', {
                              initialValue: banner && banner.banner && banner.banner.data.pageId,
                              rules: [
                                {
                                  required: type === BannerType.PAGE,
                                  message: 'Please input page ID!',
                                },
                              ],
                            })(<Input disabled={type !== BannerType.PAGE}/>)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type="flex">
                        <Col span={6}>
                          <Radio name="type"  value={BannerType.POST}>PostID</Radio>
                        </Col>
                        <Col span={18}>
                          <Form.Item>
                            {getFieldDecorator('postId', {
                              initialValue: banner && banner.banner && banner.banner.data.postId,
                              rules: [
                                {
                                  required: type === BannerType.POST,
                                  message: 'Please input post ID!',
                                },
                              ],
                            })(<Input disabled={type !== BannerType.POST}/>)}
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row type="flex">
                        <Col span={6}>
                          <Radio name="type"  value={BannerType.SCREEN}>Screen</Radio>
                        </Col>
                        <Col span={18}>
                          <Form.Item>
                            {getFieldDecorator('screen', {
                              initialValue: banner && banner.banner && banner.banner.data.screen,
                              rules: [
                                {
                                  required: type === BannerType.SCREEN,
                                  message: 'Please input screen!',
                                },
                              ],
                            })(
                              <Select
                                showSearch
                                disabled={type !== BannerType.SCREEN}
                                style={{ width: 200 }}
                                placeholder="Select a screen"
                                onChange={onChangeScreen}
                                onFocus={onFocusScreen}
                                onBlur={onBlurScreen}
                                onSearch={onSearchScreen}
                              >
                                {
                                  screens.map((item,index)=>{
                                    return (<Option key={index} value={item.screen}>{item.title}</Option>)
                                  })
                                }
                              </Select>
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="Date Active">
                    {getFieldDecorator('dateActive', rangeConfig)(
                      <RangePicker showTime={{ format: 'HH:mm' }}
                                   format="YYYY-MM-DD HH:mm"
                                   disabledDate={disableDate}/>
                    )}
                  </Form.Item>

                  <Form.Item {...buttonItemLayout} >
                    <Button type="primary" htmlType="submit">Submit</Button>
                    {
                      loading && <img src={loadingImage} className="w20" alt=""/>
                    }
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )
        }

      </Content>
    </Styled.Container>
  )
  };
const BannerUpdate = Form.create()(BannerUpdateImpl);
export default BannerUpdate;
