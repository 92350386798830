import React from 'react';
import { Layout } from 'antd';
import Styled from './../indexStyled';
const {dateByFormat} = require("../../../helpers/date");

const { Content } = Layout;

interface IEditProps{  
  fullName?: string;
  phoneNumber?: string;
  email?: string;
  gender?: string;
  group?: string;
  termStatus?: string;
  badge?: any;
  createdAt?: String;
  dateOfBirth?: String;
  interestingTopicKeys?: [String];
}

const UserDetail = (props: IEditProps) => {
  let interesting: String = (props.interestingTopicKeys) ? props.interestingTopicKeys.toString() : '';
  let badgeName: String = props.badge ? props.badge.title : '';
  let birthday: String = props.dateOfBirth ? dateByFormat(props.dateOfBirth) : '';
  return (
    <Styled.Container>
      <Content>
        <p><strong>Name: </strong> {props.fullName}</p>
        <p><strong>Phone number: </strong>{props.phoneNumber}</p>
        <p><strong>Email: </strong>{props.email}</p>
        <p><strong>Gender: </strong>{props.gender}</p>
        <p><strong>Group: </strong>{props.group}</p>
        <p><strong>Status: </strong>{props.termStatus}</p>
        <p><strong>Badge: </strong>{badgeName}</p>
        <p><strong>Created At: </strong>{dateByFormat(props.createdAt)}</p>
        <p><strong>Date of Birth: </strong>{birthday}</p>
        <p><strong>Interesting Topics: </strong>{interesting}</p>
      </Content>
    </Styled.Container>
  );
};
export default UserDetail;


