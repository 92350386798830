import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Form, Layout, notification, Popconfirm, Skeleton, Table, Input } from 'antd';
const { Search } = Input;
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';

import Styled from '../Place/indexStyled';
import { GET_SENSITIVE_WORDS, REMOVE_SENSITIVE_WORD_BY_IDS } from '../../graphql/sensitiveWords/sensitiveWords';
import {
  GetSensitiveWords,
  RemoveSensitiveWordByIds,
  RemoveSensitiveWordByIdsVariables,
  GetSensitiveWordsVariables,
} from '../../graphql/types';
import EditConfig from './components/editConfig';
import { ExportFile } from '../../utils/exportFile';

const { Content } = Layout;

const text = 'Are you sure to delete this word?';

const SensitiveWordsImpl = (props: FormComponentProps) => {
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [filterData, setFilter] = useState({
    q: '',
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const [removeWord, { data: removeWordResult }] = useMutation<
    RemoveSensitiveWordByIds,
    RemoveSensitiveWordByIdsVariables
  >(REMOVE_SENSITIVE_WORD_BY_IDS, { refetchQueries: ['GetSensitiveWords'] });

  const getSensitiveWords = () => {
    return useQuery<GetSensitiveWords, GetSensitiveWordsVariables>(GET_SENSITIVE_WORDS, {
      fetchPolicy: 'network-only',
      variables: filterData,
    });
  };

  let { data, error, loading } = getSensitiveWords();

  const confirm = id => {
    removeWord({
      variables: {
        ids: [id],
      },
    }).catch(() => {});
    if (removeWordResult) {
      notification.open({
        placement: 'bottomRight',
        type: 'success',
        message: 'Deleted',
        description: 'Sensitive word has been deleted.',
      });
    }
  };

  const deleteMultiWords = () => {
    if (selectedIds && selectedIds.length) {
      removeWord({
        variables: {
          ids: selectedIds,
        },
      }).catch(() => {});
      if (removeWordResult) {
        notification.open({
          placement: 'bottomRight',
          type: 'success',
          message: 'Deleted',
          description: 'Sensitive words has been deleted.',
        });
      }
    }
    setSelectedIds([]);
    setSelectedRowKeys([]);
  };

  const showEdit = data => {
    setShowModal(true);

    if (data) {
      setEditData({
        _id: data._id,
        key: data.key,
      });
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setEditData({});
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Word',
      dataIndex: 'key',
    },
    {
      title: 'Action',
      key: 'operation',
      width: 150,
      render: row => {
        return (
          <div>
            <Button type="primary" icon="edit" size="small" onClick={e => showEdit(row)} />

            <Popconfirm placement="left" title={text} onConfirm={e => confirm(row._id)} okText="Yes" cancelText="No">
              <Button className="ml-15" type="danger" icon="delete" size="small" onClick={e => console.log(row._id)} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const exportToExcel = () => {
    let excelDatas = [];
    if (data && data.cmsBlackListWords && data.cmsBlackListWords.items && data.cmsBlackListWords.items.length > 0) {
      let count = data.cmsBlackListWords.items.length;
      for (let i = 0; i < count; i++) {
        excelDatas.push({
          'Sensitive word': data.cmsBlackListWords.items[i].key,
          'Import words':
            i == count - 1 ? `${data.cmsBlackListWords.items[i].key}` : `${data.cmsBlackListWords.items[i].key},`,
        });
      }

      ExportFile.exportToExcel(excelDatas, 'sensitive_words');
    }
  };

  const search = value => {
    setFilter({ ...filterData, q: value });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: rowKeys => {
      setSelectedRowKeys(rowKeys);
      setSelectedIds(rowKeys);
    },
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Sensitive words</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsBlackListWords && data.cmsBlackListWords.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div>
            <Search
              placeholder="input search text"
              defaultValue={filterData.q}
              onSearch={value => search(value)}
              style={{
                width: 200,
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <div style={{ textAlign: 'right' }}>
              <Button type="primary" onClick={() => showEdit(null)}>
                Add new
              </Button>

              <Button style={{ marginLeft: 10 }} type="primary" onClick={exportToExcel}>
                Export
              </Button>
            </div>

            <Table
              rowKey="_id"
              pagination={{ pageSize: 10 }}
              columns={columns}
              dataSource={data.cmsBlackListWords.items}
              rowSelection={rowSelection}
            />

            {selectedIds && selectedIds.length ? (
              <Button type="danger" onClick={deleteMultiWords}>
                Delete
              </Button>
            ) : (
              ''
            )}
          </div>
        )}

        {showModal && (
          <EditConfig data={editData} visible={showModal} closeModal={() => closeModal()} form={props.form} />
        )}
      </Content>
    </Styled.Container>
  );
};

const SensitiveWords = Form.create()(SensitiveWordsImpl);
export default SensitiveWords;
