import React, { useEffect, useState } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Breadcrumb, Button, Form, Input, Layout, Modal, Skeleton, Table, Tooltip, Row, Col, Pagination, Select } from 'antd';
import { useQuery } from '@apollo/react-hooks';

import Styled from './indexStyled';
import { CMS_GET_PRODUCT_REPORT } from '../../graphql/report/report';
import {
  CmsGetProductReports,
  CmsGetProductReportsVariables,
  PostSortByType, PostType, ReportFor,
  SortByDirection,
  TermStatus,
} from '../../graphql/types';
import { dateByFormat } from '../../helpers/date';
import ProductDetail from '../Product/components/detail';
import CreatorDetail from '../../components/CreatorDetail';
import ReasonConfig from '../Post/components/ReasonConfig';
import imagePage from '../../assets/images/page.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailReports from './components/DetailReports';

const { Search } = Input;

const { Content } = Layout;
const { Option } = Select;

const termStatusFilter = ["ALL", TermStatus.NORMAL, TermStatus.HIDDEN, TermStatus.SUSPENDING, TermStatus.BLOCKED];
const typeFilter = ["ALL", PostType.HOTEL, PostType.TOUR];



const ProductReportImpl = (props: FormComponentProps) => {
  const [isLoading, setLoading] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [postIdEdit, setPostIdEdit] = useState(null);
  const [showReports, setShowReports] = useState(false);
  const [product, setProduct] = useState({
    _id: null,
    title: null,
    medias: null,
    content: null,
    createdAt: null,
    creator: null,
    type: null,
    price: null,
  });
  const [blockData, setBlockData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [products,setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [creator, setCreator] = useState({
    name: null,
    fullName: null,
    email: null,
    phoneNumber: null,
    avatar: null,
    point: null,
    termStatus: null,
    logo: null,
    owner: null,
    admins: null,
    isPage: false,
  });

  const [pageParams, setPageParams] = useState<CmsGetProductReportsVariables>({
    offset: 1,
    limit: 10,
    filter: {},
    sortBy: {
      type: PostSortByType.LAST_REPORT_AT,
      dir: SortByDirection.DESC,
    },
  });

  const getProductReport = () => {
    return useQuery<CmsGetProductReports, CmsGetProductReportsVariables>(CMS_GET_PRODUCT_REPORT, {
      fetchPolicy: 'network-only',
      variables: pageParams,
    });
  };

  let { data, error, loading, refetch } = getProductReport();


  useEffect(()=>{
    if (data && data.cmsGetProductReports && data.cmsGetProductReports.items) {
      setProducts(data.cmsGetProductReports.items);
      setTotal(data.cmsGetProductReports.total);
    }
  },[data]);

  const show = row => {
    setProduct(row);
    setIsShow(true);
  };

  const close = () => {
    setIsShow(false);
  };

  const showModalReason = data => {
    setShowModal(true);
    setBlockData({
      _id: data._id,
    });
  };

  const handleShowReport = (pageId) => {
    setPostIdEdit(pageId);
    setShowReports(true);
  };
  const closeReport = () =>{
    setPostIdEdit(null);
    setShowReports(false);
  };

  const closeModalReason = () => {
    setShowModal(false);
  };

  const showDetailCreator = row => {
    setCreator(
      row.page
        ? {
          ...row.page,
          isPage: true,
        }
        : {
          ...row.creator,
          isPage: false,
        },
    );
    setShowModalDetail(true);
  };
  const closeModalDetail = () => {
    setShowModalDetail(false);
  };

  const columns = [
    {
      title:"ID",
      dataIndex: '_id'
    },
    {
      title: 'Title',
      render: row => {
        return (
          <div style={{ maxWidth: '200px' }}>
            <strong>{row.title}</strong>
            <p>
              {row.content && row.content.substr(0, 150)}
              {row.content && row.content.length > 150 && '...'}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'termStatus',
      key: 'termStatus',
      render: row => <div>{row || TermStatus.NORMAL}</div>,
    },
    {
      title: 'Creator',
      render: row => {
        return (
          <div className="link" onClick={() => showDetailCreator(row)}>
            {row.page ? row.page.name : row.creator.fullName}
            {row.page && <img className="imagePage" src={imagePage} alt="" />}
          </div>
        );
      },
    },
    {
      title: 'Last reported at',
      dataIndex: 'lastReportAt',
      key: 'lastReportAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: row => {
        return <div>{row && dateByFormat(row)}</div>;
      },
    },
    {
      title: 'Total report',
      dataIndex: 'totalReports',
      key: 'totalReports',
      sorter: true,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 150,
      render: row => {
        return (
          <div className="d-flex justify-content-around">
            <Tooltip title="Detail">
            <Button type="default" icon="eye" size="small" onClick={e => show(row)} />
            </Tooltip>

            <Tooltip title="Block">
            <Button
              style={{ marginLeft: 5 }}
              icon="stop"
              type="danger"
              size="small"
              onClick={e => showModalReason(row)}
            />
            </Tooltip>
            <Tooltip title="History reports">
              <FontAwesomeIcon
                onClick={() => {
                  handleShowReport(row._id);
                }}
                icon={['fas', 'layer-group']}
                size="2x"
                className="action-icon history-report"/>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const [columnssss, setColumnssss] = useState(columns);

  const search = value => {
    setPageParams({ ...pageParams, filter: { ...pageParams.filter, q: value } });
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  const onChangePage = (page) => {
    setPageParams({
      ...pageParams, offset: page
    })
  };

  const handleChangeFilterStatus = (value) => {
    if(value !== termStatusFilter[0]){
      setPageParams({
        ...pageParams,
        offset:1,
        filter:{
          ...pageParams.filter,
          termStatus:value
        }
      })
    }else{
      const newFilter = pageParams.filter;
      delete newFilter.termStatus;
      setPageParams({
        ...pageParams,
        offset:1,
        filter:newFilter
      });
      refetch()
    }
  };

  const handleChangeFilterType = (value) => {
    if(value !== termStatusFilter[0]){
      setPageParams({
        ...pageParams,
        offset:1,
        filter:{
          ...pageParams.filter,
          type:value
        }
      })
    }else{
      const newFilter = pageParams.filter;
      delete newFilter.type;
      setPageParams({
        ...pageParams,
        offset:1,
        filter:newFilter
      });
      refetch()
    }
  };

  const onChangeTable = (pagination, filters, sorter, extra) => {
    if (sorter && sorter.columnKey) {
      if (sorter.order) {
        let columnKey = sorter.columnKey;
        let order = sorter.order === 'ascend' ? SortByDirection.ASC : SortByDirection.DESC;

        if (sorter.columnKey === 'lastReportAt' || sorter.columnKey === 'totalReports') {
          let c = columnssss.map(item => {
            if (item.key === columnKey) {
              return { ...item, sortOrder: sorter.order };
            }
            return { ...item, sortOrder: false };
          });
          setColumnssss(c);
          // update data
          if (columnKey === 'lastReportAt') {
            setPageParams({ ...pageParams, sortBy: { type: PostSortByType.LAST_REPORT_AT, dir: order } });
          } else if (columnKey === 'totalReports') {
            setPageParams({ ...pageParams, sortBy: { type: PostSortByType.TOTAL_REPORTS, dir: order } });
          }
        }
      } else {
        if (sorter.columnKey === 'lastReportAt' || sorter.columnKey === 'totalReports') {
          let c = columnssss.map(item => {
            return { ...item, sortOrder: false };
          });
          setColumnssss(c);
          setPageParams({
            ...pageParams,
            sortBy: {
              type: PostSortByType.LAST_REPORT_AT,
              dir: SortByDirection.DESC,
            },
          });
        }
      }
    }
  };

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Product reports</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetProductReports && data.cmsGetProductReports.total) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div>
            <Row gutter={10} >
              <Col span={4}>
                <Form.Item label="Status">
                  <Select
                    className="w100"
                    showSearch
                    value={pageParams.filter.termStatus || termStatusFilter[0]}
                    defaultValue={termStatusFilter[0]}
                    onChange={handleChangeFilterStatus}
                  >
                    {termStatusFilter.map((termStatus, index) => {
                      return (<Option key={index} value={termStatus}> {termStatus} </Option>);
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Type">
                  <Select
                    className="w100"
                    showSearch
                    value={pageParams.filter.type || typeFilter[0]}
                    defaultValue={typeFilter[0]}
                    onChange={handleChangeFilterType}
                  >
                    {typeFilter.map((type, index) => {
                      return (<Option key={index} value={type}> {type} </Option>);
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Search">
                  <Search
                    placeholder="input search text"
                    defaultValue={pageParams.filter.q}
                    onSearch={value => search(value)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Table
              rowKey="_id"
              // @ts-ignore
              columns={columnssss}
              dataSource={products}
              onChange={onChangeTable}
              pagination={false}
            />
            <br/>
            <Pagination
              current={pageParams.offset}
              pageSize={pageParams.limit}
              total={total}
              onChange={onChangePage}
            />
          </div>
        )}
      </Content>

      {showModalDetail && creator && (
        <Modal
          width={768}
          title="Creator detail"
          visible={showModalDetail}
          okText="Close"
          onOk={() => closeModalDetail()}
          onCancel={() => closeModalDetail()}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <CreatorDetail creator={creator} />
        </Modal>
      )}

      {isShow && product && (
        <Modal
          width={1024}
          title="Product Detail"
          visible={isShow}
          okText="Close"
          onOk={() => close()}
          onCancel={() => close()}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <ProductDetail
            id={product._id}
            title={product.title}
            content={product.content}
            medias={product.medias}
            creator={product.creator}
            createdAt={product.createdAt}
            type={product.type}
            price={product.price}
            isReport={true}
          />
        </Modal>
      )}

      {showModal ? (
        <ReasonConfig
          visible={showModal}
          closeModal={() => closeModalReason()}
          form={props.form}
          data={blockData}
          product={true}
        />
      ) : (
        ''
      )}

      <DetailReports refId={postIdEdit} close={closeReport} for={ReportFor.POST} visible={showReports}  />
    </Styled.Container>
  );
};

const ProductReport = Form.create()(ProductReportImpl);
export default ProductReport;
