import { UserGroup } from 'src/graphql/types';

interface IDataMe {
  _id?: string;
  email?: string;
  username: string;
  fullName: string;
  photoUrl?: string;
  gender?: string;
  __typename: string;
  group?: UserGroup;
  avatar: {
    thumbnail: string;
    thumbnail2x: string;
  };
}
let me = null;

export const setMe = (_me: IDataMe) => {
  me = _me;
};

export const getMe = (): IDataMe => {
  return me;
};

export const nonAccentVietnamese = (str: string): string => {
  let result = str;
  result = result.toLowerCase();
  result = result.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  result = result.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  result = result.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  result = result.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  result = result.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  result = result.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  result = result.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  result = result.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  result = result.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return result;
};

export const esQueryStringFormat = (str: string): string => {
  let result = str;
  result = nonAccentVietnamese(result.toLowerCase().replace(/ +(?= )/g, ''));
  return result;
};

export function formatPrice(num: string | number) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}
