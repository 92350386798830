import React, { useEffect, useState } from 'react';
import { Table, Pagination, Button, Row, Col, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { GetVouchers_cmsVouchers_items } from '../../../graphql/types';
import { CMS_EXPORT_VOUCHER_CODE, CMS_GET_VOUCHER_CODE } from '../../../graphql/voucher/voucher';
import { dateByFormat } from '../../../helpers/date';
import { useMutation, useQuery } from '@apollo/react-hooks';

import CreateVoucherCodes from './CreateVoucherCode';
import EditVoucherCode from './EditVoucherCode';

interface voucherCode extends FormComponentProps {
  defaultData?: string;
  form: any;
  voucher?: GetVouchers_cmsVouchers_items;
}

const VoucherCodeList = (props: voucherCode) => {
  const [dataCodes, setDataCodes] = useState([]);
  const [params, setParams] = useState({ limit: 10, page: 1 });
  const [counter, setCounter] = useState(0);
  const [modalAddCodes, setModalAddCodes] = useState(false);
  const [modalEditCodes, setModalEditCodes] = useState(false);
  const { voucher } = props;
  const { _id: voucherId } = voucher;

  const [cmsExportVoucherCodes] = useMutation(CMS_EXPORT_VOUCHER_CODE);

  const cmsGetVoucherCodes = () => {
    return useQuery(CMS_GET_VOUCHER_CODE, {
      variables: {
        voucherId: voucherId,
        limit: params.limit,
        page: params.page,
      },
    });
  };

  const { data, refetch } = cmsGetVoucherCodes();

  const exportVoucherCode = e => {
    e.preventDefault();
    cmsExportVoucherCodes({
      variables: {
        voucherId: voucher._id,
      },
    }).then(res => {
      if (res) {
        window.open(process.env.REACT_APP_SERVER_URI + res.data.exportVoucherCode);
      }
    });
  };

  const columns = [
    {
      title: 'Voucher code',
      dataIndex: 'code',
    },
    {
      title: 'Start date',
      key: 'startDate',
      render: row => {
        return <> {dateByFormat(row.startDate)}</>;
      },
    },
    {
      title: 'End date',
      key: 'endDate',
      render: row => {
        return <> {dateByFormat(row.endDate)}</>;
      },
    },
    {
      title: 'Vendor code',
      dataIndex: 'vendorCode',
    },
    {
      title: 'Remarks',
      dataIndex: 'remarks',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  useEffect(() => {
    if (data && data.cmsListVoucherCode) {
      setDataCodes(data.cmsListVoucherCode.items || []);
      setCounter(data.cmsListVoucherCode.counter || 0);
    } else {
      setDataCodes([]);
      setCounter(0);
    }
  }, [data]);

  const onChangePage = page => {
    setParams({ ...params, page });
  };

  const onToggleModalAddCodes = () => {
    setModalAddCodes(!modalAddCodes);
  };

  const onToggleModalEditCodes = () => {
    setModalEditCodes(!modalEditCodes);
  };

  return (
    <div>
      <Row gutter={5}>
        <Col offset={14} span={3}>
          <Button
            style={{ backgroundColor: '#00BA61', color: '#fff' }}
            className="btn-rectangle"
            onClick={onToggleModalAddCodes}
          >
            Add codes
          </Button>
        </Col>
        <Col span={3}>
          <Button
            style={{ backgroundColor: '#F3A391', color: '#fff' }}
            className="btn-rectangle"
            onClick={onToggleModalEditCodes}
          >
            Edit codes
          </Button>
        </Col>
        <Col span={3}>
          <Button
            style={{ backgroundColor: '#EEE508', color: '#fff' }}
            className="btn-rectangle"
            onClick={exportVoucherCode}
          >
            Export
          </Button>
        </Col>
      </Row>
      <br />
      <Table
        rowKey="code"
        columns={columns}
        dataSource={dataCodes}
        onChange={() => {}}
        scroll={{ x: 'calc(700px + 50%)', y: 0 }}
        pagination={false}
      />
      <Pagination defaultCurrent={params.page} total={counter} defaultPageSize={params.limit} onChange={onChangePage} />

      {modalAddCodes && (
        <Modal
          visible={modalAddCodes}
          width={900}
          title="Add codes"
          onOk={onToggleModalAddCodes}
          onCancel={onToggleModalAddCodes}
          footer=""
        >
          <CreateVoucherCodes
            voucher={voucher}
            form={props.form}
            reFetchParent={refetch}
            onClose={onToggleModalAddCodes}
          />
        </Modal>
      )}
      {modalEditCodes && (
        <Modal
          visible={modalEditCodes}
          width={900}
          title={'Edit codes : ' + voucher.title}
          onOk={onToggleModalEditCodes}
          onCancel={onToggleModalEditCodes}
          footer=""
        >
          <EditVoucherCode
            voucher={voucher}
            form={props.form}
            reFetchParent={refetch}
            onClose={onToggleModalEditCodes}
          />
        </Modal>
      )}
    </div>
  );
};

export default VoucherCodeList;
