import { gql } from 'apollo-boost';

export const GET_PAGE_VERIFY = gql`
    query GetPageVerify($limit: Int!, $offset: Int, $after: ID, $q: String, $status: VerifyStatus, $type: VerifyFor) {
        cmsGetVerifies(limit: $limit, offset: $offset, q: $q, after: $after, filter: { status: $status, type: $type }) {
            counter
            items {
                _id
                targetName
                targetKeyName
                type
                targetType
                status
                description
                createdAt
                rejectReason
                owner {
                    fullName
                    email
                    phoneNumber
                }
                photoIdentityMedias {
                    _id
                    uri
                }
                businessLicenseMedias {
                    _id
                    uri
                }
                photosTaken {
                    _id
                    uri
                }
            }
        }
    }
`;

export const CMS_GET_PAGES = gql`
    query CmsGetPages($limit: Int!, $offset: Int, $after: EsAfter, $q: String, $filter: PageFilter, $sortBy: PageSortBy) {
        cmsGetPages(limit: $limit, offset: $offset, after: $after, q: $q, filter: $filter, sortBy: $sortBy) {
            counter
            items {
                _id
                name
                description
                phoneNumber
                website
                verify {
                    status
                }
                email
                isPartner
                type
                creator {
                    _id
                    fullName
                    email
                    phoneNumber
                    point
                    createdAt
                    avatar {
                        uri
                    }
                }
                createdAt
                verified
                totalLikes
                totalPosts
                totalFollows
                totalReviews
                totalReports
                reason
                isProvideNews
                pinnedLimit
            }
        }
    }
`;

export const SET_VERIFY_STATUS = gql`
    mutation CmsSetVerifyStatus($_id: ID!, $status: VerifyStatus!, $reason: String) {
        cmsSetVerifyStatus(_id: $_id, status: $status, reason: $reason)
    }
`;

export const SET_PAGE_PARTNER = gql`
    mutation CmsSetPagePartner($_id: ID!, $status: Boolean!) {
        cmsSetPagePartner(_id: $_id, status: $status)
    }
`;

export const SET_PAGE_PROVIDER_NEWS = gql`
    mutation cmsSetPageNews($_id: ID!, $active: Boolean!) {
        cmsSetPageNews(_id: $_id, active: $active)
    }
`;

export const CMS_BLOCK_PAGE = gql`
    mutation CmsBlockPage($_id: ID!, $model: ReportFor!, $termStatus: TermStatus!, $reason: String) {
        cmsSetTermStatus(_id: $_id, model: $model, termStatus: $termStatus, reason: $reason)
    }
`;

export const SET_PINNED_LIMIT = gql`
    mutation cmsSetPagePinnedProduct($data: PagePinnedProductSettingInput) {
        cmsSetPagePinnedProduct(data: $data)
    }
`;
