import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, DatePicker, Form, Input, Layout, Pagination, Row, Table } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import  { CMS_TOP_USERS_REFERRAL } from "../../../graphql/user/users";
import { cmsTopReferralUsers, cmsTopReferralUsersVariables } from '../../../graphql/types';
import Styled from '../../UserPage/indexStyled';

const moment = require('moment');
const { RangePicker } = DatePicker;
const { Search } = Input;
const { Content } = Layout;

const defaultAvt =  require("../../../assets/images/icons/dashboard/avatar-default.svg");



const TopReferral = () => {
  const [counter, setCounter] = useState(0);
  const [users, setUsers] =  useState([]);
  const [pageParams, setPageParams] = useState({
    filter:{
      q:'',
      from : moment(new Date('2020-08-26')).format("YYYY-MM-DD"),
      to : moment(new Date()).format("YYYY-MM-DD"),
    },
    page:1,
    limit:10
  });

  const {data, refetch} =  useQuery<cmsTopReferralUsers, cmsTopReferralUsersVariables>(CMS_TOP_USERS_REFERRAL,{
    variables:{
      filter:{
        q: pageParams.filter.q,
        rangeDate:{
          from:pageParams.filter.from,
          to:pageParams.filter.to,
        }
      },
      limit:pageParams.limit,
      page: pageParams.page
    },
    fetchPolicy:'network-only'
  });

  const columns = [
    {
      title:'No.',
      key:'no',
      width: '5%',
      render: (text, record, index) => index + 1,
    },
    {
      title:'ID',
      dataIndex:'_id',
      width: '20%',
    },
    {
      title:'Avatar',
      key:'avatar',
      width: '20%',
      render: (row) => {
        const {avatar} = row;
        const img =  avatar && avatar.uri ? avatar.uri: defaultAvt;
        return <img src={img} alt=""/>
      }
    },
    {
      title:'Name',
      dataIndex: 'fullName',
      width: '20%',
    },
    {
      title:'Phone number',
      dataIndex: 'phoneNumber',
      width: '20%',
    },
    {
      title:'Ref',
      dataIndex: 'referredUserNumber'
    }
  ];

  useEffect(()=>{
    if(data && data.cmsTopReferralUsers ){
      setCounter(data.cmsTopReferralUsers.counter);
      setUsers(data.cmsTopReferralUsers.items);
    }
  },[data]);

  useEffect(()=>{
    refetch();
  },[pageParams]);

  const onTableChange = (pagination, filters, sorter, extra) => {

  };

  const onChangePage = (page) => {
    setPageParams({...pageParams, page});
  };

  const handleRangePickerChange = (dates) => {
    setPageParams({
      ...pageParams,
      filter: {
        ...pageParams.filter,
        from: moment(dates[0]).format("YYYY-MM-DD"),
        to: moment(dates[1]).format("YYYY-MM-DD"),
      }
    })
  };

  const search = q => {
    setPageParams({
      ...pageParams,filter: {...pageParams.filter,q}
    });
  };

  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          <Breadcrumb.Item>Top User Referrals</Breadcrumb.Item>
        </Breadcrumb>
        <div className="referral-section">

          <Row gutter={10} className="mt-10 mb-10">
            <Col span={ 6 }>
              <Form.Item label="Search">
                <Search placeholder="Enter name" enterButton="Search" onSearch={ value => search(value) } />
              </Form.Item>
            </Col>
            <Col span={ 6 } offset={12}>
              <Form.Item label="Range date">
                <RangePicker value={[moment(pageParams.filter.from), moment(pageParams.filter.to)]} onChange={handleRangePickerChange}
                             style={{ width: 256 }}/>
              </Form.Item>
            </Col>
          </Row>

          <Table rowKey="_id"
                 columns={ columns }
                 dataSource={ users }
                 pagination={ false }
                 onChange={ onTableChange }
          />

          <Pagination
            defaultCurrent={ pageParams.page||1 }
            total={ counter }
            defaultPageSize={ pageParams.limit }
            onChange={ onChangePage }
            style={ {
              marginTop: 20,
              right: 0,
            } }
          />
        </div>
      </Content>
    </Styled.Container>
  )
};

export  default TopReferral;
