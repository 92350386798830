import {
  Col,
  Row,
  Table,
  Card
  // Dropdown, Icon, Menu,
} from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import classNames from "classnames";

import { GridContent } from '@ant-design/pro-layout';
// import { RadioChangeEvent } from 'antd/es/radio';
import { RangePickerValue } from 'antd/es/date-picker/interface';
import PageLoading from './components/PageLoading';
import { getTimeDistance } from './utils/utils';
import { getFakeChartData } from './_mock';
import styles from './style.module.less';
import {CMS_NUMBER_USER_BY_COUNTRY} from "../../graphql/dashboard";
import {
  cmsNumberOfUsersByCountry,
} from "../../graphql/types";
import { useQuery } from '@apollo/react-hooks';
const IntroduceRow = React.lazy(() => import('./components/IntroduceRow'));
const SalesCard = React.lazy(() => import('./components/SalesCard'));
// const TopSearch = React.lazy(() => import('./components/TopSearch'));
// const ProportionSales = React.lazy(() => import('./components/ProportionSales'));
// const OfflineData = React.lazy(() => import('./components/OfflineData'));

interface AnalysisProps {
  dashboardAnalysis: any;
  loading: boolean;
}

const Analysis = (props:AnalysisProps) => {
  const [rangePickerValue,setRangePickerValue] = useState(getTimeDistance('year'));
  const [loading] = useState(false);
  const [usersByCountries,setUserByCountries] =  useState([]);
  const {
    visitData,
    salesData,
    offlineChartData,
  } = getFakeChartData;

  const {data:numberUserByCountry} = useQuery<cmsNumberOfUsersByCountry>(CMS_NUMBER_USER_BY_COUNTRY);

  useEffect(()=>{
    if(numberUserByCountry && numberUserByCountry.cmsNumberOfUsersByCountry){
      setUserByCountries(numberUserByCountry.cmsNumberOfUsersByCountry);
    }
  },[numberUserByCountry]);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      width: '10%',
      render: (text, record, index) => index+1,
    },
    {
      title:'Country name',
      width: '30%',
      dataIndex:'country',
    },
    {
      title:'Number',
      width: '30%',
      dataIndex:'users',
    },
    {
      title:'Percent(%)',
      width: '30%',
      dataIndex:'percentage',
    }
  ];

  const handleRangePickerChange = (rangePickerValue: RangePickerValue) => {
    setRangePickerValue(rangePickerValue);
  };

  const selectDate = (type: 'today' | 'week' | 'month' | 'year') => {
    setRangePickerValue(getTimeDistance(type));
  };

  const isActive = (type: 'today' | 'week' | 'month' | 'year') => {
    const value = getTimeDistance(type);
    if (!rangePickerValue[0] || !rangePickerValue[1]) {
      return '';
    }
    if (rangePickerValue[0].isSame(value[0], 'day') && rangePickerValue[1].isSame(value[1], 'day')) {
      return styles.currentDate;
    }
    return '';
  };

  return (
    <div className={classNames(styles.dashboardContainer,"dashboard-section")}>
      <GridContent>
        <React.Fragment>
          <Suspense fallback={<PageLoading />}>
            <IntroduceRow loading={loading} visitData={visitData} />
          </Suspense>
          <Suspense fallback={null}>
            <SalesCard
              offlineChartData={offlineChartData}
              rangePickerValue={rangePickerValue}
              salesData={salesData}
              isActive={isActive}
              handleRangePickerChange={handleRangePickerChange}
              loading={loading}
              selectDate={selectDate}
            />
          </Suspense>
          <br/>
          <Row>
            <Col span={12}>
              <Card title="The number of User by country"  className="borderRadius12">
                <Table
                  rowKey="_id"
                  columns={columns}
                  dataSource={usersByCountries}
                  onChange={()=>{}}
                  scroll={{ y: 240 }}
                  pagination={false}
                />
              </Card>

            </Col>
          </Row>

        </React.Fragment>
      </GridContent>
    </div>
  );
};


export default Analysis;
