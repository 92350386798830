import React, { useEffect, useState } from 'react';
import {Modal, Table, Pagination} from "antd";
import {
  ReportFor,
  cmsGetBlockedDetailList,
  cmsGetBlockedDetailListVariables,
  cmsGetDetailReports,
  cmsGetDetailReportsVariables,
} from "../../../graphql/types";
import {dateByFormat} from "../../../helpers/date";
import {CMS_GET_LIST_REPORTS,CMS_GET_LIST_ENTITY_BLOCKED } from "../../../graphql/report/report";
import { useQuery } from '@apollo/react-hooks';
import { reportTypes } from '../messages';

interface DetailReportsProps {
  refId:string,
  for:ReportFor,
  visible:boolean,
  close:any,
}

interface ReportContentProps {
  refId:string,
  for:ReportFor,
}

const ReportContentComponent = (props:ReportContentProps) => {
  const [blockedDetail, setBlockedDetail] = useState([]);
  const [totalBlocked, setTotalBlocked] = useState(0);

  const [reportsDetail, setReportsDetail] = useState([]);
  const [totalReports, setTotalReports] = useState(0);

  const [blockedParams, setBlockedParams] =  useState({
    limit:5,
    page:1,
  });
  const [reportsParams, setReportParams] =  useState({
    limit:5,
    page:1,
  });

  const {data:blockedListDetail} = useQuery<cmsGetBlockedDetailList, cmsGetBlockedDetailListVariables>(CMS_GET_LIST_ENTITY_BLOCKED,
    {
      fetchPolicy:'network-only',
      variables:{
        id:props.refId,
        forModel:props.for,
        limit:blockedParams.limit,
        page:blockedParams.page,
      }
    });

  const {data:reportsDetailList} = useQuery<cmsGetDetailReports, cmsGetDetailReportsVariables>(CMS_GET_LIST_REPORTS,
    {
      fetchPolicy:'network-only',
      variables:{
        id:props.refId,
        forModel:props.for,
        limit:reportsParams.limit,
        page:reportsParams.page,
      }
    });

  useEffect(()=>{
    if(blockedListDetail && blockedListDetail.cmsGetBlockedDetailList && blockedListDetail.cmsGetBlockedDetailList.counter){
      setBlockedDetail(blockedListDetail.cmsGetBlockedDetailList.items);
      setTotalBlocked(blockedListDetail.cmsGetBlockedDetailList.counter);
    }
    if(reportsDetailList && reportsDetailList.cmsGetDetailReports && reportsDetailList.cmsGetDetailReports.counter){
      setReportsDetail(reportsDetailList.cmsGetDetailReports.items);
      setTotalReports(reportsDetailList.cmsGetDetailReports.counter);
    }
  },[blockedListDetail,reportsDetailList]);


  const onChangePageReport = (page) => {
    setReportParams({...reportsParams,page:page});
  };

  const onChangePageBlocked = (page) => {
    setBlockedParams({...blockedParams,page:page});
  };

  const columnsReport = [
    {
      title: "Message",
      dataIndex: 'message'
    },
    {
      title: 'Type',
      dataIndex: '',
      render: row => {
        return (
          <p>
            <strong>{reportTypes[row.postReportType]}</strong>
          </p>
        );
      },
    },
    {
      title: 'Creator',
      dataIndex: 'creator.fullName',
    },
    {
      title: 'Created at',
      key: 'createdAt',
      render:row=>{
        return row.createdAt ? dateByFormat(row.createdAt):''
      }
    },
  ];
  const columnsBlock = [
    {
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Created at',
      key: 'createdAt',
      render:row=>{
        return row.createdAt ? dateByFormat(row.createdAt):''
      }
    },
  ];

  return (
    <div className="report-section">
      <div className="detail-report">
        <label><strong> List user's report</strong></label>
        <Table
          rowKey="_id"
          columns={columnsReport}
          dataSource={reportsDetail}
          pagination={false}
        />
        {(totalReports > 0) && <Pagination total={ totalReports} onChange={(page)=>onChangePageReport(page)} pageSize={reportsParams.limit} className="mt-4" />}

      </div>

      <hr/>
      <br/>
      <div className="detail-report">
        <label><strong>List CMS admin blocked</strong></label>
        <Table
          rowKey="createdAt"
          columns={columnsBlock}
          dataSource={blockedDetail}
          pagination={false}
        />
        {(totalBlocked > 0) && <Pagination total={totalBlocked} onChange={(page)=>onChangePageBlocked(page)} pageSize={blockedParams.limit}  className="mt-4" />}

      </div>
    </div>
  )
};

const DetailReports = (props:DetailReportsProps) =>{

  return (
    <Modal
      width="900px"
      visible={props.visible}
      title="History reports"
      onCancel={props.close}
      footer={false}
    >
      {props.visible &&  <ReportContentComponent for={props.for} refId={props.refId} />}

    </Modal>
  )
};

export default DetailReports;
