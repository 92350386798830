import React, { Fragment, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Row,
  Select,
  Skeleton,
  Table,
  Upload,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Styled from '../indexStyled';
import {
  VOUCHER,
  CMS_IMPORT_REFERRAL_PRIZE_CODES,
  CMS_GET_VOUCHER_CODE,
  CMS_DELETE_REFERRAL_VOUCHER_CODE, UPDATE_VOUCHER,
} from '../../../graphql/voucher/voucher';
import {
  cmsListVoucherCode, cmsListVoucherCodeVariables, deleteVoucherCode, deleteVoucherCodeVariables,
  GetVoucher,
  GetVoucher_voucher,
  GetVoucherVariables,
  importReferralCodes,
  importReferralCodesVariables, UpdateVoucher, UpdateVoucherVariables,
  VoucherCodeStatus,
} from '../../../graphql/types';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { ToastError, ToastSuccess } from '../../../components/Toast';
import { FileExcelOutlined } from '@ant-design/icons';

const { dateByFormat } = require('../../../helpers/date');
const iconDeleteEnable = require('../../../assets/images/icons/actions-button/actionDeleteEnable.svg');
const iconDeleteDisable = require('../../../assets/images/icons/actions-button/actionDeleteDisable.svg');

const { Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;

interface IEditProps extends FormComponentProps {
  match?: any;
}

const allowedExtend = ['.xlsx', '.xls', '.csv'];
const ReferralCodesmpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const { params } = props.match;
  const _id = params.id;
  const [voucher, setVoucher] = useState<GetVoucher_voucher|null>();
  const [referralVoucherCodes, setReferralVoucherCodes] = useState([]);
  const [counter, setCounter] = useState<number>(0);
  const voucherCodeStatus = ['ALL', VoucherCodeStatus.ACTIVE, VoucherCodeStatus.REDEEMED];
  const [filterParams, setFilterParams] = useState<any>({
    limit: 10,
    page: 1,
    status: null,
  });

  const { data: voucherData } = useQuery<GetVoucher, GetVoucherVariables>(VOUCHER, {
    variables: {
      _id: _id,
    },
    fetchPolicy: 'network-only',
  });

  const [deleteVoucherCode, {}] = useMutation<deleteVoucherCode, deleteVoucherCodeVariables>(CMS_DELETE_REFERRAL_VOUCHER_CODE, {
    refetchQueries: ['cmsListVoucherCode'],
  });

  const [importReferralCodes, { loading: importLoading }] = useMutation<importReferralCodes, importReferralCodesVariables>(CMS_IMPORT_REFERRAL_PRIZE_CODES, {
    refetchQueries: ['cmsListVoucherCode'],
  });

  const [updateVoucher] = useMutation<UpdateVoucher, UpdateVoucherVariables>(UPDATE_VOUCHER);

  const { data, refetch, loading, error } = useQuery<cmsListVoucherCode, cmsListVoucherCodeVariables>(CMS_GET_VOUCHER_CODE, {
    variables: {
      voucherId: _id,
      limit: filterParams.limit,
      page: filterParams.page,
      filter:{
        status : filterParams.status && filterParams.status !== "ALL" ? filterParams.status : null
      }
    },
    fetchPolicy:"network-only"
  });

  useEffect(() => {
    if (voucherData && voucherData.voucher) {
      setVoucher(voucherData.voucher);
    }
  }, [voucherData]);

  useEffect(() => {
    if (data && data.cmsListVoucherCode) {
      setReferralVoucherCodes(data.cmsListVoucherCode.items);
      setCounter(data.cmsListVoucherCode.counter);
    }
  }, [data]);

  useEffect(
    function() {
      setFilterParams({
        ...filterParams,
      });
    },
    [params],
  );

  useEffect(() => {

    let vars: any = {
      voucherId: _id,
      limit: filterParams.limit,
      page: filterParams.page,
    };

    if(filterParams.filter){
      vars.filter = {status:filterParams.filter.status}
    }
    refetch();
  }, [filterParams]);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      width: '5%',
      render: (text, record, index) => ((filterParams.page -1 ) * filterParams.limit + index + 1),
    },
    {
      title: 'Code',
      width: '20%',
      dataIndex: 'code',
    },
    {
      title: 'Redeemed Time',
      key: 'redeemAt',
      width: '15%',
      render: row => {
        return <>{row.redeemedAt && dateByFormat(row.redeemedAt)}</>;
      },
    },
    {
      title: 'Redeemed User',
      width: '15%',
      key: 'titleEn',
      render: row => {
        return <>{row.redeemedUser && row.redeemedUser.phoneNumber}</>;
      },
    },
    {
      title: 'Status',
      width: '20%',
      render: row => {
        let type: any = 'default';
        let className: string = 'active-status';
        if (row.status == VoucherCodeStatus.ACTIVE) {
          type = 'primary';
        }
        if (row.status == VoucherCodeStatus.REDEEMED) {
          type = 'danger';
          className = 'redeemed-status';
        }
        return (
          <Button className={`btn-rectangle ${className} `} type={type} size="small">
            {row.status}
          </Button>
        );
      },
    },
    {
      title: 'Action',
      key: 'operation',
      width: '10%',
      render: row => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              cursor: 'pointer',
            }}
          >
            {
              row.sold_qty > 0 ? <img src={iconDeleteDisable} alt=""/> :
                <img src={iconDeleteEnable} alt="" onClick={() => handleDelVoucherCode(row)}/>
            }
          </div>
        );
      },
    },
  ];

  const handleDelVoucherCode = (voucherCode) => {
    confirm({
      title: 'Are you sure delete this voucher?',
      icon: <ExclamationCircleOutlined/>,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteVoucherCode({ variables: { _id: voucherCode._id } });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };


  const onChangePage = (page) => {
    setFilterParams({ ...filterParams, page });
  };
  const changeFilterStatus = e => {
    setFilterParams({
      ...filterParams,
      status: e
    });
  };

  const uploadFile = infor => {
    const name = infor.file.name.split('.');
    if (infor.fileList.length) {
      if (allowedExtend.indexOf('.' + name[name.length - 1]) > -1) {
        infor.fileList = [infor.file];
        importReferralCodes({
          variables: {
            data: {
              voucherId: _id,
              file: infor.file,
            },
          },
        });
      } else {
        infor.fileList.length = 0;
        ToastError({ message: 'Upload error', description: 'The format is allowed is : ' + allowedExtend.join(',') });
      }
    }
  };

  const handleSave = (e) => {
    e.preventDefault();

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data = {
          _id: voucher._id,
          title: voucher.title,
          titleEn: voucher.titleEn,
          type: voucher.type,
          status: voucher.status,
          prizeTitle: values.prizeTitle,
          prizeTitleEn: values.prizeTitleEn,
          startDate: voucher.startDate,
          endDate: voucher.endDate,
        };
        updateVoucher({
          variables: data,
        }).then((res)=>{
          ToastSuccess({ message: 'Updated', description: 'Voucher is updated success' });
        }).catch(err=>{
          console.log("err",err);
          ToastError({ message: 'Error!', description: 'Please try again later' });
        })
      }
    });
  };


  if (error) return <Content className="content">Error</Content>;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <Styled.Container>
      <Content className="content referral-codes-list-section">
        <Breadcrumb>
          <Breadcrumb.Item>Referral Games</Breadcrumb.Item>
          <Breadcrumb.Item>{voucher && voucher.title}</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>
        <Form {...formItemLayout} onSubmit={handleSave}>
          <Row  className="mt-10 referral-prize-title" gutter={16}>
            <Col span={8}>
              <Form.Item label="Title code Vi">
                {getFieldDecorator('prizeTitle', {
                  initialValue: (voucher && voucher.prizeTitle) || '',
                })(
                  <Input/>
                )}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Title code En">
                {getFieldDecorator('prizeTitleEn', {
                  initialValue: (voucher && voucher.prizeTitleEn) || '',
                })(
                  <Input/>
                )}
              </Form.Item>
            </Col>
            <Col span={6} offset={2} style={{ textAlign: 'right' }}>
              <Button className="btn-rectangle save-voucher-prize-title" htmlType="submit">
                SAVE
              </Button>
            </Col>
          </Row>
          <Row className="mt-2 mb-10" gutter={16}>
            <Col span={8}>
              <Form.Item label="Group by Status">
                {getFieldDecorator('adminStatus', {
                  initialValue: filterParams.status,
                })(
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select admin status to filter"
                    onChange={e => changeFilterStatus(e)}
                  >
                    {voucherCodeStatus.map((r, i) => {
                      return (
                        <Option key={i} value={r}>
                          {r}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={6} offset={10} style={{ textAlign: 'right' }}>
              <Upload
                onChange={uploadFile}
                beforeUpload={file => {
                  return false;
                }}
                fileList={[]}
                accept={allowedExtend.join(',')}
              >
                <Button htmlType="button">
                  <FileExcelOutlined/> Import codes
                  {
                    importLoading &&
                    (
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )
                  }
                </Button>
              </Upload>

            </Col>
          </Row>
        </Form>


        <p style={{ marginTop: 15 }}>
          <strong>Total: {counter}</strong>
        </p>

        {loading ? (
          <Skeleton loading={loading} paragraph={{ rows: 5 }}>
            {' '}
          </Skeleton>
        ) : (
          <Fragment>
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={referralVoucherCodes}
              scroll={{ x: 'calc(700px + 50%)', y: 0 }}
              pagination={false}
            />
            <Pagination
              defaultCurrent={filterParams.page || 1}
              total={counter}
              defaultPageSize={filterParams.limit}
              onChange={onChangePage}
              style={{
                marginTop: 20,
                right: 0,
              }}
            />
          </Fragment>


        )}
      </Content>

    </Styled.Container>
  );
};

const ReferralVoucherCodes = Form.create()(ReferralCodesmpl);
export default ReferralVoucherCodes;
