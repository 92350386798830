import React from 'react';
import { Layout, Row, Col, Table } from 'antd';
import { useQuery } from '@apollo/react-hooks';

import Styled from './../indexStyled';
import { CMS_GET_RECENT_PRODUCT_REPORTS } from '../../../graphql/report/report';
import { ReportFor } from '../../../graphql/types';
import { formatPrice } from '../../../helpers/meHelpers';
const { dateByFormat } = require('../../../helpers/date');

const { Content } = Layout;

interface IEditProps {
  match?: any;
  title?: string;
  content?: string;
  medias?: any;
  createdAt?: string;
  creator?: any;
  id: string;
  type?: string;
  price?: number;
  isReport?: boolean;
}

const ProductDetail = (props: IEditProps) => {
  const getRecentReports = () => {
    return useQuery(CMS_GET_RECENT_PRODUCT_REPORTS, {
      fetchPolicy: 'network-only',
      variables: {
        targetId: props.id,
        limit: 3,
        type: ReportFor.POST,
      },
    });
  };

  const { data } = getRecentReports();

  const columns = [
    {
      title: 'Type',
      dataIndex: 'postReportType',
    },
    {
      title: 'Message',
      dataIndex: 'message',
    },
    {
      title: 'Creator',
      dataIndex: 'creator.fullName',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render: row => {
        return <div>{row && dateByFormat(row)}</div>;
      },
    },
  ];

  return (
    <Styled.Container>
      <Content>
        <div className="product-detail-section d-flex justify-content-center">
          <table >
            <tbody>
            <tr>
              <td><strong>Title</strong></td>
              <td>{props.title}</td>
            </tr>
            <tr>
              <td><strong>Content</strong></td>
              <td>{props.content}</td>
            </tr>
            <tr>
              <td><strong>Creator</strong></td>
              <td>{props.creator ? props.creator.fullName : null}</td>
            </tr>
            <tr>
              <td><strong>CreatedAt</strong></td>
              <td>{dateByFormat(props.createdAt)}</td>
            </tr>
            <tr>
              <td><strong>Type</strong></td>
              <td>{props.type}</td>
            </tr>
            <tr>
              <td><strong>Price</strong></td>
              <td>{formatPrice(props.price)}</td>
            </tr>
            <tr>
              <td><strong>Medias</strong></td>
              <td>
                <Row gutter={8}>
                  {props.medias.map(function(row, i) {
                    return (
                      <Col span={6}>
                        <div className="thumb">
                          <img className="image" src={row.uri} alt="" />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </td>
            </tr>
            </tbody>
          </table>
        </div>


        {props.isReport && data && data.cmsListReportByTargetId && data.cmsListReportByTargetId.length && (
          <Row style={{ marginTop: 15 }}>
            <strong>Reports:</strong>

            <Table columns={columns} dataSource={data.cmsListReportByTargetId} pagination={false} />
          </Row>
        )}
      </Content>
    </Styled.Container>
  );
};
export default ProductDetail;
