import React from 'react';
import { Form, Input, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

const { Option } = Select;

interface defaultData {
  fullName?: string,
  phoneNumber?: string,
  email?: string,
  password?: string,
  gender?: string,
  group?: string,
}

interface defaultProps extends FormComponentProps {
  defaultData?: defaultData
  form: any
}

const FormDetail = (props: defaultProps) => {
  const { getFieldDecorator } = props.form;

  const handleSelectChange = (callback) => {

  };
  const genders = ['male', 'female'];
  const groups = ['A', 'MEMBER'];

  return (
    <div>
      <Form.Item label="Title">
        {getFieldDecorator('title', {
          initialValue: props.defaultData && props.defaultData.fullName || '',
        })(<Input placeholder="Please input your name" />)}
      </Form.Item>

      <Form.Item label="Content">
        {getFieldDecorator('content', {
          initialValue: props.defaultData && props.defaultData.email || '',
        })(<Input />)}
      </Form.Item>

      <Form.Item label="Phone number">
        {getFieldDecorator('phoneNumber', {
          initialValue: props.defaultData && props.defaultData.phoneNumber || '',
        })(<Input />)}
      </Form.Item>

      <Form.Item label="Gender">
        {getFieldDecorator('gender', {
          initialValue: props.defaultData && props.defaultData.gender || 'male',
        })(
          <Select
            placeholder="Select a option and change input text above"
            onChange={handleSelectChange}
            disabled={true}
          >
            {genders.map((r, i) => {
              return <Option key={i} value={r}>{r}</Option>;
            })}
          </Select>,
        )}
      </Form.Item>

      <Form.Item label="Group">
        {getFieldDecorator('group', {
          initialValue: props.defaultData && props.defaultData.group || 'MEMBER',
          rules: [{ required: true, message: 'Please select your group!' }],
        })(
          <Select
            placeholder="Select a option and change input text above"
            onChange={handleSelectChange}
          >
            {groups.map((r, i) => {
              return <Option key={i} value={r}>{r}</Option>;
            })}
          </Select>,
        )}
      </Form.Item>
    </div>
  );
};
export default FormDetail;
