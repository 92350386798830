import { gql } from 'apollo-boost';

export const GET_POSTS = gql`
  query GetPosts($limit: Int!, $offset: Int, $after: ID, $filter: CmsGetPostFiltersInput!, $sortBy: PostSortBy) {
    cmsGetPosts(limit: $limit, offset: $offset, after: $after, filter: $filter, sortBy: $sortBy) {
      counter
      items {
        _id
        type
        title
        content
        createdAt
        totalLikes
        status
        totalComments
        totalReports
        sharedPost {
          _id
          title
          content
          creator {
            _id
            fullName
          }
          medias {
            uri
            type
            thumbnail
          }
        }
        place {
          _id
          name
        }
        creator {
          _id
          fullName
          photoUrl
        }
        medias {
          type
          thumbnail
          uri
        }
        group {
          name
        }
      }
    }
  }
`;

export const GET_BLOCKED_FEED = gql`
  query GetBlockedFeed($limit: Int!, $after: ID, $filter: CmsGetPostFiltersInput!, $page: Int, $sortBy: PostSortBy) {
    cmsGetBlockedPosts(limit: $limit, after: $after, filter: $filter, page: $page, sortBy: $sortBy) {
      counter
      item {
        _id
        type
        title
        content
        createdAt
        totalLikes
        totalComments
        status
        totalReports
        place {
          _id
          name
        }
        creator {
          _id
          fullName
          photoUrl
        }
        medias {
          type
          thumbnail
          uri
        }
        reason
      }
    }
  }
`;

export const BLOCK_POST = gql`
  mutation BlockPost($_id: ID!, $model: ReportFor!, $termStatus: TermStatus!, $reason: String) {
    cmsSetTermStatus(_id: $_id, model: $model, termStatus: $termStatus, reason: $reason)
  }
`;

export const SET_TERM_POST = gql`
  mutation cmsSetPostTermStatus($_id: ID!, $model: ReportFor!, $termStatus: TermStatus!, $reason: String) {
    cmsSetTermStatus(_id: $_id, model: $model, termStatus: $termStatus, reason: $reason)
  }
`;

export const SET_STATUS = gql`
  mutation setStatusPost($id: ID!, $status: PostStatus!) {
    cmsSetPostStatus(_id: $id, status: $status)
  }
`;
