import { Axis, Chart, Geom, Legend, Tooltip } from 'bizcharts';

import DataSet from '@antv/data-set';
import React from 'react';
// import Slider from 'bizcharts-plugin-slider';
import autoHeight from '../autoHeight';
import styles from './index.module.less';

export interface TimelineChartProps {
  data: {
    x: number;
    y1: number;
  }[];
  title?: string;
  titleMap: { y1: string; };
  padding?: [number, number, number, number];
  height?: number;
  style?: React.CSSProperties;
  borderWidth?: number;
  splitRangeDate?:number;
}

const TimelineChart: React.FC<TimelineChartProps> = props => {
  const {
    title,
    height = 400,
    padding = [60, 20, 40, 40] as [number, number, number, number],
    titleMap = {
      y1: 'y1',
    },
    borderWidth = 2,
    splitRangeDate = 1,
    data: sourceData,
  } = props;

  const data = Array.isArray(sourceData) ? sourceData : [{ x: 0, y1: 0 }];

  data.sort((a, b) => a.x - b.x);

  let max;
  if (data[0] && data[0].y1 ) {
    max = Math.max(
      [...data].sort((a, b) => b.y1 - a.y1)[0].y1,
    );
  }

  const ds = new DataSet({
    state: {
      start: (data[0] && data[0].x) ||0,
      end: (data[data.length - 1] && data[data.length - 1].x) || 0,
    },
  });

  const dv = ds.createView();
  dv.source(data)
    .transform({
      type: 'filter',
      callback: (obj: { x: string }) => {
        const date = obj.x;
        return date <= ds.state.end && date >= ds.state.start;
      },
    })
    .transform({
      type: 'map',
      callback(row: { y1: string;}) {
        const newRow = { ...row };
        newRow[titleMap.y1] = row.y1;
        return newRow;
      },
    })
    .transform({
      type: 'fold',
      fields: [titleMap.y1], // 展开字段集
      key: 'key', // key字段
      value: 'value', // value字段
    });

  const timeScale = {
    type: 'time',
    tickInterval: 60 * 60 * 24 * 1000 * splitRangeDate,
    mask: 'DD-MM',
    range: [0, 1],
  };

  const cols = {
    x: timeScale,
    value: {
      max,
      min: 0,
    },
  };

  return (
    <div className={styles.timelineChart} style={{ height: height + 30 }}>
      <div>
        {title && <h4>{title}</h4>}
        <Chart height={height}
               padding={padding}
               data={dv}
               scale={cols}
               forceFit>
          <Axis name="x" />
          <Tooltip />
          <Legend name="key" position="top" />
          <Geom type="line"
                position="x*value"
                size={borderWidth}
                color="key" />
        </Chart>
      </div>
    </div>
  );
};

export default autoHeight()(TimelineChart);
