import React, { useState } from 'react';
import { Button, Col, Form, Input, Layout, Row, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { LoadingOutlined } from '@ant-design/icons';
import Styled from '../../UserPage/indexStyled';
import { useMutation } from '@apollo/react-hooks';
import {
  cmsUpdateLuckyWheelConfig,
  cmsUpdateLuckyWheelConfigVariables,
  cmsListLuckyWheelConfigs_cmsListLuckyWheelConfigs,
} from '../../../graphql/types';
import {
  CMS_UPDATE_LUCKY_WHEEL_CONDITION_CONFIG
} from '../../../graphql/luckyWheel';
import { ToastError, ToastSuccess } from '../../../components/Toast';

const { Content } = Layout;
const { TextArea } = Input;


interface IEditProps extends FormComponentProps {
  condition:cmsListLuckyWheelConfigs_cmsListLuckyWheelConfigs
  onClose:any
}


const EditConditionImpl = (props) => {

  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);

  const [updateInstruction] = useMutation<cmsUpdateLuckyWheelConfig,cmsUpdateLuckyWheelConfigVariables>(CMS_UPDATE_LUCKY_WHEEL_CONDITION_CONFIG,{
    refetchQueries:['cmsListLuckyWheelConfigs']
  });

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        updateInstruction({
          variables:{
            _id:props.condition._id,
            prizeTier:props.condition.prizeTier,
            minimum:parseInt(values.minimum),
            missionType:props.condition.missionType,
            conditionType:props.condition.conditionType,
            description:values.description
          }
        }).then((res)=>{
          setLoading(false);
          ToastSuccess({ message: 'Updated', description: 'Condition condition success' });
          props.onClose();
        }).catch(err=>{
          console.log("err",err);
          setLoading(false);
          ToastError({
            message: 'Update Error',
            description: "Please try later",
          });
        });
      }else{
        setLoading(false);
      }
    });
  };



  return (
    <Styled.Container>
      <Content className="content">
        <Row type="flex" justify="center">
          <Col span={18}>
            <Form {...formItemLayout} onSubmit={handleSubmit}>
              <Form.Item label="Description">
                {getFieldDecorator('description', {
                  initialValue:props.condition && props.condition.description,
                  rules: [
                    {
                      required: true,
                      message: 'Please input title!',
                    },
                  ],
                })(<TextArea
                  placeholder=""
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />)}
              </Form.Item>

              <Form.Item label="Minimum">
                {getFieldDecorator('minimum', {
                  initialValue:props.condition && props.condition.minimum,
                  rules: [
                    {
                      required: true,
                      message: 'Please input title!',
                    },
                  ],
                })(<Input min={0} type="number"/>)}
              </Form.Item>

              <Form.Item {...buttonItemLayout} >
                <Button type="primary" disabled={loading} htmlType="submit">Submit</Button>
                {loading && (<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}/>)}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Styled.Container>
  );
};

const EditCondition = Form.create<IEditProps>()(EditConditionImpl);
export default EditCondition;
