import { gql } from 'apollo-boost';

export const PARTNER_PRODUCTIONS = gql`
    query PartnerProducts($limit: Int!, $offset: Int, $q: String, $type: TermStatus, 
    $filter: ProductFilter, $sortBy: ProductSortBy, $onTravelStore: OnTravelStore) {
        cmsPartnerProducts(limit: $limit, offset: $offset, q: $q, 
        type: $type, filter: $filter, sortBy: $sortBy, onTravelStore: $onTravelStore) {
            counter
            items {
                _id
                type
                title
                content
                termStatus
                totalReports
                price
                medias {
                    uri
                    type
                    thumbnail
                }
                availableOnTravelStore
                creator {
                    _id
                    fullName
                    email
                    phoneNumber
                    avatar {
                        uri
                    }
                    point
                    termStatus
                }
                page {
                    _id
                    name
                    email
                    phoneNumber
                    logo {
                        uri
                    }
                    owner {
                        fullName
                        email
                        phoneNumber
                        avatar {
                            uri
                        }
                    }
                    admins {
                        fullName
                        phoneNumber
                        avatar {
                            uri
                        }
                    }
                    termStatus
                }
                createdAt
                totalLikes
                totalShares
            }
        }
    }
`;

export const DELETE_PRODUCT = gql`
    mutation DeleteProduct($_id: ID!) {
        cmsDeleteProduct(_id: $_id)
    }
`;
