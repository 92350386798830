import React, {
  // useEffect,
  useState,
} from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Upload } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { FormComponentProps } from 'antd/lib/form';
import {
  cmsCreateFailedSpinMessages,
  cmsCreateFailedSpinMessagesVariables,
  cmsImportFailedSpinMessages,
  cmsImportFailedSpinMessagesVariables,
} from '../../../graphql/types';
import { CMS_CREATE_FAILED_SPIN_MESSAGE, CMS_IMPORT_FAILED_SPIN_MESSAGE } from '../../../graphql/luckyWheel';
import { useMutation } from '@apollo/react-hooks';
import {
  ToastError, ToastSuccess,
  // ToastSuccess
} from '../../../components/Toast';

const { TextArea } = Input;

const loadingImage = require('../../../assets/images/loadingspinner.gif');

interface PropsMessage extends FormComponentProps {
  defaultData?: any
  onClose: any
  reFetchParent: any
}

const allowedExtend = ['.xlsx', '.xls', '.csv'];
const typeCreateMessage = { IMPORT: 'IMPORT', TYPE: 'TYPE' };

const CreateMessageImpl = (props) => {
  const { getFieldDecorator } = props.form;
  const [file, setFile] = useState(null);
  const [type, setType] = useState(typeCreateMessage.TYPE);
  const [loading, setLoading] = useState(false);

  const [createMessageAPI] = useMutation<cmsCreateFailedSpinMessages, cmsCreateFailedSpinMessagesVariables>(CMS_CREATE_FAILED_SPIN_MESSAGE, {
    refetchQueries: ['cmsListFailedSpinMessages'],
  });
  const [importMessageAPI] = useMutation<cmsImportFailedSpinMessages, cmsImportFailedSpinMessagesVariables>(CMS_IMPORT_FAILED_SPIN_MESSAGE, {
    refetchQueries: ['cmsListFailedSpinMessages'],
  });

  const createVoucherCodeAPI = () => {
    setLoading(true);
    props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        setLoading(false);
      } else {
        if (type === typeCreateMessage.IMPORT && file === null) {
          setLoading(false);
          ToastError({ message: 'Upload error', description: 'You have to upload file.' });
        } else {
          if (type === typeCreateMessage.IMPORT) {
            importMessageAPI({
              variables: {
                data: {
                  file:file
                },
              },
            }).then(res => {
              ToastSuccess({ message: 'Success', description: 'Edit message success!' });
              props.onClose();
            }).catch((err)=>{
              setLoading(false);
              if (err.graphQLErrors
                && err.graphQLErrors.length > 0
                && err.graphQLErrors[0].message) {
                return ToastError({
                  message: 'The file is invalid.',
                  description: err.graphQLErrors[0].message,
                });
              }
              ToastError({
                message: 'Error',
                description: "Please try later",
              });
            });
          } else {
            createMessageAPI({
              variables: {
                data: {
                  content: values.content,
                  contentEn: values.contentEn,
                },
              },
            }).then(res => {
              ToastSuccess({ message: 'Success', description: 'Edit message success!' });
              props.onClose();
            }).catch((err)=>{
              setLoading(false);
              ToastError({
                message: 'Error',
                description: "Please try later",
              });
            });
          }
        }
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const uploadFile = (infor) => {
    const name = infor.file.name.split('.');
    if (infor.fileList.length) {
      if (allowedExtend.indexOf('.' + name[name.length - 1]) > -1) {
        infor.fileList = [infor.file];
        setFile(infor.file);
      } else {
        infor.fileList.length = 0;
        ToastError({ message: 'Upload error', description: 'The format is allowed is : ' + allowedExtend.join(',') });
      }
    }
  };

  const onRemoveFile = (file) => {
    setFile(null);
    return true;
  };

  const onchangeStatus = (e) => {
    if (e.target.checked) {
      setType(typeCreateMessage.IMPORT);
    } else {
      setType(typeCreateMessage.TYPE);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <Row type="flex" justify="center">
        <Col span={24}>
          <Form {...formItemLayout} onSubmit={onSubmit}>

            <Form.Item label="Type" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} style={{ width: '100%' }}>
              <Row>
                <Col span={8}>
                  <Upload onChange={uploadFile}
                          beforeUpload={file => {
                            return false;
                          }}
                          onRemove={onRemoveFile}
                          disabled={type !== typeCreateMessage.IMPORT}
                          accept={allowedExtend.join(',')}
                  >
                    <Button htmlType="button" disabled={type !== typeCreateMessage.IMPORT}>
                      <FileExcelOutlined/> Choose file
                    </Button>
                  </Upload>
                </Col>
                <Col span={7}>
                  <Form.Item label="Import from file" style={{ display: 'flex' }}>
                    {getFieldDecorator('checkType', {
                    })(<Checkbox checked={type === typeCreateMessage.IMPORT} onChange={onchangeStatus}> </Checkbox>)}
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="Message" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} style={{ width: '100%' }}>
              {getFieldDecorator('content', {
                rules: [
                  {
                    required: type === typeCreateMessage.TYPE,
                    whitespace:type === typeCreateMessage.TYPE,
                    message: 'Please input title!',
                  },
                ],
              })(<TextArea disabled={type !== typeCreateMessage.TYPE} rows={4}/>)}
            </Form.Item>

            <Form.Item label="Message En" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} style={{ width: '100%' }}>
              {getFieldDecorator('contentEn', {
                rules: [
                  {
                    required: type === typeCreateMessage.TYPE,
                    whitespace:type === typeCreateMessage.TYPE,
                    message: 'Please input title!',
                  },
                ],
              })(<TextArea disabled={type !== typeCreateMessage.TYPE} rows={4}/>)}
            </Form.Item>

            <Row>
              <Col offset={20} span={4}>
                <Button type="primary" disabled={loading} htmlType="button" onClick={createVoucherCodeAPI}>Add</Button>
                {
                  loading && <img src={loadingImage} className="w20" alt=""/>
                }
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const CreateMessage = Form.create<PropsMessage>()(CreateMessageImpl);
export default CreateMessage;
