import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Form, Layout, Skeleton, Table, Row, Col } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { GET_SETTINGS } from '../../graphql/settings/settings';
import { GetSettings, GetSettingsVariables } from '../../graphql/types';
import Styled from './indexStyled';
import EditSetting from './components/editSetting';

const SystemSettingImpl = (props: FormComponentProps) => {
  const [ isLoading, setLoading ] = useState(true);
  const [ visible, setVisible ] = useState(false);
  const [ editData, setEditData ] = useState({});

  const { data, error, loading, refetch } = useQuery<GetSettings, GetSettingsVariables>(GET_SETTINGS, {
    variables: {
      limit: 100,
      offset: 0
    },
  });
  
  const showEdit = (data) => {
    setVisible(true);
    setEditData({
      key: data.key,
      value: data.value,
      description: data.description
    });
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      sorter: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      sorter: true,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 150,
      fixed: 'right' as 'right',
      render: (row) => {
        return (
          <div>
            <Button type="primary" icon="edit" size="small" onClick={(e) => showEdit(row)}/>
          </div>
        );
      },
    },
  ];

  const closeModal = () => {
    setVisible(false);
    reloadData();
    setEditData({
    });
  };

  const reloadData = () => {
    refetch().catch(() => {

    });
  };

  const { Content } = Layout;

  useEffect(() => {
    if (loading) setLoading(true);
    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  if (error) return <Content className="content">Error</Content>;
  return <Styled.Container>
    <Content className="content">
      <Row type="flex" justify="start" className="pb-20">
        <Col span={12}>
          <Breadcrumb>
            <Breadcrumb.Item>System Settings</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      {isLoading ? <Skeleton loading={isLoading} paragraph={{ rows: 5 }}>
      </Skeleton> : <Table rowKey="key" columns={columns} dataSource={data.cmsGetSettings} scroll={{ x: 'calc(700px + 50%)', y: 0 }} />}
      {visible ? <EditSetting form={props.form} visible={visible} data={editData} closeModal={(e) => closeModal()}/> : ''}
    </Content>
  </Styled.Container>;
} 

const Settings = Form.create()(SystemSettingImpl);
export default Settings;