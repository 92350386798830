import React, { useState } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { UPDATE_TRUSTED_POINT_CONFIG } from '../../../graphql/trustedPoint/point';
import {
  TrustedPointConfigType,
  UpdateTrustedPointConfig,
  UpdateTrustedPointConfigVariables,
} from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
const { Option } = Select;

interface IData {
  _id?: any;
  type?: string;
  points?: number;
  actionType?: string;
  trustedPointsFrom?: any;
  trustedPointsTo?: any;
  rankFrom?: any;
  rankTo?: any;
  engagementAdjustPercent?: any;
  postContentLength?: any;
  totalPlaceReviewsFrom?: any;
  totalPlaceReviewsTo?: any;
  newPlaceCoefficient?: any;
  unlimitedPostsPerDayWhenTrustedPointGreaterThan?: any;
  maxTenPostsPerDayWhenTrustedPointLessThan?: any;
  maxFivePostsPerDayWhenTrustedPointLessThan?: any;
  description?: string;
  adjustPointPercent?: any;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
  form: any;
}

const EditConfig = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;
  const {data} = props;
  const {actionType} = data;
  const trustedPointConfigType = [
    TrustedPointConfigType.ACTION,
    TrustedPointConfigType.ENGAGEMENT_MULTIPLICATION,
    TrustedPointConfigType.LIMITATION,
    TrustedPointConfigType.OTHERS,
    TrustedPointConfigType.PROOF_OF_RATING,
    TrustedPointConfigType.RANKING,
  ];

  const [updateConfig] = useMutation<UpdateTrustedPointConfig, UpdateTrustedPointConfigVariables>(
    UPDATE_TRUSTED_POINT_CONFIG,
    {
      refetchQueries: ['GetTrustedPointConfigs'],
    },
  );

  const handleOk = e => {
    handleSubmit(e);
    // setShow(false);
    // props.closeModal();
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const { data } = props;
        updateConfig({
          variables: {
            _id: data._id,
            type: values.type,
            points: values.points,
            description: values.description,
            trustedPointsFrom: parseInt(values.trustedPointsFrom),
            trustedPointsTo: parseInt(values.trustedPointsTo),
            rankFrom: parseInt(values.trustedPointsTo),
            rankTo: parseInt(values.rankTo),
            adjustPointPercent: parseInt(values.adjustPointPercent),
            engagementAdjustPercent: parseInt(values.engagementAdjustPercent),
            postContentLength: parseInt(values.postContentLength),
            totalPlaceReviewsFrom: parseInt(values.totalPlaceReviewsFrom),
            totalPlaceReviewsTo: parseInt(values.totalPlaceReviewsTo),
            newPlaceCoefficient: parseInt(values.newPlaceCoefficient),
            unlimitedPostsPerDayWhenTrustedPointGreaterThan: parseInt(
              values.unlimitedPostsPerDayWhenTrustedPointGreaterThan,
            ),
            maxTenPostsPerDayWhenTrustedPointLessThan: parseInt(values.maxTenPostsPerDayWhenTrustedPointLessThan),
            maxFivePostsPerDayWhenTrustedPointLessThan: parseInt(values.maxFivePostsPerDayWhenTrustedPointLessThan),
          },
        })
          .catch(e => {
            console.log(e.toLocaleString());
          })
          .then(() => {
            setShow(false);
            props.closeModal();
          });
      }
    });
  };

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const changePoints = e => {
    let val = parseFloat(e.target.value);
    props.form.setFieldsValue({ points: val });
  };

  const { visible } = props;
  const [show, setShow] = useState(visible);
  return (
    <div>
      <Modal
        title={props.data._id ? 'Edit Config' : 'Add New Config'}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Save'}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form>
              <Row type="flex" justify="center" gutter={8}>
                <Col span={24}>
                  <p>
                    Action type: <strong>{props.data && props.data.actionType ? props.data.actionType : ''}</strong>
                  </p>
                </Col>
                {props.data && props.data.trustedPointsFrom && props.data.trustedPointsFrom != '' ? (
                  <Col span={12}>
                    <Form.Item label="Trusted Points From">
                      {getFieldDecorator(`trustedPointsFrom`, {
                        initialValue:
                          props.data && props.data.trustedPointsFrom != '' ? props.data.trustedPointsFrom : '',
                        rules: [],
                      })(<Input name="trustedPointsFrom" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data && props.data.trustedPointsTo && props.data.trustedPointsTo != '' ? (
                  <Col span={12}>
                    <Form.Item label="To">
                      {getFieldDecorator(`trustedPointsTo`, {
                        initialValue: props.data && props.data.trustedPointsTo != '' ? props.data.trustedPointsTo : '',
                        rules: [],
                      })(<Input name="trustedPointsTo" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data && props.data.rankFrom && props.data.rankFrom != '' ? (
                  <Col span={12} style={{ display: props.data && props.data.rankFrom != '' ? 'block' : 'none' }}>
                    <Form.Item label="Rank From">
                      {getFieldDecorator(`rankFrom`, {
                        initialValue: props.data && props.data.rankFrom != '' ? props.data.rankFrom : '',
                        rules: [],
                      })(<Input name="rankFrom" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data && props.data.rankTo && props.data.rankTo != '' ? (
                  <Col span={12} style={{ display: props.data && props.data.rankTo != '' ? 'block' : 'none' }}>
                    <Form.Item label="To">
                      {getFieldDecorator(`rankTo`, {
                        initialValue: props.data && props.data.rankTo != '' ? props.data.rankTo : '',
                        rules: [],
                      })(<Input name="rankTo" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data && props.data.engagementAdjustPercent && props.data.engagementAdjustPercent != '' ? (
                  <Col
                    span={24}
                    style={{ display: props.data && props.data.engagementAdjustPercent != '' ? 'block' : 'none' }}
                  >
                    <Form.Item label="Engagement Adjust Percent">
                      {getFieldDecorator(`engagementAdjustPercent`, {
                        initialValue:
                          props.data && props.data.engagementAdjustPercent != ''
                            ? props.data.engagementAdjustPercent
                            : '',
                        rules: [],
                      })(<Input name="engagementAdjustPercent" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data && props.data.postContentLength && props.data.postContentLength != '' ? (

                  <Col span={24} style={{ display: props.data && props.data.postContentLength != '' ? 'block' : 'none' }}>
                    <Form.Item label="Post Content Length">
                      {getFieldDecorator(`postContentLength`, {
                        initialValue:
                          props.data && props.data.postContentLength != '' ? props.data.postContentLength : '',
                        rules: [],
                      })(<Input name="postContentLength" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data ? (
                  <Col span={12} style={{ display: 'block' }}>
                    <Form.Item label="Total PlaceReviews From">
                      {getFieldDecorator(`totalPlaceReviewsFrom`, {
                        initialValue:
                          props.data && props.data.totalPlaceReviewsFrom != '' ? props.data.totalPlaceReviewsFrom : '',
                        rules: [],
                      })(<Input name="totalPlaceReviewsFrom" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data ? (
                  <Col span={12} style={{ display: 'block' }}>
                    <Form.Item label="To">
                      {getFieldDecorator(`totalPlaceReviewsTo`, {
                        initialValue:
                          props.data && props.data.totalPlaceReviewsTo != '' ? props.data.totalPlaceReviewsTo : '',
                        rules: [],
                      })(<Input name="totalPlaceReviewsTo" />)}
                    </Form.Item>
                  </Col>
                ) : null}
                {/*{props.data && props.data.adjustPointPercent && props.data.adjustPointPercent != '' ? (*/}
                {props.data && actionType === "ADDED_NON_STANDARD_REVIEW_POST" ? (
                  <Col
                    span={24}
                    // style={{ display: props.data && props.data.adjustPointPercent != '' ? 'block' : 'none' }}
                  >
                    <Form.Item label="Adjust Point Percent">
                      {getFieldDecorator(`adjustPointPercent`, {
                        initialValue:
                          props.data && props.data.adjustPointPercent != '' ? props.data.adjustPointPercent : 0,
                        rules: [],
                      })(<Input name="adjustPointPercent" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data ? (
                  <Col span={24} style={{ display: 'block' }}>
                    <Form.Item label="New Place Coefficient">
                      {getFieldDecorator(`newPlaceCoefficient`, {
                        initialValue:
                          props.data && props.data.newPlaceCoefficient != '' ? props.data.newPlaceCoefficient : '',
                        rules: [],
                      })(<Input name="newPlaceCoefficient" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data && props.data.unlimitedPostsPerDayWhenTrustedPointGreaterThan && props.data.unlimitedPostsPerDayWhenTrustedPointGreaterThan != '' ? (
                  <Col
                    span={24}
                    style={{
                      display:
                        props.data && props.data.unlimitedPostsPerDayWhenTrustedPointGreaterThan != '' ? 'block' : 'none',
                    }}
                  >
                    <Form.Item label="Unlimited Posts Per Day When Trusted Point Greater Than">
                      {getFieldDecorator(`unlimitedPostsPerDayWhenTrustedPointGreaterThan`, {
                        initialValue:
                          props.data && props.data.unlimitedPostsPerDayWhenTrustedPointGreaterThan != ''
                            ? props.data.unlimitedPostsPerDayWhenTrustedPointGreaterThan
                            : '',
                        rules: [],
                      })(<Input name="unlimitedPostsPerDayWhenTrustedPointGreaterThan" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data && props.data.maxTenPostsPerDayWhenTrustedPointLessThan && props.data.maxTenPostsPerDayWhenTrustedPointLessThan != '' ? (
                  <Col
                    span={24}
                    style={{
                      display:
                        props.data && props.data.maxTenPostsPerDayWhenTrustedPointLessThan != '' ? 'block' : 'none',
                    }}
                  >
                    <Form.Item label="Max Ten Posts PerDay When Trusted Point Less Than">
                      {getFieldDecorator(`maxTenPostsPerDayWhenTrustedPointLessThan`, {
                        initialValue:
                          props.data && props.data.maxTenPostsPerDayWhenTrustedPointLessThan != ''
                            ? props.data.maxTenPostsPerDayWhenTrustedPointLessThan
                            : '',
                        rules: [],
                      })(<Input name="maxTenPostsPerDayWhenTrustedPointLessThan" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                {props.data && props.data.maxFivePostsPerDayWhenTrustedPointLessThan && props.data.maxFivePostsPerDayWhenTrustedPointLessThan != '' ? (
                  <Col
                    span={24}
                    style={{
                      display:
                        props.data && props.data.maxFivePostsPerDayWhenTrustedPointLessThan != '' ? 'block' : 'none',
                    }}
                  >
                    <Form.Item label="Max Five Posts Per Day When Trusted Point Less Than">
                      {getFieldDecorator(`maxFivePostsPerDayWhenTrustedPointLessThan`, {
                        initialValue:
                          props.data && props.data.maxFivePostsPerDayWhenTrustedPointLessThan != ''
                            ? props.data.maxFivePostsPerDayWhenTrustedPointLessThan
                            : '',
                        rules: [],
                      })(<Input name="maxFivePostsPerDayWhenTrustedPointLessThan" />)}
                    </Form.Item>
                  </Col>
                ) : null}

                <Col span={24}>
                  <Form.Item label="Type">
                    {getFieldDecorator(`type`, {
                      initialValue: props.data && props.data.type ? props.data.type : '',
                      rules: [
                        {
                          required: true,
                          message: 'Please input your Phone number!',
                        },
                      ],
                    })(
                      <Select placeholder="Select a option and change input text above">
                        {trustedPointConfigType.map(function(r, i) {
                          return (
                            <Option key={i} value={r}>
                              {r}
                            </Option>
                          );
                        })}
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Points">
                    {getFieldDecorator(`points`, {
                      initialValue: props.data && props.data.points != null ? props.data.points : '',
                      rules: [
                        {
                          required: true,
                          message: 'Please input points!',
                        },
                      ],
                    })(<Input name="points" onBlur={e => changePoints(e)} />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Description">
                    {getFieldDecorator(`description`, {
                      initialValue: props.data && props.data.description ? props.data.description : '',
                      rules: [],
                    })(<Input name="description" />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditConfig;
