import { gql } from 'apollo-boost';

export const CMS_GET_PAGE_REPORT = gql`
    query CmsGetPageReports($limit: Int!, $offset: Int, $filter:CmsGetPageReportFilter) {
        cmsGetPageReports(limit: $limit, offset: $offset, filter:$filter) {
            total
            offset
            limit
            items {
                _id
                name
                keyName
                type
                businessType
                description
                phoneNumber
                reason 
                createdAt
                status
                termStatus
                creator {
                    _id
                    fullName
                    email
                }
            }
        }
    }
`;

export const CMS_REPORT_DETAIL = gql`
    query CmsListReportByTargetId($limit: Int!, $type: ReportFor!, $targetId: ID!) {
        cmsListReportByTargetId(limit: $limit, type: $type, targetId: $targetId) {
            _id
            message
            postReportType
            creator {
                _id
                fullName
            }
            createdAt
        }
    }
`;

export const CMS_GET_POST_REPORT = gql`
    query CmsGetPostReports($limit: Int!, $offset: Int,$filter:CmsGetPostReportFilter, $sortType: SortReportType, $sortDir: SortByDirection) {
        cmsGetPostReports(limit: $limit, offset: $offset,filter :$filter, sortType: $sortType, sortDir: $sortDir) {
            total
            offset
            limit
            items {
                _id
                note
                medias {
                    uri
                }
                title
                type
                reason
                createdAt
                termStatus
                content
                totalReports
                lastReportAt
                isAdminReport
                creator {
                    _id
                    fullName
                    email
                    phoneNumber
                    avatar {
                        uri
                    }
                    point
                    termStatus
                }
                page {
                    _id
                    name
                    email
                    phoneNumber
                    logo {
                        uri
                    }
                    owner {
                        fullName
                        email
                        phoneNumber
                        avatar {
                            uri
                        }
                    }
                    admins {
                        fullName
                        phoneNumber
                        avatar {
                            uri
                        }
                    }
                    termStatus
                }
            }
        }
    }
`;

export const CMS_GET_USER_REPORT = gql`
    query CmsGetUserReports($limit: Int!, $offset: Int) {
        cmsGetUserReports(limit: $limit, offset: $offset) {
            total
            offset
            limit
            items {
                _id
                fullName
                email
                avatar {
                    uri
                }
                reason
                createdAt
                termStatus
            }
        }
    }
`;

export const CMS_GET_COMMENT_REPORT = gql`
    query CmsGetCommentReports($limit: Int!, $offset: Int,$sortDir:SortByDirection, $filter:CmsGetCommentFilterInput, $sortType: SortReportType) {
        cmsGetCommentReports(limit: $limit, offset: $offset,sortDir:$sortDir, filter: $filter, sortType:$sortType) {
            total
            offset
            limit
            items {
                _id
                content
                isAdminReport
                medias {
                    uri
                }
                creator {
                    _id
                    fullName
                    email
                    phoneNumber
                    avatar {
                        uri
                    }
                    point
                    termStatus
                }
                commentedAsPage {
                    _id
                    name
                    email
                    phoneNumber
                    logo {
                        uri
                    }
                    owner {
                        fullName
                        email
                        phoneNumber
                        avatar {
                            uri
                        }
                    }
                    admins {
                        fullName
                        phoneNumber
                        avatar {
                            uri
                        }
                    }
                    termStatus
                }
                reason
                createdAt
                termStatus
                lastReportAt
                totalReports
            }
        }
    }
`;

export const CMS_GET_MEDIA_REPORT = gql`
    query CmsGetMediaReports($limit: Int!, $offset: Int) {
        cmsGetMediaReports(limit: $limit, offset: $offset) {
            total
            offset
            limit
            items {
                _id
                uri
                uploader {
                    _id
                    fullName
                    email
                }
                reason 
                totalLikes
                termStatus
                uploadedAt
                totalReports
            }
        }
    }
`;

export const CMS_GET_GROUP_REPORT = gql`
    query CmsGetGroupReports($limit: Int!, $offset: Int) {
        cmsGetGroupReports(limit: $limit, offset: $offset) {
            total
            offset
            limit
            items {
                _id
                name
                creator {
                    _id
                    fullName
                    email
                }
                reason 
                termStatus
                totalReports
                createdAt
                totalMembers
                totalPosts
            }
        }
    }
`;

export const CMS_GET_PRODUCT_REPORT = gql`
    query CmsGetProductReports($limit: Int!, $offset: Int, $filter: CmsGetPostFiltersInput, $sortBy: PostSortBy) {
        cmsGetProductReports(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
            total
            offset
            limit
            items {
                _id
                title
                content
                creator {
                    _id
                    fullName
                    email
                    phoneNumber
                    avatar {
                        uri
                    }
                    point
                    termStatus
                }
                medias {
                    uri
                }
                reason 
                type
                termStatus
                totalReports
                lastReportAt
                createdAt
                isAdminReport
                price
                page {
                    _id
                    name
                    email
                    phoneNumber
                    logo {
                        uri
                    }
                    owner {
                        fullName
                        email
                        phoneNumber
                        avatar {
                            uri
                        }
                    }
                    admins {
                        fullName
                        phoneNumber
                        avatar {
                            uri
                        }
                    }
                    termStatus
                }
            }
        }
    }
`;

export const CMS_GET_RECENT_PRODUCT_REPORTS = gql`
    query CmsGetRecentProductReports($targetId: ID!, $type: ReportFor!, $limit: Int!) {
        cmsListReportByTargetId(targetId: $targetId, type: $type, limit: $limit) {
            _id
            message
            postReportType
            creator {
                fullName
            }
            createdAt
        }
    }
`;

export const CMS_ADMIN_HANDLE_REPORT_POST = gql`
    mutation cmsReportPost($id: ID!) {
        cmsReportPost(_id: $id)
    }
`;

export const CMS_ADMIN_CANCEL_HANDLE_REPORT_POST = gql`
    mutation cmsCancelReportPost($id: ID!) {
        cmsCancelReportPost(_id: $id)
    }
`;

export const CMS_ADMIN_HANDLE_COMMENT_POST = gql`
    mutation cmsReportComment($id: ID!) {
        cmsReportComment(_id: $id)
    }
`;

export const CMS_ADMIN_CANCEL_HANDLE_COMMENT_POST = gql`
    mutation cmsCancelReportComment($id: ID!) {
        cmsCancelReportComment(_id: $id)
    }
`;

export const CMS_GET_POSTS_BY_CREATOR = gql`
    query cmsGetPostsByCreator($targetId: ID!, $limit: Int!, $isPage: Boolean) {
        cmsGetPostsByCreator(targetId: $targetId, limit: $limit, isPage: $isPage) {
            _id
            type
            title
            content
            createdAt
            totalLikes
            totalComments
            medias {
                type
                thumbnail
                uri
            }
        }
    }
`;

export const CMS_GET_LIST_REPORTS = gql`
    query cmsGetDetailReports($id:ID!, $forModel:ReportFor!, $limit:Int!, $page:Int!){
        cmsGetDetailReports(id:$id, forModel:$forModel, limit:$limit, page:$page){
            counter
            items{
                _id
                message
                creator{
                    fullName
                }
                for
                postReportType
                createdAt
            }
        }
    }
`;

export const CMS_GET_LIST_ENTITY_BLOCKED = gql`
    query cmsGetBlockedDetailList($id:ID!, $forModel:ReportFor!, $limit:Int!, $page:Int!){
        cmsGetBlockedDetailList(id:$id, forModel:$forModel, limit:$limit, page:$page){
            counter
            items{
                reason
                status
                createdAt
            }
        }
    }
`;
