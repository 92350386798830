import React, { useState } from 'react';
import { Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  CREATE_SYSTEM_NOTIFICATION,
  UPDATE_SYSTEM_NOTIFICATION,
  SEND_SYSTEM_NOTIFICATION,
} from '../../../graphql/systemNotification/systemNotification';
import {
  CreateSystemNotification,
  CreateSystemNotificationVariables,
  SystemNotificationTo,
  UpdateSystemNotification,
  UpdateSystemNotificationVariables,
  SendSystemNotification,
  SendSystemNotificationVariables,
} from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';

const { TextArea } = Input;
interface IData {
  _id?: any;
  customMessage?: string;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
  form: any;
}

const EditConfig = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;

  const [update] = useMutation<UpdateSystemNotification, UpdateSystemNotificationVariables>(
    UPDATE_SYSTEM_NOTIFICATION,
    {
      refetchQueries: ['GetMyNotifications'],
    },
  );

  const [create] = useMutation<CreateSystemNotification, CreateSystemNotificationVariables>(
    CREATE_SYSTEM_NOTIFICATION,
    {
      refetchQueries: ['GetMyNotifications'],
    },
  );

  const [sent] = useMutation<SendSystemNotification, SendSystemNotificationVariables>(SEND_SYSTEM_NOTIFICATION, {
    refetchQueries: ['GetMyNotifications'],
  });
  const sendNotification = id => {
    sent({
      variables: {
        _id: id,
      },
    })
      .catch(e => {
        console.log(e.toLocaleString());
      })
      .then(() => {
        console.log('End send notification');
      });
  };

  const handleOk = e => {
    props.form.validateFields((err, values) => {
      if (!err) {
        if (props.data && props.data._id) {
          const { data } = props;
          update({
            variables: {
              _id: data._id,
              systemNotificationTo: SystemNotificationTo.USER,
              customMessage: values.customMessage,
              excludedUserIds: [],
              excludedPageIds: [],
            },
          })
            .catch(e => {
              console.log(e.toLocaleString());
            })
            .then((res: any) => {
              setShow(false);
              console.log('Start send notification');
              if (res && res.data && res.data.updateSystemNotification && values.sendAfterSave) {
                sendNotification(res.data.updateSystemNotification._id);
              }
              props.closeModal();
            });
        } else {
          create({
            variables: {
              systemNotificationTo: SystemNotificationTo.USER,
              customMessage: values.customMessage,
              excludedUserIds: [],
              excludedPageIds: [],
            },
          })
            .catch(e => {
              console.log(e.toLocaleString());
            })
            .then((res: any) => {
              setShow(false);

              console.log('Start send notification');
              if (res && res.data && res.data.createSystemNotification && values.sendAfterSave) {
                sendNotification(res.data.createSystemNotification._id);
              }
              props.closeModal();
            });
        }
      }
    });
  };

  const handleSubmit = e => {};

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const { visible } = props;
  const [show, setShow] = useState(visible);
  return (
    <div>
      <Modal
        title={props.data._id ? 'Edit System Notification' : 'Add System Notification'}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Save'}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form onSubmit={handleSubmit}>
              <Form.Item label="Custom Message">
                {getFieldDecorator('customMessage', {
                  initialValue: props.data.customMessage,
                  rules: [
                    {
                      required: true,
                      message: 'Please enter message',
                    },
                  ],
                })(<TextArea placeholder="Enter message" />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('sendAfterSave', {
                  valuePropName: 'checked',
                  initialValue: false,
                })(<Checkbox>Send notification after save</Checkbox>)}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditConfig;
