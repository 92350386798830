
export const timezones = {
  VI:{
    country_code:'VI',
    UTC:'GMT +7',
    tz:'Asia/Ho_Chi_Minh'
  }
};

export const dateTypes = {
  displayType:"DD/MM/Y H:mm",
  displayType2:"DD/MM/Y"
};