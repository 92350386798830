import React, { useEffect, useState } from 'react';
import { Breadcrumb, Form, Layout, Skeleton, Table, DatePicker, Pagination, Button, Popconfirm } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import {LoadingOutlined} from "@ant-design/icons";
import Moment from 'moment';

import Styled from '../Place/indexStyled';
import {CMS_LIST_LUCKY_WHEEL,CMS_DELETE_LUCKY_WHEEL} from '../../graphql/luckyWheel'
import { cmsListLuckyWheel,
  cmsListLuckyWheelVariables,
  deleleLuckyWheel,
  deleleLuckyWheelVariables
} from '../../graphql/types';
import {dateByFormat} from "../../helpers/date";
import { Link } from 'react-router-dom';

const { Content } = Layout;
const { RangePicker } = DatePicker;




interface Props extends FormComponentProps {
  match?: any;
}

const dateFormat = 'DD/MM/YYYY';

const LuckyWheelImpl = (props: Props) => {
  const { params } = props.match;
  const { type } = params;
  const [isLoading, setLoading] = useState(true);
  const [totalWheel, setTotalWheel] = useState(0);
  const [loadingLW,setLoadingLW] = useState({});
  const [filterParams, setFilterParams] = useState({
    limit: 10,
    page: 1,
  });


  const defaultColumns = [
    {
      title: 'No.',
      key: 'index',
      width: '5%',
      render: (text, record, index) => (index + 1) + (filterParams.page -1)*filterParams.limit,
    },
    {
      title: 'Title',
      dataIndex:'title'
    },
    {
      title: 'Start date',
      key:'startDate',
      render:(row)=>{
        return  <>{dateByFormat(row.activeDate.startDate)}</>
      }
    },
    {
      title: 'End date',
      key:'endDate',
      render:(row)=>{
        return  <>{dateByFormat(row.activeDate.endDate)}</>
      }
    },
    {
      title: 'Voucher quantity',
      dataIndex:'totalPrize'
    },
    {
      title: 'Voucher redeemed',
      dataIndex:'totalRedeemPrize'
    },
    {
      title: 'Action',
      key: 'operation',
      width: '10%',
      render: row => {
        return params.type !== 'deleted' && (
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}>
            <div>
              <Link to={'/lucky-wheels/edit/' + row._id}>
                <Button type="primary" icon="edit" size="small" onClick={e => {}}/>
              </Link>
            </div>
            <Popconfirm  title="Are you sure？" onConfirm={()=>{onRemoveLW(row._id)}}
            >

              <Button type="primary" icon="delete" size="small" onClick={()=>{}}/>
              {
                loadingLW[row._id]   && <LoadingOutlined />
              }

            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const [columns,setColumns] = useState(defaultColumns);

  // change API
  const getLuckyWheels = () => {
    return useQuery<cmsListLuckyWheel, cmsListLuckyWheelVariables>(CMS_LIST_LUCKY_WHEEL, {
      fetchPolicy: 'network-only',
      variables:{
        limit:filterParams.limit,
        page:filterParams.page,
        filter:{
          deletedAt :params.type === 'deleted'
        }
      }
    });
  };

  const [deleteLuckyWheelAPI] = useMutation<deleleLuckyWheel,deleleLuckyWheelVariables>(CMS_DELETE_LUCKY_WHEEL,{
    refetchQueries:['cmsListLuckyWheel']
  });

  let { data, error, loading } = getLuckyWheels();



  useEffect(()=>{
    if(type === 'deleted'){
      columns.pop();
      setColumns(columns);
    }else{
      setColumns(defaultColumns);
    }
  },[type]);

  const onChangePage = (page) => {
    setFilterParams({ ...filterParams, page });
  };

  const onRemoveLW = (_id)=>{
    setLoadingLW({...loadingLW,_id:true});
    deleteLuckyWheelAPI(({variables:{_id}}))
      .then(()=>setLoadingLW({...loadingLW,_id:false}))
      .catch(()=>setLoadingLW({...loadingLW,_id:false}))
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  useEffect(()=>{
    if (data && data.cmsListLuckyWheel && data.cmsListLuckyWheel.items) {
      setTotalWheel(data.cmsListLuckyWheel.counter);
    }
  },[data]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Lucky Wheel</Breadcrumb.Item>
          <Breadcrumb.Item>{params.type === 'active' ? 'Active list' : 'Campaign list'}</Breadcrumb.Item>
        </Breadcrumb>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsListLuckyWheel && data.cmsListLuckyWheel.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div>
            {params.type === 'active' &&
            <RangePicker
              defaultValue={[Moment(Moment(), dateFormat), Moment(Moment(), dateFormat)]}
              format={dateFormat}
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            }

            <Table rowKey="_id"
                   pagination={false}
                   columns={columns}
                   dataSource={data.cmsListLuckyWheel.items}
            />
            <br/>
            <Pagination defaultCurrent={filterParams.page}
                        pageSize={filterParams.limit}
                        total={totalWheel}
                        onChange={onChangePage}/>
          </div>
        )}
      </Content>
    </Styled.Container>
  );
};

const LuckyWheel = Form.create()(LuckyWheelImpl);
export default LuckyWheel;
