import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Col, DatePicker, Form, Input, Layout, Radio, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { BannerType,
  CmsCreateBanner_createBanner,
  CmsCreateBannerVariables,
  cmsListBannerScreen,
} from '../../graphql/types';
import { CMS_CREATE_BANNER, CMS_GET_SCREENS_BANNER } from '../../graphql/banner/banner';
import { useMutation, useQuery } from '@apollo/react-hooks';
import history from '../../history';
import { ToastSuccess,ToastError } from '../../components/Toast';
import UploadVoucher from './compoments/Uploads';
const moment = require('moment');


const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;
const loadingImage = require("../../assets/images/loadingspinner.gif");
const expressionLink = /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/gi;

interface IEditProps extends FormComponentProps {
}

const BannerImpl = (props: IEditProps) => {
  const { getFieldDecorator} = props.form;
  const [screens,setScreens] = useState([]);
  const [type,setType] = useState(BannerType.LINK);
  const [loading,setLoading] =  useState(false);
  const [createBanner, { data }] = useMutation<CmsCreateBanner_createBanner, CmsCreateBannerVariables>(CMS_CREATE_BANNER);

  const {data:screenData } = useQuery<cmsListBannerScreen>(CMS_GET_SCREENS_BANNER);

  const refs = useRef(null);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof refs.current.upload === 'function') {
          refs.current.upload();
        }
      }else{
        setLoading(false);
      }
    });
  };

  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
  };

  const onChangeType = (event) => {
    setType(event.target.value);
  };

  const afterUpload = (mediaId: [string]) => {
    if(!mediaId.length){
      setLoading(false);
      ToastError({ message: 'Error', description: 'Banner media is required!' });
    }
    else{
      props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if(values.name.trim() === ""){
            setLoading(false);
            ToastError({ message: 'Error', description: 'Banner names must not be blank.' });
          }
          else if(values.name.trim().length >100){
            setLoading(false);
            ToastError({ message: 'Error', description: 'The maximum length of a banner is 100 characters.' });
          }
          else{
            createBanner({
              variables: {
                name: values.name,
                data:{
                  link: type === BannerType.LINK ? values.link : null,
                  postId: type === BannerType.POST ? values.postId : null,
                  pageId: type === BannerType.PAGE ? values.pageId : null,
                  screen: type === BannerType.SCREEN ? values.screen : null,
                },
                type: BannerType[type],
                mediaId: mediaId[0],
                status: values.status,
                startDate: (new Date(values.dateActive[0])).getTime(),
                endDate: (new Date(values.dateActive[1])).getTime(),
              },
            }).then(()=>setLoading(false)).catch(() => setLoading(false));
          }
        }else{setLoading(false);}
      });
    }
  };


  const onChangeScreen = (value) => {
    console.log(`selected ${value}`);
  };

  const  onBlurScreen = () => {
    console.log('blur');
  };

  const onFocusScreen = () => {
    console.log('focus');
  };

  const onSearchScreen = (val) => {
    console.log('search:', val);
  };

  useEffect(()=>{
    if(screenData && screenData.cmsListBannerScreen && screenData.cmsListBannerScreen.length){
      setScreens(screenData.cmsListBannerScreen);
    }
  },[screenData]);


  if (data) {
    history.push('/banners');
    ToastSuccess({ message: 'Created', description: 'Banner create success' });
  }

  const disableDate = (current) => {
    return current && current < moment().add(-1, 'days').endOf('day');
  };

  return <Styled.Container>
    <Content className="content">

      <Breadcrumb>
        <Breadcrumb.Item>Banner</Breadcrumb.Item>
        <Breadcrumb.Item>Create</Breadcrumb.Item>
      </Breadcrumb>

      <Row type="flex" justify="center">
        <Col span={12}>
          <Form {...formItemLayout} onSubmit={handleSubmit}>
            <Row type="flex">
              <Col span={18} offset={6}>
                <UploadVoucher ref={refs}
                               onUploaded={(data: any) => afterUpload(data)}
                               numberImagesAllowed={1}
                               imageAccept=""
                />
              </Col>
            </Row>

            <Form.Item label="Name">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    message: 'Please input name!',
                  },
                ],
              })(<Input/>)}
            </Form.Item>

            <Form.Item label="Target">
              <Radio.Group defaultValue={BannerType.LINK} size="large" onChange={onChangeType}  className="width-100" >
                <Row type="flex">
                  <Col span={6}>
                    <Radio name="type" value={BannerType.LINK}>Link</Radio>
                  </Col>
                  <Col span={18}>
                    <Form.Item>
                      {getFieldDecorator('link', {
                        rules: [
                          {
                            required: type === BannerType.LINK,
                            message: 'Please input link!',
                            pattern: new RegExp(expressionLink)
                          },
                        ],
                      })(<Input disabled={type !== BannerType.LINK}/>)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={6}>
                    <Radio name="type" value={BannerType.PAGE}>PageID</Radio>
                  </Col>
                  <Col span={18}>
                    <Form.Item>
                      {getFieldDecorator('pageId', {
                        rules: [
                          {
                            required: type === BannerType.PAGE,
                            message: 'Please input page ID!',
                          },
                        ],
                      })(<Input disabled={type !== BannerType.PAGE}/>)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={6}>
                    <Radio name="type" value={BannerType.POST}>PostID</Radio>
                  </Col>
                  <Col span={18}>
                    <Form.Item>
                      {getFieldDecorator('postId', {
                        rules: [
                          {
                            required: type === BannerType.POST,
                            message: 'Please input post ID!',
                          },
                        ],
                      })(<Input disabled={type !== BannerType.POST}/>)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type="flex">
                  <Col span={6}>
                    <Radio name="type" value={BannerType.SCREEN}>Screen</Radio>
                  </Col>
                  <Col span={18}>
                    <Form.Item>
                      {getFieldDecorator('screen', {
                        rules: [
                          {
                            required: type === BannerType.SCREEN,
                            message: 'Please input screen!',
                          },
                        ],
                      })(
                        <Select
                          showSearch
                          disabled={type !== BannerType.SCREEN}
                          style={{ width: 200 }}
                          placeholder="Select a screen"
                          onChange={onChangeScreen}
                          onFocus={onFocusScreen}
                          onBlur={onBlurScreen}
                          onSearch={onSearchScreen}
                        >
                          {
                            screens.map((item,index)=>{
                              return (<Option key={index} value={item.screen}>{item.title}</Option>)
                            })
                          }
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Date Active">
              {getFieldDecorator('dateActive', rangeConfig)(
                <RangePicker showTime={{ format: 'HH:mm' }}
                             format="YYYY-MM-DD HH:mm"
                             disabledDate={disableDate}/>
              )}
            </Form.Item>

            <Form.Item {...buttonItemLayout} >
              <Button type="primary" disabled={loading} htmlType="submit">Submit</Button>
              {
                loading && <img src={loadingImage} className="w20" alt=""/>
              }
            </Form.Item>
          </Form>
        </Col>
      </Row>

    </Content>
  </Styled.Container>;
};

const BannerCreate = Form.create()(BannerImpl);
export default BannerCreate;
