import { gql } from 'apollo-boost';

export const CREATE_LUCKY_WHEEL = gql`
  mutation createLuckyWheel(
    $title: String!
    $titleEn:String!
    $batchs: [BatchInput!]!
    $activeDate: ActiveDateInput!
    $numberOfBatch: Int!
  ) {
    createLuckyWheel(data: { title: $title, titleEn:$titleEn, batchs: $batchs, activeDate: $activeDate, numberOfBatch: $numberOfBatch }) {
      _id
      title
      activeDate {
        startDate
        endDate
      }
    }
  }
`;

export const CMS_LIST_LUCKY_WHEEL = gql`
  query cmsListLuckyWheel($limit: Int!, $page: Int, $filter: LuckyWheelFilter) {
    cmsListLuckyWheel(limit: $limit, page: $page, filter: $filter) {
      counter
      items {
        _id
        title
        totalPrize
        totalRedeemPrize
        totalRemainingPrize
        activeDate {
          startDate
          endDate
        }
        numberOfBatch
        batchs {
          _id
          activeDate {
            startDate
            endDate
          }
          createdAt
        }
        createdAt
        creator {
          _id
          fullName
        }
        deletedAt
      }
    }
  }
`;

export const CMS_GET_RUNNING_LUCKY_WHEEL = gql`
  query cmsGetRunningLuckyWheel {
    cmsGetRunningLuckyWheel {
      _id
      title
      totalPrize
      totalRedeemPrize
      totalRemainingPrize
      activeDate {
        startDate
        endDate
      }
      numberOfBatch
      batchs {
        _id
        activeDate {
          startDate
          endDate
        }
        prizes {
          _id
          name
          point
          prize_index
        }
        createdAt
      }
      createdAt
      creator {
        _id
        fullName
      }
    }
  }
`;

export const CMS_GET_LUCKY_WHEEL = gql`
  query cmsLuckyWheel($id: ID!) {
    cmsLuckyWheel(_id: $id) {
      _id
      title
      titleEn
      activeDate {
        startDate
        endDate
      }
      numberOfBatch
      batchs {
        _id
        activeDate {
          startDate
          endDate
        }
        totalPrize
        totalRedeemPrize
        prizes {
          _id
          name
          nameEn
          qty
          prize_index
          point
          remaining
          voucherId
          prizeTier
          media {
            _id
            uri
          }
        }
      }
    }
  }
`;

export const CMS_GET_LUCKY_WHEEL_WINNERS = gql`
  query cmsListLuckyWheelPrizeWinners($prizeId: ID!) {
    cmsListLuckyWheelPrizeWinners(prizeId: $prizeId) {
      wonUsers {
        _id
        fullName
        phoneNumber
      }
      designatedUsers {
        _id
        fullName
        phoneNumber
      }
    }
  }
`;

export const CMS_UPDATE_LUCKY_WHEEL_WINNER = gql`
  mutation updateLuckyWheelPrizeWinner($prizeId: ID!, $designatedUserPhoneNumbers: [String]) {
    updateLuckyWheelPrizeWinners(prizeId: $prizeId, designatedUserPhoneNumbers: $designatedUserPhoneNumbers) {
      wonUserIds
      designatedUserIds
    }
  }
`;

export const CMS_DELETE_LUCKY_PRIZE = gql`
  mutation deleteLuckyWheelPrize($_id: ID!) {
    deleteLuckyWheelPrize(_id: $_id)
  }
`;

export const CMS_CREATE_LUCKY_WHEEL_PRIZE = gql`
  mutation createLuckyWheelPrize($batchId: ID!, $data: PrizeInput!) {
    createLuckyWheelPrize(batchId: $batchId, data: $data) {
      _id
      name
      qty
      prizeTier
      prize_index
      mediaId
      voucherId
    }
  }
`;

export const CMS_UPDATE_LUCKY_WHEEL_PRIZE = gql`
  mutation updateLuckyWheelPrize($_id: ID!, $data: PrizeInput!) {
    updateLuckyWheelPrize(_id: $_id, data: $data) {
      _id
      name
      qty
      prizeTier
      prize_index
      mediaId
      voucherId
    }
  }
`;

export const CMS_UPDATE_LUCKY_WHEEL = gql`
  mutation updateLuckyWheel($_id: ID!, $data: LuckyWheelInput!) {
    updateLuckyWheel(_id: $_id, data: $data) {
      _id
      title
      activeDate {
        startDate
        endDate
      }
    }
  }
`;

export const CMS_UPDATE_LUCKY_WHEEL_PRIZE_NAME = gql`
  mutation cmsUpdateLuckyPrizesName($luckyId: ID!, $prizeTier: PrizeTier!, $name: String!, $nameEn:String!) {
    cmsUpdateLuckyPrizesName(luckyId: $luckyId, prizeTier: $prizeTier, name: $name, nameEn:$nameEn)
  }
`;

export const CMS_GET_LUCKY_WHEEL_INSTRUCTION_TYPE = gql`
  query cmsLuckyWheelInstructionTypes {
    cmsLuckyWheelInstructionTypes {
      type
      title
    }
  }
`;

export const CMS_GET_LIST_LUCKY_INSTRUCTION = gql`
  query cmsListLuckyWheelInstruction($limit: Int!, $page: Int) {
    cmsListLuckyWheelInstruction(limit: $limit, page: $page) {
      counter
      items {
        _id
        title
        titleEn
        descriptionEn
        description
        turns
        type
        createdAt
        deletedAt
        updatedAt
      }
    }
  }
`;

export const CMS_CREATE_LUCK_WHEEL_INSTRUCTION = gql`
  mutation createLuckyWheelInstruction($data: LuckyWheelInstructionInput) {
    createLuckyWheelInstruction(data: $data) {
      _id
      type
      title
      titleEn
      turns
      description
      descriptionEn
      createdAt
    }
  }
`;

export const CMS_UPDATE_LUCK_WHEEL_INSTRUCTION = gql`
  mutation updateLuckyWheelInstruction($_id: ID!, $data: LuckyWheelInstructionInput) {
    updateLuckyWheelInstruction(_id: $_id, data: $data) {
      _id
      type
      title
      titleEn
      turns
      descriptionEn
      description
      createdAt
    }
  }
`;

export const CMS_DELETE_LUCK_WHEEL_INSTRUCTION = gql`
  mutation deleteLuckyWheelInstruction($_id: ID!) {
    deleteLuckyWheelInstruction(_id: $_id)
  }
`;

export const CMS_LIST_LUCKY_WHEEL_CONDITIONS_CONFIG = gql`
  query cmsListLuckyWheelConfigs($limit: Int!, $page: Int) {
    cmsListLuckyWheelConfigs(limit: $limit, page: $page) {
      _id
      prizeTier
      description
      conditionType
      missionType
      minimum
    }
  }
`;

export const CMS_UPDATE_LUCKY_WHEEL_CONDITION_CONFIG = gql`
  mutation cmsUpdateLuckyWheelConfig(
    $_id: ID!
    $prizeTier: PrizeTier!
    $conditionType: LuckyWheelConfigType!
    $missionType: LuckyWheelInstructionType
    $minimum: Int!
    $description: String
  ) {
    cmsUpdateLuckyWheelConfig(
      _id: $_id
      prizeTier: $prizeTier
      conditionType: $conditionType
      missionType: $missionType
      minimum: $minimum
      description: $description
    ) {
      _id
      prizeTier
      conditionType
      description
      missionType
      minimum
    }
  }
`;

export const CMS_DELETE_LUCKY_WHEEL = gql`
  mutation deleleLuckyWheel($_id: ID!) {
    deleteLuckyWheel(_id: $_id)
  }
`;

export const CMS_CREATE_LUCKY_WHEEL_BATCH = gql`
    mutation createLuckyWheelBatch($luckyWheelId: ID!,$data: BatchInput){
        createLuckyWheelBatch(luckyWheelId:$luckyWheelId,data:$data){
            _id
            activeDate{
                startDate
                endDate
            }
        }
    }
`;

export const CMS_DELETE_LUCKY_WHEEL_BATCH = gql`
    mutation deleteLuckyWheelBatch($_id:ID!){
        deleteLuckyWheelBatch(_id:$_id)
    }`;

export const CMS_LIST_FAILED_SPIN_MESSAGE = gql`
    query cmsListFailedSpinMessages($q: String,$limit: Int!,$page: Int){
        cmsListFailedSpinMessages(limit:$limit,page:$page,q:$q){
            counter
            items{
                _id
                content
                contentEn
            }
        }
    }
`;


export const CMS_CREATE_FAILED_SPIN_MESSAGE = gql`
    mutation cmsCreateFailedSpinMessages($data:FailedSpinMessageInput!){
        cmsCreateFailedSpinMessages(data:$data){
            _id
            content
            contentEn
            createdAt
        }
    }
`;


export const CMS_DELETE_FAILED_SPIN_MESSAGE = gql`
    mutation cmsDeleteFailedSpinMessages($_ids:[ID!]){
        cmsDeleteFailedSpinMessages(_ids:$_ids)
    }
`;


export const CMS_IMPORT_FAILED_SPIN_MESSAGE = gql`
    mutation cmsImportFailedSpinMessages($data:ImportFailedSpinMessageInput!) {
        cmsImportFailedSpinMessages(data:$data)
    }
`;

export const CMS_UPDATE_FAILED_SPIN_MESSAGE = gql`
    mutation cmsUpdateFailedSpinMessage($_id:ID!,$data:FailedSpinMessageInput){
        cmsUpdateFailedSpinMessage(_id:$_id,data:$data){
            _id
            content
            contentEn
        }
    }
`;

export const CMS_DETAIL_LUCKY_WHEEL_TERM = gql`
    query cmsDetailLuckyWheelTerm {
        cmsDetailLuckyWheelTerm {
            _id
            content
            contentEn
            createdAt
            updatedAt
        }
    }`;



export const CMS_CREATE_LUCKY_WHEEL_TERM = gql`
    mutation createLuckyWheelTerm($data:luckyWheelTermInput!){
        cmsCreateLuckyWheelTerm(data:$data){
            _id
            content
            contentEn
            createdAt
            updatedAt
        }
    }
`;

export const CMS_UPDATE_LUCKY_WHEEL_TERM = gql`
    mutation updateLuckyWheelTerm($_id: ID!,$data: luckyWheelTermInput!){
        cmsUpdateLuckyWheelTerm(_id:$_id,data:$data)
    }
`;
