import React, { useEffect, useMemo, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Form,
  Layout,
  Modal,
  notification,
  Pagination,
  Popconfirm,
  Skeleton,
  Table,
  Row,
  Col,
  DatePicker,
  Select,
} from 'antd';
import Search from 'antd/lib/input/Search';
import { FormComponentProps } from 'antd/lib/form';
import { BLOCK_POST, GET_BLOCKED_FEED, SET_STATUS } from '../../graphql/feed/feed';
import {
  BlockPost,
  BlockPostVariables,
  GetBlockedFeed,
  GetBlockedFeedVariables,
  SortByDirection,
  ReportFor,
  TermStatus,
  PostSortByType,
  setStatusPost,
  setStatusPostVariables,
  PostStatus,
  GetUsers,
  GetUsersVariables,
} from '../../graphql/types';
import Styled from './indexStyled';
import { useMutation, useQuery } from '@apollo/react-hooks';
import DetailPost from './components/detail';
import moment from 'moment';
import './index.css';
import { GET_USERS } from 'src/graphql/user/users';

const { dateByFormat } = require('../../helpers/date');

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface Props extends FormComponentProps {
  match?: any;
}

const text = 'Are you sure to unblock this post?';

const BlockedPostFormImpl = (props: Props) => {
  const [isLoading, setLoading] = useState(true);
  const limit = 10;
  const [blockedPosts, setBlockedPosts] = useState([]);
  const [users, setUser] = useState([]);
  const [query, setQuery] = useState('');

  const [total, setTotal] = useState(0);
  const [dataLoaded, setDataLoaded] = useState([]);
  const [page] = useState({
    page: 0,
    after: null,
    isPreview: false,
  });
  const [postParams, setPostParams] = useState<any>({
    page: 1,
    limit: limit,
    filter: {},
    after: page.after,
    sortBy: {
      type: PostSortByType.CREATED_AT,
      dir: SortByDirection.DESC,
    },
  });
  const statuses = ['ALL', PostStatus.INCORRECT, PostStatus.NOT_VERIFIED, PostStatus.PENDING, PostStatus.VERIFIED];
  const creator = useMemo(() => users.map(item => ({ title: item.fullName, value: item._id })), [users]);

  const [unblockPost, { data: unblockPostResult }] = useMutation<BlockPost, BlockPostVariables>(BLOCK_POST, {
    refetchQueries: ['GetBlockedFeed'],
  });

  const getFeed = () => {
    let variables;
    variables = {
      page: postParams.page,
      limit: postParams.limit,
      filter: postParams.filter,
      after: postParams.after,
      sortBy: postParams.sortBy,
    };
    return useQuery<GetBlockedFeed, GetBlockedFeedVariables>(GET_BLOCKED_FEED, {
      fetchPolicy: 'network-only',
      variables: variables,
    });
  };

  const getUser = () => {
    let variables;
    variables = {
      query: query,
      limit: 10,
    };

    return useQuery<GetUsers, GetUsersVariables>(GET_USERS, {
      fetchPolicy: 'network-only',
      variables: variables,
    });
  };
  const { data: usersData } = getUser();

  const onChange = (pagination, filters, sorter, extra) => {
    setPostParams({
      ...postParams,
      page: pagination.current,
      sortBy:
        sorter.columnKey === 'createdAt'
          ? {
              type: PostSortByType.CREATED_AT,
              dir: sorter.order
                ? sorter.order === 'ascend'
                  ? SortByDirection.ASC
                  : SortByDirection.DESC
                : postParams.sortBy.dir === SortByDirection.ASC
                ? SortByDirection.DESC
                : SortByDirection.ASC,
            }
          : postParams.sortBy,
    });
  };

  useEffect(() => {
    if (usersData && usersData.cmsGetUsers.items && usersData.cmsGetUsers.items.length) {
      setUser(usersData.cmsGetUsers.items);
    } else {
      setUser([]);
    }
  }, [usersData]);

  const [isShow, setIsShow] = useState(false);
  const [post, setPost] = useState({
    title: null,
    medias: null,
    content: null,
    createdAt: null,
    creator: null,
    _id: null,
  });

  const [setStatus] = useMutation<setStatusPost, setStatusPostVariables>(SET_STATUS, {
    refetchQueries: ['GetPosts'],
  });
  const [statusPost, setStatusPost] = useState();
  const changeEditStatus = () => {
    setStatus({ variables: { id: post._id, status: statusPost } });
  };
  const { data, error, refetch, loading } = getFeed();
  useEffect(() => {
    if (data && data.cmsGetBlockedPosts && data.cmsGetBlockedPosts.item) {
      setBlockedPosts(data.cmsGetBlockedPosts.item);
      setTotal(data.cmsGetBlockedPosts.counter);
    }
  }, [data]);

  const unblock = id => {
    unblockPost({
      variables: {
        _id: id,
        model: ReportFor.POST,
        termStatus: TermStatus.NORMAL,
        reason: '',
      },
    }).catch(() => {});
    if (unblockPostResult) {
      notification.open({
        placement: 'bottomRight',
        type: 'success',
        message: 'Unblocked',
        description: 'Record has been unblocked.',
      });
    }
  };

  const show = row => {
    setPost(row);
    setIsShow(true);
  };

  const close = () => {
    setIsShow(false);
  };

  const handleOk = () => {
    close();
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Title',
      render: row => {
        return (
          <div style={{ maxWidth: '200px' }}>
            <strong>{row.title}</strong>
            <p>
              {row.content && row.content.substr(0, 150)}
              {row.content && row.content.length > 150 && '...'}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      render: reason => {
        if (reason) {
          let reasonParse = JSON.parse(reason);
          return (reasonParse && reasonParse[0] && reasonParse[0].reason) || '';
        }
        return '';
      },
    },
    {
      title: 'Like',
      dataIndex: 'totalLikes',
    },
    {
      title: 'Total report',
      dataIndex: 'totalReports',
    },
    {
      title: 'Comment',
      dataIndex: 'totalComments',
    },
    {
      title: 'Creator',
      dataIndex: 'creator.fullName',
    },
    {
      title: 'Created at',
      key: 'createdAt',
      sorter: true,
      width: 150,
      render: row => {
        return <div className="">{row && dateByFormat(row.createdAt)}</div>;
      },
    },
    // {
    //   title: 'Status',
    //   fixed: 'right' as 'right',
    //   align: 'center' as 'center',
    //   render: row => {
    //     let type: any = 'default';
    //     if (row.status === PostStatus.VERIFIED) type = 'primary';
    //     if (row.status === PostStatus.NOT_VERIFIED) type = 'danger';
    //     return (
    //       <Button className="btn-rectangle button" type={type} size="small" onClick={e => show(row)}>
    //         {row.status === PostStatus.NOT_VERIFIED ? 'NOT VERIFIED' : row.status}
    //       </Button>
    //     );
    //   },
    // },
    {
      title: 'Action',
      key: 'operation',
      fixed: 'right' as 'right',
      width: 90,
      render: row => {
        return (
          <div>
            <Button type="default" icon="eye" size="small" onClick={e => show(row)} />

            <Popconfirm placement="left" title={text} onConfirm={() => unblock(row._id)} okText="Yes" cancelText="No">
              <Button style={{ marginLeft: 5 }} icon="check-circle" type="default" size="small" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = page => {
    setPostParams({ ...postParams, page });
  };

  useEffect(() => {
    refetch({
      limit: postParams.limit,
      filter: postParams.filter,
      after: postParams.after,
      sortBy: postParams.sortBy,
    });
  }, [postParams]);

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
      if (data && data.cmsGetBlockedPosts) {
        dataLoaded.push(data.cmsGetBlockedPosts);
        setDataLoaded(dataLoaded);
      }
    }
  }, [loading]);

  const onChangeDateRange = dates => {
    if (!!dates.length) {
      setPostParams({
        ...postParams,
        filter: {
          ...postParams.filter,
          createdAt: {
            from: dates[0]
              .set({ hour: 12, minute: 0 })
              .utc()
              .toDate(),
            to: dates[1]
              .set({ hour: 12, minute: 0 })
              .utc()
              .toDate(),
          },
        },
      });
    } else {
      setPostParams({
        ...postParams,
        filter: {
          ...postParams.filter,
          createdAt: undefined,
        },
      });
    }
  };
  const handleChangeStatus = () => {
    close();
    statusPost && changeEditStatus();
  };
  const disableDate = current => {
    return current && current > moment().endOf('day');
  };

  const handleSearch = value => {
    setPostParams({
      ...postParams,
      filter: {
        ...postParams.filter,
        q: value,
      },
    });
  };
  const changeStatus = status => {
    if (status !== 'ALL') {
      setPostParams({
        ...postParams,
        filter: {
          ...postParams.filter,
          status: status,
        },
      });
    } else {
      setPostParams({
        ...postParams,
        filter: {
          ...postParams.filter,
          status: undefined,
        },
      });
    }
  };
  const changeUser = createdBy => {
    setPostParams({
      ...postParams,
      filter: {
        ...postParams.filter,
        createdBy: createdBy,
      },
    });
  };
  const onSearch = val => {
    setQuery(val);
  };
  useEffect(() => {
    if (!postParams.filter.createdBy) {
      setQuery('');
    }
  }, [postParams]);
  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Post</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mt-10 mb-10" gutter={16}>
          <Col span={11}>
            <Form.Item label="Search">
              <Search placeholder="Enter title" enterButton="Search" onSearch={value => handleSearch(value)} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Created At">
              <RangePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                onChange={onChangeDateRange}
                disabledDate={disableDate}
                className="w100"
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Status">
              <Select
                style={{ width: '100%' }}
                defaultValue={statuses[0]}
                placeholder="Select group to filter"
                onChange={e => changeStatus(e)}
              >
                {statuses.map((r, i) => {
                  return (
                    <Option key={i} value={r}>
                      {r}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="User">
              <Select
                style={{ width: '100%' }}
                placeholder="Select creator"
                onChange={e => changeUser(e)}
                onSearch={onSearch}
                showSearch={true}
                allowClear
                filterOption={false}
              >
                {creator.map((r, i) => {
                  return (
                    <Option key={i} value={r.value}>
                      {r.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetBlockedPosts && data.cmsGetBlockedPosts.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}></Skeleton>
        ) : (
          <div>
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={blockedPosts}
              onChange={onChange}
              scroll={{ x: 'calc(700px + 50%)', y: 0 }}
              pagination={false}
            />
            <Pagination
              defaultCurrent={postParams.page}
              total={total}
              defaultPageSize={10}
              onChange={onChangePage}
              style={{
                marginTop: 20,
                right: 0,
              }}
            />
          </div>
        )}

        {isShow && post && (
          <Modal
            width={1024}
            title="Post Detail"
            visible={true}
            okText="Ok"
            onOk={() => handleChangeStatus()}
            onCancel={() => close()}
          >
            <DetailPost
              title={post.title}
              content={post.content}
              medias={post.medias}
              creator={post.creator}
              createdAt={post.createdAt}
              post={post}
              setStatusPost={setStatusPost}
            />
          </Modal>
        )}
      </Content>
    </Styled.Container>
  );
};

const BlockedPost = Form.create()(BlockedPostFormImpl);
export default BlockedPost;
