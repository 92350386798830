import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import { useQuery } from 'react-apollo';
import { GetMe } from 'src/graphql/types';
import { GET_ME } from 'src/graphql/user/me';
import './index.css';

const Profile = () => {
  const { data } = useQuery<GetMe>(GET_ME, { fetchPolicy: 'network-only' });

  return (
    <div className="container_profile">
      <p>Profile User</p>
      <Row gutter={[16, 24]}>
        <Col span={4}>
          <div>Avatar:</div>
        </Col>
        <Col span={4}>
          <div>
            <img src={data?.me?.avatar?.thumbnail} alt="avatar" width={60} height={60} className="avatar_profile" />
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={4}>
          <div>FullName:</div>
        </Col>
        <Col span={4}>
          <div>{data?.me?.fullName}</div>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={4}>
          <div>Username:</div>
        </Col>
        <Col span={4}>
          <div>{data?.me?.username}</div>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={4}>
          <div>Email:</div>
        </Col>
        <Col span={8}>
          <div>
            <p className="text-email">{data?.me?.email}</p>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 24]}>
        <Col span={4}>
          <div>PhoneNumber:</div>
        </Col>
        <Col span={4}>{data?.me?.phoneNumber}</Col>
      </Row>
    </div>
  );
};

export default Profile;
