import { gql } from 'apollo-boost';

export const CMS_GET_NUMBER_USERS = gql`
    query cmsNumberOfUsers{
        cmsNumberOfUsers
    }
`;

export const CMS_NUMBER_PARTNER_PAGES = gql`
    query cmsNumberOfPartnerPages{
        cmsNumberOfPartnerPages
    }`;

export const CMS_NUMBER_USER_BY_COUNTRY =  gql`
    query cmsNumberOfUsersByCountry{
        cmsNumberOfUsersByCountry{
            country
            users
            percentage
        }
    }`;

export const CMS_NEW_USERS_PER_DAY = gql`
    query cmsNewUsersPerDay($startDate:DateTime!,$endDate:DateTime!){
        cmsNewUsersPerDay(startDate:$startDate,endDate:$endDate){
            newUsers
            date
        }
    }`;

export const CMS_TOP_USERS_POINT_PER_DAY = gql`
    query cmsTopUsersByPointsPerDay(
    $limit: Int!
    $page: Int
    $filterDate: DateTime
    $filterRange: [DateTime!]
    ){
        cmsTopUsersByPointsPerDay(limit:$limit,page:$page,filterDate:$filterDate,filterRange:$filterRange){
            userId
            user{
                _id
                fullName
                phoneNumber
                avatar{
                    uri
                }
            }
            changedCount
            earnedPoints

        }
    }
`;




