import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Table, Upload } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { FormComponentProps } from 'antd/lib/form';
import {
  createVoucherCode,
  createVoucherCodeVariables,
  GetVouchers_cmsVouchers_items,
  TypeVoucherCode,
} from '../../../graphql/types';
import { CREATE_VOUCHER_CODES } from '../../../graphql/voucher/voucher';
import { useMutation } from '@apollo/react-hooks';
import { ToastError, ToastSuccess } from '../../../components/Toast';
import { ApolloError } from 'apollo-client';

const moment = require('moment');

const { RangePicker } = DatePicker;

const loadingImage = require('../../../assets/images/loadingspinner.gif');

interface voucherCode extends FormComponentProps {
  defaultData?: string;
  form: any;
  onClose: any;
  reFetchParent: any;
  voucher?: GetVouchers_cmsVouchers_items;
}

interface errorAddVoucherCode {
  existCodes: any;
}

const ErrorAddVoucherCode = (props: errorAddVoucherCode) => {
  let { existCodes } = props;
  existCodes = existCodes.map((item, index) => {
    return { item, index };
  });

  const columns = [
    {
      title: 'Code existing',
      dataIndex: 'item',
    },
  ];

  return (
    <Table
      rowKey="index"
      columns={columns}
      dataSource={existCodes}
      onChange={() => {}}
      scroll={{ x: 'calc(700px + 50%)', y: 0 }}
      pagination={false}
    />
  );
};

const allowedExtend = ['.xlsx', '.xls', '.csv'];

const CreateVoucherCode = (props: voucherCode) => {
  const { getFieldDecorator } = props.form;
  const [file, setFile] = useState(null);
  const [type, setType] = useState(TypeVoucherCode.IMPORT);
  const [existCodes, setExistCodes] = useState([]);
  const [modalAddError, setModalAddError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { voucher } = props;
  const { _id: voucherId } = voucher;

  const [createVoucherCode, { data: voucherExist }] = useMutation<createVoucherCode, createVoucherCodeVariables>(
    CREATE_VOUCHER_CODES,
  );

  const createVoucherCodeAPI = () => {
    setLoading(true);
    props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        // setType(null);
        setLoading(false);
      } else {
        if (type === TypeVoucherCode.IMPORT && file === null) {
          setLoading(false);
          ToastError({ message: 'Upload error', description: 'You have to upload file.' });
        } else {
          createVoucherCode({
            variables: {
              file: type === TypeVoucherCode.IMPORT ? file : [],
              qty: type === TypeVoucherCode.GENERATE ? parseInt(values.qty) : 0,
              voucherId,
              type,
              remarks: values.remarks,
              vendorCode: values.vendorCode,
              startDate: new Date(values.dateActive[0]).getTime(),
              endDate: new Date(values.dateActive[1]).getTime(),
            },
            refetchQueries: ['GetVouchers'],
          })
            .then(res => {
              setType(null);
              setLoading(false);
            })
            .catch((err: ApolloError) => {
              setLoading(false);
              if (err.graphQLErrors && err.graphQLErrors.length > 0 && err.graphQLErrors[0].message) {
                return ToastError({
                  message: 'Create error!',
                  description: err.graphQLErrors[0].message,
                });
              }
              ToastError({
                message: 'Create error!',
                description: 'Create error!',
              });
            });
        }
      }
    });
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const toggleModalAddCodes = () => {
    setModalAddError(!modalAddError);
  };

  const uploadFile = infor => {
    const name = infor.file.name.split('.');
    if (infor.fileList.length) {
      if (allowedExtend.indexOf('.' + name[name.length - 1]) > -1) {
        infor.fileList = [infor.file];
        setFile(infor.file);
      } else {
        infor.fileList.length = 0;
        ToastError({ message: 'Upload error', description: 'The format is allowed is : ' + allowedExtend.join(',') });
      }
    }
  };

  const onRemoveFile = file => {
    setFile(null);
    return true;
  };

  const onchangeStatus = e => {
    if (e.target.checked) {
      setType(TypeVoucherCode.GENERATE);
    } else {
      setType(TypeVoucherCode.IMPORT);
    }
  };

  useEffect(() => {
    if (
      voucherExist &&
      voucherExist.createVoucherCode &&
      voucherExist.createVoucherCode.items &&
      voucherExist.createVoucherCode.items.length
    ) {
      props.onClose();
      ToastSuccess({ message: 'Created', description: 'Voucher codes create success' });
      props.reFetchParent();
    }
    if (
      voucherExist &&
      voucherExist.createVoucherCode &&
      voucherExist.createVoucherCode.exists &&
      voucherExist.createVoucherCode.exists.length
    ) {
      setExistCodes(voucherExist.createVoucherCode.exists);
      toggleModalAddCodes();
      ToastError({ message: 'Created', description: 'Some voucher codes are duplicated!' });
    }
  }, [voucherExist]);

  const onSubmit = e => {
    e.preventDefault();
  };

  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
  };

  const disableDate = current => {
    return (
      (current &&
        current <
          moment()
            .add(-1, 'days')
            .endOf('day')) ||
      current <=
        moment(voucher.startDate)
          .add(-1, 'days')
          .endOf('day') ||
      current >= moment(voucher.endDate).endOf('day')
    );
  };
  return (
    <div>
      <Row type="flex" justify="center">
        <Col span={24}>
          <Form {...formItemLayout} onSubmit={onSubmit}>
            <Form.Item label="Code" labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} style={{ width: '100%' }}>
              <Row>
                <Col span={8}>
                  <Upload
                    onChange={uploadFile}
                    beforeUpload={file => {
                      return false;
                    }}
                    onRemove={onRemoveFile}
                    disabled={type !== TypeVoucherCode.IMPORT}
                    accept={allowedExtend.join(',')}
                  >
                    <Button htmlType="button" disabled={type !== TypeVoucherCode.IMPORT}>
                      <FileExcelOutlined /> Choose file
                    </Button>
                  </Upload>
                </Col>
                <Col span={7}>
                  <Form.Item label="Auto generate" style={{ display: 'flex' }}>
                    <Checkbox onChange={onchangeStatus}> </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="Date Active" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} style={{ width: '100%' }}>
              {getFieldDecorator(
                'dateActive',
                rangeConfig,
              )(<RangePicker className="width-100" disabledDate={disableDate} />)}
            </Form.Item>

            <Form.Item label="Quantity" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} style={{ width: '100%' }}>
              {getFieldDecorator('qty', {
                rules: [
                  {
                    required: type === TypeVoucherCode.GENERATE,
                    message: 'This field is required.',
                  },
                  {
                    pattern: new RegExp('^[+]?\\d+$'),
                    message: 'You must enter a positive integer',
                  },
                ],
              })(<Input disabled={type !== TypeVoucherCode.GENERATE} step={1} type="number" />)}
            </Form.Item>

            <Form.Item label="Vendor code" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} style={{ width: '100%' }}>
              {getFieldDecorator('vendorCode')(<Input />)}
            </Form.Item>

            <Form.Item label="Remarks" labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} style={{ width: '100%' }}>
              {getFieldDecorator('remarks')(<Input />)}
            </Form.Item>

            <Row>
              <Col offset={20} span={4}>
                <Button type="primary" disabled={loading} htmlType="button" onClick={createVoucherCodeAPI}>
                  Add
                </Button>
                {loading && <img src={loadingImage} className="w20" alt="" />}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {modalAddError && (
        <Modal
          visible={modalAddError}
          width={500}
          title="ERROR: List voucher existed"
          onOk={toggleModalAddCodes}
          onCancel={toggleModalAddCodes}
          footer=""
        >
          <ErrorAddVoucherCode existCodes={existCodes} />
        </Modal>
      )}
    </div>
  );
};

export default CreateVoucherCode;
