import { gql } from 'apollo-boost';

export const GET_VOUCHERS = gql`
    query GetVouchers(
        $limit: Int!
        $uid: ID
        $page: Int
        $status: VoucherStatus
        $withTrash: Boolean
        $adminStatus: VoucherAdminStatus
        $q: String
    ) {
        cmsVouchers(
            limit: $limit,
            page: $page,
            filter: { uid: $uid, status: $status, withTrash: $withTrash, adminStatus: $adminStatus }
            q: $q
        ) {
            counter
            items {
                _id
                medias {
                    _id
                    thumbnail
                    uri
                }
                title
                createdBy
                type
                creator {
                    fullName
                }
                voucherId
                startDate
                enable
                available
                pointSpend
                qty
                endDate
                createdAt
                status
                adminStatus
            }
        }
    }
`;

export const SET_VOUCHER_ADMIN_STATUS = gql`
    mutation SetVoucherAdminStatus($_ids: [ID!]!, $adminStatus: VoucherAdminStatus) {
        cmsSetVoucherAdminStatus(_ids: $_ids, adminStatus: $adminStatus)
    }
`;

export const CREATE_VOUCHER = gql`
    mutation CreateVoucher(
        $title: String!
        $titleEn: String!
        $pointSpend: Float
        $termAndCondition: String
        $termAndConditionEn: String
        $highlight: String
        $highlightEn: String
        $type: VoucherType!
        $mediaIds: [ID!]
        $description: String
        $descriptionEn: String
        $contact: VoucherContactInput
        $status: VoucherStatus
        $startDate: DateTime!
        $endDate: DateTime!
        $referralTurns:Int
    ) {
        createVoucher(
            data: {
                title: $title
                titleEn: $titleEn
                pointSpend: $pointSpend
                mediaIds: $mediaIds
                contact: $contact
                type: $type
                highlight: $highlight
                highlightEn: $highlightEn
                description: $description
                descriptionEn: $descriptionEn
                termAndCondition: $termAndCondition
                termAndConditionEn: $termAndConditionEn
                status: $status
                startDate: $startDate
                endDate: $endDate
                referralTurns: $referralTurns
            }
        ) {
            _id
            title
        }
    }
`;

export const VOUCHER = gql`
    query GetVoucher($_id: ID!) {
        voucher(_id: $_id) {
            _id
            title
            titleEn
            mediaIds
            enable
            type
            medias {
                _id
                uri
            }
            referralTurns
            description
            pointSpend
            enable
            qty
            available
            highlight
            highlightEn
            voucherId
            createdBy
            description
            descriptionEn
            termAndCondition
            termAndConditionEn
            prizeTitle
            prizeTitleEn
            contact {
                phoneNumber
                email
                address
            }
            status
            adminStatus
            startDate
            endDate
        }
    }
`;

export const UPDATE_VOUCHER = gql`
    mutation UpdateVoucher(
        $_id: ID!
        $title: String!
        $titleEn: String!
        $pointSpend: Float
        $termAndCondition: String
        $termAndConditionEn: String
        $description: String
        $descriptionEn: String
        $type: VoucherType!
        $highlight: String
        $highlightEn: String
        $contact: VoucherContactInput
        $mediaIds: [ID!]
        $status: VoucherStatus
        $startDate: DateTime!
        $endDate: DateTime!
        $referralTurns:Int
        $prizeTitle:String
        $prizeTitleEn:String
    ) {
        updateVoucher(
            _id: $_id
            data: {
                title: $title
                titleEn: $titleEn
                pointSpend: $pointSpend
                mediaIds: $mediaIds
                description: $description
                descriptionEn: $descriptionEn
                termAndCondition: $termAndCondition
                termAndConditionEn: $termAndConditionEn
                type: $type
                highlight: $highlight
                highlightEn: $highlightEn
                contact: $contact
                status: $status
                startDate: $startDate
                endDate: $endDate
                referralTurns: $referralTurns
                prizeTitle: $prizeTitle
                prizeTitleEn: $prizeTitleEn
            }
        ) {
            _id
            title
        }
    }
`;

export const CREATE_VOUCHER_CODES = gql`
    mutation createVoucherCode(
        $voucherId: ID!
        $voucherCodes: [String]
        $file: Upload
        $qty: Int
        $vendorCode: String
        $remarks: String
        $type: TypeVoucherCode
        $startDate: DateTime!
        $endDate: DateTime!
        $reCreate: Boolean
    ) {
        createVoucherCode(
            data: {
                voucherId: $voucherId
                voucherCodes: $voucherCodes
                vendorCode: $vendorCode
                file: $file
                qty: $qty
                remarks: $remarks
                type: $type
                startDate: $startDate
                endDate: $endDate
                reCreate: $reCreate
            }
        ) {
            exists
            items {
                _id
                voucherId
                code
                qty
                sold_qty
                voucher {
                    _id
                    voucherCodes {
                        code
                    }
                    highlight
                    contact {
                        phoneNumber
                        email
                    }
                }
            }
        }
    }
`;

export const CMS_GET_VOUCHER_CODE = gql`
    query cmsListVoucherCode($voucherId: ID!, $limit: Int!, $page: Int, $filter:listVoucherCodeFilter) {
        cmsListVoucherCode(voucherId: $voucherId, limit: $limit, page: $page, filter: $filter) {
            counter
            items {
                _id
                voucherId
                code
                qty
                remarks
                status
                redeemedAt
                redeemedUserId
                redeemedUser{
                    _id
                    fullName
                    phoneNumber
                }
                vendorCode
                sold_qty
                startDate
                endDate
                deletedAt
            }
        }
    }
`;

export const CMS_EXPORT_VOUCHER_CODE = gql`
    mutation exportVoucherCode($voucherId: ID!) {
        exportVoucherCode(voucherId: $voucherId)
    }
`;

export const CMS_GET_VOUCHER_LUCKY_WHEEL = gql`
    query vouchersLuckyWheel($limit: Int!, $page: Int, $filter: VoucherFilter) {
        vouchersGame(limit: $limit, page: $page, filter: $filter) {
            _id
            code
            voucherId
            highlight
            title
            pointSpend
            type
            startDate
            endDate
            enable
            qty
            available
            medias {
                _id
                uri
            }
        }
    }
`;

export const CMS_UPDATE_LUCKY_WHEEL_PRIZE = gql`
    mutation cmsUpdateLuckyWheelPrize($_id: ID!, $data: PrizeInput) {
        updateLuckyWheelPrize(_id: $_id, data: $data) {
            _id
            name
            prizeTier
        }
    }
`;


export const CMS_GET_REFERRAL_VOUCHERS = gql`
    query cmsReferralVouchers($limit:Int!, $page:Int){
        cmsReferralVouchers(limit:$limit, page:$page){
            counter
            items{
                _id
                title
                titleEn
                type
                code
                referralTurns
                status
                startDate
                endDate
                mediaIds
                medias{
                    _id
                    uri
                }
            }
        }
    }
`;

export const CMS_IMPORT_REFERRAL_PRIZE_CODES  = gql`
    mutation importReferralCodes($data:ImportReferralCodesInput!){
        importReferralCodes(data:$data){
            exists
            items{
                _id
                voucherId
                code
            }
        }
    }
`;

export const  CMS_DELETE_REFERRAL_VOUCHER_CODE = gql`
    mutation  deleteVoucherCode($_id: ID!){
        deleteVoucherCode(_id:$_id)
    }
`;

export const MY_PRIZE_REFERRAL_VOUCHER_CODES =  gql`
    query myPrizeReferralVoucherCodes($limit: Int!,$after: ID){
        myPrizeReferralVoucherCodes(limit:$limit, after:$after){
            _id
            voucherId
            code
            qty
            sold_qty
            voucher{
                title
                titleEn
            }
        }
    }
`;

export const  CMS_DELETE_VOUCHER = gql`
    mutation  deleteVoucher($_id: ID!){
        deleteVoucher(_id:$_id)
    }
`;



export const GET_CURRENT_REFERRAL_CAMPAGIN =  gql`
    query currentReferralCampaign{
        currentReferralCampaign{
            invitedNumbers
            currentCampaign{
                _id
                title
                titleEn
                prizeTitle
                prizeTitleEn
            }
        }
    }
`;

