import React, { useState } from 'react';
import { Checkbox, Col, Form, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { cmsSetPageNews, cmsSetPageNewsVariables } from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { SET_PAGE_PROVIDER_NEWS } from '../../../graphql/pageVerify/pageVerify';

interface IData {
  _id?: any;
  isHotNews?: boolean;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
  form: any;
}

const EditProviderNews = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;

  const [update] = useMutation<cmsSetPageNews, cmsSetPageNewsVariables>(SET_PAGE_PROVIDER_NEWS, {
    refetchQueries: ['CmsGetPages'],
  });

  const handleOk = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (props.data && props.data._id) {
          const { data } = props;
          update({
            variables: {
              _id: data._id,
              active: values.isHotNews,
            },
          })
            .catch(e => {
              console.log(e.toLocaleString());
            })
            .then((res: any) => {
              setShow(false);
              props.closeModal();
            });
        }
      }
    });
  };

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const { visible } = props;
  const [show, setShow] = useState(visible);
  return (
    <div>
      <Modal title={'Change provider hot news'} visible={show} onOk={handleOk} onCancel={handleCancel} okText={'Save'}>
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form>
              <Form.Item>
                {getFieldDecorator('isHotNews', {
                  valuePropName: 'checked',
                  initialValue: props.data.isHotNews,
                })(<Checkbox>Is provider hot news</Checkbox>)}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditProviderNews;
