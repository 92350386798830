import React, { useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Row,
  Select,
  Skeleton,
  Table,
  Tooltip,
} from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import {
  CMS_ADMIN_CANCEL_HANDLE_COMMENT_POST,
  CMS_ADMIN_HANDLE_COMMENT_POST,
  CMS_GET_COMMENT_REPORT,
} from '../../graphql/report/report';
import {
  CmsGetCommentReports,
  CmsGetCommentReportsVariables,
  CmsSetTermStatus,
  CmsSetTermStatusVariables,
  ReportFor,
  SortByDirection,
  SortReportType,
  TermStatus,
  setStatusPost,
  setStatusPostVariables,
} from '../../graphql/types';
import Styled from './indexStyled';
import { CMS_SET_TERM_STATUS } from '../../graphql/report/termStatus';
import { ToastError, ToastSuccess } from '../../components/Toast';
import DetailPost from '../Post/components/detail';
import CreatorDetail from '../../components/CreatorDetail';
import imagePage from '../../assets/images/page.png';
import DetailReports from './components/DetailReports';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SET_STATUS } from 'src/graphql/feed/feed';

const { dateByFormat } = require('../../helpers/date');
const { Content } = Layout;
const { Option } = Select;

const termStatusFilter = ['ALL', TermStatus.NORMAL, TermStatus.HIDDEN, TermStatus.SUSPENDING, TermStatus.BLOCKED];

const CommentReportImpl = (props: FormComponentProps) => {
  const { getFieldDecorator } = props.form;
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [creator, setCreator] = useState({
    name: null,
    fullName: null,
    email: null,
    phoneNumber: null,
    avatar: null,
    point: null,
    termStatus: null,
    logo: null,
    owner: null,
    admins: null,
    isPage: false,
  });
  const [pageParams, setPageParams] = useState<CmsGetCommentReportsVariables>({
    limit: 10,
    offset: 1,
    filter: {},
    sortDir: SortByDirection.DESC,
    sortType: SortReportType.REPORT_DATE,
  });
  const [post, setPost] = useState({
    title: null,
    medias: null,
    content: null,
    createdAt: null,
    creator: null,
    _id: null,
  });
  const [editData, setEditData] = useState<CmsSetTermStatusVariables>({
    _id: null,
    model: ReportFor.COMMENT,
    termStatus: null,
    reason: '',
  });
  const [showReports, setShowReports] = useState(false);
  const [postIdEdit, setPostIdEdit] = useState(null);
  const [listComments, setListComments] = useState([]);
  const [counter, setCounter] = useState(0);
  const [setTermStatus, {}] = useMutation<CmsSetTermStatus, CmsSetTermStatusVariables>(CMS_SET_TERM_STATUS);

  let getCommentReport = () => {
    return useQuery<CmsGetCommentReports, CmsGetCommentReportsVariables>(CMS_GET_COMMENT_REPORT, {
      fetchPolicy: 'network-only',
      variables: pageParams,
    });
  };
  let { data, error, refetch, loading } = getCommentReport();
  const [adminHandleCheck, {}] = useMutation(CMS_ADMIN_HANDLE_COMMENT_POST);
  const [adminCancelHandleCheck, {}] = useMutation(CMS_ADMIN_CANCEL_HANDLE_COMMENT_POST);

  const showDetailCreator = row => {
    setCreator(
      row.commentedAsPage
        ? {
            ...row.commentedAsPage,
            isPage: true,
          }
        : {
            ...row.creator,
            isPage: false,
          },
    );
    setShowModalDetail(true);
  };
  const closeModalDetail = () => {
    setShowModalDetail(false);
  };

  const [filterMessagesParams, setFilterMessagesParams] = useState({
    limit: 500,
    targetId: null,
    type: ReportFor.COMMENT,
  });

  const termStatusEdit = [TermStatus.NORMAL, TermStatus.BLOCKED, TermStatus.SUSPENDING];

  const handleOk = () => {
    props.form.validateFields((err, values) => {
      if (!err && values.statusUpdate !== editData.termStatus) {
        setShowModal(false);
        setTermStatus({
          variables: {
            ...editData,
            _id: editData._id,
            reason: values.reason,
            termStatus: values.statusUpdate,
          },
        })
          .catch(e => {
            ToastError({ message: 'Error', description: e.toString() });
          })
          .then(res => {
            refetch();
            ToastSuccess({ message: 'Updated', description: 'Status admin update success' });
          });
      } else {
        setShowModal(false);
      }
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const show = row => {
    setPost(row);
    setIsShow(true);
  };

  const close = () => {
    setIsShow(false);
  };
  const checkHandle = (_id: string, checked: boolean) => {
    if (checked) {
      adminHandleCheck({
        variables: {
          id: _id,
        },
        refetchQueries: [
          {
            query: CMS_GET_COMMENT_REPORT,
            variables: {
              limit: pageParams.limit,
            },
          },
        ],
      });
    } else {
      adminCancelHandleCheck({
        variables: {
          id: _id,
        },
        refetchQueries: [
          {
            query: CMS_GET_COMMENT_REPORT,
            variables: {
              limit: pageParams.limit,
            },
          },
        ],
      });
    }
  };

  const onChangeTable = (pagination, filters, sorter) => {
    setPageParams({
      ...pageParams,
      sortType: sorter.field
        ? sorter.field === 'lastReportAt'
          ? SortReportType.REPORT_DATE
          : SortReportType.REPORT_TOTAL
        : SortReportType.REPORT_DATE,
      sortDir: sorter.order === 'ascend' ? SortByDirection.ASC : SortByDirection.DESC,
    });
  };

  const adminHandleReport = (e, id) => {
    let { checked } = e.target;
    checkHandle(id, checked);
  };

  const setEditStatus = data => {
    let { reason } = data;
    reason = reason ? JSON.parse(reason) : [];
    setFilterMessagesParams({
      ...filterMessagesParams,
      targetId: data._id,
    });
    setEditData({
      ...editData,
      _id: data._id,
      termStatus: data.termStatus || TermStatus.NORMAL,
      reason: (reason && reason[0] && reason[0].reason) || '',
    });
    setShowModal(true);
  };

  const handleShowReport = pageId => {
    setPostIdEdit(pageId);
    setShowReports(true);
  };
  const closeReport = () => {
    setPostIdEdit(null);
    setShowReports(false);
  };

  const handleChangeFilterStatus = value => {
    if (value !== termStatusFilter[0]) {
      setPageParams({
        ...pageParams,
        offset: 1,
        filter: {
          ...pageParams.filter,
          termStatus: value,
        },
      });
    } else {
      const newFilter = pageParams.filter;
      delete newFilter.termStatus;
      setPageParams({
        ...pageParams,
        offset: 1,
        filter: newFilter,
      });
      refetch();
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      width: '20%',
    },
    {
      title: 'Status',
      render: row => {
        let type: any = 'default';
        if (row.termStatus == TermStatus.SUSPENDING) type = 'primary';
        if (row.termStatus == TermStatus.BLOCKED) type = 'danger';
        return (
          <Button className="btn-rectangle" type={type} size="small" onClick={e => setEditStatus(row)}>
            {row.termStatus || TermStatus.NORMAL}
          </Button>
        );
      },
    },
    {
      title: 'Creator',
      render: row => {
        return (
          <div className="link" onClick={() => showDetailCreator(row)}>
            {row.commentedAsPage ? row.commentedAsPage.name : row.creator && row.creator.fullName}
            {row.commentedAsPage && <img className="imagePage" src={imagePage} alt="" />}
          </div>
        );
      },
    },
    {
      title: 'Last report at',
      dataIndex: 'lastReportAt',
      sorter: true,
      render: row => {
        return <>{row && dateByFormat(row)}</>;
      },
    },
    {
      title: 'Total report',
      dataIndex: 'totalReports',
      sorter: true,
    },
    {
      title: 'Handled',
      key: 'isHandle',
      render: row => {
        return <Checkbox onChange={e => adminHandleReport(e, row._id)} checked={row.isAdminReport} />;
      },
    },
    {
      title: 'Action',
      key: 'operation',
      width: 150,
      render: row => {
        return (
          <div className="d-flex justify-content-around">
            <Button type="default" icon="eye" size="small" onClick={e => show(row)} />
            <Tooltip title="History reports">
              <FontAwesomeIcon
                onClick={() => {
                  handleShowReport(row._id);
                }}
                icon={['fas', 'layer-group']}
                size="2x"
                className="action-icon history-report"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (data && data.cmsGetCommentReports.items && data.cmsGetCommentReports.items.length) {
      setListComments(data.cmsGetCommentReports.items);
      setCounter(data.cmsGetCommentReports.total);
    } else {
      setListComments([]);
      setCounter(0);
    }
  }, [data]);

  const onChangePage = page => {
    setPageParams({ ...pageParams, offset: page });
  };

  const onShowSizeChange = (current, pageSize) => {
    let cOffset = counter / pageSize;
    let cOffsetUpward = Math.ceil(cOffset);
    let nOffset = pageParams.offset;
    if (cOffsetUpward < pageParams.offset) {
      nOffset = cOffsetUpward;
    }

    setPageParams({ ...pageParams, limit: pageSize, offset: nOffset });
  };

  const [setStatus] = useMutation<setStatusPost, setStatusPostVariables>(SET_STATUS, {
    refetchQueries: ['GetPosts'],
  });
  const [statusPost, setStatusPost] = useState();
  const changeEditStatus = () => {
    setStatus({ variables: { id: post._id, status: statusPost } });
  };
  const handleChangeStatus = () => {
    close();
    statusPost && changeEditStatus();
  };
  useEffect(() => {
    refetch();
  }, [pageParams]);

  if (error) return <Content className="content">Error</Content>;

  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Comment reports</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetCommentReports && data.cmsGetCommentReports.total) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div>
            <Row gutter={10}>
              <Col span={4}>
                <Form.Item label="Status">
                  <Select
                    className="w100"
                    showSearch
                    value={pageParams.filter.termStatus || termStatusFilter[0]}
                    defaultValue={termStatusFilter[0]}
                    onChange={handleChangeFilterStatus}
                  >
                    {termStatusFilter.map((termStatus, index) => {
                      return (
                        <Option key={index} value={termStatus}>
                          {' '}
                          {termStatus}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ backgroundColor: '#fff' }}>
              <Table
                rowKey="_id"
                columns={columns}
                dataSource={listComments}
                pagination={false}
                onChange={onChangeTable}
              />

              <Pagination
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={pageParams.offset}
                total={counter}
                defaultPageSize={pageParams.limit}
                onChange={onChangePage}
                style={{
                  marginTop: 20,
                  right: 0,
                }}
              />
            </Row>
          </div>
        )}
      </Content>
      <Modal
        width={768}
        title="Change comment report status"
        visible={showModal}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        {showModal && (
          <div>
            <Form>
              <Form.Item label="">
                {getFieldDecorator('statusUpdate', {
                  initialValue: editData.termStatus,
                })(
                  <Select placeholder="Select a option and change input text above">
                    {termStatusEdit.map((r, i) => {
                      return (
                        <Option disabled={r == editData.termStatus} key={i} value={r}>
                          {r}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
              {
                <Form.Item label="">
                  {getFieldDecorator('reason', {
                    initialValue: editData.reason,
                    rules: [{ required: true, message: 'Please input reason!' }],
                  })(<Input placeholder="Reason" />)}
                </Form.Item>
              }
            </Form>
          </div>
        )}
      </Modal>

      {showModalDetail && creator && (
        <Modal
          width={768}
          title="Creator detail"
          visible={showModalDetail}
          okText="Close"
          onOk={() => closeModalDetail()}
          onCancel={() => closeModalDetail()}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <CreatorDetail creator={creator} />
        </Modal>
      )}

      {isShow && post && (
        <Modal
          width={1024}
          title="Comment Detail"
          visible={isShow}
          okText="Ok"
          onOk={() => handleChangeStatus()}
          onCancel={() => close()}
        >
          <DetailPost
            title={post.title}
            content={post.content}
            medias={post.medias}
            creator={post.creator}
            createdAt={post.createdAt}
            post={post}
            setStatusPost={setStatusPost}
          />
        </Modal>
      )}

      <DetailReports refId={postIdEdit} close={closeReport} for={ReportFor.COMMENT} visible={showReports} />
    </Styled.Container>
  );
};

const CommentReport = Form.create()(CommentReportImpl);
export default CommentReport;
