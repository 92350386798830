import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Modal, Row, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormComponentProps } from 'antd/lib/form';
import UploadVoucher from './compoments/Uploads';
import { CMS_DELETE_MENU_BANNER, CMS_GET_MENU_BANNER } from '../../graphql/banner/banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ToastError, ToastSuccess } from '../../components/Toast';
import { cmsDeleteBannerMenu, cmsDeleteBannerMenuVariables, MediaType } from '../../graphql/types';

const { confirm } = Modal;

interface BannerInterface {
  display ?:MediaType,
  download ?: MediaType
}

const CreateBanner = (props: FormComponentProps) => {
  const refBannerApp = useRef(null);
  const refBannerDownload = useRef(null);
  const [banner,setBanner] = useState<BannerInterface>();
  const [loading, setLoading] = useState({
    [MediaType.MENU_BANNER_DISPLAY] : false,
    [MediaType.MENU_BANNER_DOWNLOAD] : false,
  });

  const {data, refetch} =  useQuery(CMS_GET_MENU_BANNER,{
    fetchPolicy :'network-only'
  });


  const [deleteBannerMenuAPI] = useMutation<cmsDeleteBannerMenu,cmsDeleteBannerMenuVariables >(CMS_DELETE_MENU_BANNER,{
    refetchQueries: ['getMenuBanner']
  });


  const actionDeleteBanner = (bannerType) => {
    setLoading({...loading,[bannerType]:true});
    deleteBannerMenuAPI({
      variables:{
        bannerType
      }
    }).then(()=>{
      ToastSuccess({ message: 'Deleted success', description: 'Success' });
      setLoading({...loading,[bannerType]:false});
    }).catch(()=>{
      ToastError({ message: 'Deleted failed', description: 'Please try later!' });
      setLoading({...loading,[bannerType]:false});
    });

}

  const   showDeleteBannerMenuConfirm = () => {
    confirm({
      title: 'Confirm delete',
      content: "Are you sure you want to delete this banner?",
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {actionDeleteBanner(MediaType.MENU_BANNER_DISPLAY)},
      onCancel() {},
    });
  };
  const   showDeleteBannerDownloadConfirm = () => {
    confirm({
      title: 'Confirm delete',
      content: "Are you sure you want to delete this banner?",
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {actionDeleteBanner(MediaType.MENU_BANNER_DOWNLOAD)},
      onCancel() {},
    });
  };

  const handleSubmitDisplayBanner = e => {
    e.preventDefault();setLoading({...loading,[MediaType.MENU_BANNER_DISPLAY]:true});
     props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof refBannerApp.current.upload === 'function') {
          refBannerApp.current.upload();
        }
      }
    });
  };

  const handleSubmitDownloadBanner = e => {
    e.preventDefault();setLoading({...loading,[MediaType.MENU_BANNER_DOWNLOAD]:true});
     props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof refBannerDownload.current.upload === 'function') {
          refBannerDownload.current.upload();
        }
      }
    });
  };

  useEffect(()=>{
    if(data && data.getMenuBanner){
      setBanner({
        display:data.getMenuBanner.display,
        download : data.getMenuBanner.download
      })
    }else{
      setBanner(null);
    }
  },[data]);

  const afterUploadBannerApp = (mediaId: [string]) => {
    setLoading({...loading,[MediaType.MENU_BANNER_DISPLAY]:false});
    if(mediaId && mediaId.length){
      ToastSuccess({ message: 'Updated', description: 'Success' });
      refetch();
    }else{
      ToastError({ message: 'Upload failed', description: 'Please select image!' });
    }
  };

  const afterUploadBannerDownload = (mediaId: [string]) => {
    setLoading({...loading,[MediaType.MENU_BANNER_DOWNLOAD]:false});
    if(mediaId && mediaId.length){
      ToastSuccess({ message: 'Updated', description: 'Success' });
      refetch();
    }else{
      ToastError({ message: 'Upload failed', description: 'Please select image!' });
    }
  };

  return (
    <Row className="upload-banner-menu" type="flex" align="middle">
      <Col span={4} style={{ alignItems: 'flex-end' }}>
        <label><strong>Banner on menu app</strong></label>
        <UploadVoucher
          ref={refBannerApp}
          onUploaded={(data: any) => afterUploadBannerApp(data)}
          images={banner && banner.display ? [banner.display] : []}
          bannerType={MediaType.MENU_BANNER_DISPLAY}
          numberImagesAllowed={1}
          maxSize={(200 * 1024).toString()}
          unitSize="kb"
          imageAccept=""
        />
      </Col>

      <Col span={2}>
        <div className="d-flex justify-content-around">
          { loading[MediaType.MENU_BANNER_DISPLAY] && <LoadingOutlined twoToneColor="#3f97f1"  /> }
          <Tooltip title="Upload">
            <FontAwesomeIcon
              onClick={handleSubmitDisplayBanner}
              icon={['fas', 'upload']}
              size="2x"
              className="action-icon upload"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <FontAwesomeIcon
              onClick={showDeleteBannerMenuConfirm}
              icon={['fas', 'trash-alt']}
              size="2x"
              className="action-icon delete"
            />
          </Tooltip>
        </div>
      </Col>
      <Col span={4} offset={4} style={{ alignItems: 'flex-end' }}>
        <label><strong>Banner for download</strong></label>
        <UploadVoucher
          ref={refBannerDownload}
          onUploaded={(data: any) => afterUploadBannerDownload(data)}
          images={banner && banner.download ? [banner.download] : []}
          bannerType={MediaType.MENU_BANNER_DOWNLOAD}
          numberImagesAllowed={1}
          maxSize={(1024 * 1024).toString()}
          unitSize="mb"
          imageAccept=""
        />
      </Col>

      <Col span={2}>
        <div className="d-flex justify-content-around">
          { loading[MediaType.MENU_BANNER_DOWNLOAD] && <LoadingOutlined twoToneColor="#3f97f1"  /> }
          <Tooltip title="Upload">
            <FontAwesomeIcon
              onClick={handleSubmitDownloadBanner}
              icon={['fas', 'upload']}
              size="2x"
              className="action-icon upload"
            />
          </Tooltip>
          <Tooltip title="Delete">
            <FontAwesomeIcon
              onClick={showDeleteBannerDownloadConfirm}
              icon={['fas', 'trash-alt']}
              size="2x"
              className="action-icon delete"
            />
          </Tooltip>
        </div>
      </Col>
    </Row>
  );
};

const CreateMenuBanner = Form.create()(CreateBanner);
export default CreateMenuBanner;
