import React, { useEffect, useRef, useState } from 'react';
import {
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    Layout,
    Modal,
    Pagination,
    Popconfirm,
    Row,
    Select,
    Skeleton,
    Table,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import {
    CREATE_BADGE_REGISTER_FORM,
    DELETE_BADGE_REGISTER_FORM,
    GET_BADGE_REGISTER_FORM,
    UPDATE_BADGE_REGISTER_FORM,
} from '../../graphql/badge/badge';
import {
    BadgeInputType,
    BadgeRegisterFormSortByType,
    cmsCreateBadgeRegisterForm,
    cmsCreateBadgeRegisterFormVariables,
    cmsDeleteBadgeRegisterForm,
    cmsDeleteBadgeRegisterFormVariables,
    cmsListBadgeRegisterForms,
    cmsListBadgeRegisterFormsVariables,
    cmsUpdateBadgeRegisterForm,
    cmsUpdateBadgeRegisterFormVariables,
    SortByDirection,
    BadgeFilterRequire,
    BadgeFilterIputType,
} from '../../graphql/types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ToastError, ToastSuccess } from '../../components/Toast';
import UploadImage from './component/Uploads';

const { Search } = Input;

const { Content } = Layout;
const { Option } = Select;

const filterInputType = [BadgeFilterIputType.ALL,BadgeFilterIputType.IMAGE,BadgeFilterIputType.TEXT];
const filterRequire = [BadgeFilterRequire.ALL,BadgeFilterRequire.YES, BadgeFilterRequire.NO];

interface IEditProps extends FormComponentProps {
    match?: any;
}

const RegisterFormImpl = (props: IEditProps) => {
    const { getFieldDecorator } = props.form;
    const [isLoading, setLoading] = useState(true);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [visibilityAddOrEdit, setVisibilityAddOrEdit] = useState(false);
    const [previewUrl, setPreviewUrl] = useState('');
    const [badgeForms, setBadgeForm] = useState([]);
    const [total,setTotal] = useState(0);

    const refs = useRef(null);

    const [pageParams, setPageParams] = useState({
        limit: 10,
        page:1,
        filter:{
            require:BadgeFilterRequire.ALL,
            inputType:BadgeFilterIputType.ALL
        },
        sortBy: {
            type: BadgeRegisterFormSortByType.CREATED_AT,
            dir: SortByDirection.DESC
        },
        q: ""
    });
    const [editRow, setEditRow] = useState(null);

    const [createBadgeRegisterForm] = useMutation<cmsCreateBadgeRegisterForm, cmsCreateBadgeRegisterFormVariables>(
        CREATE_BADGE_REGISTER_FORM,
        {
            refetchQueries: ['cmsListBadgeRegisterForms'],
        },
    );

    const [updateBadgeRegisterForm] = useMutation<cmsUpdateBadgeRegisterForm, cmsUpdateBadgeRegisterFormVariables>(
        UPDATE_BADGE_REGISTER_FORM,
        {
            refetchQueries: ['cmsListBadgeRegisterForms'],
        },
    );

    const [deleteBadgeRegisterForm] = useMutation<cmsDeleteBadgeRegisterForm, cmsDeleteBadgeRegisterFormVariables>(
        DELETE_BADGE_REGISTER_FORM,
        {
            refetchQueries: ['cmsListBadgeRegisterForms'],
        },
    );

    const getBadgeRegisterForms = () => {
        return useQuery<cmsListBadgeRegisterForms, cmsListBadgeRegisterFormsVariables>(GET_BADGE_REGISTER_FORM, {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            variables: pageParams,
        });
    };
    let { data, loading,refetch } = getBadgeRegisterForms();

    useEffect(()=>{

    },[data]);

    const columns = [
        {
            title: 'STT',
            key: 'index',
            render: (text, record, index) => (pageParams.page - 1)*pageParams.limit + index + 1,
        },
        {
            title: 'Title',
            dataIndex: 'detail',
            sorter: false,
        },
        {
            title: 'Title EN',
            dataIndex: 'detailEn',
            sorter: false,
        },
        {
            title: 'Input type',
            dataIndex: 'inputType',
            sorter: true,
            render: (inputType) => {
                return (
                    <Button className="btn-rectangle-type" type="default" size="small">{inputType}</Button>
                )
            },
        },
        {
            title: 'Require',
            dataIndex: 'required',
            sorter: true,
            render: (required) => {
                let btnType = required ? "primary" : "danger";
                let txt = required ? "Yes" : "No";
                return (
                    // @ts-ignore
                    <Button className="btn-rectangle-type" type={btnType} size="small">{txt}</Button>
                )
            },
        },
        {
            title: 'Image',
            dataIndex: 'figure',
            sorter: false,
            render: figure => {
                if (figure && figure.uri) {
                    return (
                        <div className="thumb">
                            <img src={figure.uri} alt="" />
                        </div>
                    );
                }
                return <>{"-"}</>
            },
        },
        {
            title: 'Action',
            dataIndex: '',
            render: row => {
                return (
                    <div style={{
                        display: 'flex',
                        // justifyContent: 'space-between',
                    }}>
                        <Button
                            type="primary"
                            icon="edit"
                            size="small"
                            onClick={() => {
                                setVisibilityAddOrEdit(true);
                                setEditRow(row);
                            }}
                        />

                        <Popconfirm
                            placement="left"
                            title={"Are you sure to delete this badge?"}
                            onConfirm={e => deleteConfirm(row._id)} okText="Yes" cancelText="No">
                            <Button
                                type="danger"
                                icon="delete"
                                size="small"
                                style={{ marginLeft: 20 }} />
                        </Popconfirm>

                    </div>
                );
            },
        },
    ];

    const deleteConfirm = id => {
        deleteBadgeRegisterForm({
            variables: {
                _id: id,
            },
        }).then(res => {
            ToastSuccess({ message: 'Delete', description: 'Xoá thành công!' });
        }).catch((error) => {
            ToastError({ message: 'Delete', description: 'Không thể xoá!' });
        });
    };

    const handleOk = () => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err && values) {
                if (values.title.trim() == '') {
                    ToastError({
                        message: 'Error.',
                        description: `Title không được để trống`,
                    });
                    return;
                }

                if (values.titleEn.trim() == '') {
                    ToastError({
                        message: 'Error.',
                        description: `Title EN không được để trống`,
                    });
                    return;
                }

                setConfirmLoading(true);
                if (values.inputType == BadgeInputType.IMAGE) {
                    if (typeof refs.current.upload === 'function') {
                        refs.current.upload();
                    } else {
                        setConfirmLoading(false);
                    }
                } else {
                    let variables = {
                        detail: values.title,
                        detailEn: values.titleEn,
                        inputType: values.inputType,
                        required: values.require == 'Yes' ? true : false,
                    }
                    if (editRow) {
                        updateBadgeRegisterForm({
                            variables: {
                                _id: editRow._id,
                                ...variables
                            }
                        }).then(res => {
                            onUpdateSuccess(true);
                        }).catch(err => {
                            onUpdateError(true);
                        });
                    } else {
                        createBadgeRegisterForm({
                            variables: variables
                        }).then(res => {
                            onUpdateSuccess(false);
                        }).catch(err => {
                            onUpdateError(false);
                        });
                    }
                }
            }
        });
    };

    const afterImageUpload = (mediaIds) => {
        props.form.validateFieldsAndScroll((err, values) => {
            if (!err && values) {
                let variables = {
                    detail: values.title,
                    detailEn: values.titleEn,
                    inputType: values.inputType,
                    required: values.require == 'Yes' ? true : false,
                }
                if (mediaIds.length > 0) {
                    variables['figureId'] = mediaIds[0];
                }
                if (editRow) {
                    updateBadgeRegisterForm({
                        variables: {
                            _id: editRow._id,
                            ...variables
                        }
                    }).then(res => {
                        onUpdateSuccess(true);
                    }).catch(err => {
                        onUpdateError(true);
                    });
                } else {
                    createBadgeRegisterForm({
                        variables: variables
                    }).then(res => {
                        onUpdateSuccess(false);
                    }).catch(err => {
                        onUpdateError(false);
                    });
                }
            }
        });
    };

    const onUpdateSuccess = (isEdit) => {
        let title = isEdit ? 'Updated!' : 'Created!';
        let msg = isEdit ? 'Form update success!' : 'Form create success!';
        ToastSuccess({ message: title, description: msg });
        setVisibilityAddOrEdit(false);
        setConfirmLoading(false);
    }

    const onUpdateError = (isEdit) => {
        let title = isEdit ? 'Error!' : 'Created!';
        let msg = isEdit ? 'Form update failed!' : 'Form create failed!';
        ToastError({ message: title, description: msg });
        setVisibilityAddOrEdit(false);
        setConfirmLoading(false);
    }

    const handleCancel = () => {
        setVisibilityAddOrEdit(false);
    };

    useEffect(() => {
        if (loading) setLoading(true);
        setLoading(false);
    }, [loading]);

    const search = (value) => {
        setPageParams({
            ...pageParams,
            q: value,
            // offset: 1
        });
    };

    useEffect(() => {
        if (data && data.cmsListBadgeRegisterForms.items && data.cmsListBadgeRegisterForms.counter > 0) {
            setBadgeForm(data.cmsListBadgeRegisterForms.items);
            setTotal(data.cmsListBadgeRegisterForms.counter);
        } else {
            setBadgeForm([]);
            setTotal(0);
        }
    }, [data]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 18 },
        },
    };

    const handleChangeTable = (pagination, filters,sorter) => {
        if(sorter.order){
            switch (sorter.columnKey) {
                case "inputType":{
                    setPageParams({...pageParams,sortBy:{
                            type:BadgeRegisterFormSortByType.INPUT_TYPE,
                            dir: sorter.order === "ascend" ? SortByDirection.ASC : SortByDirection.DESC
                        }});
                    break;
                }
                case "required" :{
                    setPageParams({...pageParams,sortBy:{
                            type:BadgeRegisterFormSortByType.REQUIRED,
                            dir: sorter.order === "ascend" ? SortByDirection.ASC : SortByDirection.DESC
                        }});
                    break;
                }
            }
        }
    };

    const handleChangeFilterRequiredType = (value) => {
        setPageParams({
          ...pageParams,
            filter: {
              ...pageParams.filter,
              require: value
            }
        });
        refetch();
    };

    const handleChangeFilterInputType = (value) => {
        setPageParams({
          ...pageParams,
            filter: {
                ...pageParams.filter,
                inputType: value
            }
        })
        refetch();
    };

    const onChangePage = (page) => {
        console.log("page==>",page);
        setPageParams({
          ...pageParams,
            page
        })

    };

    const _renderAddOrEditModal = () => {
        let title = "";
        let titleEn = "";
        let inputType = "";
        let required = "";
        let showImage = false;
        let defaultImg = null;
        if (editRow) {
            title = editRow.detail;
            titleEn = editRow.detailEn;
            inputType = editRow.inputType;
            required = editRow.required ? "Yes" : "No";
            showImage = inputType == BadgeInputType.IMAGE;
            if (editRow.figure && editRow.figure._id && editRow.figure.uri) {
                defaultImg = {};
                defaultImg['id'] = editRow.figure._id;
                defaultImg['url'] = editRow.figure.uri;
            }
        }
        let it = props.form.getFieldValue('inputType');
        if (it) {
            showImage = it == BadgeInputType.IMAGE
        }
        return (
            <Modal
                title="Update"
                width={1024}
                visible={visibilityAddOrEdit}
                onOk={() => handleOk()}
                confirmLoading={confirmLoading}
                onCancel={() => handleCancel()}
            >
                {visibilityAddOrEdit && (
                    <Styled.Container>
                        {showImage && <Row>
                            <Col offset={4}>
                                <UploadImage
                                    ref={refs}
                                    default={defaultImg}
                                    onUploaded={(data: any) => afterImageUpload(data)} />
                            </Col>
                        </Row>}
                        <Form {...formItemLayout}>
                            <Form.Item label="Title">
                                {getFieldDecorator('title', {
                                    initialValue: title,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input your Title!',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>

                            <Form.Item label="Title EN">
                                {getFieldDecorator('titleEn', {
                                    initialValue: titleEn,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input your Title EN!',
                                        },
                                    ],
                                })(<Input />)}
                            </Form.Item>

                            <Form.Item label="Input type">
                                {getFieldDecorator('inputType', {
                                    initialValue: inputType,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please choose input type!',
                                        },
                                    ],
                                })(
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Select input type"
                                    >
                                        <Option key={"image"} value={BadgeInputType.TEXT}>
                                            {BadgeInputType.TEXT}
                                        </Option>
                                        <Option key={"text"} value={BadgeInputType.IMAGE}>
                                            {BadgeInputType.IMAGE}
                                        </Option>
                                    </Select>
                                )}

                            </Form.Item>

                            <Form.Item label="Require">
                                {getFieldDecorator('require', {
                                    initialValue: required,
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please choose require!',
                                        },
                                    ],
                                })(
                                    <Select
                                        style={{ width: '100%' }}
                                        placeholder="Select require"
                                    >
                                        <Option key={"yes"} value={"Yes"}>
                                            {"Yes"}
                                        </Option>

                                        <Option key={"no"} value={"No"}>
                                            {"No"}
                                        </Option>
                                    </Select>
                                )}


                            </Form.Item>
                        </Form>

                        <Modal visible={previewUrl != ''} footer={null} onCancel={() => setPreviewUrl('')}>
                            <img alt="" style={{ width: '100%' }} src={previewUrl} />
                        </Modal>
                    </Styled.Container>
                )}
            </Modal>
        )
    }

    return (
        <Styled.Container>
            <Content className="content">
                <Breadcrumb>
                    <Breadcrumb.Item>Register Form</Breadcrumb.Item>
                </Breadcrumb>

                <Row
                    className="mt-10 mb-10"
                    gutter={16}
                    type="flex"
                    style={{ alignItems: 'flex-end' }}>
                    <Col span={5}>
                        <Form.Item label="Required">
                            <Select
                              className="w100"
                              showSearch
                              value={pageParams.filter.require}
                              defaultValue={BadgeFilterRequire.ALL}
                              onChange={handleChangeFilterRequiredType}
                            >
                                {filterRequire.map((filterType, index) => {
                                    return (<Option key={index} value={filterType}> {filterType} </Option>);
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Type">
                            <Select
                              className="w100"
                              showSearch
                              value={pageParams.filter.inputType}
                              defaultValue={BadgeFilterIputType.ALL}
                              onChange={handleChangeFilterInputType}
                            >
                                {filterInputType.map((filterType, index) => {
                                    return (<Option key={index} value={filterType}> {filterType} </Option>);
                                })}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={9}>
                        <Form.Item label="Search">
                            <Search
                                placeholder="search"
                                enterButton="Search"
                                onSearch={value => search(value)} />
                        </Form.Item>
                    </Col>

                    <Col
                        span={5}
                        style={{ textAlign: 'right' }} >
                        <Form.Item>
                            <Button
                                type="primary"
                                onClick={() => {
                                    setVisibilityAddOrEdit(true);
                                    setEditRow(null);
                                }}>{"Add New"}</Button>
                        </Form.Item>
                    </Col>
                </Row>

                <p style={{ marginTop: 15 }}>
                    <strong>Total: {(data && data.cmsListBadgeRegisterForms && data.cmsListBadgeRegisterForms.counter) || 0}</strong>
                </p>

                {isLoading ? (
                    <Skeleton loading={isLoading} paragraph={{ rows: 5 }}></Skeleton>
                ) : (
                        <Row style={{ backgroundColor: '#fff' }}>
                            <Table
                                rowKey="_id"
                                columns={columns}
                                dataSource={badgeForms}
                                onChange={handleChangeTable}
                                pagination={false} />
                            <Pagination
                              total={total}
                              defaultPageSize={pageParams.limit}
                              onChange={onChangePage}
                            />
                        </Row>
                    )}
            </Content>

            {_renderAddOrEditModal()}
        </Styled.Container>
    );
};

const RegisterForm = Form.create()(RegisterFormImpl);
export default RegisterForm;
