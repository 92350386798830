import React, { useState } from 'react';
import { Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import {
  CmsBlockPage,
  CmsBlockPageVariables,
  CmsSetPagePartner,
  CmsSetPagePartnerVariables,
  ReportFor,
  TermStatus,
} from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { CMS_BLOCK_PAGE, SET_PAGE_PARTNER } from '../../../graphql/pageVerify/pageVerify';

interface IData {
  _id?: any;
  isPartner?: boolean;
  isBlock?: boolean;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  refetchParent?:any,
  data?: IData;
  form: any;
}

const EditConfig = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;

  const [update] = useMutation<CmsSetPagePartner, CmsSetPagePartnerVariables>(SET_PAGE_PARTNER, {
    refetchQueries: ['CmsGetPages'],
  });

  const [block] = useMutation<CmsBlockPage, CmsBlockPageVariables>(CMS_BLOCK_PAGE, { refetchQueries: ['CmsGetPages'] });

  const handleOk = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (props.data && props.data._id) {
          const { data } = props;

          if (data.isBlock) {
            if (values.reason && !values.reason.trim()) {
              props.form.setFields({
                reason: {
                  value: values.reason,
                  errors: [new Error('Please input reason')],
                },
              });
            } else {
              block({
                variables: {
                  _id: data._id,
                  model: ReportFor.PAGE,
                  termStatus: TermStatus.BLOCKED,
                  reason: values.reason,
                },
              })
                .catch(e => {
                  console.log(e.toLocaleString());
                })
                .then((res: any) => {
                  props.refetchParent();
                  setShow(false);
                  props.closeModal();
                });
            }
          } else {
            update({
              variables: {
                _id: data._id,
                status: values.isPartner,
              },
            })
              .catch(e => {
                console.log(e.toLocaleString());
              })
              .then((res: any) => {
                setShow(false);
                props.closeModal();
              });
          }
        }
      }
    });
  };

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const { visible } = props;
  const [show, setShow] = useState(visible);
  return (
    <div>
      <Modal
        title={props.data.isBlock ? 'Reason to block page' : 'Change status partner'}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Save'}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form>
              {props.data.isBlock ? (
                <Form.Item label={'Reason'}>
                  {getFieldDecorator(`reason`, {
                    rules: [
                      {
                        required: true,
                        message: 'Please input reason',
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              ) : (
                <Form.Item>
                  {getFieldDecorator('isPartner', {
                    valuePropName: 'checked',
                    initialValue: props.data.isPartner,
                  })(<Checkbox>Is partner</Checkbox>)}
                </Form.Item>
              )}
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditConfig;
