import { gql } from 'apollo-boost';

export const GET_BADGE_REGISTER_FORM = gql`
  query cmsListBadgeRegisterForms(
    $limit: Int
    $page: Int
    $sortBy: BadgeRegisterFormSortBy
    $filter: BadgeFilterType
    $q: String
  ) {
    cmsListBadgeRegisterForms(limit: $limit, page: $page, sortBy: $sortBy, filter: $filter, q: $q) {
      counter
      items {
        _id
        detail
        detailEn
        inputType
        required
        figure {
          _id
          thumbnail
          uri
        }
      }
    }
  }
`;

export const CREATE_BADGE_REGISTER_FORM = gql`
  mutation cmsCreateBadgeRegisterForm(
    $detail: String!
    $detailEn: String
    $inputType: BadgeInputType!
    $required: Boolean!
    $figureId: ID
  ) {
    cmsCreateBadgeRegisterForm(
      data: { detail: $detail, detailEn: $detailEn, inputType: $inputType, required: $required, figureId: $figureId }
    ) {
      detail
      inputType
      required
    }
  }
`;

export const UPDATE_BADGE_REGISTER_FORM = gql`
  mutation cmsUpdateBadgeRegisterForm(
    $_id: ID!
    $detail: String!
    $detailEn: String
    $inputType: BadgeInputType!
    $required: Boolean!
    $figureId: ID
  ) {
    cmsUpdateBadgeRegisterForm(
      _id: $_id
      data: { detail: $detail, detailEn: $detailEn, inputType: $inputType, required: $required, figureId: $figureId }
    ) {
      _id
    }
  }
`;

export const DELETE_BADGE_REGISTER_FORM = gql`
  mutation cmsDeleteBadgeRegisterForm($_id: ID!) {
    cmsDeleteBadgeRegisterForm(_id: $_id)
  }
`;

export const GET_BADGES = gql`
  query cmsListBadges($limit: Int, $page: Int, $filter: BadgeFilter, $sortBy: BadgeSortBy, $q: String) {
    cmsListBadges(limit: $limit, page: $page, filter: $filter, sortBy: $sortBy, q: $q) {
      counter
      items {
        _id
        title
        titleEn
        status
        media {
          _id
          thumbnail
          uri
        }
        registerForms {
          _id
          detail
          inputType
          required
        }
      }
    }
  }
`;

export const GET_BADGE = gql`
  query getBadge($_id: ID!) {
    getBadge(_id: $_id) {
      title
      titleEn
      status
      media {
        _id
        thumbnail
        uri
      }
      registerForms {
        _id
        detail
        inputType
        required
        figureId
        figure {
          _id
          thumbnail
          uri
        }
      }
    }
  }
`;

export const CREATE_BADGE = gql`
  mutation cmsCreateBadge(
    $title: String!
    $titleEn: String
    $registerFormIds: [ID!]
    $status: BadgeStatus!
    $mediaId: ID!
  ) {
    cmsCreateBadge(
      data: { title: $title, titleEn: $titleEn, registerFormIds: $registerFormIds, status: $status, mediaId: $mediaId }
    ) {
      _id
    }
  }
`;

export const DELETE_BADGE = gql`
  mutation cmsDeleteBadge($_id: ID!) {
    cmsDeleteBadge(_id: $_id)
  }
`;

export const UPDATE_BADGE = gql`
  mutation cmsUpdateBadge(
    $_id: ID!
    $title: String!
    $titleEn: String
    $status: BadgeStatus!
    $mediaId: ID!
    $registerFormIds: [ID!]
  ) {
    cmsUpdateBadge(
      _id: $_id
      data: { title: $title, titleEn: $titleEn, status: $status, mediaId: $mediaId, registerFormIds: $registerFormIds }
    ) {
      _id
    }
  }
`;

export const GET_REQUEST_BADGES = gql`
  query cmsListRequestBadges(
    $limit: Int!
    $page: Int
    $filter: RequestBadgeFilter
    $sortBy: BadgeRequestFormSortBy
    $q: String
  ) {
    cmsListRequestBadges(limit: $limit, page: $page, filter: $filter, sortBy: $sortBy, q: $q) {
      counter
      items {
        _id
        title
        status
        creator {
          fullName
        }
        phoneNumber
        createdAt
      }
    }
  }
`;

export const GET_REQUEST_BADGE = gql`
  query getRequestBadge($_id: ID!) {
    getRequestBadge(_id: $_id) {
      title
      status
      creator {
        fullName
      }
      _id
      phoneNumber
      createdAt
      requestForms {
        detail
        inputType
        required
        mediaId
        media {
          uri
          thumbnail
          _id
        }
        text
      }
    }
  }
`;

export const SET_REQUEST_BADGE_STATUS = gql`
  mutation cmsBadgeSetStatus($_id: ID!, $status: RequestBadgeStatus!) {
    cmsBadgeSetStatus(_id: $_id, status: $status)
  }
`;
