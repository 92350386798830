import React, { useEffect, useState } from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Button, Col, Form, Input, Radio, Row, Select } from 'antd';
import { ToastError } from '../../../components/Toast';
import {
  PrizeInput,
  vouchersLuckyWheel,
  vouchersLuckyWheelVariables,
} from '../../../graphql/types';
import { useQuery } from '@apollo/react-hooks';
import { CMS_GET_VOUCHER_LUCKY_WHEEL } from '../../../graphql/voucher/voucher';

const { Option } = Select;


interface Props extends FormComponentProps {
  type: string,
  numberBatch: number
  campaignRange: any
  onClose: any
  onHandleAddPrize: any
  // luckyVouchers:vouchersLuckyWheel_vouchersGame[]
}

interface outputProps extends PrizeInput {
  voucherName: String
  voucherMedia?: {_id:String,uri:String},
  qtyForEachBatch: number[]
}

const POINT = 'POINT';
const VOUCHER = 'VOUCHER';
const typePrize = { POINT, VOUCHER };

const AddPrizeTmpl = (props) => {
  const { setFieldsValue, getFieldDecorator } = props.form;
  const [type, setType] = useState(typePrize.POINT);
  const [qty, setQty] = useState(null);
  const [disQty, setDisQty] = useState(false);
  const numberBatchs = new Array(props.numberBatch).fill(null);
  const [luckyVouchers,setLuckyVouchers] = useState([]);
  const onChangeType = (event) => {
    setType(event.target.value);
    setDisQty(event.target.value === typePrize.VOUCHER);
    setQty(0);
    setFieldsValue({
      point:null,
      voucherId:'',
      qty:'',
    })
  };

  const { data: luckyVouchersData } = useQuery<vouchersLuckyWheel, vouchersLuckyWheelVariables>(CMS_GET_VOUCHER_LUCKY_WHEEL,
    {
      variables: {
        limit: 1000,
        filter: {
          activeDate: {
            startDate: props.campaignRange[0],
            endDate: props.campaignRange[1],
          },
        },
      },
      fetchPolicy: 'network-only',
    });


  const onChangeVoucher = (value) => {
    const voucherSelected = getVoucherSelected(value);
    if (voucherSelected) {
      setQty(voucherSelected.available);
      setFieldsValue({
        qty:voucherSelected.available.toString()
      })
    }
  };

  const getVoucherSelected = (voucherId: String) => {
    return luckyVouchers && luckyVouchers.find(item => item._id === voucherId);
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  useEffect(()=>{
    if(luckyVouchersData && luckyVouchersData.vouchersGame && luckyVouchersData.vouchersGame.length){
      setLuckyVouchers( luckyVouchersData.vouchersGame);
    }
  },[luckyVouchersData]);

  const onHandleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let voucherName = '';
        let mediaId = '';
        let media = {_id:"",uri:""};
        let sum = 0;
        if (type === typePrize.VOUCHER) {
          const voucherSelected = getVoucherSelected(values.voucherId);
          voucherName = voucherSelected.title;
          if (voucherSelected.medias && voucherSelected.medias[0] && voucherSelected.medias[0]._id) {
            mediaId = voucherSelected.medias[0]._id;
            media = voucherSelected.medias[0];
          }
        }
        let _values: outputProps = {
          point: type === typePrize.POINT ? parseInt(values.point) : null,
          voucherId: type === typePrize.VOUCHER ? values.voucherId : null,
          mediaId: mediaId,
          voucherMedia:media,
          name: '',
          qty: parseInt(values.qty),
          qtyForEachBatch: [],
          prizeTier: props.type,
          voucherName,
        };
        Object.keys(values).map(item => {
          if (item.search('batch') > -1) {
            _values.qtyForEachBatch.push(parseInt(values[item]));
            sum += parseInt(values[item]);
          }
        });
        if (sum <= _values.qty) {
          props.onHandleAddPrize(_values, props.type);
        } else {
          ToastError({
            message: 'Error',
            description: 'The total prize in batches cannot be greater than the total number!',
          });
        }

      }
    });
  };
  const onChangeTotal = (event) => {
    setQty(parseInt(event.target.value));
  };


  return (
    <Form onSubmit={onHandleSubmit}>
      <Row gutter={10}>
        <Col span={12}>
          <Radio.Group defaultValue={typePrize.POINT} size="large" onChange={onChangeType} className="width-100">
            <Row type="flex">
              <Col span={6}>
                <Radio name="type" value={typePrize.POINT}>Point</Radio>
              </Col>
              <Col span={6}>
                <Form.Item>
                  {getFieldDecorator('point', {
                    rules: [
                      {
                        required: type === typePrize.POINT,
                        message: 'Please input point!',
                      },
                    ],
                  })(<Input disabled={type !== typePrize.POINT} min={0} type="number"/>)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex">
              <Col span={6}>
                <Radio name="type" value={typePrize.VOUCHER}>Voucher</Radio>
              </Col>
              <Col span={18}>

                <Form.Item>
                  {getFieldDecorator('voucherId', {
                    rules: [
                      {
                        required: type === typePrize.VOUCHER,
                        message: 'Please input screen!',
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      disabled={type !== typePrize.VOUCHER}
                      style={{ width: 200 }}
                      placeholder="Select a screen"
                      onChange={onChangeVoucher}
                    >
                      {
                        luckyVouchers.map((item, index) => {
                          return (<Option key={index} value={item._id}>{item.title}</Option>);
                        })
                      }

                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Radio.Group>
        </Col>
        <Col span={12} style={{ borderLeft: '1px solid gray' }}>
          <Row>
            <Col span={12}> Total</Col>
            <Col span={6}>
              <Form.Item>
                {getFieldDecorator('qty', {
                  initialValue: qty,
                  rules: [
                    {
                      required: true,
                      whitespace:true,
                      message: 'Please input screen!',
                    },
                  ],
                })(
                  <Input type="number" disabled={disQty} min={0}className="mb-5" onChange={onChangeTotal}/>)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}> Quantity for each batch</Col>
            <Col span={6}>
              {
                numberBatchs.map((item, index) => {
                  return (
                    <Form.Item key={index}>
                      {getFieldDecorator('batch' + (++index), {
                        rules: [
                          {
                            required: true,
                            whitespace:true,
                            message: 'Please input batch' + index + '!',
                          },
                        ],
                      })(
                        <Input type="number" min={0}/>)}
                    </Form.Item>
                  );
                })
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col offset={16} span={3}>
          <Form.Item {...buttonItemLayout} >
            <Button type="primary" className="as-danger-btn p015px" onClick={props.onClose} htmlType="submit">Cancel</Button>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item {...buttonItemLayout} >
            <Button type="primary"  className="as-primary-btn p015px" htmlType="submit">Create</Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

};

const AddPrize = Form.create<Props>()(AddPrizeTmpl);
export default AddPrize;
