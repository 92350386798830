import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { CREATE_CONFIG, UPDATE_CONFIG } from '../../../graphql/config/config';
import { CreateConfig, CreateConfigVariables, UpdateConfig, UpdateConfigVariables } from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';

interface IData {
  _id?: any;
  key?: string;
  value?: string;
  type?: string;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
}

const { Option } = Select;

const AddConfigImpl = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;
  const [value, setValue] = useState(props.data && props.data.value ? props.data.value : '');
  const [type, setType] = useState(props.data && props.data.type ? props.data.type : 'NORMAL');
  const [sliders, setSliders] = useState([
    { start: 0, end: 10, value: '0' },
    { start: 10, end: 100, value: '0' },
  ]);
  const [createConfig] = useMutation<CreateConfig, CreateConfigVariables>(CREATE_CONFIG);
  const [updateConfig] = useMutation<UpdateConfig, UpdateConfigVariables>(UPDATE_CONFIG);

  const handleOk = e => {
    handleSubmit(e);
    // setShow(false);
    // props.closeModal();
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (props.data && props.data._id) {
          const { data } = props;
          updateConfig({
            variables: {
              _id: data._id,
              key: values.key,
              value: values.value,
              type: values.type,
            },
          })
            .catch(e => {
              console.log(e.toLocaleString());
            })
            .then(() => {
              setShow(false);
              props.closeModal();
            });
        } else {
          createConfig({
            variables: {
              key: values.key,
              value: values.value,
              type: values.type,
            },
          })
            .catch(e => {
              console.log(e.toLocaleString());
            })
            .then(() => {
              setShow(false);
              props.closeModal();
            });
        }
      }
    });
  };

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const genValues = () => {
    let value = '';
    sliders.map(function(r, i) {
      if (value != '') value += ';';
      if (r.start >= 0) value += r.start;
      if (r.end) value += '-' + r.end;
      if (r.value != '') value += ':' + parseInt(r.value);
    });
    setValue(value);
  };

  const rangeStartChange = (e, i) => {
    const _sliders = sliders;
    setSliders([]);
    _sliders[i].start = e.target.value;
    if (_sliders[i - 1] && _sliders[i - 1].end) {
      _sliders[i - 1].end = e.target.value;
    }
    setSliders(_sliders);
    genValues();
  };

  const rangeEndChange = (e, i) => {
    const _sliders = sliders;
    setSliders([]);
    _sliders[i].end = e.target.value;
    if (_sliders[i + 1] && _sliders[i + 1].start) {
      _sliders[i + 1].start = e.target.value;
      if (_sliders[i + 1].start > _sliders[i + 1].end) _sliders[i + 1].end = e.target.value;
    }
    setSliders(_sliders);
    genValues();
  };

  const rangeValueChange = (e, i) => {
    const _sliders = sliders;
    _sliders[i].value = e.target.value;
    setSliders(_sliders);
    genValues();
  };

  const addNewRange = () => {
    const slide = sliders[sliders.length - 1];
    const newRange = {
      start: slide.end,
      end: slide.end,
      value: '0',
    };
    setSliders([...sliders, newRange]);
    genValues();
  };

  const changeTypeConfig = e => {
    setType(e);
  };

  useEffect(function() {
    if (props.data && props.data.value){
      const data = props.data.value.split(';');
      let ranges = [];
      data.map(function(r, i) {
        let rows = r.split('-');
        if (rows.length === 1){
          rows = rows[0].split(':');
          ranges.push({
            start: rows[0], end: rows[0], value: rows[1] ? rows[1] : '0'
          })
        }else{
          const _rows = rows[1].split(':');
          ranges.push({
            start: rows[0], end: _rows[0], value: _rows[1] ? _rows[1] : '0'
          })
        }

        if (i === data.length - 1){
          setSliders(ranges);
        }
      })
    }
  }, [props.data]);

  const { visible } = props;
  const [show, setShow] = useState(visible);
  return (
    <div>
      <Modal
        title={props.data._id ? 'Edit Config' : 'Add New Config'}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Save'}
        width={768}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form onSubmit={handleSubmit}>
              <Row type="flex" justify="center" gutter={8}>
                <Col span={24}>
                  <Form.Item label="Type">
                    {getFieldDecorator(`type`, {
                      initialValue: type,
                    })(
                      <Select onChange={e => changeTypeConfig(e)} defaultValue={type}>
                        <Option value="NORMAL">Normal</Option>
                        <Option value="VALUE_RANGE">Range</Option>
                      </Select>,
                    )}
                  </Form.Item>
                </Col>
                {
                  type === 'VALUE_RANGE' ? (
                    <Col span={24}>
                      <Row>

                        <Col span={24}>
                          <Form.Item label="Key">
                            {getFieldDecorator(`key`, {
                              initialValue: props.data && props.data.key ? props.data.key : '',
                              rules: [
                                {
                                  required: true,
                                  message: 'Please input your key!',
                                },
                              ],
                            })(<Input />)}
                          </Form.Item>
                        </Col>
                      </Row>
                      {sliders.map(function(r, i) {
                        return (
                          <Row gutter={10} key={i}>
                            <Col span={9}>
                              <Form.Item label={'Range start ' + (i + 1) + ' (<=)'}>
                                <Input
                                  defaultValue={r.start.toString()}
                                  value={r.start}
                                  onChange={e => rangeStartChange(e, i)}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={9}>
                              <Form.Item label={'Range end ' + (i + 1) + ' (<)'}>
                                <Input defaultValue={r.end.toString()} value={r.end} onChange={e => rangeEndChange(e, i)} />
                              </Form.Item>
                            </Col>

                            <Col span={6}>
                              <Form.Item label={'Value ' + (i + 1) + ''}>
                                <Input
                                  defaultValue={r.value.toString()}
                                  value={r.value}
                                  onChange={e => rangeValueChange(e, i)}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      })}
                      <Button style={{ marginBottom: '15px' }} type={'primary'} onClick={addNewRange}>
                        Add new range
                      </Button>

                      <Row>
                        <Col span={24}>
                          <Form.Item label="Value">
                            {getFieldDecorator(`value`, {
                              initialValue: value,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please input your value!',
                                },
                              ],
                            })(<Input readOnly={true} />)}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  ) :(
                    <Col span={24}>
                      <Row>
                        <Col span={12}>
                          <Form.Item label="Key">
                            {getFieldDecorator(`key`, {
                              initialValue: props.data && props.data.key ? props.data.key : '',
                              rules: [
                                {
                                  required: true,
                                  message: 'Please input your key!',
                                },
                              ],
                            })(<Input />)}
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Value">
                            {getFieldDecorator(`value`, {
                              initialValue: value,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please input your value!',
                                },
                              ],
                            })(<Input />)}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )
                }
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AddConfigImpl;
