import { gql } from 'apollo-boost';

export const GET_PLACES = gql`
  query TopPlaces($limit: Int!, $q: String) {
    topPlaces(limit: $limit, q: $q) {
      _id
      name
      description
      checkins
      reviews
      primaryImage {
        _id
        uri
      }
      medias {
        _id
        uri
      }
      location {
        _id
        place {
          _id
          name
        }
        country
      }
      phone
      isVerified
      createdAt
    }
  }
`;

export const CMS_SET_PLACE_PRIMARY_IMAGE = gql`
  mutation CmsSetPlacePrimaryImage($_id: ID!, $imageId: ID!) {
    cmsSetPlacePrimaryImage(_id: $_id, imageId: $imageId)
  }
`;
