import React, { Fragment, useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Layout, Modal, Pagination, Row, Select, Skeleton, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from '../indexStyled';
import {
  CMS_GET_REFERRAL_VOUCHERS,
  UPDATE_VOUCHER ,
  CMS_DELETE_VOUCHER ,
} from 'src/graphql/voucher/voucher';
import {
  cmsReferralVouchers,
  cmsReferralVouchersVariables, deleteVoucher, deleteVoucherVariables,
  UpdateVoucher, UpdateVoucherVariables,
  VoucherStatus,
} from 'src/graphql/types';

import { useMutation, useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import { ToastError } from 'src/components/Toast';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { dateByFormat } = require('src/helpers/date');

const imgDefault = require('src/assets/images/default_voucher_image.jpg');
const iconEdit = require('src/assets/images/icons/actions-button/edit.svg');
const iconViewList = require('src/assets/images/icons/actions-button/view-list.svg');
const iconDeleteEnable = require('../../../assets/images/icons/actions-button/actionDeleteEnable.svg');
const iconDeleteDisable = require('../../../assets/images/icons/actions-button/actionDeleteDisable.svg');

const { Content } = Layout;
const { Option } = Select;
const { confirm } = Modal;

interface IEditProps extends FormComponentProps {
  match?: any;
}

const ReferralImpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const { params } = props.match;
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [voucher, setVoucher] = useState<UpdateVoucherVariables>();
  const [vouchers, setVouchers] = useState([]);
  const [counter, setCounter] = useState<number>(0);
  const  voucherStatuses = [VoucherStatus.ENABLED, VoucherStatus.DISABLED];
  const [filterParams, setFilterParams] = useState({
    limit: 10,
    page:1,
    adminStatus: (params.type && params.type.toUpperCase()) || null,
    status: null,
    q: null,
  });

  const [editData, setEditData] = useState<any>({
    _id: '',
    status: VoucherStatus.ENABLED,
  });

  const [updateVoucher] = useMutation<UpdateVoucher, UpdateVoucherVariables>(UPDATE_VOUCHER, {
    refetchQueries: ['cmsReferralVouchers'],
  });

  const [delVoucher] = useMutation<deleteVoucher, deleteVoucherVariables>(CMS_DELETE_VOUCHER,{
    refetchQueries: ['cmsReferralVouchers'],
  });

  let { data, error, refetch, loading } = useQuery<cmsReferralVouchers, cmsReferralVouchersVariables>(CMS_GET_REFERRAL_VOUCHERS, {
    fetchPolicy: 'network-only',
    variables: {
      limit: filterParams.limit,
      page:filterParams.page,
    },
  });

  useEffect(
    function() {
      setFilterParams({
        ...filterParams,
        adminStatus: (params.type && params.type.toUpperCase()) || null,
      });
    },
    [params],
  );


  useEffect(()=>{
    if(data && data.cmsReferralVouchers){
      setVouchers(data.cmsReferralVouchers.items);
      setCounter(data.cmsReferralVouchers.counter);

    }
  },[data]);

  useEffect(()=>{
    refetch();
  },[filterParams]);

  const columns = [
    {
      title: 'No.',
      key: 'index',
      width: '5%',
      render: (text, record, index) => (filterParams.page - 1)*filterParams.limit + index + 1,
    },
    {
      title: 'Title',
      width: '10%',
      dataIndex: 'title',
    },
    {
      title: 'Title En',
      width: '10%',
      dataIndex: 'titleEn',
    },

    {
      title: 'Active date',
      key: 'activeDate',
      sorter: false,
      width: '25%',
      render: row => {
        return <>{dateByFormat(row.startDate)} - {dateByFormat(row.endDate)}</>;
      },
    },
    {
      title: 'Prize image',
      key: 'image',
      width: '10%',
      render: row => {
        return (
          <div className="thumb">
            <img src={row.medias[0] ? row.medias[0].uri : imgDefault} alt="" />
          </div>
        );
      },
    },
    {
      title: 'Rule',
      key: 'rule',
      width: '8%',
      render: row => {
        return <div>Block x refe</div>;
      },
    },
    {
      title: 'Block',
      width: '8%',
      dataIndex: 'referralTurns',
    },
    {
      title: 'Status',
      render: row => {
        let type: any = 'default';
        let className: string = 'disabled-status';
        if (row.status == VoucherStatus.ENABLED) {type = 'primary' ; className = "enabled-status"}
        if (row.status == VoucherStatus.DISABLED) type = 'danger';
        return (
          <Button className={`btn-rectangle ${className} `} type={type} size="small" onClick={e => setEditVoucher(row)}>
            {row.status}
          </Button>
        );
      },
    },
    {
      title: 'Action',
      key: 'operation',
      render: row => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            <div>
              <Link to={'/vouchers/' + row._id + '/edit'}>
                <img src={iconEdit} alt=""/>
              </Link>
            </div>
            <div>
              <Link to={'/vouchers/referrals/codes/' + row._id }>
                <img src={iconViewList} alt=""/>
              </Link>
            </div>
            <div>
              {
                row.status == VoucherStatus.ENABLED ?
                  <img src={ iconDeleteDisable} alt=""/> :
                  <img src={iconDeleteEnable} alt=""  onClick={() => handleDelVoucher(row)}/>
              }
            </div>
          </div>
        );
      },
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    // console.log('params', pagination, filters, sorter, extra);
  }

  const handleDelVoucher = (voucher) => {
    confirm({
      title: 'Are you sure delete this voucher code?',
      icon: <ExclamationCircleOutlined/>,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        delVoucher({ variables: { _id: voucher._id } }).catch(err=>{
          ToastError({message:"Error!",description:"Something went wrong! Please try again later."});
          console.log("err==>",err);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const setEditVoucher = data => {
    setEditData({
      _id: data._id,
      status: data.status,
      data
    });
    setVoucher(data);
    setShowModal(true);
  };

  const handleOk = () => {
    setShowModal(false);
    updateVoucher({
      variables:{
        ...voucher,
        status:editData.status
      }
    }).catch(err=>{
      ToastError({message:"Error!",description:"There is a campaign running this time"})
    })
  };

  const handleCancel = () => {
    setShowModal(false);
  };


  useEffect(() => {
    if (loading) setLoading(true);
    if (!loading) setLoading(false);
  }, [loading]);


  const onChangePage = (page) => {
    setFilterParams({...filterParams, page});
  };
  const changeVoucherAdminStatus = e => {
    setEditData({
      ...editData,
      status: e,
    });
  };



  if (error) return <Content className="content">Error</Content>;

  return (
    <Styled.Container>
      <Content className="content referral-list-section">
        <Breadcrumb>
          <Breadcrumb.Item>ReferralVouchers</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mt-10 mb-10" gutter={16}>
          <Col span={ 12 } offset={12} style={ { textAlign: 'right' } }>
            <Link to={ '/vouchers/create' }>
              <Button type={ 'primary' }>Add Voucher</Button>
            </Link>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {counter}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}>
            {' '}
          </Skeleton>
        ) : (
          <Fragment>
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={vouchers}
              onChange={onChange}
              scroll={{ x: 'calc(700px + 50%)', y: 0 }}
              pagination={false}
            />
            <Pagination
              defaultCurrent={ filterParams.page||1 }
              total={ counter }
              defaultPageSize={ filterParams.limit }
              onChange={ onChangePage }
              style={ {
                marginTop: 20,
                right: 0,
              } }
            />
          </Fragment>


        )}
      </Content>

      <Modal
        title="Change voucher admin status"
        visible={showModal}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        {showModal && (
          <div>
            <Form>
              <Form.Item label="">
                {getFieldDecorator('statusUpdate', {
                  initialValue: editData.status,
                })(
                  <Select
                    placeholder="Select a option and change input text above"
                    onChange={e => changeVoucherAdminStatus(e)}
                  >
                    {voucherStatuses.map((r, i) => {
                      return (
                        <Option disabled={r == editData.status} key={i} value={r}>
                          {r}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>

    </Styled.Container>
  );
};

const ReferralVouchers = Form.create()(ReferralImpl);
export default ReferralVouchers;
