import React, { useState } from 'react';
import { Col, Form, Input, Modal, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { BlockPost, BlockPostVariables, ReportFor, TermStatus } from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { BLOCK_POST } from '../../../graphql/feed/feed';

interface IData {
  _id?: any;
  isPartner?: boolean;
  isBlock?: boolean;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
  form: any;
  product?: boolean;
  refetchParent?:any
}

const ReasonConfig = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;

  const [blockPost] = useMutation<BlockPost, BlockPostVariables>(BLOCK_POST, {
    refetchQueries: [props.product ? 'CmsGetProductReports' : 'GetPosts'],
  });

  const handleOk = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (values.reason && !values.reason.trim()) {
          props.form.setFields({
            reason: {
              value: values.reason,
              errors: [new Error('Please input reason')],
            },
          });
        } else if (props.data && props.data._id) {
          const { data } = props;
          blockPost({
            variables: {
              _id: data._id,
              model: ReportFor.POST,
              termStatus: TermStatus.BLOCKED,
              reason: values.reason,
            },
          })
            .catch(e => {
              console.log(e.toLocaleString());
            })
            .then((res: any) => {
          
              props.refetchParent && props.refetchParent();
              if (res.data.cmsSetTermStatus) {
                notification.open({
                  placement: 'bottomRight',
                  type: 'success',
                  message: 'Blocked',
                  description: 'Record has been blocked.',
                });
              }
              setShow(false);
              props.closeModal();
            });
        }
      }
    });
  };

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const { visible, product } = props;
  const [show, setShow] = useState(visible);
  return (
    <div>
      <Modal
        title={product ? 'Reason to block product' : 'Reason to block post'}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Save'}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form>
              <Form.Item label={'Reason'}>
                {getFieldDecorator(`reason`, {
                  rules: [
                    {
                      required: true,
                      message: 'Please input reason',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ReasonConfig;
