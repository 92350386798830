import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Layout, Row, Col, Select, Form, Skeleton } from 'antd';
const { Option } = Select;
import styled from 'styled-components';
import {
  GET_REQUEST_BADGE,
  SET_REQUEST_BADGE_STATUS
} from '../../../graphql/badge/badge';

import {
  useMutation,
  useQuery
} from '@apollo/react-hooks';
import {
  getRequestBadge,
  getRequestBadgeVariables,
  RequestBadgeStatus,
  BadgeInputType,
  cmsBadgeSetStatus,
  cmsBadgeSetStatusVariables
} from '../../../graphql/types';
import {
  ToastError,
} from '../../../components/Toast';

const { Content } = Layout;

interface IEditProps {
  _id: string;
  onUpdated: () => void;
}

const BadgeVerifyDetail = React.forwardRef((props: IEditProps, ref) => {
  const [value, setValue] = useState(RequestBadgeStatus.PENDING);
  const [detailBadge, setDetailBadge] = useState(null);
  const [isLoading, setLoading] = useState(true);

  let { data, loading } = useQuery<getRequestBadge, getRequestBadgeVariables>(GET_REQUEST_BADGE, {
    fetchPolicy: 'network-only',
    variables: {
      _id: props._id
    },
  });

  const [setRequestBadgeStatus] = useMutation<cmsBadgeSetStatus, cmsBadgeSetStatusVariables>(
    SET_REQUEST_BADGE_STATUS,
    {
      refetchQueries: ['cmsListRequestBadges'],
    },
  );

  useEffect(() => {
    if (data && data.getRequestBadge) {
      setDetailBadge(data.getRequestBadge);
      setValue(data.getRequestBadge.status);
    }
  }, [data]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useImperativeHandle(ref, () => ({
    setStatus() {
      setRequestBadgeStatus({
        variables: {
          _id: props._id,
          status: value
        }
      }).then(res => {
        if (res && res.data && res.data.cmsBadgeSetStatus) {
          props.onUpdated();
        } else {
          ToastError({ message: 'Error', description: 'Có lỗi xảy ra!' });
        }
      }).catch(err => {
        ToastError({ message: 'Error', description: 'Có lỗi xảy ra!' });
      })
    },
  }));

  const _renderContent = () => {
    let title = "";
    let name = "";
    let phoneNumber = "";
    let requestForms = [];
    // let status = BadgeStatus.DISABLED;
    if (detailBadge) {
      title = detailBadge.title;
      if(detailBadge.creator) {
        name = detailBadge.creator.fullName;
      }
      phoneNumber = detailBadge.phoneNumber;
      // url = detailBadge.media && detailBadge.media.uri;
      if (detailBadge.requestForms) {
        requestForms = detailBadge.requestForms;
      }
    }

    return (
      <Styled.Container>
        {isLoading ?
          (<Skeleton loading={isLoading} paragraph={{ rows: 5 }} > </Skeleton >)
          :
          (<Content className="detailContent">
            <Row>
              <Col span={8}>
                <div>
                  {"Full name: "}<strong>{name}</strong>
                </div>
              </Col>

              <Col span={8}>
                <div>
                  {"Phone number: "}<strong>{phoneNumber}</strong>
                </div>
              </Col>

              <Col span={8}>
                <div>
                  {"Title: "}<strong>{title}</strong>
                </div>
              </Col>
            </Row>

            <Row gutter={8} style={{ marginTop: 16 }}>
              <div className="register-title">
                {"Register form: "}
              </div>

              {
                requestForms.map(function (row, i) {
                  let url = '';
                  if (row.media && row.media.uri) {
                    url = row.media.uri;
                  }
                  return (
                    <Form>
                      <Form.Item label={row.detail}>
                        <div className="form_text">
                          {row.inputType == BadgeInputType.TEXT && row.text}
                          {(row.inputType == BadgeInputType.IMAGE && url) && <img
                            className="thumb"
                            src={url}
                            alt="" />}
                        </div>
                      </Form.Item>

                    </Form>
                  )
                })
              }
              {/* </Col> */}
            </Row>

            <Row>
              <div>
                <Select
                  defaultValue={value}
                  value={value}
                  onChange={value => {
                    setValue(value);
                  }}
                  style={{ width: 233, marginTop: 35 }}>
                  <Option value={RequestBadgeStatus.APPROVED}>{RequestBadgeStatus.APPROVED}</Option>
                  <Option value={RequestBadgeStatus.PENDING}>{RequestBadgeStatus.PENDING}</Option>
                  <Option value={RequestBadgeStatus.REJECTED}>{RequestBadgeStatus.REJECTED}</Option>
                </Select>
              </div>

            </Row>


          </Content >)
        }
      </Styled.Container>


    )
  }

  return (
    <Styled.Container>
      {_renderContent()}
    </Styled.Container>
  );
})

export default BadgeVerifyDetail;


const Styled = {
  Container: styled.div`
    .detailContent{
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
    }
    .ant-btn-sm{
      padding: 0;
      height: auto;
      &.btn-rectangle{
        padding: 2px 5px;
      }
    }
    .thumb{
      width: 240px;
      max-height: 240px;
      img{
        width: 1000%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .register-title {
      font-size: 14px;
      color: #8C8D97;
    }
    .register-content {
      font-size: 14px;
      color: #000000;
      line-height: 19px;
    }
    .form_text {
      font-size: 14px;
    }
    .ant-form-item-label > label {
      color: rgba(62, 151, 241, 1);
      font-size: 14px
    }
    .ant-form-item-control {
      line-height: 20px;
    }
  `,
};