import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Select } from 'antd';
const { Option } = Select;
import styled from 'styled-components';
import { GET_BADGE } from '../../../graphql/badge/badge';
import {
  BadgeStatus,
} from '../../../graphql/types';
import {
  // useMutation, 
  useQuery
} from '@apollo/react-hooks';
import {
  getBadge,
  getBadgeVariables
} from '../../../graphql/types';

const { Content } = Layout;

interface IEditProps {
  _id: string;
  onRowUpdated: (updatedRow) => void;
}

const BadgeDetail = (props: IEditProps) => {
  const [value, setValue] = useState(status);
  const [badge, setBadge] = useState(null);

  let { data } = useQuery<getBadge, getBadgeVariables>(GET_BADGE, {
    fetchPolicy: 'network-only',
    variables: {
      _id: props._id
    },
  });

  useEffect(() => {
    if (data && data.getBadge) {
      setBadge(data.getBadge);
      setValue(data.getBadge.status);
    }
  }, [data]);

  const _renderContent = () => {
    let title = "";
    let url = "";
    let registerForms = [];
    // let status = BadgeStatus.DISABLED;
    if (badge) {
      title = badge.title;
      url = badge.media && badge.media.uri;
      registerForms = badge.registerForms;
      // status = badge.status;
    }
    return (
      <Content className="detailContent">
        <Col>
          <div>
            {"Title: "}<strong>{title}</strong>
          </div>
          {/* {
            props.creator ? <strong>{"Hướng dẫn viên"}</strong> : null
          } */}
          {url && <img
            className="badge-icon"
            src={url}
            alt="" />}

          <div>
            <Select
              defaultValue={value}
              value={value}
              onChange={value => {
                setValue(value);
                props.onRowUpdated({ ...badge, status: value })
              }}
              style={{ width: 233, marginTop: 35 }}>
              <Option value={BadgeStatus.ENABLED}>{BadgeStatus.ENABLED}</Option>
              <Option value={BadgeStatus.DISABLED}>{BadgeStatus.DISABLED}</Option>
            </Select>
          </div>

        </Col>

        <Row gutter={8} style={{ marginTop: 23 }}>
          <Col span={6}>
            <div className="register-title">
              {"Register form: "}
            </div>
          </Col>

          <Col span={16}>
            {
              registerForms.map(function (row, i) {
                return <Row style={{
                  marginTop: i != 0 ? 8 : 0
                }}>
                  <div className="register-content">
                    {row.detail}
                  </div>
                </Row>
              })
            }
          </Col>
        </Row>
      </Content >
    )
  }

  return (
    <Styled.Container>
      {_renderContent()}
    </Styled.Container>
  );
};
export default BadgeDetail;


const Styled = {
  Container: styled.div`
    .detailContent{
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
    }
    .ant-btn-sm{
      padding: 0;
      height: auto;
      &.btn-rectangle{
        padding: 2px 5px;
      }
    }
    .badge-icon {
      width: 120px;
      height: 120px;
      margin-top: 28px;
    }
    .register-title {
      font-size: 14px;
      color: #8C8D97;
    }
    .register-content {
      font-size: 14px;
      color: #000000;
      line-height: 19px;
    }
  `,
};