import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useState, useEffect } from 'react';
import { Breadcrumb, Form, Layout, Col, Row, Button, Alert } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastSuccess, ToastError } from '../../components/Toast';

import Styled from '../Place/indexStyled';
import {
  CMS_DETAIL_LUCKY_WHEEL_TERM,
  CMS_CREATE_LUCKY_WHEEL_TERM,
  CMS_UPDATE_LUCKY_WHEEL_TERM,
} from '../../graphql/luckyWheel';
import {
  cmsDetailLuckyWheelTerm,
  createLuckyWheelTerm_cmsCreateLuckyWheelTerm,
  createLuckyWheelTermVariables,
  updateLuckyWheelTerm,
  updateLuckyWheelTermVariables,
} from '../../graphql/types';
import Title from 'antd/lib/typography/Title';
const loadingImage = require('../../assets/images/loadingspinner.gif');

const { Content } = Layout;

interface Props extends FormComponentProps {
  match?: any;
}

const LuckyWheelTermsImpl = (props: Props) => {
  const { getFieldDecorator } = props.form;
  const [isLoading, setLoading] = useState(false);
  const [termId, setTermId] = useState(null);
  const [createLuckyWheelTerms, { data: createResult }] = useMutation<
    createLuckyWheelTerm_cmsCreateLuckyWheelTerm,
    createLuckyWheelTermVariables
  >(CMS_CREATE_LUCKY_WHEEL_TERM);
  const [updateLuckyWheelTerms] = useMutation<updateLuckyWheelTerm, updateLuckyWheelTermVariables>(
    CMS_UPDATE_LUCKY_WHEEL_TERM,
  );

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const { data: term } = useQuery<cmsDetailLuckyWheelTerm>(CMS_DETAIL_LUCKY_WHEEL_TERM);

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        if (termId) {
          updateLuckyWheelTerms({
            variables: {
              _id: termId,
              data: {
                content: values.content.replace(/&nbsp;/gim, '') || '',
                contentEn: values.contentEn.replace(/&nbsp;/gim, '') || '',
              },
            },
          })
            .then(() => {
              setLoading(false);
              ToastSuccess({ message: 'Saved', description: 'Terms successfully saved' });
            })
            .catch(e => {
              console.log(e);

              ToastError({ message: 'Error', description: 'Error!' });
              setLoading(false);
            });
        } else {
          createLuckyWheelTerms({
            variables: {
              data: {
                content: values.content.replace(/&nbsp;/gim, '') || '',
                contentEn: values.contentEn.replace(/&nbsp;/gim, '') || '',
              },
            },
          })
            .then(() => {
              setLoading(false);
              ToastSuccess({ message: 'Saved', description: 'Terms successfully saved' });
            })
            .catch(e => {
              console.log(e);

              ToastError({ message: 'Error', description: 'Error!' });
              setLoading(false);
            });
        }
      } else {
        ToastError({ message: 'Error', description: 'Error!' });
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (createResult && createResult._id) {
      setTermId(createResult._id);
    }
  }, [createResult]);

  useEffect(() => {
    if (term && term.cmsDetailLuckyWheelTerm) {
      setTermId(term.cmsDetailLuckyWheelTerm._id);
    }
  }, [term]);

  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Lucky Wheel</Breadcrumb.Item>
          <Breadcrumb.Item>Terms</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col span={24}>
            <Alert banner message="Nhập thể lệ cho vòng quay không bao gồm gạch đầu dòng" type="info" showIcon />

            <Form onSubmit={handleSubmit}>
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={12} className="gutter-row">
                  <Title level={2}>Thể lệ tiếng Việt</Title>
                  <Form.Item>
                    {getFieldDecorator('content', {
                      getValueFromEvent: (event, editor) => {
                        const data = editor.getData();
                        return data;
                      },
                      rules: [{ required: true, message: 'Please enter term' }],
                      initialValue:
                        term && term.cmsDetailLuckyWheelTerm ? term.cmsDetailLuckyWheelTerm.content : 'Thể lệ vi',
                    })(
                      <CKEditor
                        editor={ClassicEditor}
                        data={term && term.cmsDetailLuckyWheelTerm ? term.cmsDetailLuckyWheelTerm.content : undefined}
                        config={{
                          placeholder: 'Nhập thể lệ tiếng Việt...',
                          removePlugins: ['Heading', 'Bold', 'Italic', 'BlockQuote', 'NumberedList', 'BulletedList'],
                          toolbar: ['link'],
                        }}
                      />,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} className="gutter-row">
                  <Title level={2}>Thể lệ tiếng Anh</Title>
                  <Form.Item>
                    {getFieldDecorator('contentEn', {
                      getValueFromEvent: (event, editor) => {
                        const data = editor.getData();
                        return data;
                      },
                      rules: [{ required: true, message: 'Please enter term' }],
                      initialValue:
                        term && term.cmsDetailLuckyWheelTerm ? term.cmsDetailLuckyWheelTerm.contentEn : 'Thể lệ en',
                    })(
                      <CKEditor
                        editor={ClassicEditor}
                        data={term && term.cmsDetailLuckyWheelTerm ? term.cmsDetailLuckyWheelTerm.contentEn : undefined}
                        config={{
                          placeholder: 'Nhập thể lệ tiếng Anh...',
                          removePlugins: ['Heading', 'Bold', 'Italic', 'BlockQuote', 'NumberedList', 'BulletedList'],
                          toolbar: ['link'],
                        }}
                      />,
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col offset={22} span={2}>
                  <Form.Item {...buttonItemLayout}>
                    <Button type="primary" disabled={isLoading} htmlType="submit">
                      Save
                    </Button>
                    {isLoading && <img src={loadingImage} className="w20" alt="" />}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Content>
    </Styled.Container>
  );
};

const LuckyWheelTerms = Form.create()(LuckyWheelTermsImpl);
export default LuckyWheelTerms;
