import { gql } from 'apollo-boost';

export const GET_SYSTEM_NOTIFICATIONS = gql`
  query GetMyNotifications {
    getSystemNotifications {
      counter
      items {
        _id
        sendToAllUsers
        customMessage
        systemNotificationTo
        sendToAllUsers
        sent
        type
        createdAt
        fromUser {
          _id
          fullName
        }
      }
    }
  }
`;

export const CREATE_SYSTEM_NOTIFICATION = gql`
  mutation CreateSystemNotification(
    $customMessage: String!
    $systemNotificationTo: SystemNotificationTo!
    $excludedUserIds: [ID!]
    $excludedPageIds: [ID!]
  ) {
    createSystemNotification(
      data: {
        customMessage: $customMessage
        systemNotificationTo: $systemNotificationTo
        excludedUserIds: $excludedUserIds
        excludedPageIds: $excludedPageIds
      }
    ) {
      _id
      sendToAllUsers
      systemNotificationTo
      customMessage
    }
  }
`;

export const UPDATE_SYSTEM_NOTIFICATION = gql`
  mutation UpdateSystemNotification(
    $_id: ID!
    $customMessage: String!
    $systemNotificationTo: SystemNotificationTo!
    $excludedUserIds: [ID!]
    $excludedPageIds: [ID!]
  ) {
    updateSystemNotification(
      _id: $_id
      data: {
        customMessage: $customMessage
        systemNotificationTo: $systemNotificationTo
        excludedUserIds: $excludedUserIds
        excludedPageIds: $excludedPageIds
      }
    ) {
      _id
      sendToAllUsers
      systemNotificationTo
      customMessage
    }
  }
`;

export const DELETE_SYSTEM_NOTIFICATION = gql`
  mutation DeleteSystemNotification($_id: ID!) {
    deleteSystemNotification(_id: $_id)
  }
`;

export const SEND_SYSTEM_NOTIFICATION = gql`
  mutation SendSystemNotification($_id: ID!) {
    sendSystemNotification(_id: $_id)
  }
`;
