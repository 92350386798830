import React, { useEffect, useState } from 'react';
import { Breadcrumb, Form, Input, Layout, Pagination, Skeleton, Table, Modal, Row, Col, notification } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { CMS_GET_GROUPS, GET_ALL_CMS_GROUPS, REMOVE_CMS_GROUP } from '../../graphql/group/pageVerify';
import {
  CmsSearchGroups,
  CmsSearchGroupsVariables,
  deleteGroup,
  deleteGroupVariables,
  getAllGroup,
  getAllGroup_getCmsGroups,
  getAllGroup_getCmsGroups_items,
} from '../../graphql/types';
import Styled from './indexStyled';
import Button from 'antd/es/button/button';
import PopUpDelete from './PopUpDelete';
import { WrappedFormUtils } from 'antd/lib/form/Form';

const { Content } = Layout;
const { Search } = Input;

enum FilterInputType {
  LIMIT = 10,
  OFFSET = 0,
}

interface IDefaultProps extends FormComponentProps {
  form: any;
}

const GroupImpl = (props: IDefaultProps) => {
  const { getFieldDecorator } = props.form;
  const [reason, setReason] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [counter, setCounter] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [groupItem, setGroupItem] = useState<getAllGroup_getCmsGroups_items>();
  const [groupParams, setGroupParams] = useState({
    limit: FilterInputType.LIMIT,
    offset: FilterInputType.OFFSET,
    filter: {
      name_contain: '' || undefined,
    },
  });

  const getGroups = () => {
    const variables = {
      limit: groupParams.limit,
      offset: groupParams.offset,
      filter: groupParams.filter,
    };
    return useQuery<getAllGroup>(GET_ALL_CMS_GROUPS, {
      fetchPolicy: 'network-only',
      variables: variables,
    });
  };
  const { data, error, loading, refetch } = getGroups();

  const [removeGroup] = useMutation<deleteGroup>(REMOVE_CMS_GROUP, {
    refetchQueries: ['deleteGroup'],
  });

  const columns = [
    {
      title: 'Id',
      dataIndex: '_id',
      render: row => {
        return (
          <div>
            <p>{row}</p>
          </div>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: false,
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      sorter: false,
      render: row => {
        return (
          <div>
            <p>{row.fullName}</p>
          </div>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: false,
      render: row => {
        return (
          <div>
            <p>{row.name.VI_VN}</p>
          </div>
        );
      },
    },
    {
      title: 'Member',
      dataIndex: 'totalMembers',
      sorter: false,
      render: row => {
        return (
          <div>
            <p>{row}</p>
          </div>
        );
      },
    },
    {
      title: 'Action',
      sorter: false,
      render: row => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{
                marginLeft: 5,
                width: 30,
                height: 30,
                fontSize: 13,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              icon="delete"
              type="danger"
              size="small"
              onClick={() => showModal(row)}
            />
          </div>
        );
      },
    },
  ];

  const showModal = row => {
    setGroupItem(row);
    setIsShow(!isShow);
  };

  const onRemoveGroup = (reason, id) => {
    removeGroup({
      variables: {
        id,
        reason: reason,
      },
    }).then(() => {
      notification.open({
        placement: 'topRight',
        type: 'success',
        message: 'Success',
        description: 'Group has been removed.',
      });
      handleCancel();
      refetch();
    });
  };

  const onShowSizeChange = (current, postSize) => {
    const cOffset = counter / postSize;
    const cOffsetUpward = Math.ceil(cOffset);

    let nOffset = groupParams.offset;
    if (cOffsetUpward < groupParams.offset) {
      nOffset = cOffsetUpward;
      setCurrentPage(1);
    }

    setGroupParams({ ...groupParams, limit: postSize, offset: nOffset });
  };

  const onChangePage = (page, pageSize) => {
    const countPage = (page - 1) * pageSize;
    setGroupParams({ ...groupParams, offset: countPage });
    setCurrentPage(page);
  };

  const searchGroup = value => {
    if (value) {
      setGroupParams({
        ...groupParams,
        offset: 0,
        filter: {
          name_contain: value,
        },
      });
    } else {
      setGroupParams({
        ...groupParams,
        offset: 0,
        filter: {
          name_contain: undefined,
        },
      });
    }
    setCurrentPage(1);
  };

  const handleOk = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (values.reason && !values.reason.trim()) {
          props.form.setFields({
            reason: {
              value: values.reason,
              errors: [new Error('Please input reason')],
            },
          });
        } else {
          onRemoveGroup(values.reason, groupItem._id);
        }
      }
    });
  };

  const handleCancel = () => {
    setIsShow(false);
  };
  useEffect(() => {
    if (data && data.getCmsGroups.items && data.getCmsGroups.items.length) {
      setGroups(data.getCmsGroups.items);
      setCounter(data.getCmsGroups.counter);
    } else {
      setGroups([]);
      setCounter(0);
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Group</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.getCmsGroups.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div>
            <Search
              placeholder="Search Group"
              onSearch={value => searchGroup(value)}
              style={{ width: 200, marginBottom: 20 }}
              defaultValue={groupParams.filter.name_contain}
            />
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={groups}
              scroll={{ x: 'calc(700px + 50%)', y: 0 }}
              pagination={false}
            />
            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={currentPage}
              total={counter}
              defaultPageSize={groupParams.limit}
              onChange={onChangePage}
              style={{
                marginTop: 20,
                right: 0,
              }}
            />
            {isShow && (
              <Modal
                width={400}
                visible={true}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={'Remove'}
                title={'Reason remove group'}
              >
                <Row type="flex" justify="center">
                  <Col span={24}>
                    <Form>
                      <Form.Item label={'Reason'}>
                        {getFieldDecorator(`reason`, {
                          rules: [
                            {
                              required: true,
                              message: 'Please input reason',
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Modal>
            )}
          </div>
        )}
      </Content>
    </Styled.Container>
  );
};

const Group = Form.create()(GroupImpl);
export default Group;
