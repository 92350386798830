import React, { useState } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from '../indexStyled';
import { useMutation } from '@apollo/react-hooks';
import { CmsSetUserStatus, CmsSetUserStatusVariables, ReportFor, Gender } from '../../../graphql/types';
import { CMS_SET_USER_STATUS } from '../../../graphql/user/users';
import { ToastSuccess } from '../../../components/Toast';
import { countries, getDialCodeByCountryCode } from 'src/utils/countries';

const { Option } = Select;

interface defaultData {
  _id?: any;
  fullName?: string;
  phoneNumber?: string;
  email?: string;
  password?: string;
  gender?: string;
  group?: string;
  status?: string;
  countryCode?: string;
}

interface defaultProps extends FormComponentProps {
  defaultData?: defaultData;
  form: any;
}

const FormDetail = (props: defaultProps) => {
  const { getFieldDecorator } = props.form;
  const [showModal, setShowModal] = useState(false);
  const [statusValue, setStatusValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const genders = [Gender.MALE, Gender.FEMALE, Gender.OTHER];
  const groups = ['ADMIN', 'STAFF', 'PROVIDER', 'MEMBER'];
  const statuses = ['NORMAL', 'BLOCKED', 'SUSPENDING'];

  const [updateUserStatus] = useMutation<CmsSetUserStatus, CmsSetUserStatusVariables>(CMS_SET_USER_STATUS);

  const handleSelectChange = () => {};

  const changeStatus = value => {
    setShowModal(true);
    setStatusValue(value);
  };

  const handleOk = () => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        setShowModal(false);
        updateUserStatus({
          variables: {
            _id: props.defaultData._id,
            termStatus: props.form.getFieldValue('status'),
            model: ReportFor.USER,
            reason: '',
          },
        })
          .then(() => ToastSuccess({ message: 'Success', description: 'Update status success' }))
          .catch(err => {});
      }
    });
  };

  const handleCancel = () => {
    props.form.setFieldsValue({
      status: props.defaultData.status,
    });
    setShowModal(false);
  };

  const prefixCountryCode = getFieldDecorator('countryCode', {
    initialValue: props?.defaultData?.countryCode ?? 'VN',
  })(
    <Select
      className="country-code-select"
      disabled={!!props?.defaultData}
      showSearch
      filterOption={(input, option) =>
        option.props.children
          .toLocaleString()
          .toLocaleLowerCase()
          .indexOf(input.toLocaleLowerCase()) >= 0 ||
        option.props.value
          .toLocaleString()
          .toLocaleLowerCase()
          .indexOf(input.toLocaleLowerCase()) >= 0
      }
    >
      {countries.map(country => (
        <Option value={country.code} key={country.code}>
          {country.dial_code}
        </Option>
      ))}
    </Select>,
  );

  return (
    <Styled.Container>
      <Form.Item label="Full name">
        {getFieldDecorator('fullName', {
          initialValue: props?.defaultData?.fullName ?? '',
          rules: [{ required: !props?.defaultData, message: 'Please input your full name!' }],
        })(<Input placeholder="Input your full name" disabled={!!props?.defaultData} />)}
      </Form.Item>

      {props?.defaultData && (
        <Form.Item label="E-mail">
          {getFieldDecorator('email', {
            initialValue: props?.defaultData?.email ?? '',
          })(<Input placeholder="Input your email" disabled />)}
        </Form.Item>
      )}

      <Form.Item label="Phone number">
        {getFieldDecorator('phoneNumber', {
          initialValue:
            props?.defaultData?.phoneNumber && props?.defaultData?.countryCode
              ? props.defaultData.phoneNumber.slice(
                  getDialCodeByCountryCode(props.defaultData.countryCode).length,
                  props.defaultData.phoneNumber.length,
                )
              : '',
          rules: [{ required: !props?.defaultData, message: 'Please input your phome number!' }],
        })(
          <Input
            addonBefore={prefixCountryCode}
            placeholder="Input your phone number"
            disabled={!!props?.defaultData}
          />,
        )}
      </Form.Item>

      <Form.Item label="Gender">
        {getFieldDecorator('gender', {
          initialValue: props?.defaultData?.gender || 'MALE',
        })(
          <Select
            placeholder="Select a option and change input text above"
            onChange={handleSelectChange}
            disabled={!!props?.defaultData}
          >
            {genders.map((r, i) => {
              return (
                <Option key={i} value={r}>
                  {r}
                </Option>
              );
            })}
          </Select>,
        )}
      </Form.Item>

      {props?.defaultData && (
        <>
          <Form.Item label="Group">
            {getFieldDecorator('group', {
              initialValue: props?.defaultData?.group || 'MEMBER',
              rules: [{ required: true, message: 'Please select your group!' }],
            })(
              <Select placeholder="Select a option and change input text above" onChange={handleSelectChange}>
                {groups.map((r, i) => {
                  return (
                    <Option key={i} value={r}>
                      {r}
                    </Option>
                  );
                })}
              </Select>,
            )}
          </Form.Item>

          <Form.Item label="Status">
            {getFieldDecorator('status', {
              initialValue: props?.defaultData?.status || '',
              rules: [{ required: true, message: 'Please select your status!' }],
            })(
              <Select placeholder="Select a option and change input text above" onChange={value => changeStatus(value)}>
                {statuses.map((r, i) => {
                  return (
                    <Option key={`status-${i}`} value={r}>
                      {r}
                    </Option>
                  );
                })}
              </Select>,
            )}
          </Form.Item>
        </>
      )}

      {!props?.defaultData && (
        <>
          <Form.Item label="Password">
            {getFieldDecorator('password', { rules: [{ required: true, message: 'Please input your password!' }] })(
              <Input.Password placeholder="Input your password" autoComplete="new-password" />,
            )}
          </Form.Item>

          <Form.Item label="Password confirm">
            {getFieldDecorator('passwordConfirm', {
              rules: [
                { required: true, message: 'Please input your password confirm!' },
                {
                  validator: (_, value, callback) => {
                    if (value && value !== props.form.getFieldValue('password')) {
                      callback('Two passwords that you enter is inconsistent!');
                    }
                    callback();
                  },
                },
              ],
            })(<Input.Password placeholder="Input your confirm password" />)}
          </Form.Item>
        </>
      )}

      <Modal title="Change user status" visible={showModal} onOk={() => handleOk()} onCancel={() => handleCancel()}>
        <p>
          Would you like to change the status to <strong>{statusValue}</strong>
        </p>
        {statusValue == 'BLOCKED' ? (
          <Form.Item>
            {getFieldDecorator('reason', {
              initialValue: '',
              rules: [{ required: true, message: 'Please select your reason!' }],
            })(
              <Input
                placeholder="Reason to blocked"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
              />,
            )}
          </Form.Item>
        ) : (
          ''
        )}
      </Modal>
    </Styled.Container>
  );
};
export default FormDetail;
