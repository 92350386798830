import React, { useState, useImperativeHandle, useEffect } from 'react';
import { Upload, Modal, Icon } from 'antd';
import { UPLOAD_PHOTO } from 'src/graphql/photo/uploadPhoto';
import { UploadPhoto, UploadPhotoVariables } from 'src/graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { ToastError } from 'src/components/Toast';

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

interface IEditProps {
  onUploaded?: any;
  images?: any;
  numberImage?:number;
  onChangeImage?: any
}

const UploadImpl = React.forwardRef((props: IEditProps, ref) => {
  const [imageList, setimageList] = useState<Array<any>>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [mediaIds] = useState([]);
  const [uploadPhoto] = useMutation<UploadPhoto, UploadPhotoVariables>(UPLOAD_PHOTO, {
    refetchQueries: ['uploadPhoto'],
  });

  const imgNumber =  props.numberImage ||5;

  useEffect(function() {
    let imgs = [];
    if (props.images) {
      props.images.map(function(r, i) {
        if(r){
          imgs.push({
            uid: r._id,
            thumbUrl: r.uri,
            url: r.uri,
            uploaded: true,
          });
        }
      });
      setimageList(imgs);
    }
  }, []);

  useImperativeHandle(ref, () => ({
    upload() {
      let ids = mediaIds;
      const result = imageList.filter(row => !row.uploaded);
      if (result.length > 0) {
        result.map((row, index) => {
          uploadPhoto({
            variables: {
              file: row.originFileObj,
              dimensions: {
                height: 100,
                width: 100,
              },
            },
          }).then(res => {
            ids.push(res.data.uploadPhoto._id);
            if (ids.length === result.length) {
              const uploadeds = imageList.filter(row => row.uploaded);
              if (uploadeds.length > 0) {
                uploadeds.map(function(r, i) {
                  ids.push(r.uid);
                });
              }
              if (typeof props.onUploaded === 'function') {
                props.onUploaded(ids);
              }
            }
          }).catch(err=>{
            console.log("errors : ",err);
          });

        });
      } else {
        const uploadeds = imageList.filter(row => row.uploaded);
        if (uploadeds.length > 0) {
          uploadeds.map(function(r, i) {
            ids.push(r.uid);
          });
        }
        props.onUploaded(ids);
      }
    },
  }));

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
  };

  const handleChange = ({ fileList }) => {
    if(imageList.length < 5 || fileList.length < 5){
      const max = 2 * 1024 * 1024;
      const error = fileList.filter(row => row.size > max);
      if (error.length > 0) {
        ToastError({
          message: 'Upload image error',
          description: `Please select image has size less than ${max / (1024 * 1024)} MB`,
        });
      }
      const data = fileList.filter(row => row.size?row.size <= max:true);
      props?.onChangeImage && props?.onChangeImage();
      setimageList(data.slice(0,imgNumber));
      setPreviewVisible(false);
    }else{
      ToastError({
        message: 'Upload image error',
        description: `You can only upload up to 5 photos`,
      });
    }
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      <Upload
        listType="picture-card"
        fileList={imageList}
        multiple={true}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={file => {
          return false;
        }}
        accept="image/*"
      >
        {imageList.length >= imgNumber ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
});

export default UploadImpl;
