import React, { useRef, useState } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { CMS_SET_PLACE_PRIMARY_IMAGE } from '../../../graphql/place/place';
import { CmsSetPlacePrimaryImage, CmsSetPlacePrimaryImageVariables } from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import Upload from './Uploads';

interface IData {
  _id?: any;
  type?: string;
  actionType?: string;
  points?: number;
}

interface IDefaultProps extends FormComponentProps {
  visible: boolean;
  closeModal: any;
  data?: IData;
  form: any;
}

const EditConfig = (props: IDefaultProps) => {
  const refs = useRef(null);

  const [updateConfig] = useMutation<CmsSetPlacePrimaryImage, CmsSetPlacePrimaryImageVariables>(
    CMS_SET_PLACE_PRIMARY_IMAGE,
    {
      refetchQueries: ['TopPlaces'],
    },
  );

  const handleOk = e => {
    if (typeof refs.current.upload === 'function') {
      refs.current.upload();
    }
    // setShow(false);
    // props.closeModal();
  };

  const afterUpload = (mediaIds: []) => {
    handleSubmit(mediaIds);
  };

  const handleSubmit = mediaIds => {
    updateConfig({
      variables: {
        _id: props.data._id,
        imageId: mediaIds && mediaIds[0],
      },
    })
      .catch(e => {
        console.log(e.toLocaleString());
      })
      .then(() => {
        setShow(false);
        props.closeModal();
      });
  };

  const handleCancel = e => {
    setShow(false);
    props.closeModal();
  };

  const { visible } = props;
  const [show, setShow] = useState(visible);
  return (
    <div>
      <Modal
        title={props.data._id ? 'Edit Media' : ''}
        visible={show}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'Save'}
      >
        <Row type="flex" justify="center">
          <Col span={24}>
            <Form onSubmit={handleSubmit}>
              <Row type="flex" justify="center" gutter={8}>
                <Col span={24}>
                  <Upload ref={refs} onUploaded={(data: any) => afterUpload(data)} />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default EditConfig;
