import { gql } from 'apollo-boost';

export const GET_TRUSTED_POINT_CONFIGS = gql`
  query GetTrustedPointConfigs ($q: String) {
    getTrustedPointConfigs (q: $q)  {
      _id
      type
      actionType
      points
      description
      trustedPointsFrom
      trustedPointsTo
      rankFrom
      rankTo
      engagementAdjustPercent
      postContentLength
      totalPlaceReviewsFrom
      totalPlaceReviewsTo
      newPlaceCoefficient
      adjustPointPercent
      unlimitedPostsPerDayWhenTrustedPointGreaterThan
      maxTenPostsPerDayWhenTrustedPointLessThan
      maxFivePostsPerDayWhenTrustedPointLessThan
      createdAt
      invitedFrom
      invitedTo
    }
  }
`;

export const UPDATE_TRUSTED_POINT_CONFIG = gql`
mutation UpdateTrustedPointConfig($_id: ID!, 
  $type: TrustedPointConfigType!, 
  $description: String, 
  $trustedPointsFrom: Int, 
  $trustedPointsTo: Long, 
  $rankFrom: Int, 
  $rankTo: Int, 
  $engagementAdjustPercent: Int, 
  $postContentLength: Int, 
  $totalPlaceReviewsFrom: Int, 
  $totalPlaceReviewsTo: Long, 
  $newPlaceCoefficient: Int, 
  $adjustPointPercent: Int, 
  $unlimitedPostsPerDayWhenTrustedPointGreaterThan: Int, 
  $maxTenPostsPerDayWhenTrustedPointLessThan: Int, 
  $maxFivePostsPerDayWhenTrustedPointLessThan: Int, 
  $points: Float!,
  $invitedFrom: Int, 
  $invitedTo: Int){
  updateTrustedPointConfig (
    _id: $_id, 
    data: {
      type: $type,
      points: $points,
      description: $description,
      trustedPointsFrom: $trustedPointsFrom,
      trustedPointsTo: $trustedPointsTo,
      rankFrom: $rankFrom,
      rankTo: $rankTo,
      engagementAdjustPercent: $engagementAdjustPercent,
      postContentLength: $postContentLength,
      totalPlaceReviewsFrom: $totalPlaceReviewsFrom,
      adjustPointPercent: $adjustPointPercent,
      totalPlaceReviewsTo: $totalPlaceReviewsTo,
      newPlaceCoefficient: $newPlaceCoefficient,
      unlimitedPostsPerDayWhenTrustedPointGreaterThan: $unlimitedPostsPerDayWhenTrustedPointGreaterThan,
      maxTenPostsPerDayWhenTrustedPointLessThan: $maxTenPostsPerDayWhenTrustedPointLessThan,
      maxFivePostsPerDayWhenTrustedPointLessThan: $maxFivePostsPerDayWhenTrustedPointLessThan,
      invitedFrom: $invitedFrom,
      invitedTo: $invitedTo,
    }
  ){
    _id
    type
    actionType
    points
    invitedFrom
    invitedTo
  }
}
`;
