import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Layout, Row, Spin, Switch } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { ToastError, ToastSuccess } from 'src/components/Toast';
import UploadImage from './components/Uploads';
import { useMutation } from '@apollo/react-hooks';
import {  createEvent, createEventVariables } from 'src/graphql/types';
import { CMS_CREATE_EVENT } from 'src/graphql/event';
import history from '../../history';

const { Content } = Layout;

interface IEditProps extends FormComponentProps {
  match?: any;
}

const EventImpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);
  const logoRefs = useRef(null);
  const backgroudRefs = useRef(null);
  const animationRefs = useRef(null);
  const [createEvent, {data}] = useMutation<createEvent, createEventVariables>(CMS_CREATE_EVENT);
  const [logoId, setLogoId] = useState(null);
  const [backgroundId, setBackgroundId] = useState(null);
  const [animationId, setAnimationId] = useState(null);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof logoRefs.current.upload === 'function') {
          logoRefs.current.upload();
        }
        if (typeof backgroudRefs.current.upload === 'function') {
          backgroudRefs.current.upload();
        }
        if (typeof animationRefs.current.upload === 'function') {
          animationRefs.current.upload();
        }
      }else{
        setLoading(false);
      }
    });
  };

  const afterUploadLogo = (mediaIds: []) => {
    if(!mediaIds.length ){
      setLoading(false);
      ToastError({
        message: "Logo's required",
        description:'Please select logo for this event type'
      });
      return;
    }
    setLogoId(mediaIds)
  };

  const afterUploadBackground = (mediaIds: []) => {
    if(!mediaIds.length ){
      setLoading(false);
      ToastError({
        message: "Background's required",
        description:'Please select background for this event type'
      });
      return;
    }
    setBackgroundId(mediaIds)
  };

  const afterUploadAnimation = (mediaIds: []) => {
    if(!mediaIds.length ){
      setLoading(false);
      ToastError({
        message: "Animation's required",
        description:'Please select animation for this event type'
      });
      return;
    }
    setAnimationId(mediaIds)
  };

  useEffect(() => {
    if(animationId && logoId && backgroundId){
      props.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          if (values.name.length > 100) {
            setLoading(false);
            ToastError({
              message: 'Create event error.',
              description: `The maximum length of the title is 100 characters.`,
            });
          } else {
            let data: createEventVariables = {
              name: values.name,
              loop: Number(values.loop),
              logoStatus: values.logoStatus,
              backgroundStatus: values.backgroundStatus,
              animationStatus: values.animationStatus,
              logoId: logoId[0],
              backgroundId: backgroundId[0],
              animationId: animationId[0]
            };
            createEvent({variables: data});
          }
        } else {
          setLoading(false);
        }
      });
    }
  }, [logoId, backgroundId, animationId]);

  useEffect(() => {
    if (data) {
      history.push('/events');
      ToastSuccess({ message: 'Created', description: 'Event create success' });
    }
  }, [data]);

  return <Styled.Container>
    <Content className="content voucher-section">

      <Breadcrumb>
        <Breadcrumb.Item>Event</Breadcrumb.Item>
        <Breadcrumb.Item>Create</Breadcrumb.Item>
      </Breadcrumb>

      <Row type="flex" justify="center">
        <Col span={ 20 }>
          <Form { ...formItemLayout } onSubmit={ handleSubmit }>
            <Form.Item label="Event name" labelCol={{ span: 4 }} wrapperCol={{ span: 10 }}>
              { getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    whitespace:true,
                    message: "Please input event's name!"

                  },
                ],
              })(<Input autoComplete="off" />) }
            </Form.Item>
            <Row>
              <Col span={8}>
                <Form.Item label="Logo status"  >
                  { getFieldDecorator('logoStatus',{
                    initialValue: true,
                    valuePropName: 'checked'
                  })(<Switch  />)}

                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Background status" >
                  { getFieldDecorator('backgroundStatus',{
                    initialValue: true,
                    valuePropName: 'checked'
                  })(<Switch  />) }
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Animation status" >
                  { getFieldDecorator('animationStatus',{
                    initialValue: true,
                    valuePropName: 'checked'
                  })(<Switch  />) }
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <Form.Item label="Logo">
                  { getFieldDecorator('logo'
                  )(
                    <UploadImage
                      ref={ logoRefs }
                      numberImage={1}
                      onUploaded={ (data: any) => afterUploadLogo(data) } />
                  ) }
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Background">
                  { getFieldDecorator('background'
                  )(
                    <UploadImage
                      ref={ backgroudRefs }
                      numberImage={1}
                      onUploaded={ (data: any) => afterUploadBackground(data) } />
                  ) }
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="animation">
                  { getFieldDecorator('animation'
                  )(
                    <UploadImage
                      ref={ animationRefs }
                      numberImage={1}
                      onUploaded={ (data: any) => afterUploadAnimation(data) } />
                  ) }
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Loop" labelCol={{ span: 4 }} wrapperCol={{ span: 4 }}>
              { getFieldDecorator('loop', {
                rules: [
                  {
                    required: true,
                    whitespace:true,
                    message: 'Please input loop number!',

                  },
                ],
              })(<span className="d-flex"><Input type="number" min={0} /> <div className="ml-3">second</div></span>) }
            </Form.Item>


            <Form.Item { ...buttonItemLayout } >
              <Button type="primary" disabled={ loading } htmlType="submit">Submit</Button>
              { loading && (<Spin indicator={ <LoadingOutlined style={ { fontSize: 24 } } spin /> } />) }
            </Form.Item>
          </Form>
        </Col>
      </Row>

    </Content>
  </Styled.Container>;
};

const EventCreate = Form.create()(EventImpl);
export default EventCreate;
