import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Layout, Modal, Row, Select, Skeleton, Table, Input, Popconfirm, Pagination } from 'antd';
const { Search } = Input;
import { ToastError, ToastSuccess } from '../../components/Toast';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { GET_BADGES, DELETE_BADGE, UPDATE_BADGE } from '../../graphql/badge/badge';
import {
  SortByDirection,
  BadgeSortByType,
  cmsListBadges,
  cmsListBadgesVariables,
  BadgeStatus,
  cmsDeleteBadge,
  cmsDeleteBadgeVariables,
  cmsUpdateBadgeVariables,
  cmsUpdateBadge
} from '../../graphql/types';

import {
  useMutation,
  useQuery
} from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
// import { ToastError, ToastSuccess } from '../../components/Toast';
import BadgeDetail from './modal/detail';

const { Content } = Layout;
const { Option } = Select;

interface IEditProps extends FormComponentProps {
  match?: any;
}


const BadgeImpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const [isLoading, setLoading] = useState(true);
  const [isShowDetail, setShowDetailModal] = useState(false);
  const statuses = ['ALL', BadgeStatus.ENABLED, BadgeStatus.DISABLED];

  const [bagdeParam, setBadgeParam] = useState({
    limit: 10,
    page: 1,
    q: ""
  });
  const [badges, setBadges] = useState([]);
  const [counter, setCounter] = useState(0);
  const [editRow, setEditRow] = useState(null);

  let { data, error, loading } = useQuery<cmsListBadges, cmsListBadgesVariables>(GET_BADGES, {
    fetchPolicy: 'network-only',
    variables: bagdeParam,
  });

  const [deleteBadge] = useMutation<
    cmsDeleteBadge,
    cmsDeleteBadgeVariables
  >(DELETE_BADGE, { refetchQueries: ['cmsListBadges'] });

  const [updateBadge] = useMutation<
    cmsUpdateBadge,
    cmsUpdateBadgeVariables
  >(UPDATE_BADGE, { refetchQueries: ['cmsListBadges'] });

  const columns = [
    {
      title: 'Image',
      key: 'media',
      dataIndex: 'media',
      sorter: false,
      render: (media) => {
        if (media && media.uri) {
          return (
            <div className="thumb">
              <img src={media.uri} alt="" />
            </div>
          );
        }
        return <>{"-"}</>
      },
    },
    {
      title: 'Tile',
      key: 'title',
      dataIndex: 'title',
      sorter: true,
    },
    {
      title: 'Tile EN',
      key: 'titleEn',
      dataIndex: 'titleEn',
      sorter: false,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      render: row => {
        let type: any = row == BadgeStatus.ENABLED ? 'primary' : 'danger';
        return (
          <Button
            className="btn-rectangle"
            type={type} size="small"
          // onClick={e => setEditStatus(row)}
          >
            {row}
          </Button>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      render: row => {
        return (
          <div style={{
            display: 'flex',
          }}>
            <Button
              type="default"
              icon="eye"
              size="small"
              onClick={() => showDetail(row)} />


            <Link to={{
              pathname: "/badges/edit",
              state: row
            }}>
              <Button
                type="primary"
                icon="edit"
                size="small"
                onClick={() => { }}
                style={{ marginLeft: 20 }} />
            </Link>

            <Popconfirm
              placement="left"
              title={"Are you sure to delete this badge?"}
              onConfirm={e => confirm(row._id)} okText="Yes" cancelText="No">
              <Button
                type="danger"
                icon="delete"
                size="small"
                onClick={e => {

                }}
                style={{ marginLeft: 20 }} />
            </Popconfirm>

          </div>
        );
      },
    },
  ];

  const confirm = id => {
    deleteBadge({
      variables: {
        _id: id,
      },
    }).then(res => {
      if (res && res.data && res.data.cmsDeleteBadge) {
        ToastSuccess({ message: 'Deleted', description: 'Badge has been deleted!' });
      } else {
        ToastError({ message: 'Deleted', description: 'Badge cannot delete!' });
      }
    }).catch((err) => {
      ToastError({ message: 'Deleted', description: 'Badge cannot delete!' });
    });

  };

  const [dynamicColumns, setDynamicColumns] = useState(columns);

  const showDetail = row => {
    console.log('BACHK______showDetail:', row)
    setEditRow(row);
    setShowDetailModal(true);
  }

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter && sorter.columnKey) {
      if (sorter.order) {
        let columnKey = sorter.columnKey;
        let order = sorter.order == 'ascend' ? SortByDirection.ASC : SortByDirection.DESC;

        if (sorter.columnKey == 'image' || sorter.columnKey == 'title' || sorter.columnKey == 'status') {
          let c = dynamicColumns.map(item => {
            if (item.key == columnKey) {
              return { ...item, sortOrder: sorter.order };
            }
            return { ...item, sortOrder: false };
          });
          setDynamicColumns(c);
          // update data
          if (columnKey == 'title') {
            // @ts-ignore
            setBadgeParam({ ...bagdeParam, sortBy: { type: BadgeSortByType.TITLE, dir: order } });
          } else if (columnKey == 'status') {
            // @ts-ignore
            setBadgeParam({ ...bagdeParam, sortBy: { type: BadgeSortByType.STATUS, dir: order } });
          }
        }
      } else {
        if (sorter.columnKey == 'image' || sorter.columnKey == 'title' || sorter.columnKey == 'status') {
          let c = dynamicColumns.map(item => {
            return { ...item, sortOrder: false };
          });
          setDynamicColumns(c);
          setBadgeParam({
            limit: bagdeParam.limit,
            page: bagdeParam.page,
            q: bagdeParam.q,
          });
        }
      }
    }
  };

  const onSearch = value => {
    setBadgeParam({ ...bagdeParam, page: 1, q: value })
  };

  useEffect(() => {
    if (loading) setLoading(true);
    if (!loading) setLoading(false);
  }, [loading]);

  useEffect(() => {
    if (data && data.cmsListBadges.items && data.cmsListBadges.counter > 0) {
      setBadges(data.cmsListBadges.items);
      setCounter(data.cmsListBadges.counter)
    } else {
      setBadges([]);
      setCounter(0);
    }
  }, [data]);

  const onChangePage = (page) => {
    setBadgeParam({ ...bagdeParam, page: page })
  }

  const onShowSizeChange = (current, pageSize) => {
    let cOffset = counter / pageSize;
    let cOffsetUpward = Math.ceil(cOffset);
    let nOffset = bagdeParam.page;
    if (cOffsetUpward < bagdeParam.page) {
      nOffset = cOffsetUpward;
    }

    setBadgeParam({ ...bagdeParam, limit: pageSize, page: nOffset });
  }

  const onFilterStatusChange = (e) => {
    if (e == "ALL") {
      setBadgeParam({
        limit: bagdeParam.limit,
        page: bagdeParam.page,
        q: bagdeParam.q,
      })
    } else {
      setBadgeParam({
        ...bagdeParam,
        // @ts-ignore
        filter: { status: e },
        page: 1
      })
    }
  }

  const [formUpdated, setFormUpdated] = useState(null);
  const _renderDetailModal = () => {
    if (!editRow) {
      return null;
    }
    return (
      <Modal
        title="Chi tiết huy hiệu"
        visible={isShowDetail}
        okText="OK"
        cancelText="Cancel"
        onOk={actionUpdateBadge}
        onCancel={() => setShowDetailModal(false)}
      // cancelButtonProps={{ style: { display: 'none' } }}
      >
        <BadgeDetail _id={editRow._id} onRowUpdated={onFormUpdated} />
      </Modal>
    )
  }

  const onFormUpdated = (formUpdated) => {
    setFormUpdated(formUpdated);
  }

  const actionUpdateBadge = () => {
    if (formUpdated && editRow) {

      let registerFormIds = [];
      let idsCount = (formUpdated && formUpdated.registerForms) ? formUpdated.registerForms.length : 0;
      if (idsCount > 0) {
        for (let i = 0; i < idsCount; i++) {
          registerFormIds.push(formUpdated.registerForms[i]._id);
        }
      }

      let variables = {
        _id: editRow._id,
        title: formUpdated.title,
        status: formUpdated.status,
        mediaId: formUpdated.media._id,
        registerFormIds: registerFormIds,
      }

      updateBadge({
        variables: variables
      }).then(res => {
        setShowDetailModal(false)
        ToastSuccess({ message: 'Updated', description: 'Updated badge success!' });
      }).catch(err => {
        setShowDetailModal(false)
        ToastError({ message: 'Updated', description: 'Cannot update badge!' });
      })
    } else {
      setShowDetailModal(false);
    }
  }

  if (error) return <Content className="content">Error</Content>;

  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Badge</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row
          className="mt-10 mb-10"
          gutter={16}
          type="flex"
          style={{ alignItems: 'flex-end' }}>
          <Col span={3}>
            <Form.Item label="Badge Status">
              {getFieldDecorator('status', {
                initialValue: statuses[0],
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder="Select status to filter"
                  onChange={e => onFilterStatusChange(e)}
                >
                  {statuses.map((r, i) => {
                    return (
                      <Option key={i} value={r}>
                        {r}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={9} >
            <Form.Item>
              <Search
                placeholder="Enter title"
                enterButton="Search"
                size="large"
                onSearch={value => onSearch(value)} />
            </Form.Item>
          </Col>

          <Col span={12} style={{ textAlign: 'right' }}>
            <Form.Item>
              <Link to={'/badges/create'}>
                <Button type={'primary'}>Add Badge</Button>
              </Link>
            </Form.Item>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsListBadges && data.cmsListBadges.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}> </Skeleton>
        ) : (
            <Row style={{
              backgroundColor: 'white'
            }}>
              <Table rowKey="_id"
                columns={dynamicColumns}
                dataSource={badges}
                scroll={{ x: 'calc(700px + 50%)', y: 0 }}
                pagination={false}
                onChange={onTableChange}
              />

              <Pagination
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                defaultCurrent={bagdeParam.page}
                total={counter}
                defaultPageSize={bagdeParam.limit}
                onChange={onChangePage}
                style={{
                  marginTop: 20,
                  right: 0,
                }}
              />
            </Row>
          )}
      </Content>

      {/* modal detail */}
      {_renderDetailModal()}

    </Styled.Container>
  );
};

const Badge = Form.create()(BadgeImpl);
export default Badge;
