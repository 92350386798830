import styled from 'styled-components';

const Styled = {
  Container: styled.div`
    .content{
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
      min-height: 80vh;
    }
   
    .ant-btn-sm {
      padding: 0;
      height: auto;
      &.btn-rectangle{
        padding: 2px 5px;
      }
    }

    .trusted-point {
      height: 30px;
      border-radius: 30px;
      background: #BDFFCC;
      border: 2px solid #00C763;
      box-sizing: border-box;
      border-radius: 100px;
      padding: 0 8px;
    }

    .trusted-point:hover {
      border: 2px solid #029e4f
      background: #93f5a9;
    }

    .trusted-point div {
      display: flex
    }

    .trusted-point div span {
      font-weight: bolder;
      font-size: 15px;
      line-height: 14px;
      color: #33D156;
      margin-right: 3px
    }

    .country-code-select {
      width: 86px !important;
    }
  `,
};
export default Styled;
