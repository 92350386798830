import React from 'react';
import { Layout, Row, Col, Table, Skeleton } from 'antd';

import Styled from '../indexStyled';
import { dateByFormat } from '../../../helpers/date';
import { useQuery } from '@apollo/react-hooks';
import { CMS_GET_POSTS_BY_CREATOR } from '../../../graphql/report/report';

const { Content } = Layout;

interface Props {
  admin: any;
}

const AdminDetail = (props: Props) => {
  const { admin } = props;

  const getPostsByCreator = () => {
    return useQuery(CMS_GET_POSTS_BY_CREATOR, {
      fetchPolicy: 'network-only',
      variables: {
        targetId: admin._id,
        limit: 10,
      },
    });
  };

  const { data } = getPostsByCreator();

  const columns = [
    {
      title: 'Title',
      render: row => {
        return (
          <div style={{ maxWidth: '200px' }}>
            <strong>{row.title}</strong>
            <p>
              {row.content && row.content.substr(0, 150)}
              {row.content && row.content.length > 150 && '...'}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Total like',
      dataIndex: 'totalLikes',
    },
    {
      title: 'Total comment',
      dataIndex: 'totalComments',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render: row => {
        return <div>{row && dateByFormat(row)}</div>;
      },
    },
  ];

  return (
    <Styled.Container>
      <Content>
        <Row>
          <Col span={4}>
            <div className="thumb thumbCol">
              <img className="image" src={admin.avatar && admin.avatar.uri} alt="" />
            </div>
          </Col>

          <Col span={20}>
            <div style={{ marginLeft: 10 }} className="detail-admin-page">
              <table>
                <tr>
                  <td>
                    <strong>Full name</strong>
                  </td>
                  <td>{admin.fullName}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Email</strong>
                  </td>
                  <td>{admin.email}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Phone number</strong>
                  </td>
                  <td>{admin.phoneNumber}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Created at</strong>
                  </td>
                  <td>{dateByFormat(admin.createdAt)}</td>
                </tr>
                <tr>
                  <td>
                    <strong>{process.env.REACT_APP_PROJECT_NAME} point</strong>
                  </td>
                  <td>{admin.point}</td>
                </tr>
              </table>
            </div>
          </Col>
        </Row>
        <br />
        {data && data.cmsGetPostsByCreator && data.cmsGetPostsByCreator.length ? (
          <Row>
            <Table
              rowKey="_id"
              dataSource={data.cmsGetPostsByCreator}
              columns={columns}
              pagination={false}
              scroll={{ y: 250 }}
            />
          </Row>
        ) : (
          <Skeleton paragraph={{ rows: 3 }} />
        )}
      </Content>
    </Styled.Container>
  );
};

export default AdminDetail;
