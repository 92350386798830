import React, { useState, useImperativeHandle } from 'react';
import { Upload, Modal, Icon } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { UPLOAD_PHOTO } from '../../../graphql/photo/uploadPhoto';
import { UploadPhoto, UploadPhotoVariables } from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

interface IEditProps {
  onUploaded?: any;
}

const UploadImpl = React.forwardRef((props: IEditProps, ref) => {

  const [fileList, setFileList] = useState<Array<UploadFile>>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [mediaIds, setMediaIds] = useState([]);
  const [uploadPhoto] = useMutation<UploadPhoto, UploadPhotoVariables>(UPLOAD_PHOTO, {
    refetchQueries: ['uploadPhoto'],
  });


  useImperativeHandle(ref, () => ({

    upload() {
      if (fileList.length > 0){
        fileList.map((row, i) => {
          uploadPhoto({
            variables: {
              file: row.originFileObj,
              dimensions: {
                height: 100,
                width: 100,
              },
            },
          }).then((res) => {
            let d = mediaIds;
            d.push(res.data.uploadPhoto._id);
            setMediaIds(d);
            if (i === fileList.length - 1) {
              if (typeof props.onUploaded === 'function') {
                props.onUploaded(mediaIds);
              }
            }
          });
        });
      } else{
        props.onUploaded(mediaIds);
      }

    },

  }));

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList);
    setPreviewVisible(false);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const uploadButton = (
    <div>
      <Icon type="plus"/>
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return <div>
    <Upload
      listType="picture-card"
      fileList={fileList}
      multiple={false}
      onPreview={handlePreview}
      onChange={handleChange}
      beforeUpload={(file) => {
        return false;
      }}
      accept="image/*"
    >
      {fileList.length >= 1 ? null : uploadButton}
    </Upload>
    <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
      <img alt="example" style={{ width: '100%' }} src={previewImage}/>
    </Modal>
  </div>;
});

export default UploadImpl;
