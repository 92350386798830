import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Form, Layout, Modal, Table } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from '../../UserPage/indexStyled';
import {  useQuery } from '@apollo/react-hooks';
import {
  cmsListLuckyWheelConfigs,
  cmsListLuckyWheelConfigsVariables,

} from '../../../graphql/types';
import {
  CMS_LIST_LUCKY_WHEEL_CONDITIONS_CONFIG,
} from '../../../graphql/luckyWheel';
import EditCondition from './edit';
// import { ToastError, ToastSuccess } from '../../../components/Toast';

const { Content } = Layout;


interface IEditProps extends FormComponentProps {
  match?: any;
}


const WinningConditionImpl = (props: IEditProps) => {

  const [conditions, setConditions] = useState<any>();
  const [modalEditInstruction, setModalEditInstruction] = useState(false);
  const [conditionEdit,setConditionEdit] = useState(null);
  const [filterParams] = useState({
    limit: 20,
    page: 1,
  });
  const { data: conditionData
  } = useQuery<cmsListLuckyWheelConfigs, cmsListLuckyWheelConfigsVariables>(CMS_LIST_LUCKY_WHEEL_CONDITIONS_CONFIG, {
    variables: {
      limit: filterParams.limit,
      page: filterParams.page,
    },
  });

  const columns = [
    {
      title: 'No.',
      key: 'index',
      width: '5%',
      render: (text, record, index) => index+1,
    },
    {
      title: 'Prize level',
      width:'10%',
      dataIndex: 'prizeTier',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Minimum',
      dataIndex: 'minimum',
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: row => {
        return (
          <div style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}>
            <Button type="primary" icon="edit" size="small" onClick={e => onEditCondition(row)}/>
          </div>
        );
      },
    },
  ];

  useEffect(()=>{
    if(conditionData && conditionData.cmsListLuckyWheelConfigs && conditionData.cmsListLuckyWheelConfigs){
      setConditions(conditionData.cmsListLuckyWheelConfigs)
    }
  },[conditionData]);
  const toggleModalEditCondition = () => {
    setModalEditInstruction(!modalEditInstruction);
  };
  const onEditCondition = (conditionEdit) => {
    setConditionEdit(conditionEdit);
    toggleModalEditCondition();
  };


  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Winning Condition</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>
        <br/>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={conditions}
          onChange={() => {
          }}
          scroll={{ x: 'calc(700px + 50%)', y: 0 }}
          pagination={false}
        /><br/>

      </Content>

      {
        modalEditInstruction &&
        (
          <Modal
            className="winning-condition-modal"
            visible={modalEditInstruction}
            width={900}
            title={'Edit condition'}
            onOk={toggleModalEditCondition}
            onCancel={toggleModalEditCondition}
            footer=""
          >
            <EditCondition
              condition={conditionEdit}
              onClose={toggleModalEditCondition}
            />
          </Modal>
        )
      }
    </Styled.Container>
  );
};

const WinningCondition = Form.create()(WinningConditionImpl);
export default WinningCondition;
