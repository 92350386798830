import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import {
  ChartCard,
} from './../components/Charts';
import { useQuery } from '@apollo/react-hooks';
import {CMS_NUMBER_PARTNER_PAGES} from '../../../graphql/dashboard';
import {
  cmsNumberOfPartnerPages,
} from "../../../graphql/types";

const topColResponsiveProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 6,
  style: { marginBottom: 24 },
};

const IntroduceRow = ({ loading, visitData }: { loading: boolean; visitData: any[] }) => {
  const [numberPartner,setnumberPartners] = useState(0);


  const {data:numberPartnerApi} = useQuery<cmsNumberOfPartnerPages>(CMS_NUMBER_PARTNER_PAGES,{
    fetchPolicy: 'network-only',
  });

  useEffect(()=>{
    if(numberPartnerApi && numberPartnerApi.cmsNumberOfPartnerPages){
      setnumberPartners(numberPartnerApi.cmsNumberOfPartnerPages)
    }
  },[numberPartnerApi]);


  return (
    <Row gutter={24} type="flex">
      <Col {...topColResponsiveProps}>
        <ChartCard
          className="borderRadius12"
          bordered={false}
          title={'Number of pages being partner'}
          loading={loading}
          total={() =>
            <div>
              <div className="d-flex justify-content-center">
               <h2> <strong>{numberPartner}</strong> </h2>
              </div>
            </div>}
          contentHeight={46}
        >
        </ChartCard>
      </Col>
    </Row>
  )
};

export default IntroduceRow;
