import React, { useEffect, useState } from 'react';
import { Form, Layout, Skeleton, Card, Row, Col } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import classNames from 'classnames';
import { FormComponentProps } from 'antd/lib/form';
import Styled from '../../Place/indexStyled';
import { dateByFormat } from '../../../helpers/date';
import { dateTypes } from '../../../configs/dateConfig';
import { CMS_TOP_USERS_POINT_PER_DAY } from '../../../graphql/dashboard';
import { cmsTopUsersByPointsPerDay, cmsTopUsersByPointsPerDayVariables } from '../../../graphql/types';
import { esQueryStringFormat } from '../../../helpers/meHelpers';
const { Content } = Layout;
const moment = require('moment');

const avatar = require('../../../assets/images/icons/dashboard/avatar-default.svg');

interface Props extends FormComponentProps {
  match?: any;
}
const now = new Date();

const TopUsersImpl = (props: Props) => {
  const [isLoading, setLoading] = useState(true);
  const [topUsers, setTopUsers] = useState([]);
  const [dateFilter, setDateFilter] = useState(moment(now).add(-1, 'day'));
  const [filterParams] = useState({
    limit: 10,
    page: 1,
  });

  let { data: topUsersPerDay, error, loading } = useQuery<
    cmsTopUsersByPointsPerDay,
    cmsTopUsersByPointsPerDayVariables
  >(CMS_TOP_USERS_POINT_PER_DAY, {
    fetchPolicy: 'network-only',
    variables: {
      limit: filterParams.limit,
      page: filterParams.page,
      filterDate: moment(dateFilter),
    },
  });

  const onChangeDateTop = date => {
    setDateFilter(moment(date));
  };
  const renderDate = () => {
    let res = [];
    for (let i = 1; i <= 7; i++) {
      res.push(
        <div
          className={classNames('date-top', { active: moment(dateFilter).isSame(subDate(-i, false), 'day') })}
          onClick={() => {
            onChangeDateTop(subDate(-i, false));
          }}
        >
          {subDate(-i)}
        </div>,
      );
    }
    return res;
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (topUsersPerDay && topUsersPerDay.cmsTopUsersByPointsPerDay) {
      setTopUsers(topUsersPerDay.cmsTopUsersByPointsPerDay);
    }
  }, [topUsersPerDay]);

  if (topUsers.length > 0) {
    topUsers.sort(function(a, b) {
      let aName = esQueryStringFormat(a.user.fullName);
      let bName = esQueryStringFormat(b.user.fullName);
      let aPoint = a.earnedPoints;
      let bPoint = b.earnedPoints;

      if (aPoint > bPoint) return -1;
      if (aPoint < bPoint) return 1;
      if (aName < bName) return -1;
      if (aName > bName) return 1;
      return 0;
    });
  }
  const subDate = (number, display = true) => {
    if (display) {
      return dateByFormat(moment(now).add(number, 'day'), dateTypes.displayType2);
    } else {
      return moment(now).add(number, 'day');
    }
  };

  const renderTopUsers = () => {
    let res = [
      <Card.Grid style={{ width: '100%' }}>
        <Row>
          <Col span={6}> Avatar </Col>
          <Col span={8}> Name </Col>
          <Col span={6}> Phone number </Col>
          <Col span={4}> {process.env.REACT_APP_PROJECT_NAME} point/day </Col>
        </Row>
      </Card.Grid>,
    ];

    topUsers.map((data, index) => {
      res.push(
        <Card.Grid key={data.userId} style={{ width: '100%' }}>
          <Row>
            <Col span={6}>
              <div>
                <img src={(data.user.avatar && data.user.avatar.uri) || avatar} className="avatar-top" width="25%" />
              </div>
            </Col>
            <Col span={8}> {data.user.fullName} </Col>
            <Col span={6}> {data.user.phoneNumber} </Col>
            <Col span={4} style={{ color: '#00BAA5' }}>
              {' '}
              <strong>{data.earnedPoints}</strong>{' '}
            </Col>
          </Row>
        </Card.Grid>,
      );
    });
    return res;
  };

  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content borderRadius12">
        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
          <div className="top-users">
            <Card
              title={
                <Row>
                  <Col span={10}>Top 10 user có điểm tăng cao nhất</Col>
                  <Col span={14}>
                    <div className="d-flex justify-content-end">{renderDate()}</div>
                  </Col>
                </Row>
              }
            >
              {renderTopUsers()}
            </Card>
          </div>
        )}
      </Content>
    </Styled.Container>
  );
};

const TopUsers = Form.create()(TopUsersImpl);
export default TopUsers;
