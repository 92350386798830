import React, { useEffect, useState, useRef } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Layout, Modal, Pagination, Row, Select, Skeleton, Table } from 'antd';
const { Search } = Input;
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { GET_REQUEST_BADGES, GET_BADGES } from '../../graphql/badge/badge';
import {
    RequestBadgeStatus,
    cmsListRequestBadges,
    cmsListRequestBadgesVariables,
    BadgeRequestFormSortByType,
    SortByDirection,
    cmsListBadges,
    cmsListBadgesVariables,
} from '../../graphql/types';
import { useQuery } from '@apollo/react-hooks';
import BadgeVerifyDetail from './modal/detailVerify';
const { dateByFormat } = require('../../helpers/date');

const { Content } = Layout;
const { Option } = Select;

interface IEditProps extends FormComponentProps {
    match?: any;
}

const VerifyBadgesImpl = (props: IEditProps) => {
    const { getFieldDecorator } = props.form;
    const [isLoading, setLoading] = useState(true);
    const [editRow, setEditRow] = useState(null);
    const [visibilityDetail, setVisibilityDetail] = useState(false);
    const [requestBadges, setRequestBadges] = useState([]);
    const [counter, setCounter] = useState(0);
    const detailModalRef = useRef(null);
    const [listBadges] = useState({});

    const statuses = [
        'ALL',
        RequestBadgeStatus.PENDING,
        RequestBadgeStatus.APPROVED,
        RequestBadgeStatus.REJECTED,
    ];

    const [rBadgesParams, setRBadgesParams] = useState<cmsListRequestBadgesVariables>({
        limit: 10,
        page: 1,
        q: '',
        filter: {},
    });

    const getListBadges = () => {
        return useQuery<cmsListBadges, cmsListBadgesVariables>(GET_BADGES, {
            fetchPolicy: 'network-only',
            variables: listBadges,
        });
    }; 

    const getUsersVerify = () => {
        return useQuery<cmsListRequestBadges, cmsListRequestBadgesVariables>(GET_REQUEST_BADGES, {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
            variables: rBadgesParams,
        });
    };
    
    let { data, loading } = getUsersVerify();
    let { data:dataListBadge } = getListBadges();

    const columns = [
        {
            title: 'Name',
            dataIndex: 'creator.fullName',
            key: 'creator.fullName',
            sorter: true,
        },
        {
            title: 'Phone number',
            dataIndex: 'phoneNumber',
            sorter: false,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: row => {
                return <>{dateByFormat(row)}</>;
            },
        },
        {
            title: 'Status',
            sorter: true,
            key: "status",
            render: row => {
                let type: any = 'default';
                if (row.status == RequestBadgeStatus.APPROVED) type = 'primary';
                if (row.status == RequestBadgeStatus.REJECTED) type = 'danger';
                return (
                    <Button className="btn-rectangle" type={type} size="small" onClick={e => showDetail(row)}>
                        {row.status}
                    </Button>
                );
            },
        },
    ];
    const [dynamicColumns, setDynamicColumns] = useState(columns);

    const onFilterStatusChange = (e) => {
        if (e == "ALL") {
            setRBadgesParams({
                limit: rBadgesParams.limit,
                page: rBadgesParams.page,
                q: rBadgesParams.q,
                filter: { badge: rBadgesParams.filter.badge }
            })
        } else {
            setRBadgesParams({
                ...rBadgesParams,
                // @ts-ignore
                filter: { ...rBadgesParams.filter ,status: e },
                page: 1
            })
        }
    }

    const onFilterBadgeChange = e => {
        if (e == 'ALL') {
            setRBadgesParams({
                limit: rBadgesParams.limit,
                page: rBadgesParams.page,
                q: rBadgesParams.q,
                filter: { status: rBadgesParams.filter.status }
            })
        } else {
            setRBadgesParams({
                ...rBadgesParams,
                // @ts-ignore
                filter: { ...rBadgesParams.filter, badge: e },
                page: 1
            })
        }
    };

    useEffect(() => {
        if (loading) setLoading(true);
        setLoading(false);
    }, [loading]);

    useEffect(() => {
        if (data && data.cmsListRequestBadges.items && data.cmsListRequestBadges.counter > 0) {
            setRequestBadges(data.cmsListRequestBadges.items);
            setCounter(data.cmsListRequestBadges.counter);
        } else {
            setRequestBadges([]);
            setCounter(0);
        }
    }, [data]);

    const onSearch = value => {
        setRBadgesParams({ ...rBadgesParams, page: 1, q: value })
    };

    const onChangePage = (page) => {
        setRBadgesParams({ ...rBadgesParams, page: page })
    }

    const onShowSizeChange = (current, pageSize) => {
        let cOffset = counter / pageSize;
        let cOffsetUpward = Math.ceil(cOffset);
        let nOffset = rBadgesParams.page;
        if (cOffsetUpward < rBadgesParams.page) {
            nOffset = cOffsetUpward;
        }

        setRBadgesParams({ ...rBadgesParams, limit: pageSize, page: nOffset });
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        if (sorter && sorter.columnKey) {
            if (sorter.order) {
                let columnKey = sorter.columnKey;
                let order = sorter.order == 'ascend' ? SortByDirection.ASC : SortByDirection.DESC;

                if (sorter.columnKey == 'name' || sorter.columnKey == 'title' || sorter.columnKey == 'status' || sorter.columnKey == 'createdAt') {
                    let c = dynamicColumns.map(item => {
                        if (item.key == columnKey) {
                            return { ...item, sortOrder: sorter.order };
                        }
                        return { ...item, sortOrder: false };
                    });
                    setDynamicColumns(c);
                    // update data
                    if (columnKey == 'title') {
                        // @ts-ignore
                        setRBadgesParams({ ...rBadgesParams, sortBy: { type: BadgeRequestFormSortByType.TITLE, dir: order } });
                    } else if (columnKey == 'name') {
                        // @ts-ignore
                        setRBadgesParams({ ...rBadgesParams, sortBy: { type: BadgeRequestFormSortByType.NAME, dir: order } });
                    } else if (columnKey == 'status') {
                        // @ts-ignore
                        setRBadgesParams({ ...rBadgesParams, sortBy: { type: BadgeRequestFormSortByType.STATUS, dir: order } });
                    } else if (columnKey == 'createdAt') {
                        // @ts-ignore
                        setRBadgesParams({ ...rBadgesParams, sortBy: { type: BadgeRequestFormSortByType.CREATED_AT, dir: order } });
                    }
                }
            } else {
                if (sorter.columnKey == 'name' || sorter.columnKey == 'title' || sorter.columnKey == 'status' || sorter.columnKey == 'createdAt') {
                    let c = dynamicColumns.map(item => {
                        return { ...item, sortOrder: false };
                    });
                    setDynamicColumns(c);
                    setRBadgesParams({
                        limit: rBadgesParams.limit,
                        page: rBadgesParams.page,
                        q: rBadgesParams.q,
                    });
                }
            }
        }
    };

    const showDetail = row => {
        setEditRow(row);
        setVisibilityDetail(true);
    }

    const _renderDetailModal = () => {
        if (!editRow) {
            return null;
        }
        return (
            <Modal
                width={1024}
                title="Chi tiết huy hiệu"
                visible={visibilityDetail}
                okText="OK"
                cancelText="Cancel"
                onOk={actionUpdateStatus}
                onCancel={() => setVisibilityDetail(false)}
            >
                <BadgeVerifyDetail
                    _id={editRow._id}
                    ref={detailModalRef}
                    onUpdated={() => {
                        setVisibilityDetail(false);
                    }} />
            </Modal>
        )
    }

    const actionUpdateStatus = () => {
        if (detailModalRef && detailModalRef.current) {
            if (typeof detailModalRef.current.setStatus === 'function') {
                detailModalRef.current.setStatus();
            }
        }
    }
    let dataBadge = dataListBadge ? dataListBadge.cmsListBadges.items : [];

    return (
        <Styled.Container>
            <Content className="content">
                <Breadcrumb>
                    <Breadcrumb.Item>Badge verify</Breadcrumb.Item>
                    <Breadcrumb.Item>List</Breadcrumb.Item>
                </Breadcrumb>

                <Row className="mt-10 mb-10" gutter={16}>
                    <Col span={6}>
                        <Form.Item label="Badge verify Status">
                            {getFieldDecorator('status', {
                                initialValue: statuses[0],
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select status to filter"
                                    onChange={e => onFilterStatusChange(e)}>
                                    {statuses.map((r, i) => {
                                        return (
                                            <Option key={i} value={r}>
                                                {r}
                                            </Option>
                                        );
                                    })}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="Badge title">
                            {getFieldDecorator('badge', {
                                initialValue: 'ALL',
                            })(
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Select badge"
                                    onChange={e => onFilterBadgeChange(e)}>
                                    <Option value=''>
                                        ALL
                                    </Option>
                                    {dataBadge.map((r, i) => {
                                        return (
                                            <Option key={i} value={r._id}>
                                                {r.title}
                                            </Option>
                                        );
                                    })}
                                </Select>,
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={9}>
                        <Form.Item label="Search">
                            <Search
                                placeholder="search"
                                enterButton="Search"
                                size="large"
                                onSearch={value => onSearch(value)} />
                        </Form.Item>
                    </Col>
                </Row>

                <p style={{ marginTop: 15 }}>
                    <strong>Total: {(data && data.cmsListRequestBadges && data.cmsListRequestBadges.counter) || 0}</strong>
                </p>

                {isLoading ? (
                    <Skeleton loading={isLoading} paragraph={{ rows: 5 }}></Skeleton>
                ) : (
                        <Row style={{ backgroundColor: '#fff' }}>
                            <Table
                                rowKey="_id"
                                columns={dynamicColumns}
                                dataSource={requestBadges}
                                pagination={false}
                                onChange={onTableChange} />

                            <Pagination
                                showSizeChanger
                                onShowSizeChange={onShowSizeChange}
                                defaultCurrent={rBadgesParams.page}
                                total={counter}
                                defaultPageSize={rBadgesParams.limit}
                                onChange={onChangePage}
                                style={{
                                    marginTop: 20,
                                    right: 0,
                                }}
                            />
                        </Row>
                    )}
            </Content>

            {_renderDetailModal()}
        </Styled.Container>
    );
};

const VerifyBadge = Form.create()(VerifyBadgesImpl);
export default VerifyBadge;
