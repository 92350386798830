import styled from 'styled-components';

const Styled = {
  Container: styled.div`
    .content {
      background: #fff;
      padding: 15px;
      box-sizing: border-box;
      min-height: 80vh;
    }
    .thumb {
      width: 100%;
      position: relative;
      padding-top: 100%;
      overflow: hidden;
      background-color: #cccccc;
      .image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
      }
      .play_btn {
        position: absolute;
        top: 36%;
        left: 36%;
        cursor: pointer;
      }
    }
  `,
};
export default Styled;
