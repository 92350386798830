import React, { useState, useImperativeHandle, useEffect } from 'react';
import { Upload, Modal, Icon } from 'antd';
import { UPLOAD_PHOTO } from '../../../graphql/photo/uploadPhoto';
import { MediaType, UploadPhoto, UploadPhotoVariables } from '../../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { ToastError } from '../../../components/Toast';

const getBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

interface IEditProps {
  onUploaded?: any;
  numberImagesAllowed?: Number;
  imageAccept?: string;
  maxSize?: string;
  unitSize?:string
  images?: any;
  bannerType?: MediaType;
}

const UploadImpl = React.forwardRef((props: IEditProps, ref) => {
  const [imageList, setimageList] = useState<Array<any>>([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const { numberImagesAllowed, imageAccept, bannerType } = props;
  const [uploadPhoto] = useMutation<UploadPhoto, UploadPhotoVariables>(UPLOAD_PHOTO, {
    refetchQueries: ['uploadPhoto'],
  });

  useEffect(function() {
    let imgs = [];
    if (props.images) {
      props.images.map(function(r, i) {
        imgs.push({
          uid: r._id,
          thumbUrl: r.uri,
          url: r.uri,
          uploaded: true,
        });
      });
      setimageList(imgs);
    }
  }, []);

  useEffect(function() {
    let imgs = [];
    if (props.images) {
      props.images.map(function(r, i) {
        imgs.push({
          uid: r._id,
          thumbUrl: r.uri,
          url: r.uri,
          uploaded: true,
        });
      });
      setimageList(imgs);
    }
  },[props.images]);

  useImperativeHandle(ref, () => ({
    upload() {
      let ids = [];
      const result = imageList.filter(row => !row.uploaded);
      if (result.length > 0) {
        result.map((row, index) => {
          uploadPhoto({
            variables: {
              file: row.originFileObj,
              dimensions: {
                height: 100,
                width: 100,
              },
              bannerType,
            },
          })
            .then(res => {
              ids.push(res.data.uploadPhoto._id);
              if (ids.length === result.length) {
                const uploadeds = imageList.filter(row => row.uploaded);
                if (uploadeds.length > 0) {
                  uploadeds.map(function(r, i) {
                    ids.push(r.uid);
                  });
                }
                if (typeof props.onUploaded === 'function') {
                  props.onUploaded(ids);
                  ids=[];
                }
              }
            })
            .catch(err => {
              console.log('fucking errors : ', err);
            });
        });
      } else {
        const uploadeds = imageList.filter(row => row.uploaded);
        if (uploadeds.length > 0) {
          uploadeds.map(function(r, i) {
            ids.push(r.uid);
          });
        }
        props.onUploaded(ids);
      }
    },
  }));

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);
  };

  const handleChange = ({ fileList }) => {
    if (imageList.length < numberImagesAllowed || fileList.length < numberImagesAllowed) {
      const max =  props.maxSize ? parseInt(props.maxSize) : 10 * 1024 * 1024;
      const error = fileList.filter(row => row.size > max);
      if (error.length > 0) {
        const limitSize = props.unitSize === "kb" ? `${max / (1024 )} Kb` : `${max / (1024 * 1024 )} Mb`;
        ToastError({
          message: 'Upload image error',
          description: `Please select image has size less than ${limitSize}`,
        });
      }
      const data = fileList.filter(row => (row.size ? row.size <= max : true));
      setimageList(data.slice(0, numberImagesAllowed));
      setPreviewVisible(false);
    } else {
      ToastError({
        message: 'Upload image error',
        description: `You can only upload up to 5 photos`,
      });
    }
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">{bannerType ? 'Menu banner' : 'Upload'}</div>
    </div>
  );

  return (
    <div>
      <Upload
        listType="picture-card"
        fileList={imageList}
        multiple={true}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={file => {
          return false;
        }}
        accept={imageAccept || 'image/*'}
      >
        {imageList.length >= numberImagesAllowed ? null : uploadButton}
      </Upload>
      <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
});

export default React.memo(UploadImpl);
