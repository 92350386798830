import React, { Component } from 'react';
import {
  CREATE_MEDIA_LUCKY_WHEEL,
  CLEAR_MEDIA_LUCKY_WHEEL,
  EDIT_MEDIA_LUCKY_WHEEL,
} from './type';

export interface AppContextInterface {
  dummy: any;
  posts: object,
  collapsedSidebar: boolean;
  luckyWheel: {
    medias: any,
    mediasEdit: any,
  };
  onUpdateContext: (...params: any) => void;
  onUpdateLuckyWheelData: (payload: object, type: string) => void;
}

export const AppContext = React.createContext<AppContextInterface>({
  dummy: null,
  collapsedSidebar: false,
  posts: [],
  luckyWheel: {
    medias: [],
    mediasEdit:[]
  },
  onUpdateContext: context => context,
  onUpdateLuckyWheelData: context => context,
});

export class AppProvider extends Component {

  onUpdateContext = context => {
    const newContext = { ...this.state, ...context };
    this.setState(newContext);
  };
  onUpdateLuckyWheelData = (payload, type) => {
    let { luckyWheel } = this.state;
    switch (type) {
      case CREATE_MEDIA_LUCKY_WHEEL: {
        if (payload && (payload.media || payload.mediaSavedId)) {
          luckyWheel = { ...luckyWheel, medias: [...luckyWheel.medias, payload] };
        } else {
          let medias = luckyWheel.medias.filter(item => {
            return item.position !== payload.position;
          });
          luckyWheel = { ...luckyWheel, medias: medias };
        }
        break;
      }
      case CLEAR_MEDIA_LUCKY_WHEEL: {
        luckyWheel = { ...luckyWheel, medias: [],mediasEdit:[] };
        break;
      }
      case EDIT_MEDIA_LUCKY_WHEEL: {
        if (payload && payload.media) {
          luckyWheel = { ...luckyWheel, mediasEdit: [...luckyWheel.mediasEdit, payload] };
        } else {
          let medias = luckyWheel.mediasEdit.filter(item => {
            return item.position !== payload.position;
          });
          luckyWheel = { ...luckyWheel, mediasEdit: medias };
        }
        break;
      }
    }
    this.setState({ ...this.state, luckyWheel });
  };

  state = {
    dummy: null,
    collapsedSidebar: false,
    posts: [],
    luckyWheel: {
      medias: [],
      mediasEdit: [],
    },
    onUpdateContext: this.onUpdateContext,
    onUpdateLuckyWheelData: this.onUpdateLuckyWheelData,
  };

  render() {
    return <AppContext.Provider value={{ ...this.state }}>{this.props.children}</AppContext.Provider>;
  }
}

export default AppProvider;
