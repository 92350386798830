import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import Homepage from './pages/Homepage';
import TopUsers from './pages/Homepage/top-users';
import DashboardContent from './pages/Homepage/contents';
import UserPage from './pages/UserPage';
import ProviderPage from './pages/UserPage/providers';
import UserUpdate from './pages/UserPage/update';
import UserCreate from './pages/UserPage/create';
import SettingPage from './pages/Config';
import Post from './pages/Post';
import BlockedPost from './pages/Post/blockedPosts';
import PostCreate from './pages/Post/create';
import PostDetail from './pages/Post/detail';
import PostUpdate from './pages/Post/update';
import VoucherUpdate from './pages/Voucher/update';
import Voucher from './pages/Voucher';
import CreateVoucher from './pages/Voucher/create';
import Request from './pages/PageVerify';
import VerifyUser from './pages/PageVerify/verifiUser';
import PageReport from './pages/Report/pageReport';
import PostReport from './pages/Report/postReport';
import UserReport from './pages/Report/userReport';
import CommentReport from './pages/Report/commentReport';
import GroupReport from './pages/Report/groupReport';
import MediaReport from './pages/Report/mediaReport';
import ProductReport from './pages/Report/productReport';
import Product from './pages/Product';
import Page from './pages/Page';
import Place from './pages/Place';
import Group from './pages/Group';
import TrustedPoint from './pages/TrustedPoint';
import SystemNotification from './pages/SystemNotification';
import PageCategory from './pages/PageCategory';
import Banner from './pages/Banner';
import CreateBanner from './pages/Banner/create';
import UpdateBanner from './pages/Banner/update';
import BannerRunningApp from './pages/Banner/listApp';
import SensitiveWords from './pages/SensitiveWords';
import Settings from './pages/Settings';
import CreateLuckyWheel from './pages/LuckyWheel/create';
import LuckyWheels from './pages/LuckyWheel/index';
import UpdateLuckyWheel from './pages/LuckyWheel/update/index';
import InstructionLuckyWheel from './pages/LuckyWheel/instruction/index';
import WinningCondition from './pages/LuckyWheel/winning-condition/index';
import Badge from './pages/Badge';
import BadgeCreate from './pages/Badge/create';
import BadgeEdit from './pages/Badge/editBadge';
import BadgeVerifyList from './pages/Badge/VerifyList';
import BadgeRegisterForm from './pages/Badge/RegisterForm';
import LuckyWheelRunning from './pages/LuckyWheel/luckyWheelRunning';
import MessageResult from './pages/LuckyWheel/message-result';
import LuckyWheelTerms from './pages/LuckyWheel/luckyWheelTerms';
import CreateMenuBanner from './pages/Banner/createMenuBanner';
import TopReferral from './pages/Homepage/top-referral';
import ReferralVouchers from './pages/Voucher/referral';
import ReferralVoucherCodes from './pages/Voucher/referral/referralCodes';
import Events from './pages/Events/index';
import EventCreate from './pages/Events/create';
import EventUpdate from './pages/Events/update';
import Profile from './pages/Account/Profile';

const PageLoading = () => (
  <div
    style={{
      width: '100vw',
      position: 'absolute',
      height: '100vh',
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <h6>Loading...</h6>
  </div>
);

const ProtectedRoute = ({ component: Component = null, path, children = null, user = null, isLogin, ...rest }) => {
  if (!isLogin) return <Redirect to={'/login'} />;
  if (children) {
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    );
  }
  return <Route path={path} {...rest} render={props => <Component {...props} />} />;
};

const renderRoutes = (isLogin: boolean) => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <ProtectedRoute exact path="/account/profile" component={Profile} isLogin={isLogin} />
      <ProtectedRoute exact path="/" component={Homepage} isLogin={isLogin} />
      <ProtectedRoute exact path="/dashboard" component={Homepage} isLogin={isLogin} />
      <ProtectedRoute exact path="/dashboard/top-users" component={TopUsers} isLogin={isLogin} />
      <ProtectedRoute exact path="/dashboard/contents" component={DashboardContent} isLogin={isLogin} />
      <ProtectedRoute exact path="/dashboard/top-referrals" component={TopReferral} isLogin={isLogin} />
      <ProtectedRoute exact path="/users" component={UserPage} isLogin={isLogin} />
      <ProtectedRoute exact path="/users/providers" component={ProviderPage} isLogin={isLogin} />
      <ProtectedRoute exact path="/users/create" component={UserCreate} isLogin={isLogin} />
      <ProtectedRoute exact path="/users/:type" component={UserPage} isLogin={isLogin} />
      <ProtectedRoute exact path="/posts" component={Post} isLogin={isLogin} />
      <ProtectedRoute exact path="/posts/blocked" component={BlockedPost} isLogin={isLogin} />
      <ProtectedRoute exact path="/posts/create" component={PostCreate} isLogin={isLogin} />
      <ProtectedRoute exact path="/posts/:id" component={PostDetail} isLogin={isLogin} />
      <ProtectedRoute exact path="/users/:id/edit" component={UserUpdate} isLogin={isLogin} />
      <ProtectedRoute exact path="/posts/:id/edit" component={PostUpdate} isLogin={isLogin} />
      <ProtectedRoute exact path="/vouchers" component={Voucher} isLogin={isLogin} />
      <ProtectedRoute exact path="/vouchers/create" component={CreateVoucher} isLogin={isLogin} />
      <ProtectedRoute exact path="/vouchers/:id/edit" component={VoucherUpdate} isLogin={isLogin} />
      <ProtectedRoute exact path="/vouchers/type/:type" component={Voucher} isLogin={isLogin} />
      <ProtectedRoute exact path="/vouchers/referrals" component={ReferralVouchers} isLogin={isLogin} />
      <ProtectedRoute exact path="/vouchers/referrals/codes/:id" component={ReferralVoucherCodes} isLogin={isLogin} />
      <ProtectedRoute exact path="/verifies/page" component={Request} isLogin={isLogin} />
      <ProtectedRoute exact path="/verifies/page/:type" component={Request} isLogin={isLogin} />
      <ProtectedRoute exact path="/verifies/user" component={VerifyUser} isLogin={isLogin} />
      <ProtectedRoute exact path="/verifies/user/:type" component={VerifyUser} isLogin={isLogin} />
      <ProtectedRoute exact path="/settings" component={SettingPage} isLogin={isLogin} />
      <ProtectedRoute exact path="/reports/page" component={PageReport} isLogin={isLogin} />
      <ProtectedRoute exact path="/reports/post" component={PostReport} isLogin={isLogin} />
      <ProtectedRoute exact path="/reports/user" component={UserReport} isLogin={isLogin} />
      <ProtectedRoute exact path="/reports/comment" component={CommentReport} isLogin={isLogin} />
      <ProtectedRoute exact path="/reports/group" component={GroupReport} isLogin={isLogin} />
      <ProtectedRoute exact path="/reports/media" component={MediaReport} isLogin={isLogin} />
      <ProtectedRoute exact path="/reports/product" component={ProductReport} isLogin={isLogin} />
      <ProtectedRoute exact path="/products" component={Product} isLogin={isLogin} />
      <ProtectedRoute exact path="/products/:type" component={Product} isLogin={isLogin} />
      <ProtectedRoute exact path="/places" component={Place} isLogin={isLogin} />
      <ProtectedRoute exact path="/pages" component={Page} isLogin={isLogin} />
      <ProtectedRoute exact path="/pages/:type" component={Page} isLogin={isLogin} />
      <ProtectedRoute exact path="/groups" component={Group} isLogin={isLogin} />
      <ProtectedRoute exact path="/trusted-points" component={TrustedPoint} isLogin={isLogin} />
      <ProtectedRoute exact path="/system-notifications" component={SystemNotification} isLogin={isLogin} />
      <ProtectedRoute exact path="/page-categories" component={PageCategory} isLogin={isLogin} />
      <ProtectedRoute exact path="/banners" component={Banner} isLogin={isLogin} />
      <ProtectedRoute exact path="/banners/app" component={BannerRunningApp} isLogin={isLogin} />
      <ProtectedRoute exact path="/banners/create" component={CreateBanner} isLogin={isLogin} />
      <ProtectedRoute exact path="/banners/:id/edit" component={UpdateBanner} isLogin={isLogin} />
      <ProtectedRoute exact path="/sensitive-words" component={SensitiveWords} isLogin={isLogin} />
      <ProtectedRoute exact path="/system-settings" component={Settings} isLogin={isLogin} />
      <ProtectedRoute exact path="/lucky-wheels/create" component={CreateLuckyWheel} isLogin={isLogin} />
      <ProtectedRoute exact path="/lucky-wheels" component={LuckyWheels} isLogin={isLogin} />
      <ProtectedRoute exact path="/lucky-wheels/:type" component={LuckyWheels} isLogin={isLogin} />
      <ProtectedRoute exact path="/lucky-wheels/edit/:id" component={UpdateLuckyWheel} isLogin={isLogin} />
      <ProtectedRoute exact path="/instruction/lucky-wheels" component={InstructionLuckyWheel} isLogin={isLogin} />
      <ProtectedRoute exact path="/winning-condition/lucky-wheels" component={WinningCondition} isLogin={isLogin} />
      <ProtectedRoute exact path="/badges" component={Badge} isLogin={isLogin} />
      <ProtectedRoute exact path="/badges/create" component={BadgeCreate} isLogin={isLogin} />
      <ProtectedRoute exact path="/badges/edit" component={BadgeEdit} isLogin={isLogin} />
      <ProtectedRoute exact path="/badges/verifies" component={BadgeVerifyList} isLogin={isLogin} />
      <ProtectedRoute exact path="/badges/register_form" component={BadgeRegisterForm} isLogin={isLogin} />
      <ProtectedRoute exact path="/running/lucky-wheels" component={LuckyWheelRunning} isLogin={isLogin} />
      <ProtectedRoute exact path="/message/lucky-wheels" component={MessageResult} isLogin={isLogin} />
      <ProtectedRoute exact path="/terms/lucky-wheels" component={LuckyWheelTerms} isLogin={isLogin} />
      <ProtectedRoute exact path="/menu_banner" component={CreateMenuBanner} isLogin={isLogin} />
      <ProtectedRoute exact path="/events" component={Events} isLogin={isLogin} />
      <ProtectedRoute exact path="/event/create" component={EventCreate} isLogin={isLogin} />
      <ProtectedRoute exact path="/event/edit/:id" component={EventUpdate} isLogin={isLogin} />
      <Route exact path="*">
        <React.Suspense fallback={<PageLoading />}>
          <h1>404</h1>
        </React.Suspense>
      </Route>
    </Switch>
  );
};
export default renderRoutes;
