import React, { useEffect, useState } from 'react';
import {
  cmsListLuckyWheelPrizeWinners,
  cmsListLuckyWheelPrizeWinnersVariables, PrizeInput,
  updateLuckyWheelPrizeWinner,
  updateLuckyWheelPrizeWinnerVariables,
} from '../../../graphql/types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { Button, Col, Form, Input, Row, Table } from 'antd';

import { CMS_GET_LUCKY_WHEEL_WINNERS, CMS_UPDATE_LUCKY_WHEEL_WINNER } from '../../../graphql/luckyWheel';
import { ToastError, ToastSuccess } from '../../../components/Toast';

const loadingImage = require('../../../assets/images/loadingspinner.gif');

const prizeType = { point: 'Point', voucher: 'Vooucher' };
const winnerType = { DESTINATED: 'DESTINATED', WON: 'WON' };

interface PrizeInfor extends PrizeInput {
  prizeId: string,
  prizeType: string,
  prize: string
}

interface AssignWinnerProps extends FormComponentProps {
  batch: {},
  prizeInfor: PrizeInfor
  onClose:any
}

const AssignWinnerImpl = (props) => {
  const { getFieldDecorator } = props.form;
  const [numberPrizes, setNumberPrizes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [winners, setWinners] = useState([]);
  const { data: luckyWinners } = useQuery<cmsListLuckyWheelPrizeWinners, cmsListLuckyWheelPrizeWinnersVariables>(CMS_GET_LUCKY_WHEEL_WINNERS, {
    variables: {
      prizeId: props.batch._id,
    },
    fetchPolicy: 'network-only'
  });
  const [updateWinner] = useMutation<updateLuckyWheelPrizeWinner, updateLuckyWheelPrizeWinnerVariables>(CMS_UPDATE_LUCKY_WHEEL_WINNER, {
    // refetchQueries:['cmsListLuckyWheelPrizeWinners']
  });

  const handleSubmitAssign = async (e) => {
    e.preventDefault();
    setLoading(true);
    props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const data = Object.keys(values).filter(item => values[item])
          .map(item => {
            // Xoá dấu + or  0 ở đầu số điện thoai
            switch (values[item][0]) {
              case "+": return values[item].slice(1, values[item].length);
              case "0": return '84' + values[item].slice(1, values[item].length);
              case "8": return  values[item];
              default : return '84' + values[item]
            }
          });
        setLoading(false);
        updateWinner({
          variables: {
            prizeId: props.batch._id,
            designatedUserPhoneNumbers: data || [],
          },
        }).then(res=>{
          console.log("res",res);
          ToastSuccess({ message: 'Updated', description: 'Update winner success' });
          props.onClose();

        }).catch(err=>{
          console.log("err",err);
          ToastError({message:"Error",description:""})
          setLoading(false);
        })
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (luckyWinners && luckyWinners.cmsListLuckyWheelPrizeWinners) {
      let { wonUsers, designatedUsers } = luckyWinners.cmsListLuckyWheelPrizeWinners;
      wonUsers = wonUsers.map(item => {
        return { ...item, type: winnerType.WON };
      });
      designatedUsers = designatedUsers.map(item => {
        return { ...item, type: winnerType.DESTINATED };
      });
      setWinners([...wonUsers, ...designatedUsers]);
    }

  }, [luckyWinners]);

  useEffect(() => {
    if (props.batch && props.batch.qty) {
      setNumberPrizes(new Array(props.batch.qty).fill(0).map((item, index) => {
        return { index };
      }));
    }
  }, []);

  const columns = [
    {
      title: 'Type',
      key: 'date',
      render: (row) => {
        return prizeType[props.prizeInfor.prizeType];
      },
    },
    {
      title: 'Prize',
      key: 'prize',
      render: (row) => {
        return props.prizeInfor.prize;
      },
    },
    {
      title: 'Winner (Phone Number)',
      key: 'redeem',
      render: (row, r, index) => {
        return (
          <Form.Item>
            {getFieldDecorator('winner_' + index, {
              initialValue: winners[index] ? winners[index].phoneNumber : '',
            })(
              <Input disabled={winners[index] && winners[index].type === winnerType.WON}/>,
            )}
          </Form.Item>
        );
      },
    },
  ];
  return (
    <div>
      <Form >
        <Table
          rowKey="index"
          columns={columns}
          dataSource={numberPrizes}
          onChange={() => {
          }}
          pagination={false}
        />
        <br/>
        <Row>
          <Col offset={16} span={2}>
            <Form.Item>
              <Button type="primary" className="as-danger-btn p015px" onClick={props.onClose}>Cancel</Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item>
              <Button type="primary" disabled={loading} onClick={handleSubmitAssign} className="as-primary-btn p015px" >Create</Button>
              {
                loading && <img src={loadingImage} className="w20" alt=""/>
              }
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div>
  );
};
const AssignWinner = Form.create<AssignWinnerProps>()(AssignWinnerImpl);
export default AssignWinner;
