import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Collapse, Form, Input, Layout, Skeleton, Table } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import { FormComponentProps } from 'antd/lib/form';
import { GET_TRUSTED_POINT_CONFIGS } from '../../graphql/trustedPoint/point';
import { GetTrustedPointConfigs, TrustedPointConfigType } from '../../graphql/types';
import Styled from './indexStyled';
import EditConfig from './components/editConfig';
import AddOrEditConfig from './components/adOrEditConfig';
const { Content } = Layout;
const { Search } = Input;
const { Panel } = Collapse;

function onChange(pagination, filters, sorter, extra) {
  console.log('params', pagination, filters, sorter, extra);
}

const ProductImpl = (props: FormComponentProps) => {
  const [isLoading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visibleReferall, setVisibleReferral] = useState(false);
  const [editData, setEditData] = useState({});
  const [referralData, setReferralData] = useState([]);
  const [filterData, setFilter] = useState({
    q: '',
  });
  const { data, error, loading } = useQuery<GetTrustedPointConfigs>(GET_TRUSTED_POINT_CONFIGS, {
    fetchPolicy: 'network-only',
    variables: filterData,
  });
  const showEdit = data => {
    setVisible(true);
    setEditData({
      _id: data._id,
      type: data.type,
      actionType: data.actionType,
      points: data.points,
      description: data.description,
      trustedPointsFrom: data.trustedPointsFrom,
      trustedPointsTo: data.trustedPointsTo,
      rankFrom: data.rankFrom,
      rankTo: data.rankTo,
      engagementAdjustPercent: data.engagementAdjustPercent,
      postContentLength: data.postContentLength,
      totalPlaceReviewsFrom: data.totalPlaceReviewsFrom,
      totalPlaceReviewsTo: data.totalPlaceReviewsTo,
      newPlaceCoefficient: data.newPlaceCoefficient,
      adjustPointPercent: data.adjustPointPercent,
      unlimitedPostsPerDayWhenTrustedPointGreaterThan: data.unlimitedPostsPerDayWhenTrustedPointGreaterThan,
      maxTenPostsPerDayWhenTrustedPointLessThan: data.maxTenPostsPerDayWhenTrustedPointLessThan,
      maxFivePostsPerDayWhenTrustedPointLessThan: data.maxFivePostsPerDayWhenTrustedPointLessThan,
    });
  };

  const showReferralConfig = () => {
    setVisibleReferral(true);
    let referralData = data.getTrustedPointConfigs.filter(i => {
      return i.type == "REFERRAL";
    });
    setReferralData(referralData)
  };


  const search = value => {
    setFilter({ ...filterData, q: value });
  };
  const trustedPointConfigType = [
    TrustedPointConfigType.ACTION,
    TrustedPointConfigType.ENGAGEMENT_MULTIPLICATION,
    TrustedPointConfigType.LIMITATION,
    TrustedPointConfigType.OTHERS,
    TrustedPointConfigType.PROOF_OF_RATING,
    TrustedPointConfigType.RANKING,
    TrustedPointConfigType.REFERRAL,
  ];
  const trustedPointConfigTypeConfig = {
    ACTION: [
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: false,
      },
      {
        title: 'Points',
        dataIndex: 'points',
        sorter: false,
      },
      {
        title: 'Action',
        key: 'operation',
        render: row => {
          return (
            <div>
              <Button type="primary" icon="edit" size="small" onClick={e => showEdit(row)} />
            </div>
          );
        },
      },
    ],
    ENGAGEMENT_MULTIPLICATION: [
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: false,
      },
      {
        title: 'Points',
        dataIndex: 'points',
        sorter: false,
      },
      {
        title: 'Trusted Points From',
        dataIndex: 'trustedPointsFrom',
        sorter: false,
      },
      {
        title: 'Trusted Points To',
        dataIndex: 'trustedPointsTo',
        sorter: false,
      },
      {
        title: 'Engagement Adjust Percent',
        dataIndex: 'engagementAdjustPercent',
        sorter: false,
      },
      {
        title: 'Action',
        key: 'operation',
        render: row => {
          return (
            <div>
              <Button type="primary" icon="edit" size="small" onClick={e => showEdit(row)} />
            </div>
          );
        },
      },
    ],
    LIMITATION: [
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: false,
      },
      {
        title: 'Points',
        dataIndex: 'points',
        sorter: false,
      },
      {
        title: 'Unlimited Posts Per Day When Trusted Point Greater Than',
        dataIndex: 'unlimitedPostsPerDayWhenTrustedPointGreaterThan',
        sorter: false,
      },
      {
        title: 'Max Ten Posts PerDay When Trusted Point Less Than',
        dataIndex: 'maxTenPostsPerDayWhenTrustedPointLessThan',
        sorter: false,
      },
      {
        title: 'Max Five Posts Per Day When Trusted Point Less Than',
        dataIndex: 'maxFivePostsPerDayWhenTrustedPointLessThan',
        sorter: false,
      },
      {
        title: 'Action',
        key: 'operation',
        render: row => {
          return (
            <div>
              <Button type="primary" icon="edit" size="small" onClick={e => showEdit(row)} />
            </div>
          );
        },
      },
    ],
    OTHERS: [
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: false,
      },
      {
        title: 'Points',
        dataIndex: 'points',
        sorter: false,
      },
      {
        title: 'Post Content Length',
        dataIndex: 'postContentLength',
        sorter: false,
      },
      {
        title: 'Action',
        key: 'operation',
        render: row => {
          return (
            <div>
              <Button type="primary" icon="edit" size="small" onClick={e => showEdit(row)} />
            </div>
          );
        },
      },
    ],
    PROOF_OF_RATING: [
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: false,
      },
      {
        title: 'Points',
        dataIndex: 'points',
        sorter: false,
      },
      {
        title: 'Total PlaceReviews From',
        dataIndex: 'totalPlaceReviewsFrom',
        sorter: false,
      },
      {
        title: 'Total PlaceReviews To',
        dataIndex: 'totalPlaceReviewsTo',
        sorter: false,
      },
      {
        title: 'New Place Coefficient',
        dataIndex: 'newPlaceCoefficient',
        sorter: false,
      },
      {
        title: 'Action',
        key: 'operation',
        render: row => {
          return (
            <div>
              <Button type="primary" icon="edit" size="small" onClick={e => showEdit(row)} />
            </div>
          );
        },
      },
    ],
    RANKING: [
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: false,
      },
      {
        title: 'Points',
        dataIndex: 'points',
        sorter: false,
      },
      {
        title: 'Rank From',
        dataIndex: 'rankFrom',
        sorter: false,
      },
      {
        title: 'Rank To',
        dataIndex: 'rankTo',
        sorter: false,
      },
      {
        title: 'Action',
        key: 'operation',
        render: row => {
          return (
            <div>
              <Button type="primary" icon="edit" size="small" onClick={e => showEdit(row)} />
            </div>
          );
        },
      },
    ],
    REFERRAL: [
      {
        title: 'Description',
        dataIndex: 'description',
        sorter: false,
      },
      {
        title: 'Points',
        dataIndex: 'points',
        sorter: false,
      },
      {
        title: 'Invited From',
        dataIndex: 'invitedFrom',
        sorter: false,
      },
      {
        title: 'Invited To',
        dataIndex: 'invitedTo',
        sorter: false,
      },
      {
        title: 'Action',
        key: 'operation',
        render: row => {
          return (
            <div>
              <Button type="primary" icon="edit" size="small" onClick={e => showReferralConfig()} />
            </div>
          );
        },
      },
    ],
  };

  const closeModal = () => {
    setVisible(false);
  };

  const closeReferralModal = () => {
    setVisibleReferral(false);
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
    }

    if (!loading) {
      setLoading(false);
    }
  }, [loading]);
  if (isLoading && data && data.getTrustedPointConfigs) {
    data.getTrustedPointConfigs.sort(function (a, b) {
      let comparison = 0;
      if (a._id > b._id) {
        comparison = 1;
      } else if (a._id < b._id) {
        comparison = -1;
      }
      return comparison;
    });
  }
  if (error) return <Content className="content">Error</Content>;
  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Place</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>
        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }} />
        ) : (
            <div>
              <Search
                placeholder="input search text"
                defaultValue={filterData.q}
                onSearch={value => search(value)}
                style={{ width: 200 }}
              />
              {trustedPointConfigType.map(function (type, i) {
                let _data = [];
                return (
                  <Collapse key={i}>
                    {data.getTrustedPointConfigs.map(function (d, index) {
                      if (type == d.type) {
                        _data.push(d);
                      }
                      if (data.getTrustedPointConfigs.length - 1 === index) {
                        return (
                          <Panel header={type} key={type}>
                            <Table
                              rowKey="_id"
                              pagination={{
                                pageSize: 100,
                              }}
                              columns={trustedPointConfigTypeConfig[type]}
                              dataSource={_data}
                              onChange={onChange}
                              scroll={{ x: 'calc(700px + 50%)', y: 0 }}
                            />
                          </Panel>
                        );
                      }
                      return '';
                    })}
                  </Collapse>
                );
              })}
            </div>
          )}

        {visible ? (
          <EditConfig form={props.form} visible={visible} data={editData} closeModal={e => closeModal()} />
        ) : (
            ''
          )}
        {visibleReferall && <AddOrEditConfig form={props.form} visible={visibleReferall} datas={referralData} closeModal={(e) => closeReferralModal()} />}
      </Content>
    </Styled.Container>
  );
};

const Product = Form.create()(ProductImpl);
export default Product;
