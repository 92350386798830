import React, { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, DatePicker, Form, Input, Layout, Modal, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormComponentProps } from 'antd/lib/form';
import Styled from '../indexStyled';
import AddPrize from '../create/modalPrize';
import VoucherPrizeDetail from './VoucherPrizeDetail';
import PointDetail from './PointDetail';
import { Link } from 'react-router-dom';
import {
  ActiveDateForEachBatchInput,
  cmsLuckyWheel,
  cmsLuckyWheel_cmsLuckyWheel,
  cmsLuckyWheelVariables,
  cmsUpdateLuckyPrizesName,
  cmsUpdateLuckyPrizesNameVariables,
  cmsUpdateLuckyWheelPrize,
  cmsUpdateLuckyWheelPrizeVariables,
  createLuckyWheelPrize,
  createLuckyWheelPrizeVariables, PrizeInput,
  PrizeTier,
  updateLuckyWheel,
  updateLuckyWheelVariables,
  UploadPhoto,
  UploadPhotoVariables,
  createLuckyWheelBatch,
  createLuckyWheelBatchVariables,
  deleteLuckyWheelBatch,
  deleteLuckyWheelBatchVariables,
} from '../../../graphql/types';
import {
  CMS_CREATE_LUCKY_WHEEL_PRIZE,
  CMS_GET_LUCKY_WHEEL,
  CMS_UPDATE_LUCKY_WHEEL,
  CMS_UPDATE_LUCKY_WHEEL_PRIZE,
  CMS_UPDATE_LUCKY_WHEEL_PRIZE_NAME,
  CMS_CREATE_LUCKY_WHEEL_BATCH,
  CMS_DELETE_LUCKY_WHEEL_BATCH,
} from '../../../graphql/luckyWheel';
import { useMutation, useQuery } from '@apollo/react-hooks';
import PointTmpl from '../create/PointTmpl';
import VoucherPrizeTmpl from '../create/VoucherPrizeTmpl';
import history from '../../../history';
import { ToastError, ToastSuccess } from '../../../components/Toast';
import { validateRangeTimes } from '../../../utils/luck-wheel';
import { UPLOAD_PHOTO } from '../../../graphql/photo/uploadPhoto';
import { AppContext } from '../../../contexts/AppContext';
import { CLEAR_MEDIA_LUCKY_WHEEL } from '../../../contexts/type';

const moment = require('moment');

const { Content } = Layout;
const { RangePicker } = DatePicker;
const loadingImage = require('../../../assets/images/loadingspinner.gif');

interface IEditProps extends FormComponentProps {
  match?: any;
}

let timerPrizeName = [];
const lang = { VI: 'VI', EN: 'EN' };

const LuckyWheelDetailImpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const { params } = props.match;
  const now = new Date();
  const [numberBatch, setNumberBatch] = useState(3);
  const [loading, setLoading] = useState(false);
  const [addPrize, setAddPrize] = useState(false);
  const [typeAdd, setTypeAdd] = useState(null);
  const [campaignRange, setCampaignRange] = useState([new Date()]);
  const [luckyWheel, setLuckyWheel] = useState<cmsLuckyWheel_cmsLuckyWheel>();
  const [availableRangeBatch, setAvailableRangeBatch] = useState([[now, now]]);
  const [rangeBatch, setRangeBatch] = useState<any>();
  const [prizeSaved, setPrizeSaved] = useState({});
  const [canChangeStartCampaign, setChangeDate] = useState(true);
  const [trackingPrizes, setTrackingPrizes] = useState([]);
  const [trackingRemovePrize, setTrackingRemovePize] = useState([]);
  const [dateStartCampaign, setDateStart] = useState();
  const [
    // prizeUpdating,
    // setPrizeUpdating
  ] = useState<any>([]);
  const [namePrizes, setNamePrize] = useState({
    [PrizeTier.DIAMOND]: { name: '', nameEn: '', isChanged: false },
    [PrizeTier.GOLD]: { name: '', nameEn: '', isChanged: false },
    [PrizeTier.SILVER]: { name: '', nameEn: '', isChanged: false },
    [PrizeTier.BRONZE]: { name: '', nameEn: '', isChanged: false },
  });
  const context = useContext(AppContext);
  const { medias: mediasContext, mediasEdit: mediasEditContext } = context.luckyWheel;


  const [prizes, setPrizes] = useState([]);
  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };
  const [uploadPhoto] = useMutation<UploadPhoto, UploadPhotoVariables>(UPLOAD_PHOTO, {
    refetchQueries: ['uploadPhoto'],
  });
  const [createLuckyWheelBatchAPI] = useMutation<createLuckyWheelBatch, createLuckyWheelBatchVariables>(CMS_CREATE_LUCKY_WHEEL_BATCH, {
    // refetchQueries: ['cmsLuckyWheel'],
  });

  const [deleteLWBatch] = useMutation<deleteLuckyWheelBatch, deleteLuckyWheelBatchVariables>(CMS_DELETE_LUCKY_WHEEL_BATCH);

  const { data: luckyWheelDetail } = useQuery<cmsLuckyWheel, cmsLuckyWheelVariables>(CMS_GET_LUCKY_WHEEL, {
    variables: {
      id: params.id,
    },
    fetchPolicy: 'network-only',
  });

  const [updateLuckyWheelAPI] = useMutation<updateLuckyWheel, updateLuckyWheelVariables>(CMS_UPDATE_LUCKY_WHEEL, {
    // refetchQueries: ['cmsListLuckyWheel'],
  });
  const [updateLuckyWheelPrizeAPI] = useMutation<cmsUpdateLuckyWheelPrize, cmsUpdateLuckyWheelPrizeVariables>(CMS_UPDATE_LUCKY_WHEEL_PRIZE);

  const [createLuckyWheelPrizeAPI] = useMutation<createLuckyWheelPrize, createLuckyWheelPrizeVariables>(CMS_CREATE_LUCKY_WHEEL_PRIZE);
  const [
    cmsUpdateLuckyPrizeNameAPI,
  ] = useMutation<cmsUpdateLuckyPrizesName, cmsUpdateLuckyPrizesNameVariables>(CMS_UPDATE_LUCKY_WHEEL_PRIZE_NAME);

  const getBatchsFromValues = (valuesForm) => {
    let rangeBatchs = [];
    Object.keys(valuesForm).map(item => {
      if (item.indexOf('batch') > -1) {
        rangeBatchs = [...rangeBatchs, valuesForm[item]];
      }
    });
    return rangeBatchs;
  };

  const getUpdateDateOfBatch = (rangeOfBatchs) => {

    const batchIds = luckyWheel.batchs.map(item => item._id);
    let activeDateForEachBatch: ActiveDateForEachBatchInput[] = [];
    batchIds.map((item, index) => {
      if (rangeOfBatchs[index]) {
        activeDateForEachBatch = [...activeDateForEachBatch,
          { batchId: item, activeDate: { startDate: rangeOfBatchs[index][0], endDate: rangeOfBatchs[index][1] } }];
      }
    });
    return activeDateForEachBatch;
  };

  //  Handle store medias .
  const handleUploadMedia = async (medias) => {
    return await Promise.all<{ position: number, mediaId: string, batchs: any[] }>(medias.map(async item => {
      if (item.media) {
        const mediaId = await uploadPhoto({
          variables: {
            file: item.media,
            dimensions: {
              height: 100,
              width: 100,
            },
          },
        });
        return {
          ...item,
          position: item.position,
          mediaId: mediaId.data.uploadPhoto._id,
        };
      } else if (item.mediaSavedId) {
        return {
          ...item,
          position: item.position,
          mediaId: item.mediaSavedId,
        };
      }
      return true;
    }));
  };

  const mergeLuckyData = async (batchIds, values, prizes, mediasContext) => {
    const listMedias = await handleUploadMedia(mediasContext);

    // merge media with prize
    prizes = [...prizes].map((item, index) => {
      let media = listMedias.find(media => media.position === index);
      return {
        ...item,
        mediaId: (media && media.mediaId) || '',
      };
    });

    // Split prize by batch;
    let newPrizes = [];
    const numberSavedPrize = Object.keys(prizeSaved).reduce((total, prizeByTier) => {
      let counterOnTier = prizeSaved[prizeByTier].reduce((subTotal, prizeByBatch) => {
        return subTotal + prizeByBatch.batchs.length;
      }, 0);
      return total + counterOnTier;
    }, 0);
    batchIds.map((batchId, index) => {
      prizes.map((prize, prize_index) => {
        let _prize: PrizeInput = {
          name: values[prize.prizeTier + '_vi_title'],
          nameEn: values[prize.prizeTier + '_en_title'],
          qty: prize.qtyForEachBatch[index],
          prize_index: prize_index + numberSavedPrize,
          prizeTier: prize.prizeTier,
          mediaId: prize.mediaId,
          point: prize.point,
          voucherId: prize.voucherId,
        };
        newPrizes = [...newPrizes, {
          batchId: batchId,
          data: _prize,
        }];
      });
    });
    return newPrizes;
  };

  const onHandleAddPrize = (values) => {
    setPrizes([...prizes, values]);
    toggleModalAddPrize();
  };

  const openModalAdd = (type) => {
    setTypeAdd(type);
    setAddPrize(true);
  };

  const toggleModalAddPrize = () => {
    setAddPrize(!addPrize);
  };

  const onChangeCampaignRange = (dates) => {
    setCampaignRange(dates);
    availableRangeBatch[0] = dates;
    setAvailableRangeBatch(availableRangeBatch.map(item => {
      item[1] = dates[1];
      return [...item];
    }));
  };

  const onChangeNewBatchPrize = (batchPrizeId, value, type) => {
    const newPrizeSaved = {};
    Object.keys(prizeSaved).map(prizeTier => {
      if (prizeTier === type) {
        let prizeTierObj = prizeSaved[prizeTier];
        prizeTierObj = prizeTierObj.map(prize => {
          let prizeBatchs = [...prize.batchs];
          prizeBatchs = prizeBatchs.map(prizeBatch => {
            if (prizeBatch._id === batchPrizeId) {
              prizeBatch.qty = value;
            }
            return prizeBatch;
          });

          return {
            ...prize,
            batchs: prizeBatchs,
          };
        });
        newPrizeSaved[prizeTier] = prizeTierObj;
      }
      newPrizeSaved[prizeTier] = prizeSaved[prizeTier];
    });
    setPrizeSaved(newPrizeSaved);

  };

  const splitRangBatch = (luckyWheelDetail: cmsLuckyWheel_cmsLuckyWheel) => {
    const { batchs } = luckyWheelDetail;
    setRangeBatch(batchs.map(item => item.activeDate));
  };

  const splitPrize = (luckyWheelDetail: cmsLuckyWheel_cmsLuckyWheel) => {
    const { batchs } = luckyWheelDetail;
    let _prizeData = batchs.reduce((origin, step) => {
      let _newPrizes = step.prizes.map(item => {
        return { ...item, batchId: step._id };
      });
      return origin.concat(_newPrizes);
    }, []);

    // Lấy tên của từng cấp độ phần thưởng
    let _namePrize = namePrizes;
    _prizeData.map(item => {
      _namePrize[item.prizeTier] = { name: item.name, nameEn: item.nameEn, isChanged: false };
    });

    // Gộp prize theo Cấp độ phần thưởng.
    let _prizeByBatch = {};
    _prizeData.map((prize) => {
      if (!_prizeByBatch[prize.prizeTier]) _prizeByBatch[prize.prizeTier] = [];
      _prizeByBatch[prize.prizeTier] = [..._prizeByBatch[prize.prizeTier], prize];
    });

    //Gộp batch  trong từng cấp độ giải thưởng  về lại prize tương ứng
    let finalData = {};
    Object.keys(_prizeByBatch).map(prizeByTier => {
      let mergePrizeToAward = {};
      _prizeByBatch[prizeByTier].map(item => {
        if (!mergePrizeToAward[item.prize_index]) mergePrizeToAward[item.prize_index] = { batchs: [], qty: 0 };
        mergePrizeToAward[item.prize_index] = {
          ...mergePrizeToAward[item.prize_index],
          prizeId: item._id,
          name: item.name,
          nameEn: item.nameEn,
          voucherId: item.voucherId,
          point: item.point,
          qty: mergePrizeToAward[item.prize_index].qty + item.qty,
          media: item.media,
          batchs: [...mergePrizeToAward[item.prize_index].batchs, item],
        };
      });
      finalData[prizeByTier] = Object.values(mergePrizeToAward);
    });

    setPrizeSaved(finalData);
    setNamePrize(_namePrize);
  };

  useEffect(() => {
    if (luckyWheelDetail && luckyWheelDetail.cmsLuckyWheel) {
      setLuckyWheel(luckyWheelDetail.cmsLuckyWheel);
      setCampaignRange([luckyWheelDetail.cmsLuckyWheel.activeDate.startDate, luckyWheelDetail.cmsLuckyWheel.activeDate.endDate]);
      setNumberBatch(luckyWheelDetail.cmsLuckyWheel.numberOfBatch);

      // set date avaiable
      const avai = luckyWheelDetail.cmsLuckyWheel.batchs.map(item => item.activeDate).map((item) => {
        return [item.startDate, luckyWheelDetail.cmsLuckyWheel.activeDate.endDate];
      });
      setAvailableRangeBatch(avai);
      setDateStart(luckyWheelDetail.cmsLuckyWheel.activeDate.startDate);

      // Check whether this campain can change start active date or not.
      setChangeDate(moment(moment(new Date())).isBefore(luckyWheelDetail.cmsLuckyWheel.activeDate.startDate));

      splitRangBatch(luckyWheelDetail.cmsLuckyWheel);
      splitPrize(luckyWheelDetail.cmsLuckyWheel);

    }
  }, [luckyWheelDetail]);

  const createNewBatchOnEachPrize = (number) => {
    let newOne = {};
    let prizeRemovedIds = trackingRemovePrize;
    let prizeNewBatchPrize = trackingPrizes;

    // Tạo thêm batch prize tại mỗi prize ;
    Object.keys(prizeSaved).map(prizeTierItem => {
      const prizeTierObj = prizeSaved[prizeTierItem];
      let newPrizeObj = prizeTierObj.map(prize => {
        let batchs = prize.batchs;
        // Tăng số lượng batch
        if (number > 0) {
          let dataCopy = {
            name: batchs[0].name,
            nameEn: batchs[0].nameEn,
            mediaId: batchs[0].media._id,
            qty: 0,
            point: batchs[0].point,
            voucherId: batchs[0].voucherId,
            prizeTier: batchs[0].prizeTier,
            prize_index: batchs[0].prize_index,
          };
          let newbatchPrize = [];
          for (let i = 0; i < number; i++) {
            newbatchPrize = [...newbatchPrize,
              {
                _id: new Date().getTime() + (Math.floor(Math.random() * 20)),
                ...dataCopy,
              }];
          }
          batchs = [...batchs, ...newbatchPrize];
          prizeNewBatchPrize = [...prizeNewBatchPrize, ...newbatchPrize];

        }
        // Giảm số lượng batch
        else if (number < 0) {
          const absNumber = Math.abs(number);
          let remainNewPrize = [];
          // Giảm trong số các batch mới
          if (absNumber <= trackingPrizes.length) {
            remainNewPrize = trackingPrizes.slice(0, trackingPrizes.length - Math.abs(number) * 2);
            // Giữ lại prize còn lại
            prizeNewBatchPrize = prizeNewBatchPrize.filter(item => remainNewPrize.includes(item));
          }
          // Giảm trong các  batch cũ
          else {
            let prizeRmoveds = batchs.slice(batchs.length - absNumber, batchs.length);
            prizeRemovedIds = [...prizeRemovedIds, ...prizeRmoveds.map(item => item.batchId)];
          }
          batchs = batchs.slice(0, batchs.length - Math.abs(number));
          prizeNewBatchPrize = [...prizeNewBatchPrize];
        }
        return {
          ...prize,
          batchs,
        };
      });

      newOne = {
        ...newOne,
        [prizeTierItem]: newPrizeObj,
      };
    });
    setTrackingPrizes([...prizeNewBatchPrize]);
    if (prizeRemovedIds && prizeRemovedIds.length) {
      setTrackingRemovePize([...prizeRemovedIds]);
    }
    setPrizeSaved(newOne);

  };

  const onChangeNumberBatch = (event) => {
    const value = parseInt(event.target.value);
    setNumberBatch(value > 1 ? value : 1);

    if (value > availableRangeBatch.length) {
      const newOne = new Array(value - availableRangeBatch.length).fill([rangeBatch[rangeBatch.length - 1].endDate, campaignRange[1]]);
      setAvailableRangeBatch(availableRangeBatch.concat(newOne));

      const newRange = new Array(value - rangeBatch.length).fill(rangeBatch[rangeBatch.length - 1]);
      setRangeBatch([...rangeBatch, ...newRange]);

      createNewBatchOnEachPrize(value - availableRangeBatch.length);


    } else if (value < availableRangeBatch.length) {
      createNewBatchOnEachPrize(value - availableRangeBatch.length);

      availableRangeBatch.splice(value, availableRangeBatch.length);
      setAvailableRangeBatch(availableRangeBatch);
      rangeBatch.splice(value, rangeBatch.length);
      setRangeBatch(rangeBatch);

    }
  };

  const rangeConfigBatch = (position) => {
    return {
      initialValue: rangeBatch && rangeBatch[position] && [moment(rangeBatch[position].startDate), moment(rangeBatch[position].endDate)],
      rules: [{ type: 'array', required: true, message: 'Please select time!' }],
    };
  };
  const disableConfigBatch = (position) => {
    return rangeBatch && rangeBatch[position] && moment(rangeBatch[position].startDate).isBefore(moment(now),'minute')
  };

  const rangeConfig = {
    initialValue: luckyWheel && [moment(luckyWheel.activeDate.startDate), moment(luckyWheel.activeDate.endDate)],
    rules: [{
      type: 'array', required: true,
      whitespace: true, message: 'Please select time!',
    }],
  };

  const onRemovePrize = (position) => {
    prizes.splice(position, 1);
    setPrizes([...prizes]);
  };

  const onRemoveSavedPrize = (position, type) => {
    prizeSaved[type].splice(position, 1);
    setPrizeSaved({ ...prizeSaved });
  };

  const disableDate = (current) => {
    return current && current < moment().add(-1, 'days').endOf('day');
  };

  const disableBatchDate = (current, position) => {
    return current &&
      current < moment(availableRangeBatch[position][0]).add(-1, 'days').endOf('day') ||
      current > moment(availableRangeBatch[position][1]).endOf('day');
  };

  const onChangeBatchRange = (dates, position) => {
    setRangeBatch([...rangeBatch.map((item, index) => {
      return index === position ? { startDate: dates[0], endDate: dates[1] } : item;
    })]);
    if (position < numberBatch - 1) {
      let newNextAvaiRange = availableRangeBatch;
      newNextAvaiRange[position + 1] = [moment(dates[1]).add(10, 'minutes'), campaignRange[1]];
      setAvailableRangeBatch([].concat(newNextAvaiRange));
    }
  };

  const renderNumberBatch = () => {
    return (new Array(numberBatch).fill(null)).map((item, index) => {
      return (
        <Form.Item key={index}>
          {getFieldDecorator('batch' + index, rangeConfigBatch(index))(
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              disabled={disableConfigBatch(index)}
              format="YYYY-MM-DD HH:mm"
              onChange={(dates) => onChangeBatchRange(dates, index)}
              disabledDate={(current => disableBatchDate(current, index))} className="w100"/>,
          )}
        </Form.Item>
      );
    });
  };

  const onChangePrizeName = (event, type, language) => {
    setNamePrize({
      ...namePrizes,
      [type]: {
        name: language === lang.VI ? event.target.value : namePrizes[type].name,
        nameEn: language === lang.EN ? event.target.value : namePrizes[type].nameEn,
        isChanged: true,
      },
    });
  };

  const renderPrizeSaved = (type) => {
    const data = prizeSaved[type];
    return data && data.map((item, index) => {
      if (item.point) {
        return <PointDetail key={index}
                            position={index}
                            type={type}
                            rangeBatch={rangeBatch}
                            data={item}
                            onChangeNewBatchPrize={onChangeNewBatchPrize}
                            batchs={item.batchs}
                            onRemove={onRemoveSavedPrize}/>;
      } else {
        return <VoucherPrizeDetail key={index}
                                   position={index}
                                   type={type}
                                   data={item}
                                   rangeBatch={rangeBatch}
                                   batchs={item.batchs}
                                   voucherName={item.voucherId}
                                   onRemove={onRemoveSavedPrize}/>;
      }
    });
  };

  const renderPrizeType = (type) => {
    return prizes.map((item, index) => {
      if (item.prizeTier === type) {
        if (item.point) {
          return <PointTmpl key={index} position={index} data={item} qtyForEachBatch={item.qtyForEachBatch}
                            onRemove={onRemovePrize}/>;
        } else {
          return <VoucherPrizeTmpl key={index} position={index} data={item} qtyForEachBatch={item.qtyForEachBatch}
                                   voucherName={item.voucherName} onRemove={onRemovePrize}/>;
        }
      }
      return null;
    });
  };

  //Promise save main content of this lucky wheel
  const updateLuckyWheelMainContent = (values, rangeBatchs) => {
    const numberLevelPrize = Object.keys(prizeSaved).length;
    const numberNewBatchs = trackingPrizes.length / numberLevelPrize;
    const _rangeRealUpdate = rangeBatchs.slice(0, rangeBatchs.length - numberNewBatchs);
    return updateLuckyWheelAPI({
      variables: {
        _id: params.id,
        data: {
          title: values.title,
          titleEn: values.titleEn,
          activeDate: { startDate: values.dateActive[0], endDate: values.dateActive[1] },
          numberOfBatch: parseInt(values.numberBatch),
          activeDateForEachBatch: getUpdateDateOfBatch(_rangeRealUpdate),
        },
      },
    });
  };

  // Promise save new prizes
  const createNewPrizesLuckyWheel = (newPrizes) => {
    if (newPrizes && newPrizes.length) {
      return newPrizes.map(item => {
        return createLuckyWheelPrizeAPI({ variables: item });
      });
    }
    return [];
  };

  //Promise save name of the prize
  const updateLuckyPrizeName = () => {
    let res = [];
    Object.keys(namePrizes).map(item => {
      if (namePrizes[item].isChanged) {
        res.push(cmsUpdateLuckyPrizeNameAPI({
          variables: {
            luckyId: params.id,
            prizeTier: PrizeTier[item],
            name: namePrizes[item].name,
            nameEn: namePrizes[item].nameEn,
          },
        }));
      }
    });
    return res;
  };

  //Promise save name of the prize
  const updateLuckyPrizeMedia = (mediasHandle) => {
    let res = [];
    mediasHandle.map(mediaHandle => {
      mediaHandle.batchs.map(batch => {
        res.push(updateLuckyWheelPrizeAPI({
          variables: {
            _id: batch._id,
            data: {
              name: batch.name,
              nameEn: batch.nameEn,
              qty: batch.qty,
              prizeTier: batch.prizeTier,
              mediaId: mediaHandle.mediaId,
            },
          },
        }));
      });
    });
    return res;
  };

  const createNewBatchsAndRemoveBatch = (rangeBatchs) => {

    let resPromise = [];
    // Thêm batch mới với list prize
    if (trackingPrizes) {
      const numberLevelPrize = Object.keys(prizeSaved).length;
      const numberNewBatchs = trackingPrizes.length / numberLevelPrize;
      let position = 0;
      rangeBatchs.map((item, index) => {
        if (numberNewBatchs && index >= (rangeBatchs.length - numberNewBatchs)) {
          let prizeInBatch = trackingPrizes.slice(position * numberLevelPrize, (position + 1) * numberLevelPrize)
            .map(prize => {
              delete prize._id;
              return prize;
            });
          position++;

          resPromise.push(createLuckyWheelBatchAPI({
            variables: {
              luckyWheelId: params.id,
              data: {
                activeDate: { startDate: item[0], endDate: item[1] },
                prizes: prizeInBatch,
              },
            },
          }));
        }
      });
    }
    ;

    // Xoá batch bị xoá
    if (trackingRemovePrize) {
      const batchRmovedIds = [...new Set(trackingRemovePrize)];
      batchRmovedIds.map(batchID => {
        resPromise.push(deleteLWBatch({ variables: { _id: batchID } }));
      });
    }

    return resPromise;
  };

  const validateByHand = (values) => {
    const rangeBatchs = getBatchsFromValues(values);
    let { title } = values;
    if (title) title = title.trim();
    if (!canChangeStartCampaign && !moment(dateStartCampaign).isSame(values.dateActive[0])) {
      setLoading(false);
      ToastError({ message: 'Error time', description: 'You cannot fix the starting time of the started wheel.' });
      return false;
    }
    if (prizes.length > mediasContext.length) {
      setLoading(false);
      ToastError({ message: 'Error media', description: 'Please select enough photos for the prize!' });
      return false;
    }
    if (!validateRangeTimes(campaignRange, rangeBatchs, 'update')) {
      setLoading(false);
      ToastError({ message: 'Error duration', description: 'The duration(s) you have chosen is not valid!' });
      return false;
    }
    if (!title || title.length > 100) {
      setLoading(false);
      ToastError({ message: 'Error', description: 'The maximum title length is 100 characters!' });
      return false;
    }
    return true;
  };

  // Action save all changes on this page
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      props.form.validateFieldsAndScroll(async (err, values) => {
        if (!err && validateByHand(values)) {
          const rangeBatchs = getBatchsFromValues(values);
          const batchIds = luckyWheel.batchs.map(item => item._id);
          let allPromise = [];
          let newPrizes = [];
          let mediasHandle = [];
          if (mediasEditContext && mediasEditContext.length) {
            mediasHandle = await handleUploadMedia(mediasEditContext);
          }
          if (prizes && prizes.length) {
            newPrizes = await mergeLuckyData(batchIds, values, prizes, mediasContext);
          }
          // if(false){
          //Promise update main content.
          allPromise.push(updateLuckyWheelMainContent(values, rangeBatchs));

          // Promise save new prizes.
          allPromise = allPromise.concat(createNewPrizesLuckyWheel(newPrizes));

          //Promise save name of the prize
          allPromise = allPromise.concat(updateLuckyPrizeName());

          //Promise edit media of the prize
          allPromise = allPromise.concat(updateLuckyPrizeMedia(mediasHandle));
          // }

          //Promise add new batchs
          allPromise = allPromise.concat(createNewBatchsAndRemoveBatch(rangeBatchs));

          Promise.all(allPromise.map(item => item)).then(res => {
            history.push('/lucky-wheels');
            ToastSuccess({ message: 'Updated', description: 'Lucky wheel updated success' });
          })
            .catch(err => {
              console.log('err', err);
              ToastError({ message: 'Error', description: err.message });
              setLoading(false);
            });

        } else {
          setLoading(false);
        }
      });
    } catch (err) {
      setLoading(false);
      ToastError({ message: 'Error', description: '' });
    }
  };

  useEffect(() => {
    return () => {
      context.onUpdateLuckyWheelData([], CLEAR_MEDIA_LUCKY_WHEEL);
      timerPrizeName.map(item => {
        clearTimeout(item);
      });
    };
  }, []);

  return <Styled.Container>
    {
      luckyWheel &&
      (
        <Content className="content lucky-wheel fwBold">
          <Breadcrumb>
            <Breadcrumb.Item> Lucky Wheel List</Breadcrumb.Item>
            <Breadcrumb.Item><strong>{luckyWheel.title}</strong> </Breadcrumb.Item>
          </Breadcrumb>
          <br/>
          <Row>
            <Col span={24}>
              <Form onSubmit={handleSubmit}>
                <Row gutter={10}>
                  <Col span={12}>
                    <Form.Item label="Title" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                      {getFieldDecorator('title', {
                        initialValue: luckyWheel.title,
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input title!',
                          },
                        ],
                      })(<Input/>)}
                    </Form.Item>
                    <Form.Item label="Title En" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                      {getFieldDecorator('titleEn', {
                        initialValue: luckyWheel.titleEn,
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input title!',
                          },
                        ],
                      })(<Input/>)}
                    </Form.Item>
                    <Form.Item label="Date Active" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                      {getFieldDecorator('dateActive', rangeConfig)(
                        <RangePicker
                          showTime={{ format: 'HH:mm' }}
                          format="YYYY-MM-DD HH:mm"
                          onChange={onChangeCampaignRange} disabledDate={disableDate} className="w100"/>,
                      )}
                    </Form.Item>
                    <Form.Item label="Number of batch" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                      {getFieldDecorator('numberBatch', {
                        initialValue: numberBatch.toString(),
                        rules: [
                          {
                            min: 1,
                            required: true,
                            whitespace: true,
                            message: 'Please input number of batch!',
                          },
                        ],
                      })(<Input type="number"
                                min={1}
                                disabled={!canChangeStartCampaign}
                                onChange={onChangeNumberBatch}
                      />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Active date for each batch" labelCol={{ span: 12 }} wrapperCol={{ span: 12 }}>
                      {renderNumberBatch()}
                    </Form.Item>
                  </Col>
                </Row>

                <strong><h3>Prize</h3></strong>

                {/*1*/}
                <div className="prize-section">
                  <Row gutter={5}>
                    <Col span={10} className="d-flex justify-content-between">
                      <Form.Item className="w40">
                        {getFieldDecorator(PrizeTier.DIAMOND + '_vi_title', {
                          initialValue: (namePrizes[PrizeTier.DIAMOND] && namePrizes[PrizeTier.DIAMOND].name)||'Giải nhất',
                          rules: [
                            {
                              max: 50,
                              message: 'Prize title must not exceed 50 characters',
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: 'This field is required!',
                            },
                          ],
                        })(<Input  onChange={(event) => onChangePrizeName(event, PrizeTier.DIAMOND, lang.VI)}/>)}
                      </Form.Item>
                      <Form.Item className="w40">
                        {getFieldDecorator(PrizeTier.DIAMOND + '_en_title', {
                          initialValue: (namePrizes[PrizeTier.DIAMOND] && namePrizes[PrizeTier.DIAMOND].nameEn) || 'First prize',
                          rules: [
                            {
                              max: 50,
                              message: 'Prize title must not exceed 50 characters',
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: 'This field is required!',
                            },
                          ],
                        })(<Input placeholder="Prize english name "
                                  onChange={(event) => onChangePrizeName(event, PrizeTier.DIAMOND, lang.EN)}/>)}
                      </Form.Item>

                    </Col>
                    <Col span={2}>
                      <Button type="default" className="pd0-10" onClick={() => openModalAdd(PrizeTier.DIAMOND)}>
                        <PlusOutlined className="iconPlus"/>
                      </Button>
                    </Col>
                  </Row>
                  {
                    renderPrizeType(PrizeTier.DIAMOND)
                  }
                  {
                    renderPrizeSaved(PrizeTier.DIAMOND)
                  }
                </div>

                {/*2*/}
                <div className="prize-section">
                  <Row gutter={5}>
                    <Col span={10} className="d-flex justify-content-between">
                      <Form.Item className="w40">
                        {getFieldDecorator(PrizeTier.GOLD + '_vi_title', {
                          initialValue: (namePrizes[PrizeTier.GOLD] && namePrizes[PrizeTier.GOLD].name) || 'Giải nhì',
                          rules: [
                            {
                              max: 50,
                              message: 'Prize title must not exceed 50 characters',
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: 'This field is required!',
                            },
                          ],
                        })(<Input placeholder="Prize english name "
                                  onChange={(event) => onChangePrizeName(event, PrizeTier.GOLD, lang.VI)}/>)}
                      </Form.Item>
                      <Form.Item className="w40">
                        {getFieldDecorator(PrizeTier.GOLD + '_en_title', {
                          initialValue: (namePrizes[PrizeTier.GOLD] && namePrizes[PrizeTier.GOLD].nameEn) || 'Second prize',
                          rules: [
                            {
                              max: 50,
                              message: 'Prize title must not exceed 50 characters',
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: 'This field is required!',
                            },
                          ],
                        })(<Input placeholder="Prize english name "
                                  onChange={(event) => onChangePrizeName(event, PrizeTier.GOLD, lang.EN)}/>)}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Button type="default" className="pd0-10" onClick={() => openModalAdd(PrizeTier.GOLD)}>
                        <PlusOutlined className="iconPlus"/>
                      </Button>
                    </Col>
                  </Row>
                  {
                    renderPrizeType(PrizeTier.GOLD)
                  }
                  {
                    renderPrizeSaved(PrizeTier.GOLD)
                  }
                </div>

                {/*3*/}
                <div className="prize-section">
                  <Row gutter={5}>
                    <Col span={10} className="d-flex justify-content-between">
                      <Form.Item className="w40">
                        {getFieldDecorator(PrizeTier.SILVER + '_vi_title', {
                          initialValue: (namePrizes[PrizeTier.SILVER] && namePrizes[PrizeTier.SILVER].name) || 'Giải ba',
                          rules: [
                            {
                              max: 50,
                              message: 'Prize title must not exceed 50 characters',
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: 'This field is required!',
                            },
                          ],
                        })(<Input onChange={(event) => onChangePrizeName(event, PrizeTier.SILVER, lang.VI)}/>)}
                      </Form.Item>
                      <Form.Item className="w40">
                        {getFieldDecorator(PrizeTier.SILVER + '_en_title', {
                          initialValue: (namePrizes[PrizeTier.SILVER] && namePrizes[PrizeTier.SILVER].nameEn) || 'Third prize',
                          rules: [
                            {
                              max: 50,
                              message: 'Prize title must not exceed 50 characters',
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: 'This field is required!',
                            },
                          ],
                        })(<Input placeholder="Prize english name "
                                  onChange={(event) => onChangePrizeName(event, PrizeTier.SILVER, lang.EN)}/>)}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Button type="default" className="pd0-10" onClick={() => openModalAdd(PrizeTier.SILVER)}>
                        <PlusOutlined className="iconPlus"/>
                      </Button>
                    </Col>
                  </Row>
                  {
                    renderPrizeType(PrizeTier.SILVER)
                  }
                  {
                    renderPrizeSaved(PrizeTier.SILVER)
                  }
                </div>

                {/*4*/}
                <div className="prize-section">
                  <Row gutter={5}>
                    <Col span={10} className="d-flex justify-content-between">
                      <Form.Item className="w40">
                        {getFieldDecorator(PrizeTier.BRONZE + '_vi_title', {
                          initialValue: (namePrizes[PrizeTier.BRONZE] && namePrizes[PrizeTier.BRONZE].name) || 'Giải khuyến khích',
                          rules: [
                            {
                              max: 50,
                              message: 'Prize title must not exceed 50 characters',
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: 'This field is required!',
                            },
                          ],
                        })(<Input onChange={(event) => onChangePrizeName(event, PrizeTier.BRONZE, lang.VI)}/>)}
                      </Form.Item>
                      <Form.Item className="w40">
                        {getFieldDecorator(PrizeTier.BRONZE + '_en_title', {
                          initialValue: (namePrizes[PrizeTier.BRONZE] && namePrizes[PrizeTier.BRONZE].nameEn) || 'Fourth prize',
                          rules: [
                            {
                              max: 50,
                              message: 'Prize title must not exceed 50 characters',
                            },
                            {
                              required: true,
                              whitespace: true,
                              message: 'This field is required!',
                            },
                          ],
                        })(<Input placeholder="Prize english name "
                                  onChange={(event) => onChangePrizeName(event, PrizeTier.BRONZE, lang.EN)}/>)}
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Button type="default" className="pd0-10" onClick={() => openModalAdd(PrizeTier.BRONZE)}>
                        <PlusOutlined className="iconPlus"/>
                      </Button>
                    </Col>
                  </Row>
                  {
                    renderPrizeType(PrizeTier.BRONZE)
                  }
                  {
                    renderPrizeSaved(PrizeTier.BRONZE)
                  }
                </div>
                <Row>
                  <Col offset={16} span={2}>
                    <Link to={'/lucky-wheels/'}>
                      <Form.Item {...buttonItemLayout} >
                        <Button type="primary" className="as-danger-btn p015px" htmlType="submit">Cancel</Button>
                      </Form.Item>
                    </Link>

                  </Col>
                  <Col span={4}>
                    <Form.Item {...buttonItemLayout} >
                      <Button type="primary" disabled={loading} className="as-primary-btn p015px"
                              htmlType="submit">Save</Button>
                      {
                        loading && <img src={loadingImage} className="w20" alt=""/>
                      }
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          {
            addPrize &&
            (
              <Modal
                visible={addPrize}
                width={800}
                title="Add prize"
                onOk={toggleModalAddPrize}
                onCancel={toggleModalAddPrize}
                footer=""
              >
                <AddPrize type={typeAdd}
                          campaignRange={campaignRange}
                          numberBatch={numberBatch}
                          onClose={toggleModalAddPrize}
                          onHandleAddPrize={onHandleAddPrize}
                />
              </Modal>
            )
          }
        </Content>
      )
    }
  </Styled.Container>;
};

const LuckyWheelDetail = Form.create()(LuckyWheelDetailImpl);
export default LuckyWheelDetail;
