import React, { Fragment, useRef, useState } from 'react';
import { Breadcrumb, Button, Col, DatePicker, Form, Input, Layout, Row, Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { CREATE_VOUCHER } from '../../graphql/voucher/voucher';
import { VoucherStatus, VoucherType } from '../../graphql/types';
import { useMutation } from '@apollo/react-hooks';
import { ToastError, ToastSuccess } from '../../components/Toast';
import UploadVoucher from './compoments/Uploads';
import history from '../../history';

const moment = require('moment');

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

interface defaultData {
  _id?: any,
  image?: string,
  title?: string,
  titleEn?: string,
  highlight?: string,
  highlightEn?: string,
  type?: string,
  description?: string,
  descriptionEn?: string,
  termAndCondition?: string,
  termAndConditionEn?: string,
  contact?: string,
  voucherId?: string,
  point?: string,
  startDate?: Date,
  endDate?: Date,
  phoneNumber?: string,
  email?: string,
  address?: string,
}

interface IEditProps extends FormComponentProps {
  defaultData?: defaultData
}

const VoucherImpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(VoucherType.REFERRAL);
  const [createVoucher, { data }] = useMutation(CREATE_VOUCHER, {
    refetchQueries: ['cmsVouchers'],
  });


  const refs = useRef(null);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 6 },
  };

  const onChangeType = (value) => {
    setType(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof refs.current.upload === 'function') {
          refs.current.upload();
        }
      }else{
        setLoading(false);
      }
    });
  };

  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
  };

  const afterUpload = (mediaIds: []) => {
    if(type === VoucherType.REFERRAL && !mediaIds.length ){
      setLoading(false);
      ToastError({
        message: "Image's required",
        description:'Please select image for this voucher type'
      });
      return;
    }

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (values.title.length > 100 || values.titleEn.length > 100) {
          setLoading(false);
          ToastError({
            message: 'Create voucher error.',
            description: `The maximum length of the title is 100 characters.`,
          });
        } else {
          let data:any = {};
          switch (type) {
              case VoucherType.REFERRAL:{
                data = {
                  title: values.title,
                  titleEn: values.titleEn,
                  mediaIds: mediaIds.slice(0,1),
                  status: VoucherStatus.DISABLED,
                  type,
                  startDate: (new Date(values.dateActive[0])).getTime(),
                  endDate: (new Date(values.dateActive[1])).getTime(),
                  referralTurns:parseInt(values.referralTurns)
                };
                break;
              }
            default:{
              data = {
                title: values.title,
                titleEn: values.titleEn,
                mediaIds: mediaIds,
                highlight: values.highlight,
                highlightEn: values.highlightEn,
                termAndCondition: values.termAndCondition,
                termAndConditionEn: values.termAndConditionEn,
                contact: { phoneNumber: values.phoneNumber, email: values.email, address: values.address },
                description: values.description,
                descriptionEn: values.descriptionEn,
                type,
                pointSpend: type === VoucherType.POINT_EXCHANGE ? parseFloat(values.pointSpend) : 0,
                startDate: (new Date(values.dateActive[0])).getTime(),
                endDate: (new Date(values.dateActive[1])).getTime(),
              }
            }
          }
          createVoucher({
            variables: data,
          })
            .then((res) => {
              setLoading(false)
            })
            .catch((e) => {
              console.log("e==>",e);
              ToastError({ message: 'Error!', description: 'Please try again later' });
              setLoading(false);
            });
        }
      } else {
        setLoading(false);
      }
    });
  };

  if (data) {
    switch (type) {
      case VoucherType.REFERRAL:
        history.push('/vouchers/referrals'); break;
      default:
        history.push('/vouchers');
    }
    ToastSuccess({ message: 'Created', description: 'Voucher create success' });
  }

  const disableDate = (current) => {
    return current && current <= moment().add(-1, 'days').endOf('day');
  };

  const rangeDateType = () => {
    switch (type) {
      case VoucherType.REFERRAL: {
        return <RangePicker disabledDate={ disableDate }
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD HH:mm" />
      }
      default :return <RangePicker disabledDate={ disableDate } />
    }
  };


  return <Styled.Container>
    <Content className="content voucher-section">

      <Breadcrumb>
        <Breadcrumb.Item>Voucher</Breadcrumb.Item>
        <Breadcrumb.Item>Create</Breadcrumb.Item>
      </Breadcrumb>

      <Row type="flex" justify="center">
        <Col span={ 12 }>
          <Form { ...formItemLayout } onSubmit={ handleSubmit }>
            <Row type="flex">
              <Col span={ 18 } offset={ 6 }>
                <UploadVoucher
                  ref={ refs }
                  numberImage={type === VoucherType.REFERRAL ? 1 : 5}
                  onUploaded={ (data: any) => afterUpload(data) } />
              </Col>
            </Row>

            <Form.Item label="Title">
              { getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    whitespace:true,
                    message: 'Please input title!',

                  },
                ],
              })(<Input />) }
            </Form.Item>

            <Form.Item label="TitleEn">
              { getFieldDecorator('titleEn', {
                rules: [
                  {
                    required: true,
                    whitespace:true,
                    message: 'Please input titleEn!',
                  },
                ],
              })(<Input />) }
            </Form.Item>

            <Form.Item label="Type">
              { getFieldDecorator('type', {
                initialValue: type,
                rules: [
                  {
                    required: true,
                    message: 'Please select one!',
                  },
                ],
              })(
                <Select onChange={ onChangeType }  >
                  <Option value={ VoucherType.POINT_EXCHANGE }>Point transfer</Option>
                  <Option value={ VoucherType.REFERRAL }>Referral game</Option>
                  <Option value={ VoucherType.GAME }>Game</Option>
                </Select>
              ) }
            </Form.Item>

            {
              type !== VoucherType.REFERRAL &&
              (
                <Fragment>
                  <Form.Item label="Highlight">
                    { getFieldDecorator('highlight', {
                      initialValue: props.defaultData && props.defaultData.highlight || '',
                    })(<Input />) }
                  </Form.Item>

                  <Form.Item label="HighlightEn">
                    { getFieldDecorator('highlightEn', {
                      initialValue: props.defaultData && props.defaultData.highlightEn || '',
                    })(<Input />) }
                  </Form.Item>

                  <Form.Item label="Term and conditions">
                    { getFieldDecorator('termAndCondition', {
                      initialValue: props.defaultData && props.defaultData.termAndCondition || '',
                    })(<Input />) }
                  </Form.Item>

                  <Form.Item label="Term and conditions En">
                    { getFieldDecorator('termAndConditionEn', {
                      initialValue: props.defaultData && props.defaultData.termAndConditionEn || '',
                    })(<Input />) }
                  </Form.Item>

                  <Form.Item label="Contact">
                    <Input.Group size="large">
                      <Row gutter={ 1 }>
                        <Col span={ 6 }>
                          <Form.Item>
                            { getFieldDecorator('phoneNumber', {
                              initialValue: props.defaultData && props.defaultData.phoneNumber || '',
                            })(<Input placeholder="Phone number" />) }
                          </Form.Item>
                        </Col>
                        <Col span={ 8 }>
                          <Form.Item>
                            { getFieldDecorator('email', {
                              initialValue: props.defaultData && props.defaultData.email || '',
                            })(<Input placeholder="Email" />) }
                          </Form.Item>
                        </Col>
                        <Col span={ 10 }>
                          <Form.Item>
                            { getFieldDecorator('address', {
                              initialValue: props.defaultData && props.defaultData.address || '',
                            })(<Input placeholder="Address" />) }
                          </Form.Item>
                        </Col>
                      </Row>
                    </Input.Group>
                  </Form.Item>

                  <Form.Item label="Description">
                    { getFieldDecorator('description', {
                      rules: [
                        {
                          required: true,
                          whitespace:true,
                          message: 'Please input description!',
                        },
                      ],
                    })(<Input />) }
                  </Form.Item>

                  <Form.Item label="DescriptionEn">
                    { getFieldDecorator('descriptionEn', {
                      rules: [
                        {
                          required: true,
                          whitespace:true,
                          message: 'Please input descriptionEn!',
                        },
                      ],
                    })(<Input />) }
                  </Form.Item>

                  <Form.Item label="Point Spend">
                    { getFieldDecorator('pointSpend', {
                      rules: [
                        {
                          required: type === VoucherType.POINT_EXCHANGE,
                          message: 'Please input point Spend!',
                        },
                      ],
                    })(<Input disabled={ type !== VoucherType.POINT_EXCHANGE } type="number" step="0.1" min={ 0 } />) }
                  </Form.Item>
                </Fragment>
              )
            }

            <Form.Item label="Date Active">
              {
                getFieldDecorator('dateActive', rangeConfig)(rangeDateType())
              }
            </Form.Item>

            {
              type === VoucherType.REFERRAL &&
              (
                <Form.Item label="Rule" className="referral-rule">
                  <Row gutter={ 1 }>
                    <Col span={8}>
                      <input className="referral-input" type="checkbox" id="referral-rule" defaultChecked={true} />
                      <label className="referral-label"  htmlFor="referral-rule">
                        Block x turn
                      </label>
                    </Col>
                    <Col span={16} className="d-flex justify-content-around" >
                      <label htmlFor="referralTurnsIds"> Block</label>

                      { getFieldDecorator('referralTurns', {
                        rules: [
                          {
                            required: type === VoucherType.REFERRAL,
                            message: 'Please input referralTurns!',
                          },
                        ],
                      })(<Input id="referralTurnsIds" className="referral-turn" type="number" step="1" min={ 0 }  placeholder="block"/>) }
                    </Col>
                  </Row>
                </Form.Item>
              )
            }


            <Form.Item { ...buttonItemLayout } >
              <Button type="primary" disabled={ loading } htmlType="submit">Submit</Button>
              { loading && (<Spin indicator={ <LoadingOutlined style={ { fontSize: 24 } } spin /> } />) }
            </Form.Item>
          </Form>
        </Col>
      </Row>

    </Content>
  </Styled.Container>;
};

const VoucherCreate = Form.create()(VoucherImpl);
export default VoucherCreate;
