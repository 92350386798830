import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Input, Layout, Modal, Pagination, Row, Select, Skeleton, Table } from 'antd';

import { FormComponentProps } from 'antd/lib/form';
import Styled from './indexStyled';
import { GET_PAGE_VERIFY, SET_VERIFY_STATUS } from '../../graphql/pageVerify/pageVerify';
import {
  GetPageVerify,
  GetPageVerifyVariables,
  CmsSetVerifyStatusVariables,
  CmsSetVerifyStatus,
  VerifyFor,
  VerifyStatus,
  PageType,
} from '../../graphql/types';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ToastError, ToastSuccess } from '../../components/Toast';
const { dateByFormat } = require('../../helpers/date');

const { Content } = Layout;
const { Option } = Select;

interface IEditProps extends FormComponentProps {
  match?: any;
}

interface IEditData extends CmsSetVerifyStatusVariables {
  images?: [
    {
      _id: string;
      uri: string;
    }?,
  ];
  owner?: any;
  description?: any;
  targetType?: PageType;
}

const VerifyPageImpl = (props: IEditProps) => {
  const { getFieldDecorator } = props.form;
  const { params } = props.match;
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const statuses = ['ALL', VerifyStatus.PENDING, VerifyStatus.VERIFIED, VerifyStatus.REJECTED, VerifyStatus.IGNORED];

  const [pageParams, setPageParams] = useState<GetPageVerifyVariables>({
    limit: 10,
    offset: 1,
    q: '',
    status: null,
    type: VerifyFor.PAGE,
  });
  const [pages, setPages] = useState([]);
  const [counter, setCounter] = useState(0);

  const [editData, setEditData] = useState<IEditData>({
    _id: null,
    status: null,
    images: [],
    owner: null,
  });

  const [srtVerifyStatus, { data: setStatusResult }] = useMutation<CmsSetVerifyStatus, CmsSetVerifyStatusVariables>(
    SET_VERIFY_STATUS,
    {
      refetchQueries: ['cmsGetVerifies'],
    },
  );

  const getPagesVerify = () => {
    let variables;
    if (params && params.type) {
      variables = {
        limit: pageParams.limit,
        offset: pageParams.offset,
        q: pageParams.q,
        status: params.type,
        type: pageParams.type,
      };
    } else {
      variables = {
        limit: pageParams.limit,
        offset: pageParams.offset,
        q: pageParams.q,
        status: pageParams.status,
        type: pageParams.type,
      };
    }

    return useQuery<GetPageVerify, GetPageVerifyVariables>(GET_PAGE_VERIFY, {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: variables,
    });
  };

  let { data, loading, refetch } = getPagesVerify();

  useEffect(
    function() {
      setPageParams({
        ...pageParams,
        status: (params.type && params.type.toUpperCase()) || null,
      });
    },
    [params],
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'targetName',
      sorter: false,
    },
    {
      title: 'Phone',
      dataIndex: 'owner.phoneNumber',
    },
    {
      title: 'Email',
      dataIndex: 'owner.email',
      sorter: false,
    },
    {
      title: 'Created by',
      dataIndex: 'owner.fullName',
      sorter: false,
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      sorter: false,
      render: createdAt => {
        return <>{createdAt && dateByFormat(createdAt)}</>;
      },
    },
    {
      title: 'Reject Reason',
      dataIndex: 'rejectReason',
    },
    {
      title: 'Status',
      render: row => {
        let type: any = 'default';
        if (row.status == VerifyStatus.VERIFIED) type = 'primary';
        if (row.status == VerifyStatus.REJECTED) type = 'danger';
        return (
          <Button className="btn-rectangle" type={type} size="small" onClick={e => setEditStatus(row)}>
            {row.status}
          </Button>
        );
      },
    },
  ];

  const changeStatus = e => {
    setPageParams({
      ...pageParams,
      status: e != 'ALL' ? e : null,
    });
  };

  const search = () => {
    setPageParams({
      ...pageParams,
      q: props.form.getFieldValue('q'),
    });
  };

  const changeEditStatus = e => {
    setEditData({
      ...editData,
      status: e,
      reason: e !== VerifyStatus.REJECTED ? '' : editData.reason,
    });
  };

  const changeReason = e => {
    setEditData({
      ...editData,
      reason: e.target.value,
    });
  };

  const handleOk = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        setShowModal(false);
        srtVerifyStatus({
          variables: {
            _id: editData._id,
            status: editData.status,
            reason: editData.reason,
          },
        })
          .catch(e => {
            ToastError({ message: 'Error', description: e.toString() });
          })
          .then(res => {
            refetch();
            data.cmsGetVerifies.items.map((row, i) => {
              if (row._id == editData._id) {
                data.cmsGetVerifies.items[i].status = editData.status;
                data.cmsGetVerifies.items[i].rejectReason = editData.reason;
                setEditData({
                  _id: null,
                  status: null,
                });
                ToastSuccess({ message: 'Updated', description: 'Status admin update success' });
                return;
              }
            });
          });
        if (setStatusResult) {
          // console.log('ok');
        }
      }
    });
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const setEditStatus = data => {
    const photoIdentityMedias = data.photoIdentityMedias || [];
    const businessLicenseMedias = data.businessLicenseMedias || [];
    const photosTaken = data.photosTaken || [];
    setEditData({
      _id: data._id,
      status: data.status,
      images: photoIdentityMedias.concat(businessLicenseMedias.concat(photosTaken)),
      owner: data.owner,
      reason: data.rejectReason,
      description: data.description,
      targetType: data.targetType,
    });
    setShowModal(true);
  };

  useEffect(() => {
    if (loading) setLoading(true);
    if (!loading) setLoading(false);
  }, [loading]);

  useEffect(() => {
    if (data && data.cmsGetVerifies.items && data.cmsGetVerifies.items.length) {
      setPages(data.cmsGetVerifies.items);
      setCounter(data.cmsGetVerifies.counter);
    } else {
      setPages([]);
      setCounter(0);
    }
  }, [data]);

  const onChangePage = page => {
    setPageParams({ ...pageParams, offset: page });
  };

  const onShowSizeChange = (current, pageSize) => {
    setPageParams({ ...pageParams, limit: pageSize });
  };

  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Page verify</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="mt-10 mb-10" gutter={16}>
          <Col span={6}>
            <Form.Item label="Search">
              {getFieldDecorator('q', {
                initialValue: pageParams.q,
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Page verify Status">
              {getFieldDecorator('status', {
                initialValue: pageParams.status,
              })(
                <Select style={{ width: '100%' }} placeholder="Select status to filter" onChange={e => changeStatus(e)}>
                  {statuses.map((r, i) => {
                    return (
                      <Option key={i} value={r}>
                        {r}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="&nbsp;">
              <Button type={'primary'} onClick={() => search()}>
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <p style={{ marginTop: 15 }}>
          <strong>Total: {(data && data.cmsGetVerifies && data.cmsGetVerifies.counter) || 0}</strong>
        </p>

        {isLoading ? (
          <Skeleton loading={isLoading} paragraph={{ rows: 5 }}></Skeleton>
        ) : (
          <Row style={{ backgroundColor: '#fff' }}>
            <Table rowKey="_id" columns={columns} dataSource={pages} pagination={false} />

            <Pagination
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
              defaultCurrent={pageParams.offset}
              total={counter}
              defaultPageSize={pageParams.limit}
              onChange={onChangePage}
              style={{
                marginTop: 20,
                right: 0,
              }}
            />
          </Row>
        )}
      </Content>

      <Modal
        title="Change verify status"
        width={1024}
        visible={showModal}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
      >
        {showModal && (
          <Styled.Container>
            <Form>
              <Row>
                <Col span={24}>
                  <p>
                    Full name: <strong>{editData.owner.fullName}</strong>
                  </p>
                  <p>
                    Phone: <strong>{editData.owner.phoneNumber}</strong>
                  </p>
                  <p>
                    {editData.targetType === PageType.BUSINESS_OR_BRAND ? 'Email' : 'CMND'}:{' '}
                    <strong>{editData.description}</strong>
                  </p>
                </Col>
              </Row>
              <Form.Item label="">
                <Row>
                  {editData.images.map(function(r, i) {
                    return (
                      r && (
                        <Col span={6} key={i}>
                          <div className="thumb-modal" onClick={() => setPreviewUrl(r.uri)}>
                            <img src={r.uri} alt="" />
                          </div>
                        </Col>
                      )
                    );
                  })}
                </Row>
              </Form.Item>
              <Form.Item label="">
                {getFieldDecorator('statusUpdate', {
                  initialValue: editData.status,
                })(
                  <Select placeholder="Select a option and change input text above" onChange={e => changeEditStatus(e)}>
                    {statuses.map((r, i) => {
                      if (r != 'ALL')
                        return (
                          <Option disabled={r == editData.status} key={i} value={r}>
                            {r}
                          </Option>
                        );
                      return null;
                    })}
                  </Select>,
                )}
              </Form.Item>
              {editData.status === VerifyStatus.REJECTED && (
                <Form.Item label="">
                  {getFieldDecorator('reason', {
                    initialValue: editData.reason,
                    rules: [
                      {
                        required: true,
                        message: 'Please enter reason',
                      },
                    ],
                  })(<Input type="text" placeholder="Enter your reject reason" onChange={e => changeReason(e)} />)}
                </Form.Item>
              )}
            </Form>

            <Modal visible={previewUrl != ''} footer={null} onCancel={() => setPreviewUrl('')}>
              <img alt="" style={{ width: '100%' }} src={previewUrl} />
            </Modal>
          </Styled.Container>
        )}
      </Modal>
    </Styled.Container>
  );
};

const VerifyPage = Form.create()(VerifyPageImpl);
export default VerifyPage;
