import React from 'react';
import { Layout, Row, Col, Table } from 'antd';

import Styled from './indexStyled';
import { useQuery } from '@apollo/react-hooks';
import { CMS_GET_POSTS_BY_CREATOR } from '../../graphql/report/report';
import { dateByFormat } from '../../helpers/date';

const { Content } = Layout;

interface IEditProps {
  creator: any;
}

const CreatorDetail = (props: IEditProps) => {
  const { creator } = props;

  const getPostsByCreator = () => {
    return useQuery(CMS_GET_POSTS_BY_CREATOR, {
      fetchPolicy: 'network-only',
      variables: {
        targetId: creator._id,
        limit: 3,
        isPage: creator.isPage,
      },
    });
  };

  const { data } = getPostsByCreator();

  const columns = [
    {
      title: 'Title',
      render: row => {
        return (
          <div style={{ maxWidth: '200px' }}>
            <strong>{row.title}</strong>
            <p>
              {row.content && row.content.substr(0, 150)}
              {row.content && row.content.length > 150 && '...'}
            </p>
          </div>
        );
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Total like',
      dataIndex: 'totalLikes',
    },
    {
      title: 'Total comment',
      dataIndex: 'totalComments',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      render: row => {
        return <div>{row && dateByFormat(row)}</div>;
      },
    },
  ];

  return (
    <Styled.Container>
      <Content>
        <Row>
          <Col span={4}>
            <div className="thumb thumbCol">
              <img
                className="image"
                src={
                  creator.isPage
                    ? creator.logo && creator.logo.uri
                      ? creator.logo.uri
                      : ''
                    : creator.avatar && creator.avatar.uri
                    ? creator.avatar.uri
                    : ''
                }
                alt=""
              />
            </div>
          </Col>

          <Col span={20}>
            <div style={{ marginLeft: 10 }}>
              <p>
                <strong>{creator.isPage ? creator.name : creator.fullName}</strong>
              </p>

              <p>Email: {creator.email || (creator.owner && creator.owner.email)}</p>
              <p>Phone number: {creator.phoneNumber || (creator.owner && creator.owner.phoneNumber)}</p>
            </div>
          </Col>
        </Row>

        <Row>
          <p>Status: {creator.termStatus}</p>

          {!creator.isPage && (
            <p>
              {process.env.REACT_APP_PROJECT_NAME} point: {creator.point}
            </p>
          )}
        </Row>

        {creator.isPage && (
          <Row>
            <p>
              <strong>Admins:</strong>
            </p>

            <Row style={{ marginBottom: 10 }}>
              <Col span={4}>
                <div className="thumb">
                  <img
                    className="image"
                    src={(creator.owner && creator.owner.avatar && creator.owner.avatar.uri) || ''}
                    alt=""
                  />
                </div>
              </Col>

              <Col span={20}>
                <div style={{ marginLeft: 10 }}>
                  <p>{creator.owner && creator.owner.fullName}</p>
                  <p>Phone number: {creator.owner && creator.owner.phoneNumber}</p>
                </div>
              </Col>
            </Row>

            {creator.admins.map(val => {
              return (
                <Row style={{ marginBottom: 10 }}>
                  <Col span={4}>
                    <div className="thumb">
                      <img src={(val.avatar && val.avatar.uri) || ''} alt="" />
                    </div>
                  </Col>

                  <Col span={20}>
                    <div style={{ marginLeft: 10 }}>
                      <p>{val.fullName}</p>
                      <p>Phone number: {val.phoneNumber}</p>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Row>
        )}

        {data && data.cmsGetPostsByCreator && data.cmsGetPostsByCreator.length ? (
          <Row>
            <Table rowKey="_id" columns={columns} dataSource={data.cmsGetPostsByCreator} pagination={false} />
          </Row>
        ) : null}
      </Content>
    </Styled.Container>
  );
};
export default CreatorDetail;
