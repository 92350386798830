import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Layout, Modal, Pagination, Table, Row, Popconfirm } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import Styled from '../../UserPage/indexStyled';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  cmsLuckyWheelInstructionTypes,
  cmsListLuckyWheelInstruction,
  cmsListLuckyWheelInstructionVariables,
  deleteLuckyWheelInstruction,
  deleteLuckyWheelInstructionVariables,

} from '../../../graphql/types';
import {
  CMS_GET_LUCKY_WHEEL_INSTRUCTION_TYPE,
  CMS_GET_LIST_LUCKY_INSTRUCTION,
  CMS_DELETE_LUCK_WHEEL_INSTRUCTION,
} from '../../../graphql/luckyWheel';

import AddInstruction from './addInstruction';
import EditInstruction from './editInstruction';
import { ToastError, ToastSuccess } from '../../../components/Toast';

const { Content } = Layout;


interface IEditProps extends FormComponentProps {
  match?: any;
}


const InstructionImpl = (props: IEditProps) => {

  const [instructionTypes, setInstructionTypes] = useState<any>();
  const [instructions, setInstructions] = useState<any>();

  const [modalAddInstruction, setModalAddInstruction] = useState(false);
  const [modalEditInstruction, setModalEditInstruction] = useState(false);
  const [instructionEdit, setInstructionEdit] = useState(null);
  const [total, setTotal] = useState(0);
  const [filterParams, setFilterParams] = useState({
    limit: 10,
    page: 1,
  });

  const [delInstruction] = useMutation<deleteLuckyWheelInstruction, deleteLuckyWheelInstructionVariables>(CMS_DELETE_LUCK_WHEEL_INSTRUCTION, {
    refetchQueries: ['cmsListLuckyWheelInstruction']
  });

  const { data: instructionTypesData } = useQuery<cmsLuckyWheelInstructionTypes>(CMS_GET_LUCKY_WHEEL_INSTRUCTION_TYPE);
  const { data: instructionsData, refetch } = useQuery<cmsListLuckyWheelInstruction, cmsListLuckyWheelInstructionVariables>(CMS_GET_LIST_LUCKY_INSTRUCTION, {
    variables: {
      limit: filterParams.limit,
      page: filterParams.page,
    },
  });

  const columns = [
    {
      title: 'No.',
      key: 'index',
      width: '5%',
      render: (text, record, index) => index+1,
    },
    {
      title: 'title',
      // dataIndex: 'title',
      render: (row) => {
        return <><>{ 'vi: ' + (row && row.title) || '' }</><br /> <> { 'en: ' + (row && row.titleEn) || '' } </> </>;
      },
    },

    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Turns',
      dataIndex: 'turns',
    },
    {
      title: 'Description',
      render: (row) => {
        return <><>{ 'vi: ' + (row && row.description) || '' }</><br /> <> { 'en: ' + (row && row.descriptionEn) || '' } </> </>;
      },
      // dataIndex: 'description',
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: row => {
        return (
          <div style={ {
            display: 'flex',
            justifyContent: 'space-around',
          } }>
            <Button type="primary" icon="edit" size="small" onClick={ e => onEditInstruction(row) } />
            <Popconfirm title="Are you sure？" onConfirm={ () => onDeleteInstruction(row._id) }
            >
              <Button type="primary" icon="delete" size="small" />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onChangePage = (page) => {
    setFilterParams({ ...filterParams, page });
  };
  const toggleModalAddInstruction = () => {
    setModalAddInstruction(!modalAddInstruction);
  };
  const toggleModalEditInstruction = () => {
    setModalEditInstruction(!modalEditInstruction);
  };
  const onEditInstruction = (instructionEdit) => {
    setInstructionEdit(instructionEdit);
    toggleModalEditInstruction();
  }
  const onDeleteInstruction = (_id) => {
    delInstruction({
      variables: { _id }
    }).then(res => {
      ToastSuccess({ message: 'Success', description: 'Instruction is deleted.' });
      refetch();
    }).catch(err => {
      ToastError({
        message: 'Error',
        description: "Please try later",
      });
    })
  };


  useEffect(() => {
    if (instructionTypesData && instructionTypesData.cmsLuckyWheelInstructionTypes && instructionTypesData.cmsLuckyWheelInstructionTypes) {
      setInstructionTypes(instructionTypesData.cmsLuckyWheelInstructionTypes);
    }
  }, [instructionTypesData]);

  useEffect(() => {
    if (instructionsData && instructionsData.cmsListLuckyWheelInstruction && instructionsData.cmsListLuckyWheelInstruction.items) {
      setInstructions(instructionsData.cmsListLuckyWheelInstruction.items);
      setTotal(instructionsData.cmsListLuckyWheelInstruction.counter);
    }
  }, [instructionsData]);


  return (
    <Styled.Container>
      <Content className="content">
        <Breadcrumb>
          <Breadcrumb.Item>Instruction</Breadcrumb.Item>
          <Breadcrumb.Item>List</Breadcrumb.Item>
        </Breadcrumb>

        <Row>
          <Col span={ 24 } style={ { textAlign: 'right' } }>
            <Button type={ 'primary' } onClick={ toggleModalAddInstruction }>Add Instruction</Button>
          </Col>
        </Row>
        <br />

        <p style={{ marginTop: 15 }}>
          <strong>
            Total:{' '}
            {(instructionsData &&
              instructionsData.cmsListLuckyWheelInstruction &&
              instructionsData.cmsListLuckyWheelInstruction.counter) ||
              0}
          </strong>
        </p>

        <Table
          rowKey="_id"
          columns={ columns }
          dataSource={ instructions }
          onChange={ () => {
          } }
          scroll={ { x: 'calc(700px + 50%)', y: 0 } }
          pagination={ false }
        /><br />
        <Pagination defaultCurrent={ filterParams.page } pageSize={ filterParams.limit } total={ total }
          onChange={ onChangePage } />
        {
          modalAddInstruction &&
          (
            <Modal
              visible={ modalAddInstruction }
              width={ 900 }
              title={ 'Add instruction' }
              onOk={ toggleModalAddInstruction }
              onCancel={ toggleModalAddInstruction }
              footer=""
            >
              <AddInstruction
                instructionTypes={ instructionTypes }
                onClose={ toggleModalAddInstruction }
              />
            </Modal>
          )
        }
        {
          modalEditInstruction &&
          (
            <Modal
              className="lucky-instruction-modal"
              visible={ modalEditInstruction }
              width={ 900 }
              title={ 'Edit instruction' }
              onOk={ toggleModalEditInstruction }
              onCancel={ toggleModalEditInstruction }
              footer=""
            >
              <EditInstruction
                instruction={ instructionEdit }
                instructionTypes={ instructionTypes }
                onClose={ toggleModalEditInstruction }
              />
            </Modal>
          )
        }

      </Content>
    </Styled.Container>
  );
};

const Instruction = Form.create()(InstructionImpl);
export default Instruction;
